import { useQuery } from "react-query";
import { getMidtermEvals } from "../components/Api/Getters";

// ...
export const useMidtermsQuery = (token, currentTerm) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		enabled: !!token && !!currentTerm,
	};

	return useQuery(
		["midtermsQuery", token, currentTerm],
		() => getMidtermEvals(token, currentTerm),
		queryOptions
	);
};
