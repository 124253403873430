import * as React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import isAuthenticated from "../../components/Utils/isAuthenticated";
import { useFacultyRank } from "../../Hooks/useFacultyRankQuery";

export default function InstructorSearchAutocomplete(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const { data: facultyRank } = useFacultyRank(token);

	const [options, setOptions] = React.useState([]);
	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = useState("");
	const [openSearch, setOpenSearch] = useState(false);

	useEffect(() => {
		if (facultyRank && facultyRank.length > 0) {
			const unique = [...new Set(facultyRank)];
			unique.forEach((element, index) => {
				const newElement = {
					key: index,
					empid: element.empid,
					name: element.name,
					ccid: element.ccid,
					rank: element.rank,
				};
				unique[index] = newElement;
			});

			setOptions(unique);
		}
	}, [facultyRank, props.currentRow]);

	const handleSubmit = () => {
		if (value === null) {
			alert("Error impersonating instructor. Please try again.");
		} else {
			if (value.empid === null || value.ccid === null || value.name === null) {
				alert("Error impersonating instructor. Please try again.");
			} else {
				sessionStorage.setItem("id", value.empid);
				sessionStorage.setItem("ccid", value.ccid);
				sessionStorage.setItem("name", value.name);
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		}
	};

	return (
		<React.Fragment>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
					width: "100%",
				}}
			>
				<Autocomplete
					onClick={props.onClick}
					value={value}
					onChange={(event, newValue) => {
						event.stopPropagation();
						if (typeof newValue === "string") {
							// timeout to avoid instant validation of the dialog's form.
							setTimeout(() => {
								setOpenSearch(false);
							});
						} else if (newValue && newValue.inputValue) {
							setOpenSearch(false);
						} else {
							setValue(newValue);
							setOpenSearch(false);
						}
					}}
					onInputChange={(event, newInputValue) => {
						setValue(null);
						setInputValue(newInputValue);
						setOpenSearch(newInputValue.length > 2);
					}}
					id="free-solo-dialog-demo"
					options={options}
					getOptionLabel={(option) => {
						if (typeof option === "string") {
							return option;
						}
						if (option.inputValue) {
							return option.inputValue;
						}
						return option.name + " - " + option.empid + " - " + option.ccid;
					}}
					disableClearable
					filterSelectedOptions
					selectOnFocus
					clearOnBlur
					renderOption={(props, option) => (
						<li {...props}>
							{option.name ? option.name : ""}
							{option.empid ? " - " + option.empid + " - " : ""}
							{option.ccid ? option.ccid : ""}
						</li>
					)}
					sx={{ width: "100%", p: 1 }}
					freeSolo
					open={openSearch}
					renderInput={(params) => (
						<TextField {...params} label="Employee ID or CCID" />
					)}
				/>
				<Button variant="contained" sx={{ mb: 1 }} onClick={handleSubmit}>
					Impersonate
				</Button>
			</Box>
		</React.Fragment>
	);
}
