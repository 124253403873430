import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { GridFooterContainer } from "@mui/x-data-grid";

export default function SimilarItemTable(props) {
	const CustomFooter = () => {
		return (
			<GridFooterContainer
				sx={{ display: "flex", justifyContent: "flex-start" }}
			>
				<Box sx={{ position: "absolute", right: "10px" }}></Box>
			</GridFooterContainer>
		);
	};

	const columns = [
		{
			field: "itemno",
			headerName: "Item",
			width: 60,
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "text",
			headerName: "Item Text",
			flex: 1,
			sortable: false,
			disableColumnMenu: true,
		},
	];

	return (
		<div style={{ height: 200, width: "800px" }}>
			<DataGrid
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "none !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},
				}}
				density="compact"
				components={{ Footer: CustomFooter }}
				disableRowSelectionOnClick={true}
				rows={props.rows}
				columns={columns}
				isRowSelectable={() => false}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 5 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.itemno}
			/>
		</div>
	);
}
