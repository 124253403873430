import React, { useEffect, useState } from "react";
import isAuthenticated from "../../../components/Utils/isAuthenticated";
import ClassAdjustTable from "./components/ClassAdjustTable";
import { useTermsQueryFull } from "../../../Hooks/useTermsQuery";
import {
	useClassIdObjectsByDeptAndTerm,
	useAdjustedClassIdObjectsByDeptAndTerm,
} from "../../../Hooks/useClassIdsQuery";
import { useDepartmentsQuery } from "../../../Hooks/useDepartmentsQuery";
import { useMutation, useQueryClient } from "react-query";
import { updateClassAdjust } from "../../../components/Api/Updates";
import dayjs from "dayjs";
import {
	useAllBlockItems,
	useBlockItemsQuery,
	useBlockIdsByDeptQuery,
} from "../../../Hooks/useBlockItemsQuery";
import { useAllItemsQuery } from "../../../Hooks/useAllItemsQuery";
import HelpIcon from "@mui/icons-material/Help";
import InformationalDialog from "./components/InformationalDialog";
import LaunchIcon from "@mui/icons-material/Launch";

export default function ClassAdjustments() {
	let auth = isAuthenticated();
	const token = auth.token;
	const userid = auth.ccid;
	const deptIdsFromLDAP = auth.dept_ids;
	const queryClient = useQueryClient();
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const [originalRows, setOriginalRows] = useState([]);
	const [rows, setRows] = useState([]);
	const [termNumber, setTermNumber] = useState(null);
	const [deptId, setDeptId] = useState(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [selectedDept, setSelectedDept] = useState(null);
	const [departmentsList, setDepartmentsList] = useState([]);
	const [termValue, setTermValue] = useState(null);
	const [terms, setTerms] = useState([]);
	const [rowsFromClassIds, setRowsFromClassIds] = useState([]);
	const [selectedClass, setSelectedClass] = useState(null);
	const [rowsForTable, setRowsForTable] = useState([]);
	const [rowsForTableOriginal, setRowsForTableOriginal] = useState([]);
	const [openInformationalDialog, setOpenInformationalDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [warnUnsavedChanges, setWarnUnsavedChanges] = useState(false);
	const [blockIdsFiltered, setBlockIdsFiltered] = useState([]);
	const [changes, setChanges] = useState({});
	const [customCheckboxSelection, setCustomCheckboxSelection] = useState([]);
	const [batchUpdated, setBatchUpdated] = useState(false);

	const { data: termsList, isFetching: termsLoading } = useTermsQueryFull(token);

	const { data: departments, isFetching: departmentsLoading } = useDepartmentsQuery(token);

	const {
		data: allItemsData,
		isFetching: allItemsDataLoading,
		status: allItemsDataStatus,
	} = useAllItemsQuery(token);

	const {
		data: allBlockItemsData,
		isFetching: allBlockItemsDataLoading,
		status: allBlockItemsStatus,
	} = useBlockItemsQuery(token);

	const { data: allBlockItems, isFetching: allBlockItemsLoading } = useAllBlockItems(
		allBlockItemsStatus === "success" ? allBlockItemsData : null,
		allItemsDataStatus === "success" ? allItemsData : null
	);

	const {
		data: blockIdsByDept,
		isFetching: blockIdsByDeptLoading,
		status: blockIdsByDeptStatus,
	} = useBlockIdsByDeptQuery(token, deptId, {
		enabled: !!token && !!deptId && !unsavedChanges,
	});

	const { data: fetchedClassIds, isFetching: fetchedClassIdsLoading } =
		useClassIdObjectsByDeptAndTerm(token, deptId, termNumber, {
			enabled: !!token && !!deptId && !!termNumber && !unsavedChanges,
			refetchOnWindowFocus: false,
		});

	const { data: fetchedAdjustedClassIds, isFetching: fetchedAdjustedClassIdsLoading } =
		useAdjustedClassIdObjectsByDeptAndTerm(token, deptId, termNumber, {
			enabled: !!token && !!deptId && !!termNumber && !unsavedChanges,
			refetchOnWindowFocus: false,
		});

	const updateClassMutation = useMutation(
		async ({ token, selectedClass }) => {
			await updateClassAdjust({ token, selectedClass });
		},
		{
			onSuccess: () => {
				//Invalidate and refetch
				queryClient.invalidateQueries(["adjustedClassIdObjectsByDeptAndTerm", deptId, termNumber]);
				queryClient.invalidateQueries(["classIdObjectsByDeptAndTerm", deptId, termNumber]);
				//Set unsaved changes to false only if status code is 204
				setUnsavedChanges(false);
			},
			onError: (error) => {
				console.error("Failed to update class:", error);
				// Handle error appropriately
			},
		}
	);

	useEffect(() => {
		if (
			termsLoading ||
			departmentsLoading ||
			fetchedClassIdsLoading ||
			fetchedAdjustedClassIdsLoading ||
			blockIdsByDeptLoading ||
			allBlockItemsLoading ||
			allItemsDataLoading ||
			allBlockItemsDataLoading
		) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [
		termsLoading,
		departmentsLoading,
		fetchedClassIdsLoading,
		fetchedAdjustedClassIdsLoading,
		blockIdsByDeptLoading,
		allBlockItemsLoading,
		allItemsDataLoading,
		allBlockItemsDataLoading,
	]);

	useEffect(() => {
		// only setBlockIdsFiltered to the values from allBlockItems if the key exists in blockIdsByDept
		if (blockIdsByDept && allBlockItems && blockIdsByDeptStatus === "success") {
			const blockIds = blockIdsByDept.map((blockId) => blockId.blkid);
			const blockItemsFiltered = Object.keys(allBlockItems)
				.filter((blockItem) => blockIds.includes(blockItem))
				.reduce((acc, blockItem) => {
					acc[blockItem] = allBlockItems[blockItem];
					return acc;
				}, {});
			setBlockIdsFiltered(blockItemsFiltered);
		}
	}, [blockIdsByDept, allBlockItems, blockIdsByDeptStatus]);

	useEffect(() => {
		if (rows.length > 0) {
			const rowsWithCsizeGTE4 = rows.filter((row) => row.csize > 0);
			const fixRowsNoEvaluate = rowsWithCsizeGTE4.map((row) => {
				if (row.evaluate === null) {
					row.evaluate = "";
				}
				return row;
			});
			setRowsForTable(fixRowsNoEvaluate);
			setRowsForTableOriginal(fixRowsNoEvaluate);
		}
	}, [rows]);
	useEffect(() => {
		rows.forEach((row) => {
			const class_id_obj = row;
			if (class_id_obj.c_edate !== null) {
				const end_date = dayjs(class_id_obj.c_edate);
				const fall_winter = ["1890", "1900", "1930", "1940"];
				const spring_summer = ["1880", "1910", "1920", "1950", "1960"];
				let survey_start = null;
				let survey_end = null;

				if (class_id_obj.examdate !== null) {
					const examdate = dayjs(class_id_obj.examdate);
					if (examdate.isAfter(end_date.add(2, "day"))) {
						if (fall_winter.includes(class_id_obj.term)) {
							survey_start = examdate.subtract(2, "week");
							survey_end = examdate.add(2, "day");
						} else if (spring_summer.includes(class_id_obj.term)) {
							survey_start = examdate.subtract(1, "week");
							survey_end = examdate.add(2, "day");
						} else {
							survey_start = examdate.subtract(2, "week");
							survey_end = examdate.add(2, "day");
						}
					} else {
						if (fall_winter.includes(class_id_obj.term)) {
							survey_start = examdate.subtract(15, "day");
							survey_end = examdate.subtract(1, "day");
						} else if (spring_summer.includes(class_id_obj.term)) {
							survey_start = examdate.subtract(8, "day");
							survey_end = examdate.subtract(1, "day");
						} else {
							survey_start = examdate.subtract(15, "day");
							survey_end = examdate.subtract(1, "day");
						}
					}
				} else {
					survey_start = end_date.subtract(2, "week");
					survey_end = end_date.add(2, "day");
				}
				if (class_id_obj.survey_start === null || class_id_obj.survey_start === undefined) {
					class_id_obj.survey_start = dayjs(survey_start).format("YYYY-MM-DD");
				}
				if (class_id_obj.survey_end === null || class_id_obj.survey_end === undefined) {
					class_id_obj.survey_end = dayjs(survey_end).format("YYYY-MM-DD");
				}
			}
		});

		// Only update state if rows have changed
	}, [selectedClass, rows, blockIdsByDept]);

	useEffect(() => {
		if (rowsForTable.length > 0 && blockIdsByDept && blockIdsByDept.length > 0) {
			const basicBlocks = blockIdsByDept.filter((blockId) => blockId.origin === "BASIC");
			const customBlocks = blockIdsByDept.filter(
				(blockId) => blockId.origin === "CUSTOM" && blockId.status !== "O"
			);
			const smallBasicBlocks = basicBlocks.filter((blockId) => blockId.csize === "SMALL");
			const largeBasicBlocks = basicBlocks.filter((blockId) => blockId.csize === "LARGE");
			const smallCustomBlocks = customBlocks.filter((blockId) => blockId.csize === "SMALL");
			const largeCustomBlocks = customBlocks.filter((blockId) => blockId.csize === "LARGE");
			const eitherCustomBlocks = customBlocks.filter((blockId) => blockId.csize === "EITHER");

			rowsForTable.forEach((row) => {
				if (
					blockIdsByDept &&
					blockIdsByDept.length > 0 &&
					(!row.blkid || (row.blkid && row.blkid === ""))
				) {
					if (row.csize >= 10 || row.dept === 79) {
						// Check for counterpart courses if department is Nursing (79)
						if (row.dept === 79) {
							const hasParentLEC = rowsForTable.some(
								(parentRow) =>
									parentRow.subject_id === row.subject_id &&
									parentRow.cnumber === row.cnumber &&
									/* parentRow.section === row.section && */
									parentRow.ctype === "LEC"
							);

							if (hasParentLEC) {
								if (row.ctype === "CLN") {
									row.blkid = "1NURS2CLN";
								} else if (row.ctype === "LAB") {
									row.blkid = "1NURS2LAB";
								} else if (row.ctype === "LEC") {
									row.blkid = "1NURS1LEC";
								}
							} else {
								if (row.ctype === "CLN") {
									row.blkid = "1NURS1CLN";
								} else if (row.ctype === "LAB") {
									row.blkid = "1NURS1LAB";
								} else if (row.ctype === "LEC") {
									row.blkid = "1NURS1LEC";
								} else if (row.ctype === "SEM") {
									row.blkid = "3SPOT";
								}
							}
						} else {
							// General case for other departments with multi-ctype blocks
							//LEC = apply to course with NO counterpart
							//LEC:SEM = apply to LEC course that has (only) a SEM counterpart
							//LEC:LAB = apply to LEC course that has (only) a LAB counterpart
							//LEC:LAB/SEM = apply to LEC course that has both LAB and SEM counterparts
							if (
								row.ctype === "LEC" ||
								row.ctype === "SEM" ||
								row.ctype === "LAB" ||
								row.ctype === "CLN" ||
								row.ctype === "LCL" ||
								row.ctype === "LBL"
							) {
								// Find counterparts for this course
								const courseCounterparts = rowsForTable.filter(
									(counterpartRow) =>
										counterpartRow.subject_id === row.subject_id &&
										counterpartRow.cnumber === row.cnumber &&
										counterpartRow.ctype !== "LEC"
								);

								const hasSem = courseCounterparts.some((cp) => cp.ctype === "SEM");
								const hasLab = courseCounterparts.some((cp) => cp.ctype === "LAB");

								// Determine which block to assign based on counterparts
								let blockToAssign = null;

								if (!hasSem && !hasLab) {
									// No counterparts, apply LEC block
									blockToAssign = largeCustomBlocks.find((blockId) => blockId.ctype === "LEC");
								} else if (hasSem && !hasLab) {
									// Only SEM counterpart, apply LEC:SEM block
									blockToAssign = largeCustomBlocks.find((blockId) => blockId.ctype === "LEC:SEM");
								} else if (!hasSem && hasLab) {
									// Only LAB counterpart, apply LEC:LAB block
									blockToAssign = largeCustomBlocks.find((blockId) => blockId.ctype === "LEC:LAB");
								} else if (hasSem && hasLab) {
									// Both SEM and LAB counterparts, apply LEC:LAB/SEM block
									blockToAssign = largeCustomBlocks.find(
										(blockId) => blockId.ctype === "LEC:LAB/SEM"
									);
								}

								if (blockToAssign) {
									row.blkid = blockToAssign.blkid;
								} else {
									if (row.roles.some((role) => role.class_role === "PI")) {
										// Find all counterparts for this row's course

										// Original logic for large blocks
										if (largeCustomBlocks.length === 0 && eitherCustomBlocks.length === 0) {
											const blockId = largeBasicBlocks.find((blockId) => blockId.blkid === "3SPOT");
											if (blockId) {
												row.blkid = blockId.blkid;
											}
										} else if (largeCustomBlocks.length > 0 || eitherCustomBlocks.length > 0) {
											if (row.ctype === "LEC") {
												const blockId = largeCustomBlocks.find((blockId) =>
													blockId.ctype.includes("LEC")
												);
												if (blockId) {
													row.blkid = blockId.blkid;
												} else {
													const blockId = eitherCustomBlocks.find((blockId) =>
														blockId.ctype.includes("LEC")
													);
													if (blockId) {
														row.blkid = blockId.blkid;
													} else {
														const blockId = largeBasicBlocks.find(
															(blockId) => blockId.blkid === "3SPOT"
														);
														if (blockId) {
															row.blkid = blockId.blkid;
														}
													}
												}
											} else if (row.ctype === "LAB") {
												const blockId = largeCustomBlocks.find((blockId) =>
													blockId.ctype.includes("LAB")
												);
												if (blockId) {
													row.blkid = blockId.blkid;
												} else {
													const blockId = eitherCustomBlocks.find((blockId) =>
														blockId.ctype.includes("LAB")
													);
													if (blockId) {
														row.blkid = blockId.blkid;
													} else {
														const blockId = largeBasicBlocks.find(
															(blockId) => blockId.blkid === "3SPOT"
														);
														if (blockId) {
															row.blkid = blockId.blkid;
														}
													}
												}
											} else if (row.ctype === "SEM") {
												const blockId = largeCustomBlocks.find((blockId) =>
													blockId.ctype.includes("SEM")
												);
												if (blockId) {
													row.blkid = blockId.blkid;
												} else {
													const blockId = eitherCustomBlocks.find((blockId) =>
														blockId.ctype.includes("SEM")
													);
													if (blockId) {
														row.blkid = blockId.blkid;
													} else {
														const blockId = largeBasicBlocks.find(
															(blockId) => blockId.blkid === "3SPOT"
														);
														if (blockId) {
															row.blkid = blockId.blkid;
														}
													}
												}
											} else if (row.ctype === "CLN") {
												const blockId = largeCustomBlocks.find(
													(blockId) => blockId.ctype === "CLN"
												);
												if (blockId) {
													row.blkid = blockId.blkid;
												} else {
													const blockId = eitherCustomBlocks.find(
														(blockId) => blockId.ctype === "CLN"
													);
													if (blockId) {
														row.blkid = blockId.blkid;
													} else {
														const blockId = largeBasicBlocks.find(
															(blockId) => blockId.blkid === "3SPOT"
														);
														if (blockId) {
															row.blkid = blockId.blkid;
														}
													}
												}
											}
										}
									} else if (row.roles.some((role) => role.class_role === "TA")) {
										if (row.ctype === "LAB") {
											if (largeCustomBlocks.length === 0 && eitherCustomBlocks.length === 0) {
												const blockId = largeBasicBlocks.find(
													(blockId) => blockId.blkid === "3SLAB"
												);
												if (blockId) {
													row.blkid = blockId.blkid;
												}
											}
										} else if (row.ctype === "SEM") {
											if (largeCustomBlocks.length === 0 && eitherCustomBlocks.length === 0) {
												const blockId = largeBasicBlocks.find(
													(blockId) => blockId.blkid === "3SLAB"
												);
												if (blockId) {
													row.blkid = blockId.blkid;
												}
											}
										} else {
											if (largeCustomBlocks.length === 0 && eitherCustomBlocks.length === 0) {
												const blockId = largeBasicBlocks.find(
													(blockId) => blockId.blkid === "3STA"
												);
												if (blockId) {
													row.blkid = blockId.blkid;
												}
											}
										}
									} else {
										if (largeCustomBlocks.length === 0 && eitherCustomBlocks.length === 0) {
											const blockId = largeBasicBlocks.find((blockId) => blockId.blkid === "3SPOT");
											if (blockId) {
												row.blkid = blockId.blkid;
											}
										}
									}
								}
							}
						}
					} else if (row.csize < 10 && row.csize >= 4) {
						if (smallCustomBlocks.length > 0 || eitherCustomBlocks.length > 0) {
							const blockId = smallCustomBlocks[0];
							if (blockId) {
								row.blkid = blockId.blkid;
							} else {
								const blockId = eitherCustomBlocks[0];
								if (blockId) {
									row.blkid = blockId.blkid;
								} else {
									const blockId = smallBasicBlocks.find((blockId) => blockId.blkid === "3OPN");
									if (blockId) {
										row.blkid = blockId.blkid;
									}
								}
							}
						} else if (smallCustomBlocks.length === 0 && eitherCustomBlocks.length === 0) {
							const blockId = smallBasicBlocks.find((blockId) => blockId.blkid === "3OPN");
							if (blockId) {
								row.blkid = blockId.blkid;
							}
						} else {
							const blockId = smallCustomBlocks.find((blockId) => blockId.blkid === "3OPN");
							if (blockId) {
								row.blkid = blockId.blkid;
							}
						}
					}
				}
				if ((row.roles && row.roles.length < 1) || row.excluded || !row.blkid || row.csize === 0) {
					row.evaluate = "Caution";
				} else if (!row.evaluate) {
					row.evaluate = "";
				}
			});
		}
	}, [rowsForTable, blockIdsByDept]);

	useEffect(() => {
		//fetched classids will always have data, but fetchedAdjustedClassIds will only have data if there are adjusted classids.
		//populate a nested dict with the main parent key as the classid, and then original and adjusted as the subkeys
		if (fetchedClassIds && fetchedClassIds.length > 0) {
			const classIds = {};
			fetchedClassIds.forEach((classId, index) => {
				const id = classId.id;

				classIds[id] = { original: classId, adjusted: null };
			});
			if (fetchedAdjustedClassIds && fetchedAdjustedClassIds.length > 0) {
				fetchedAdjustedClassIds.forEach((classId, index) => {
					const id = classId.id;

					if (classIds[id]) {
						classIds[id].adjusted = classId;
					}
				});
			}
			//for each entry in classIds, if it has an adjusted value, use that, otherwise use the original value. Turn into list of objects

			const originalClassIds = Object.keys(classIds).map((key) => {
				const classId = classIds[key];
				const original = classId.original;
				const row = original;
				return row;
			});

			const classIdsList = Object.keys(classIds).map((key) => {
				const classId = classIds[key];
				const original = classId.original;
				const adjusted = classId.adjusted;
				const row = adjusted ? adjusted : original;
				return row;
			});
			setRows(classIdsList);
			setOriginalRows(classIdsList);
			setRowsFromClassIds(originalClassIds);
		} else {
			setRows([]);
		}
	}, [fetchedClassIds, fetchedAdjustedClassIds]);

	useEffect(() => {
		if (departments && departments.length > 0) {
			//map object to list of department names
			const deptNames = departments.map((dept) => dept.department);
			setDepartmentsList(deptNames);
		}
	}, [departments]);

	useEffect(() => {
		if (deptIdsFromLDAP && deptIdsFromLDAP.length > 0) {
			const deptArray = deptIdsFromLDAP.split(",").map((deptId) => Number(deptId));
			if (deptArray.length > 0 && departments && departments.length > 0) {
				deptArray.forEach((deptId) => {
					const dept = departments.find((dept) => dept.key === deptId);
					if (dept) {
						setSelectedDept(dept.department);
						setDeptId(deptId);
					}
				});
			}
		}
	}, [deptIdsFromLDAP, departments]);

	useEffect(() => {
		if (termsList && termsList.length > 0 && termValue && termValue.length > 0) {
			//get value in brackets of termValue
			const termNumber = termValue.match(/\(([^)]+)\)/)[1];
			setTermNumber(Number(termNumber));
		}
	}, [termsList, termValue]);

	useEffect(() => {
		if (termsList && termsList.length > 0) {
			const termDescriptions = termsList.map((term) => term.desc);
			const termNumbers = termsList.map((term) => term.term);
			const terms = termNumbers.map((term, index) => {
				return termDescriptions[index] + " (" + term + ")";
			});
			setTerms(terms);

			const highestTermNumber = Math.max(...termNumbers);
			const highestTermIndex = termNumbers.indexOf(highestTermNumber);
			const highestTerm = terms[highestTermIndex];
			setTermValue(highestTerm);
		}
	}, [termsList]);

	useEffect(() => {
		// Display notification to the user
		setSnackbarOpen(unsavedChanges);
	}, [unsavedChanges]);

	const handleChangeBlkId = (event) => {
		const value = event.target.value;

		const classId = rowsForTable.find((row) => row.classid === selectedClass.classid);
		const updatedClass = { ...classId, blkid: value };

		const updatedRows = rowsForTable.map((row) =>
			row.classid === selectedClass.classid ? updatedClass : row
		);
		setUnsavedChanges(true);
		setRowsForTable(updatedRows);
		setRowsForTableOriginal(updatedRows);
	};

	const handleDepartmentChange = (event) => {
		const deptName = event.target.value;
		setSelectedDept(deptName);

		const dept = departments.find((dept) => dept.department === deptName);
		setDeptId(dept.key);
		//queryClient.invalidateQueries(["useBlockIdsByDeptQuery", token]);
	};

	const handleRefresh = () => {
		queryClient.invalidateQueries(["adjustedClassIdObjectsByDeptAndTerm", deptId, termNumber]);
		queryClient.invalidateQueries(["classIdObjectsByDeptAndTerm", deptId, termNumber]);
	};

	const discardChanges = () => {
		if (customCheckboxSelection.length > 0) {
			setCustomCheckboxSelection([]);
		} else {
			setRows(originalRows);
		}
		setUnsavedChanges(false);
		setWarnUnsavedChanges(false);
	};

	return (
		<div className="flex flex-col items-center justify-start w-full m-auto py-16 relative h-screen">
			{snackbarOpen && (
				<div
					className={`absolute top-2 left-1/2 transform -translate-x-1/2 text-white text-lg font-medium bg-red-700 px-20 rounded-md py-1 ring-2 ring-red-300 ${
						warnUnsavedChanges ? "animate-pulse ring-yellow-500 ring-4" : ""
					}`}
					aria-live="assertive"
					message="You have unsaved changes!"
				>
					*You have unsaved changes!{" "}
					<button
						onClick={discardChanges}
						className="px-1 border-2 bg-red-300 text-sm text-black rounded-md"
					>
						Discard Changes
					</button>
				</div>
			)}
			<div className="absolute top-2 right-2 flex items-center gap-4">
				<a
					className="text-blue-600 hover:underline px-2 py-1 border-2 border-green-800 rounded-md shadow-md bg-gray-100"
					href="https://youtu.be/I6HccL6s-zQ?si=TeYLD4WdGk0lLmcK"
					target="_blank"
					rel="noreferrer"
				>
					Video Tutorial <LaunchIcon />
				</a>
				<button
					className="px-4 py-1 hover:bg-green-900 bg-green-800 text-white rounded-md "
					onClick={() => setOpenInformationalDialog(true)}
				>
					Help <HelpIcon />
				</button>
			</div>

			<InformationalDialog open={openInformationalDialog} setOpen={setOpenInformationalDialog} />
			<ClassAdjustTable
				batchUpdated={batchUpdated}
				setBatchUpdated={setBatchUpdated}
				customCheckboxSelection={customCheckboxSelection}
				setCustomCheckboxSelection={setCustomCheckboxSelection}
				warnUnsavedChanges={warnUnsavedChanges}
				setWarnUnsavedChanges={setWarnUnsavedChanges}
				handleChangeBlkId={handleChangeBlkId}
				rows={rowsForTable}
				blockIdsByDept={blockIdsByDept}
				allBlockItems={blockIdsFiltered}
				updateClassMutation={updateClassMutation}
				//refetchBlockIdsByDept={refetchBlockIdsByDept}
				setRows={setRowsForTable}
				rowsForTableOriginal={rowsForTableOriginal}
				setRowsForTableOriginal={setRowsForTableOriginal}
				unsavedChanges={unsavedChanges}
				setUnsavedChanges={setUnsavedChanges}
				departmentsList={departmentsList}
				handleDepartmentChange={handleDepartmentChange}
				terms={terms}
				loading={loading}
				setTermValue={setTermValue}
				termValue={termValue}
				termNumber={termNumber}
				deptId={deptId}
				selectedDept={selectedDept}
				fetchedClassIds={fetchedClassIds}
				originalRows={originalRows}
				handleRefresh={handleRefresh}
				setChanges={setChanges}
				changes={changes}
				rowsFromClassIds={rowsFromClassIds}
				selectedClass={selectedClass}
				setSelectedClass={setSelectedClass}
			/>
		</div>
	);
}
