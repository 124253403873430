import * as React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FixedSizeList } from "react-window";

// Modify renderRow to accept rows as an additional parameter
function renderRow(props, rows) {
	//rows is an object, destructure into list of items
	let errorList = [];
	if (rows.length === 0) {
		return (
			<ListItem style={props.style} key={0} component="div" disablePadding>
				<ListItemButton>
					<ListItemText primary={"No errors"} />
				</ListItemButton>
			</ListItem>
		);
	} else {
		rows.forEach((item) => {
			errorList.push(item.empid + " " + item.ccid + " " + item.error);
		});
		const { index, style } = props;
		const item = errorList[index];

		return (
			<ListItem style={style} key={index} component="div" disablePadding>
				<ListItemButton>
					<ListItemText primary={item} />
				</ListItemButton>
			</ListItem>
		);
	}
}

// Modify BlueDataErrorLog to accept a rows prop
export default function BlueDataErrorLog({ rows }) {
	return (
		<Box sx={{ width: "100%", height: 400, maxWidth: 1200, bgcolor: "background.paper" }}>
			<FixedSizeList
				height={400}
				width={1200}
				itemSize={46}
				itemCount={rows.length} // Use the length of rows for itemCount
				overscanCount={5}
				itemData={rows} // Pass rows as itemData
			>
				{(props) => renderRow(props, rows)}
			</FixedSizeList>
		</Box>
	);
}
