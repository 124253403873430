import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles/info.module.css";
import Banner from "./components/Banner.js";
import Footer from "./components/Footer.js";
import { Box, Button, TextField, CircularProgress } from "@mui/material";

export default function ReqSuccess() {
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate("/newordisplay");
		}, 7000);
	}, []);
	return (
		<div className={styles.container}>
			<main className={styles.main}>
				<Box
					sx={{
						width: { sm: "80%", md: "60%", lg: "50%" },
						height: 520,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "10px",
						borderRadius: "25px",
						backgroundColor: "white",
						flexWrap: "nowrap",
						outline: "2px solid #275d38",
					}}
				>
					<div className={styles.successtext}>
						Success! Your request has been submitted. A receipt of your request
						has been automatically downloaded.
					</div>
					<div className={styles.successtext}>
						If you have not already, please drop off your exams at GSB 2-40.
						Please bring your receipt with you.
					</div>
					<div className={styles.successtext}>Redirecting...</div>
					<CircularProgress sx={{ padding: "20px" }} />
				</Box>
			</main>
			<Footer />
		</div>
	);
}
