import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import { GridFooterContainer, GridFooter, DataGrid } from "@mui/x-data-grid";
import { lighten, darken, styled } from "@mui/material/styles";
import { Checkbox } from "@mui/material";
import { updateMidtermToggles } from "../../components/Api/Updates";
import ListSelect from "../../components/Utils/ListSelect";
import { useTermsQueryFull } from "../../Hooks/useTermsQuery";
import { useMidtermsQuery } from "../../Hooks/useMidtermsQuery";
import { useMutation, useQueryClient } from "react-query";
import { GridToolbarExport, GridToolbarContainer } from "@mui/x-data-grid";
import isAuthenticated from "../../components/Utils/isAuthenticated";
import Snackbar from "@mui/material/Snackbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import { textAlign } from "@mui/system";

const tssList = ["G", "J", "Mg", "Mt", "None"];

const getBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .super-app-theme--G": {
		backgroundColor: getBackgroundColor(theme.palette.george.main, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.george.main, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.george.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.george.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Mg": {
		backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.success.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Mt": {
		backgroundColor: getBackgroundColor(theme.palette.mitra.main, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.mitra.main, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.mitra.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.mitra.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--J": {
		backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.info.main,
					theme.palette.mode
				),
			},
		},
	},
}));

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90%",
	height: "50%",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	display: "flex",
	flexDirection: "row",
};

export default function MidtermView() {
	const auth = isAuthenticated();
	const token = auth.token;
	const firstName = auth.firstName;
	const [rows, setRows] = useState([]);
	const [open, setOpen] = useState(false);
	const [currentRow, setCurrentRow] = useState(null);
	const { data: terms, status: termsStatus } = useTermsQueryFull(token);
	const [currentTerm, setCurrentTerm] = useState(null);
	const [termsList, setTermsList] = useState(null);
	const [initialRows, setInitialRows] = useState([]);
	const [filteredRowsLookup, setFilteredRowsLookup] = useState({});
	const [filterKey, setFilterKey] = useState("");
	const [numRows, setNumRows] = useState(0);
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const queryClient = useQueryClient();

	let tsqsUser = "";

	if (firstName === "George") {
		tsqsUser = "G";
	} else if (firstName === "Mitra") {
		tsqsUser = "Mt";
	} else if (firstName === "Johnathan") {
		tsqsUser = "J";
	} else if (firstName === "Margarita") {
		tsqsUser = "Mg";
	}

	useEffect(() => {
		if (terms && terms.length > 0) {
			const termNumbers = terms.map((term) => term.term);
			setTermsList(termNumbers);
			const highestTermNumber = Math.max(...termNumbers);
			const highestTermIndex = termNumbers.indexOf(highestTermNumber);
			const highestTerm = terms[highestTermIndex];
			const currentTerm = highestTerm?.term;
			setCurrentTerm(currentTerm);
		}
	}, [terms]);

	const handleOpen = (row) => {
		setCurrentRow(row);
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	useEffect(() => {
		const filteredRows = initialRows.filter((row) => {
			if (row.term === currentTerm) {
				return true;
			} else {
				return false;
			}
		});

		setRows(filteredRows);
	}, [currentTerm, initialRows]);

	const { data: midtermEvals, status: midtermEvalsStatus } = useMidtermsQuery(token, currentTerm);

	useEffect(() => {
		if (midtermEvals && midtermEvals.length > 0) {
			const transformProperty = (midtermEval, property) => {
				midtermEval[property] = [undefined, null, false, "", "N"].includes(midtermEval[property])
					? false
					: true;
			};

			midtermEvals.forEach((midtermEval) => {
				transformProperty(midtermEval, "eval_done");
				transformProperty(midtermEval, "eval_ia");
				transformProperty(midtermEval, "eval_sa");
			});

			setInitialRows([...midtermEvals]);
		}
	}, [midtermEvals]);

	const renderMidtermDoneToggle = (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);

		return (
			<strong>
				<Checkbox
					checked={row.eval_done || false}
					sx={{}}
					onChange={() => handleMidtermDoneToggle(rowIndex)}
					inputProps={{ "aria-label": "controlled" }}
				/>
			</strong>
		);
	};

	const createToggleHandler = (property) => (rowIndex) => {
		const updatedList = [...rows];
		const mt_id = updatedList[rowIndex].mt_id;
		updatedList[rowIndex][property] = !updatedList[rowIndex][property];
		setChanges((prevChanges) => ({
			...prevChanges,
			[rowIndex]: {
				...prevChanges[rowIndex],
				[property]: rows[rowIndex][property],
				mt_id: mt_id,
			},
		}));
	};

	const handleMidtermDoneToggle = createToggleHandler("eval_done");
	const handleMidtermSAToggle = createToggleHandler("eval_sa");
	const handleMidtermIAToggle = createToggleHandler("eval_ia");

	const renderMidtermIAToggle = (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);
		return (
			<strong>
				<Checkbox
					checked={row.eval_ia || false}
					sx={{}}
					onChange={() => handleMidtermIAToggle(rowIndex)}
					inputProps={{ "aria-label": "controlled" }}
				/>
			</strong>
		);
	};

	const renderMidtermSAToggle = (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);

		return (
			<strong>
				<Checkbox
					checked={row.eval_sa || false}
					sx={{}}
					onChange={() => handleMidtermSAToggle(rowIndex)}
					inputProps={{ "aria-label": "controlled" }}
				/>
			</strong>
		);
	};

	const handleChangeMidtermAssigned = (event, rowIndex) => {
		const updatedList = [...rows];
		if (event.target.value === "None") {
			updatedList[rowIndex].eval_assigned = null;
		} else {
			updatedList[rowIndex].eval_assigned = event.target.value;
		}
		const mt_id = updatedList[rowIndex].mt_id;
		setChanges((prevChanges) => ({
			...prevChanges,
			[rowIndex]: {
				...prevChanges[rowIndex],
				eval_assigned: rows[rowIndex].eval_assigned,
				mt_id: mt_id,
			},
		}));
	};

	const renderEvalAssigned = (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);
		return (
			<div>
				<ListSelect
					list={tssList}
					size="small"
					width="70px"
					marginBottom="0px"
					value={row.eval_assigned || ""}
					onChange={(event) => handleChangeMidtermAssigned(event, rowIndex)}
				/>
			</div>
		);
	};

	const copyCustomQuestion = (text) => {
		// Copy list to clipboard
		navigator.clipboard.writeText(text).then(
			function () {
				setSnackbarOpen(true);
			},
			function (err) {
				console.error("Could not copy text: ", err);
			}
		);

		return text;
	};

	const renderCustomQuestionPopover = (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);

		if (row.question_set === "Custom") {
			return (
				<div>
					<Button variant="contained" onClick={() => handleOpen(row)}>
						Show
					</Button>
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
						sx={{ display: "flex" }}
					>
						{currentRow ? (
							<Box sx={style}>
								<Box sx={{ width: "50%" }}>
									<Typography sx={{ p: 2 }}>MT_ID: {currentRow.mt_id}</Typography>
									<Typography sx={{ p: 2 }}>
										Requested by: {currentRow.instructor_name} - {currentRow.instructor_email}
									</Typography>
									<Typography sx={{ p: 2 }}>Classes: {currentRow.courses}</Typography>
									<Typography sx={{ p: 2 }}>Start Date: {currentRow.start_date}</Typography>
									<Typography sx={{ p: 2 }}>End Date: {currentRow.end_date}</Typography>
									<Typography sx={{ p: 2 }}>Team Teaching: {currentRow.team_teaching}</Typography>
								</Box>
								<Box sx={{ width: "50%", overflow: "auto", pl: 2 }}>
									<Typography sx={{ p: 2 }}>Custom Questions:</Typography>
									<Typography component="div" sx={{ p: 2 }}>
										<ol>
											{currentRow.custom_questions.split("+").map((q, index) => (
												<div>
													<li>
														<Box
															sx={{
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
																width: "100%",
															}}
														>
															<Box sx={{ width: "70%" }}>
																<Button
																	onClick={() => copyCustomQuestion(q.split("=")[0])}
																	variant="standard"
																	color="primary"
																	sx={{ "&:hover": { cursor: "pointer" }, textAlign: "left" }}
																>
																	{q.split("=")[0]}
																</Button>
															</Box>
															<Box
																sx={{
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "space-between",
																	width: "30%",
																}}
															>
																<Button
																	onClick={() =>
																		copyCustomQuestion(`${currentRow.mt_id}-${index + 1}`)
																	}
																	variant="standard"
																	color="primary"
																	sx={{ "&:hover": { cursor: "pointer" } }}
																>
																	{currentRow.mt_id}-{index + 1}
																</Button>
																<Typography fontSize={14}>
																	{q.split("=")[1] === "1;2;3;4;5"
																		? "Default Scale"
																		: q.split("=")[1]}
																</Typography>
															</Box>
														</Box>
													</li>
												</div>
											))}
										</ol>
									</Typography>
								</Box>
							</Box>
						) : (
							<Box sx={style}>Hello</Box>
						)}
					</Modal>
				</div>
			);
		}
	};

	const [changes, setChanges] = useState({});

	const bulkUpdateMutation = useMutation(
		({ token, changes }) => updateMidtermToggles({ token, changes }), // replace '/api/endpoint' with your API endpoint
		{
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries("midtermsQuery");
			},
		}
	);

	const handleRefresh = () => {
		queryClient.invalidateQueries("midtermsQuery");
	};

	const handleSave = async () => {
		await bulkUpdateMutation.mutateAsync({ token, changes });
		// After successful update, refresh rows from the server
		// and clear the changes
		/* fetchRows(); */
		setChanges({});
	};

	const resetTable = () => {
		setRows(initialRows);
		setFilterKey((prev) => prev + 1);
	};

	const CustomToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					p: 1,
					borderBottom: "1px solid #e0e0e0",
				}}
			>
				<Box sx={{ display: "flex", gap: "10px" }}>
					<ListSelect
						className="termSelect"
						label="Term"
						list={termsList || []}
						width="200px"
						size="small"
						marginBottom="0px"
						value={currentTerm || ""}
						onChange={(event) => setCurrentTerm(event.target.value)}
					/>
					<Button variant="outlined" color="error" onClick={resetTable}>
						Reset
					</Button>
				</Box>
				<Typography fontSize={18}>Midterm View</Typography>
				<Box
					sx={{
						border: "1px solid #bdbdbd",
						borderRadius: "5px",
						display: "flex",
						gap: "5px",
						p: 0.5,
					}}
				>
					<Button variant="standard" sx={{ color: "primary.main" }} onClick={handleRefresh}>
						<RefreshIcon sx={{ marginRight: "5px" }} />
						Refresh
					</Button>
					<Button variant="standard" sx={{ color: "primary.main" }} onClick={handleSave}>
						<SaveIcon sx={{ marginRight: "5px" }} />
						SAVE
					</Button>
					<GridToolbarExport sx={{ marginRight: "5px" }} />
				</Box>
			</GridToolbarContainer>
		);
	};

	const filterOnlyMe = () => {
		const filteredRows = rows.filter((row) => {
			if (row.eval_assigned === tsqsUser) {
				return true;
			} else {
				return false;
			}
		});

		setRows(filteredRows);
	};
	const filterNotStarted = () => {
		const filteredRows = rows.filter((row) => {
			if (
				row.eval_assigned === tsqsUser &&
				row.eval_done === false &&
				row.eval_ia === false &&
				row.eval_sa === false
			) {
				return true;
			} else {
				return false;
			}
		});

		setRows(filteredRows);
	};
	const filterNotDone = () => {
		const filteredRows = rows.filter((row) => {
			if (
				row.eval_assigned === tsqsUser &&
				(row.eval_done === false || row.eval_ia === false || row.eval_sa === false)
			) {
				return true;
			} else {
				return false;
			}
		});

		setRows(filteredRows);
	};

	const filterRegularWindow = () => {
		const filteredRows = rows.filter((row) => {
			if (row.start_date === "2024-02-12" && row.end_date === "2024-02-16") {
				return true;
			} else {
				return false;
			}
		});
		setRows(filteredRows);
	};

	const filterNoCustom = () => {
		const filteredRows = rows.filter((row) => {
			if (row.custom_questions === "") {
				return true;
			} else {
				return false;
			}
		});
		setRows(filteredRows);
	};

	const generateList = () => {
		const updatedList = [...rows];
		let list = [];
		let nodupesList = [];
		if (updatedList && updatedList.length > 0) {
			updatedList.forEach((row) => {
				if (filteredRowsLookup[row.mt_id]) {
					const item = row.courses;
					if (item.length > 5) {
						let itemlist = item.split(",");
						itemlist.forEach((i) => {
							list.push(i);
						});
					} else {
						list.push(item);
					}
				} else {
					console.log("filtered out");
				}
			});

			//remove dupes
			nodupesList = [...new Set(list)];
			const sortedList = nodupesList.sort();
			//join into string
			list = sortedList.join(",");
		}

		// Copy list to clipboard
		navigator.clipboard.writeText(list).then(
			function () {
				setSnackbarOpen(true);
			},
			function (err) {
				console.error("Could not copy text: ", err);
			}
		);

		return list;
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackbarOpen(false);
	};

	const generateColumn = () => {
		let list = [];
		let nodupesList = [];
		let sortedList = [];
		const updatedList = [...rows];
		if (updatedList && updatedList.length > 0) {
			updatedList.forEach((row) => {
				if (filteredRowsLookup[row.mt_id]) {
					const item = row.courses;
					if (item.length > 5) {
						let itemlist = item.split(",");
						itemlist.forEach((i) => {
							list.push(i);
						});
					} else {
						list.push(item);
					}
				}
			});
			//remove dupes
			nodupesList = [...new Set(list)];
			sortedList = nodupesList.sort();
		}

		// Create a string where each item is on a new line
		const listString = sortedList.join("\n");

		// Copy list to clipboard
		navigator.clipboard.writeText(listString).then(
			function () {
				setSnackbarOpen(true);
			},
			function (err) {
				console.error("Could not copy text: ", err);
			}
		);

		return list;
	};

	const handleAssignAllToMe = () => {
		const updatedList = [...rows];
		updatedList.forEach(
			(
				row // eslint-disable-next-line
			) => {
				if (filteredRowsLookup[row.mt_id]) {
					const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);

					if (
						row.eval_assigned === "None" ||
						row.eval_assigned === null ||
						row.eval_assigned === ""
					) {
						row.eval_assigned = tsqsUser;
						setChanges((prevChanges) => ({
							...prevChanges,
							[rowIndex]: {
								...prevChanges[rowIndex],
								eval_assigned: tsqsUser,
								mt_id: row.mt_id,
							},
						}));
					}
				}
			}
		);
		//update changes

		setRows(updatedList);
	};

	const handleMarkAllDone = () => {
		const updatedList = [...rows];
		updatedList.forEach(
			(
				row // eslint-disable-next-line
			) => {
				if (filteredRowsLookup[row.mt_id]) {
					const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);
					if (row.eval_assigned === tsqsUser) {
						row.eval_done = true;
						setChanges((prevChanges) => ({
							...prevChanges,
							[rowIndex]: {
								...prevChanges[rowIndex],
								eval_done: true,
								mt_id: row.mt_id,
							},
						}));
					}
				}
			}
		);
		//update changes

		setRows(updatedList);
	};

	const handleMarkAllIA = () => {
		const updatedList = [...rows];
		updatedList.forEach(
			(
				row // eslint-disable-next-line
			) => {
				if (filteredRowsLookup[row.mt_id]) {
					const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);
					if (row.eval_assigned === tsqsUser) {
						row.eval_ia = true;
						setChanges((prevChanges) => ({
							...prevChanges,
							[rowIndex]: {
								...prevChanges[rowIndex],
								eval_ia: true,
								mt_id: row.mt_id,
							},
						}));
					}
				}
			}
		);
		//update changes

		setRows(updatedList);
	};

	const handleMarkAllSA = () => {
		const updatedList = [...rows];
		updatedList.forEach(
			(
				row // eslint-disable-next-line
			) => {
				if (filteredRowsLookup[row.mt_id]) {
					const rowIndex = rows.findIndex((r) => r.mt_id === row.mt_id);
					if (row.eval_assigned === tsqsUser) {
						row.eval_sa = true;
						setChanges((prevChanges) => ({
							...prevChanges,
							[rowIndex]: {
								...prevChanges[rowIndex],
								eval_sa: true,
								mt_id: row.mt_id,
							},
						}));
					}
				}
			}
		);
		//update changes

		setRows(updatedList);
	};

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "flex-start", ml: 2, gap: 2 }}>
				<Box sx={{ mx: "auto", display: "flex", gap: 1 }}></Box>
				<Box sx={{ mx: "auto", display: "flex", gap: 1 }}>
					<Button color="greys" variant="contained" onClick={filterOnlyMe}>
						Filter Me
					</Button>
					<Button color="greys" variant="contained" onClick={filterNotStarted}>
						Filter Not Started
					</Button>
					<Button color="greys" variant="contained" onClick={filterNotDone}>
						Filter Not Done
					</Button>
					<Button color="greys" variant="contained" onClick={filterRegularWindow}>
						Filter Regular Window
					</Button>
					<Button color="greys" variant="contained" onClick={filterNoCustom}>
						Filter No Custom
					</Button>
				</Box>
				<Snackbar
					open={snackbarOpen}
					autoHideDuration={2000}
					onClose={handleCloseSnackbar}
					message="Copied to clipboard!"
				/>
				<Box sx={{ mx: "auto", display: "flex", gap: 1 }}>
					<Button variant="contained" onClick={generateList}>
						Get MT-class String ({numRows})
					</Button>
					<Button variant="contained" onClick={generateColumn}>
						Get CoursEval String ({numRows})
					</Button>
				</Box>
				<Box sx={{ mx: "auto", display: "flex", gap: 1 }}>
					<Button variant="outlined" color="error" onClick={handleAssignAllToMe}>
						Assign Me {numRows} Rows
					</Button>
					<Button variant="outlined" color="error" onClick={handleMarkAllDone}>
						Mark {numRows} Done
					</Button>
					<Button variant="outlined" color="error" onClick={handleMarkAllIA}>
						Mark {numRows} IA
					</Button>
					<Button variant="outlined" color="error" onClick={handleMarkAllSA}>
						Mark {numRows} SA
					</Button>
				</Box>

				<GridFooter sx={{}} />
			</GridFooterContainer>
		);
	};

	const columns = [
		{
			field: "mt_id",
			headerName: "ID",
			width: 50,
		},
		{
			field: "rqdate",
			headerName: "Requested",
			renderCell: (params) => {
				return dayjs(params.row.rqdate).format("DD-MMM-YYYY");
			},
			width: 100,
		},
		{
			field: "instructor_name",
			headerName: "Instructor",
			width: 150,
		},
		{
			field: "instructor_email",
			headerName: "Email",
			width: 140,
		},
		{
			field: "start_date",
			headerName: "Start Date",
			renderCell: (params) => {
				return dayjs(params.row.start_date).format("DD-MMM-YYYY");
			},
			width: 100,
		},
		{
			field: "end_date",
			headerName: "End Date",
			renderCell: (params) => {
				return dayjs(params.row.end_date).format("DD-MMM-YYYY");
			},
			width: 100,
		},
		{
			field: "question_set",
			headerName: "Question Set",
			width: 160,
		},
		{
			field: "questions",
			headerName: "Custom",
			renderCell: renderCustomQuestionPopover,
			width: 85,
			headerAlign: "center",
		},
		{
			field: "courses",
			headerName: "Courses",
			width: 100,
			flex: 1,
		},
		{
			field: "team_teaching",
			headerName: "TT",
			width: 50,
		},
		{
			field: "term",
			headerName: "Term",
			width: 60,
		},
		{
			field: "eval_assigned",
			headerName: "Assigned",
			width: 80,
			align: "center",
			renderCell: renderEvalAssigned,
		},
		{
			field: "eval_done",
			headerName: "Done",
			width: 40,
			align: "center",
			renderCell: renderMidtermDoneToggle,
		},
		{
			field: "eval_ia",
			headerName: "IA",
			width: 40,
			headerAlign: "center",
			align: "center",
			renderCell: renderMidtermIAToggle,
		},
		{
			field: "eval_sa",
			headerName: "SA",
			width: 40,
			headerAlign: "center",
			align: "center",
			renderCell: renderMidtermSAToggle,
		},
	];

	return (
		<Grid
			container
			spacing={2}
			sx={{
				margin: "auto",
				height: "600px",
				mt: 3,
			}}
		>
			<StyledDataGrid
				key={filterKey}
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "None !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},

					borderRadius: "1px 1px 4px 4px",
				}}
				onStateChange={(e) => {
					setFilteredRowsLookup(e.filter.filteredRowsLookup);
					setNumRows(e.pagination.rowCount);
				}}
				autoPageSize
				disableSelectionOnClick
				getRowClassName={(params) => `super-app-theme--${params.row.eval_assigned}`}
				components={{ Footer: CustomFooter, Toolbar: CustomToolbar }}
				rows={rows}
				density="compact"
				columns={columns}
				initialState={{
					sorting: {
						sortModel: [{ field: "start_date", sort: "asc" }],
					},
					pagination: {
						paginationModel: { pageSize: 100, page: 0 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.mt_id}
			/>
		</Grid>
	);
}
