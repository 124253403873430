import * as React from "react";
import Button from "@mui/material/Button";
import { useEffect, useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ChooseInstructorDialog from "../Manual/AddRequisition/components/Dialogs/ChooseInstructorDialog";
import CombineDialog from "../Manual/AddRequisition/components/Dialogs/CombineDialog";
import { Tooltip, Checkbox } from "@mui/material";
import ToolTipHelp from "../../../components/Utils/ToolTipHelp";
import { useContext } from "react";
import {
	ClassesContext,
	ClassIdsContext,
	StartDateContext,
	EndDateContext,
	TeamTeachingContext,
	SmallRequisitionContext,
} from "../../../context/RequisitionContext";
import AddInstructorDialog from "../Manual/AddRequisition/components/Dialogs/AddInstructorDialog";
import { GridFooterContainer, GridToolbarContainer, GridFooter, DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { darken, lighten, styled } from "@mui/material/styles";
import { GetTodayDatePlus21 } from "./GetTodayDate";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useWebClasses } from "../../../Hooks/useClassIdsQuery";
import { arraysAreEqual, deepEqual } from "../../../components/Utils/UtilityFunctions";

const getBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .super-app-theme--reqnum": {
		backgroundColor: getBackgroundColor(theme.palette.classids.locked, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.classids.locked, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.george.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.george.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--ending": {
		backgroundColor: getBackgroundColor(theme.palette.classids.ending, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.classids.ending, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.success.main,
					theme.palette.mode
				),
			},
		},
	},
}));

export default function ClassIdsTable(props) {
	const token = sessionStorage.getItem("token");
	const ccid = sessionStorage.getItem("ccid");
	const adminUser =
		ccid === "ms4" ||
		ccid === "mmohseni" ||
		ccid === "babic" ||
		ccid === "nutlo" ||
		ccid === "gnassar";
	const [enableCombineButton, setEnableCombineButton] = useState(false);
	const [openInstructorDialog, setOpenInstructorDialog] = useState(false);
	const [currentRow, setCurrentRow] = useState({});
	const { chosenClasses, setChosenClasses } = useContext(ClassesContext);
	const teamTeachingAgree = useContext(TeamTeachingContext);
	const startDate = useContext(StartDateContext);
	const endDate = useContext(EndDateContext);
	const { classIds } = useContext(ClassIdsContext);
	const { smallRequisition, setSmallRequisition } = useContext(SmallRequisitionContext);
	const [rows, setRows] = useState([]);
	const [openCombineDialog, setOpenCombineDialog] = useState(false);
	const [openAddInstructorDialog, setOpenAddInstructorDialog] = useState(false);
	const [message, setMessage] = useState("");

	const [unwantedCombination, setUnwantedCombination] = useState(false);

	const {
		data: classesClassIdsArray,
		isLoading,
		isError,
	} = useWebClasses(token, props.termValue);

	useEffect(() => {
		const twoWeeksFromToday = GetTodayDatePlus21();
		if (classIds.length > 0) {
			classIds.forEach((classId) => {
				if (classId.c_edate && typeof classId.c_edate === "string") {
					// if c_edate is within 2 weeks from today
					if (classId.c_edate < twoWeeksFromToday) {
						if (classId.theme !== "reqnum") {
							classId.theme = "ending";
						}
					}
				}
			});
		}
	}, [classIds]);

	useEffect(() => {
		if (!deepEqual(classIds, rows)) {
			const filteredClassIds = classIds.filter((c) => c.csize > 0);
			setRows(filteredClassIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [classIds]);

	useEffect(() => {
		const updatedListOfRows = [...rows];
		updatedListOfRows.forEach((row, index) => {
			if (props.combineClasses && props.combineClasses.some((c) => c.classid === row.classid)) {
				const combineClass = props.combineClasses.find((c) => c.classid === row.classid);
				updatedListOfRows[index] = combineClass;
			}
		});
		if (!arraysAreEqual(updatedListOfRows, rows)) {
			setRows(updatedListOfRows);
		}
	}, [rows, props.combineClasses]);

	useEffect(() => {
		const updatedChosenClasses = [...chosenClasses];
		const updatedChosenClassIds = [...rows];
		if (updatedChosenClasses.length > 0) {
			let classIdsList = [];

			updatedChosenClasses.forEach((chosenClass) => {
				if (!classIdsList.includes(chosenClass.classid)) {
					classIdsList.push(chosenClass.classid);
				}
			});
			let combinedArray = [];
			updatedChosenClasses.forEach((chosenClass) => {
				//keeping track of all the classes that are combined
				if (chosenClass.comb_classes && chosenClass.comb_classes.length > 0) {
					if (!Array.isArray(chosenClass.comb_classes)) {
						chosenClass.comb_classes = chosenClass.comb_classes.split(",");
						chosenClass.comb_classes.forEach((combClassId) => {
							combinedArray.push(combClassId);
						});
						combinedArray.push(chosenClass.classid);
					} else {
						chosenClass.comb_classes.forEach((combClassId) => {
							combinedArray.push(combClassId);
						});
						combinedArray.push(chosenClass.classid);
					}
				}
			});
			updatedChosenClassIds.forEach((row) => {
				//disabling the "add" and other options for courses that are being combined.
				if (combinedArray.includes(row.classid)) {
					row.combined = true;
				} else {
					row.combined = false;
				}
			});

			if (!arraysAreEqual(updatedChosenClassIds, rows)) {
				setRows(updatedChosenClassIds);
			}
		}
	}, [chosenClasses, rows]);

	console.log("rows", rows);

	useEffect(() => {
		if (props.combineClasses && props.combineClasses.length > 0) {
			const empidList = props.combineClasses.map((c) => c.roles.map((r) => r.empid));
			const sameEmpid = empidList.some((empidArray) =>
				empidArray.some((empid) => empidList.every((empidArray2) => empidArray2.includes(empid)))
			);
			let totalCSize = 0;
			props.combineClasses.forEach((c) => {
				console.log("c", c);
				totalCSize += c.csize;
			});
			if (props.combineClasses.length === 1) {
				setEnableCombineButton(false);
				setMessage(`${props.combineClasses.length} class(es) selected to combine`);
			} else if (props.combineClasses.length > 1) {
				if (sameEmpid) {
					if (totalCSize < 4) {
						setEnableCombineButton(false);
						setMessage(`Combine csize must exceed 4!`);
					} else {
						if ((smallRequisition.classes || smallRequisition.items) && !adminUser) {
							if (totalCSize > 9) {
								setEnableCombineButton(false);
								setMessage(`Small classes only!`);
							} else {
								setEnableCombineButton(true);
								setMessage(`${props.combineClasses.length} class(es) selected to combine`);
							}
						} else {
							if (totalCSize < 10 && !adminUser) {
								setEnableCombineButton(false);
								setMessage(`Csize must equal or exceed 10!`);
							} else {
								setEnableCombineButton(true);
								setMessage(`${props.combineClasses.length} class(es) selected to combine`);
							}
						}
					}
				} else {
					setEnableCombineButton(false);
					setMessage(`Instructor must be the same!`);
				}
			}
		} else if (props.combineClasses && props.combineClasses.length === 0) {
			setEnableCombineButton(false);
			setMessage(``);
		}
	}, [props.combineClasses, smallRequisition, adminUser, unwantedCombination]);

	const handleCombineToggle = (rowIndex, row) => {
		const updatedList = [...rows];
		const combinedArray = [...props.combineClasses];
		updatedList[rowIndex].combined = !updatedList[rowIndex].combined;
		if (updatedList[rowIndex].combined) {
			combinedArray.push(updatedList[rowIndex]);
		}
		if (!updatedList[rowIndex].combined) {
			const index = combinedArray.findIndex((c) => c.classid === updatedList[rowIndex].classid);
			combinedArray.splice(index, 1);
		}

		props.setCombineClasses(combinedArray);
	};

	const handleCombineDialog = () => {
		setOpenCombineDialog(!openCombineDialog);
	};

	const checkIfRowSelectable = (params) => {
		const { row } = params;
		const chosenClassesList = chosenClasses.map((c) => c.classid);
		if (classesClassIdsArray && classesClassIdsArray.some((item) => item.classid === row.classid)) {
			return false;
		}
		if (row.csize === 0) {
			return false;
		}
		if (row.roles && row.roles.length === 0) {
			return false;
		}
		if (row.combined) {
			return false;
		}
		if (chosenClassesList.includes(row.classid)) {
			return false;
		}
		return true;
	};

	const handleAddButton = useCallback(
		(row) => {
			if (teamTeachingAgree) {
				if (row.roles && row.roles.length < 11) {
					row.roles.forEach((role) => {
						role.checked = true;
					});
				} else {
					row.roles.forEach((role, index) => {
						if (index < 10) {
							role.checked = true;
						} else {
							role.checked = false;
						}
					});
				}
				setCurrentRow(row);
				setOpenInstructorDialog(true);
			} else {
				setCurrentRow(row);
				setOpenInstructorDialog(true);
			}
		},
		[teamTeachingAgree]
	);

	const [selectedRole, setSelectedRole] = React.useState(null);

	const handleInstructorSubmit = useCallback(
		//choose instructors to add to a class
		(instructorCCID, row) => {
			if (currentRow.roles || row || instructorCCID) {
				if (teamTeachingAgree) {
					//TT, must pick instructors (at least 2)
					const updatedRoles = [...currentRow.roles];
					const updatedChosenClasses = [...chosenClasses];
					const checkedRoles = updatedRoles.filter((role) => role.checked);
					checkedRoles.forEach((role, index) => {
						let newClass = {};
						if (role.checked) {
							const newId = currentRow.classid + role.empid;
							if (index === 0) {
								newClass = {
									...currentRow,
									rank: role.class_role,
									empid: role.empid,
									name: role.name,
									roles: role,
									id: newId,
									teamTeaching: true,
									comb_classes: currentRow.combine,
								};
							} else {
								newClass = {
									...currentRow,
									csize: 0,
									rank: role.class_role,
									empid: role.empid,
									name: role.name,
									roles: role,
									id: newId,
									teamTeaching: true,
									comb_classes: currentRow.combine,
								};
							}
							if (!newClass.survey_start) {
								newClass.survey_start = startDate;
							}
							if (!newClass.survey_end) {
								newClass.survey_end = endDate;
							}
							updatedChosenClasses.push(newClass);

							role.checked = false;
						}
					});
					setChosenClasses(updatedChosenClasses);
				} else {
					if (instructorCCID) {
						//multiple instructors in non TT, must pick
						const updatedRoles = [...currentRow.roles];
						const updatedChosenClasses = [...chosenClasses];
						const selectedRoles = updatedRoles.filter((role) => role.checked);
						const selectedRole = selectedRoles[0];

						if (selectedRole) {
							const newId = currentRow.classid + selectedRole.empid;
							let newClass = {
								...currentRow,
								rank: selectedRole.class_role,
								empid: selectedRole.empid,
								name: selectedRole.name,
								roles: selectedRole,
								id: newId,
								teamTeaching: false,
							};
							if (currentRow.combine && currentRow.combine.length > 0) {
								newClass.comb_classes.push(currentRow.combine);
								newClass.combined = true;
							}
							if (!newClass.survey_start) {
								newClass.survey_start = startDate;
							}
							if (!newClass.survey_end) {
								newClass.survey_end = endDate;
							}
							updatedChosenClasses.push(newClass);
							newClass = {};
						}
						setChosenClasses(updatedChosenClasses);
						setSelectedRole(null);
					} else if (row) {
						//single instructor in a class, add it
						if (!chosenClasses.some((c) => c.classid === row.classid)) {
							const updatedChosenClasses = [...chosenClasses];
							if (!row.survey_start) {
								row.survey_start = startDate;
							}
							if (!row.survey_end) {
								row.survey_end = endDate;
							}
							if (row.combine && (row.combine.length > 0 || row.combine > 0)) {
								row.comb_classes = row.combine;
							}
							row.rank = row.roles[0].class_role;
							updatedChosenClasses.push(row);
							setChosenClasses(updatedChosenClasses);
							setSelectedRole(null);
						}
					}
				}
			}

			setOpenInstructorDialog(false);
		},
		[chosenClasses, teamTeachingAgree, startDate, endDate, currentRow, setChosenClasses]
	);

	const handleInstructorCancel = () => {
		if (currentRow.roles) {
			const updatedRoles = [...currentRow.roles];
			updatedRoles.forEach((role) => {
				if (role.checked && !role.disabled) {
					role.checked = false;
				}
			});
		}
		setOpenInstructorDialog(false);
	};

	const handleTeamVisibility = (row) => {
		const teamNames = row.roles.map((role) => "'" + role.name + "'");
		const teamName = teamNames.join(", ");
		const updatedRows = [...rows];
		const index = updatedRows.findIndex((r) => r.id === row.id);
		updatedRows[index].teamName = teamName;
		updatedRows[index].nameExpanded = true;

		setRows(updatedRows);
	};

	const handleAddInstructor = (row) => () => {
		setOpenAddInstructorDialog(true);
		setCurrentRow(row);
	};

	const renderCombineToggle = (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.id === row.id);

		const chosenClassIds = chosenClasses.map((c) => c.classid);
		let chosenClassIdsWithCombClasses = [];
		chosenClasses.forEach((c) => {
			if (c.comb_classes) {
				c.comb_classes.forEach((combClassId) => {
					chosenClassIdsWithCombClasses.push(combClassId);
				});
			}
		});

		if (teamTeachingAgree) {
			if (!row.roles.every((role) => role.disabled)) {
				if (
					chosenClassIds.includes(row.classid) ||
					chosenClassIdsWithCombClasses.includes(row.classid) ||
					(classesClassIdsArray &&
						classesClassIdsArray.some((item) => item.classid === row.classid))
				) {
					return <strong></strong>;
				} else {
					return (
						<strong>
							<Checkbox
								checked={row.combined || false}
								sx={{ marginLeft: "8px" }}
								onChange={() => handleCombineToggle(rowIndex, params.row)}
								inputProps={{ "aria-label": "controlled" }}
							/>
						</strong>
					);
				}
			}
		} else {
			if (
				chosenClassIds.includes(row.classid) ||
				chosenClassIdsWithCombClasses.includes(row.classid) ||
				(classesClassIdsArray && classesClassIdsArray.some((item) => item.classid === row.classid))
			) {
				return <strong></strong>;
			} else {
				return (
					<strong>
						<Checkbox
							checked={row.combined || false}
							sx={{ marginLeft: "8px" }}
							onChange={() => handleCombineToggle(rowIndex, params.row)}
							inputProps={{ "aria-label": "controlled" }}
						/>
					</strong>
				);
			}
		}
	};

	const renderAddHeader = () => {
		return (
			<div>
				<ToolTipHelp
					title="If you cannot add a class, hover over the add button to see why."
					placement="top"
					sx={{ marginLeft: "23px", marginTop: "15px" }}
				/>
			</div>
		);
	};

	const renderAddButton = useCallback(
		(params) => {
			const { row } = params;
			const chosenClassesList = chosenClasses.map((c) => c.classid);
			let chosenClassIdsWithCombClasses = [];
			chosenClasses.forEach((c) => {
				if (c.comb_classes) {
					c.comb_classes.forEach((combClassId) => {
						chosenClassIdsWithCombClasses.push(combClassId);
					});
				}
			});
			let classInRequisition = null;

			classesClassIdsArray &&
				classesClassIdsArray.some((item) => {
					if (item.classid === row.classid) {
						row.inRequisition = true;
						classInRequisition = item;
						return true;
					}
					return false;
				});
			if (classInRequisition) {
				const reqnum = classInRequisition.reqnum;
				row.theme = "reqnum";
				return (
					<strong>
						<Tooltip
							title="This class is part of another requisition. To add this to a new requisition, delete it from the other requisition first or create an instant class for it."
							placement="right"
						>
							<span>
								<Typography
									fontSize={14}
									sx={{ marginLeft: "10px" }}
								>
									{reqnum}
								</Typography>
							</span>
						</Tooltip>
					</strong>
				);
			} else if (row.name === "") {
				return (
					<strong>
						<Button
							disabled
							variant="outlined"
							sx={{ marginLeft: "5px", padding: "1px" }}
							color="error"
						>
							Add
						</Button>
					</strong>
				);
			} else if (row.csize < 4 && !adminUser) {
				return (
					<strong>
						<Tooltip
							title="This class has less than 4 students. Combine this class if you would like to add it to any survey, regardless of the kind of questions being asked."
							placement="right"
						>
							<Button variant="outlined" sx={{ marginLeft: "5px", padding: "1px" }} color="warning">
								Add
							</Button>
						</Tooltip>
					</strong>
				);
			} else if (row.combined) {
				return (
					<strong>
						<Button
							disabled
							variant="outlined"
							sx={{ marginLeft: "5px", padding: "1px" }}
							color="error"
						>
							Comb
						</Button>
					</strong>
				);
			} else if (
				props.source === "AddRequisition"
					? smallRequisition &&
					  !smallRequisition.items &&
					  row.csize < 10 &&
					  !adminUser &&
					  !chosenClassesList.includes(row.classid)
					: smallRequisition &&
					  !smallRequisition.classes &&
					  row.csize < 10 &&
					  !adminUser &&
					  !chosenClassesList.includes(row.classid)
			) {
				return (
					<strong>
						<Tooltip
							title="This class has less than 10 students. Remove any numerical rating questions from the survey, or combine this class if you would like to add this class."
							placement="right"
						>
							<Button variant="outlined" sx={{ marginLeft: "5px", padding: "1px" }} color="warning">
								Add
							</Button>
						</Tooltip>
					</strong>
				);
			} else if (teamTeachingAgree) {
				if (row.teamsize < 2) {
					return (
						<strong>
							<Tooltip
								title="This class has less 2 instructors assigned. Assign instructors to get rid of this error."
								placement="right"
							>
								<Button variant="outlined" sx={{ marginLeft: "5px", padding: "1px" }} color="error">
									Add
								</Button>
							</Tooltip>
						</strong>
					);
				} else if (row.combined) {
					return (
						<strong>
							<Button
								variant="outlined"
								sx={{ marginLeft: "5px", padding: "1px" }}
								color="greys"
								onClick={() => handleAddButton(row)}
							>
								Added
							</Button>
						</strong>
					);
				} else if (chosenClassesList.includes(row.classid)) {
					return (
						<strong>
							<Button variant="outlined" sx={{ marginLeft: "5px", padding: "1px" }} color="greys">
								Added
							</Button>
						</strong>
					);
				} else {
					return (
						<strong>
							<Button
								variant="outlined"
								sx={{ marginLeft: "5px", padding: "1px" }}
								onClick={() => handleAddButton(row)}
							>
								Add
							</Button>
						</strong>
					);
				}
			} else if (!teamTeachingAgree) {
				if (chosenClassesList.includes(row.classid)) {
					return (
						<strong>
							<Button variant="outlined" sx={{ marginLeft: "5px", padding: "1px" }} color="greys">
								Added
							</Button>
						</strong>
					);
				} else if (row.roles && row.roles.length === 1) {
					return (
						<strong>
							<Button
								variant="outlined"
								sx={{ marginLeft: "5px", padding: "1px" }}
								onClick={() => handleInstructorSubmit(null, row)}
							>
								Add
							</Button>
						</strong>
					);
				} else if (row.roles && row.roles.length > 1) {
					return (
						<strong>
							<Button
								variant="outlined"
								sx={{ marginLeft: "5px", padding: "1px" }}
								onClick={() => handleAddButton(row)}
							>
								Add
							</Button>
						</strong>
					);
				}
			}
		},
		[
			chosenClasses,
			teamTeachingAgree,
			classesClassIdsArray,
			smallRequisition,
			handleAddButton,
			handleInstructorSubmit,
		]
	);

	const renderInstructorName = (params) => {
		const { row } = params;
		if (row.roles && row.roles.length === 1) {
			return (
				<strong>
					<Button disabled={row.inRequisition} onClick={handleAddInstructor(row)}>
						<Typography sx={{ marginRight: "10px" }}>{row.name}</Typography>
						<EditIcon />
					</Button>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && !row.nameExpanded) {
			return (
				<strong>
					<Button onClick={() => handleTeamVisibility(row)}>
						<Typography sx={{ marginRight: "10px" }}>(Several)</Typography>
						<VisibilityIcon />
					</Button>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && row.nameExpanded) {
			return (
				<strong>
					<Button disabled={row.inRequisition} onClick={handleAddInstructor(row)}>
						<Typography sx={{ marginRight: "10px" }}>{row.teamName}</Typography>
						<EditIcon />
					</Button>
				</strong>
			);
		} else {
			return (
				<strong>
					<Button
						disabled={row.inRequisition}
						onClick={handleAddInstructor(row)}
					>
						<Typography
							sx={{ marginRight: "10px" }}
							color="red"
						>
							(Click to Add)
						</Typography>
						<EditIcon />
					</Button>
				</strong>
			);
		}
	};

	function CustomToolbar() {
		return (
			<GridToolbarContainer
				sx={{
					display: "flex",
					justifyContent: "space-between",
					height: "50px",
				}}
			>
				<Box sx={{ display: "flex", justifyContent: "flex-start" }}>
					<Box sx={{ p: 1 }}>Legend:</Box>
					<Box sx={{ backgroundColor: "#f7cdb5", p: 1 }}>Ending Soon</Box>
					<Box sx={{ backgroundColor: "#dadde0", p: 1 }}>Already In Requisition</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "center",
						gap: "10px",
						height: "100%",
					}}
				>
					<Typography sx={{}}>{message}</Typography>
					<Button
						disabled={!enableCombineButton}
						variant="outlined"
						size="small"
						sx={{ marginLeft: "5px" }}
						onClick={handleCombineDialog}
					>
						Combine and Add
					</Button>
				</Box>
			</GridToolbarContainer>
		);
	}

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "space-between" }}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						marginLeft: "10px",
						gap: "10px",
					}}
				>
					{/* <Button
						variant="contained"
						disabled={
							(props.checkboxSelectionState && props.checkboxSelectionState.length === 0) ||
							disableMassAdd()
						}
						size="small"
						sx={{
							display: "flex",
						}}
						onClick={massAdd}
					>
						Add All Selected
					</Button> */}
				</Box>

				<Box
					sx={{
						outline: rows.length > 100 ? "1px solid red" : "none",
						borderRadius: rows.length > 100 ? "5px" : "0px",
						margin: "1px",
					}}
				>
					<GridFooter
						sx={{
							display: "flex",
						}}
					/>
				</Box>
			</GridFooterContainer>
		);
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class ID",
			width: 80,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			type: "string",
			width: 180,
		},
		{
			field: "csize",
			editable: true,
			headerName: "Enrolled",
			width: 80,
		},
		{
			field: "teamsize",
			headerName: "Team Size",
			width: 90,
			disableClickEventBubbling: true,
		},
		{
			field: "name",
			headerName: "Instructor",
			width: 180,
			flex: 1,
			renderCell: renderInstructorName,
		},

		{
			field: "c_edate",
			headerName: "Class End Date",
			width: 110,
			disableClickEventBubbling: true,
			align: "center",
			renderCell: (params) => {
				const { row } = params;
				const date = dayjs(row.c_edate);
				const formattedDate = dayjs(date).format("DD/MM/YY");
				if (formattedDate === "Invalid Date") {
					return "";
				}
				return formattedDate;
			},
		},

		{
			field: "examdate",
			headerName: "Exam Date",
			align: "center",
			headerAlign: "center",
			width: 110,
			disableClickEventBubbling: true,
			renderCell: (params) => {
				const { row } = params;
				const date = dayjs(row.examdate);
				const formattedDate = dayjs(date).format("DD/MM/YY");
				if (formattedDate === "Invalid Date") {
					return "";
				}

				return formattedDate;
			},
		},

		{
			field: "c_location",
			headerName: "Location",
			width: 80,
			disableClickEventBubbling: true,
		},
		{
			field: "  ",
			headerName: "Combine",
			width: 80,
			disableColumnMenu: true,
			renderCell: renderCombineToggle,
			sortable: false,
		},

		{
			field: "renderAdd",
			headerName: " ",
			width: 100,
			sortable: false,
			disableColumnMenu: true,
			renderHeader: renderAddHeader,
			renderCell: renderAddButton,
		},
	];

	const [paginationModel, setPaginationModel] = React.useState({
		pageSize: 100,
		page: 0,
	});

	const CustomNoRowsOverlay = () => {
		return (
			<Typography
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
				}}
			>
				Please select a department and term from the above dropdown menus.
			</Typography>
		);
	};

	return (
		<div style={{ height: "100%", width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					height: "100%",
				}}
			>
				<StyledDataGrid
					key={props.filterKey}
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					loading={rows.length === 0 && props.inputValue !== "" ? true : false}
					sx={{
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
							width: "0.4em",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
							background: "#f1f1f1",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
							backgroundColor: "#888",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
							background: "#555",
						},
					}}
					getRowClassName={(params) =>
						`super-app-theme--${
							params.row.theme && params.row.theme.length > 0 ? params.row.theme : "default"
						}`
					}
					/* checkboxSelection
					onSelectionModelChange={(newSelection) => {
						props.setCheckboxSelectionState(newSelection);
					}} */
					disableSelectionOnClick
					density="compact"
					components={{
						Footer: CustomFooter,
						Toolbar: CustomToolbar,
						LoadingOverlay: LinearProgress,
						NoRowsOverlay: CustomNoRowsOverlay,
					}}
					rows={rows}
					columns={columns}
					isRowSelectable={(params) => checkIfRowSelectable(params)}
					initialState={{
						sorting: {
							sortModel: [{ field: "ctitle", sort: "asc" }],
						},
					}}
					getRowId={(row) => row.classid}
				/>
				<ChooseInstructorDialog
					open={openInstructorDialog}
					setOpen={setOpenInstructorDialog}
					handleInstructorSubmit={handleInstructorSubmit}
					selectedRole={selectedRole}
					setSelectedRole={setSelectedRole}
					currentRow={currentRow}
					setCurrentRow={setCurrentRow}
					teamTeachingAgree={teamTeachingAgree}
					handleInstructorCancel={handleInstructorCancel}
				/>
				<CombineDialog
					open={openCombineDialog}
					setOpen={setOpenCombineDialog}
					list={props.combineClasses}
					setList={props.setCombineClasses}
					chosenClasses={chosenClasses}
					setChosenClasses={setChosenClasses}
					teamTeachingAgree={teamTeachingAgree}
					unwantedCombination={unwantedCombination}
					setUnwantedCombination={setUnwantedCombination}
				/>
				<AddInstructorDialog
					open={openAddInstructorDialog}
					setOpen={setOpenAddInstructorDialog}
					currentRow={currentRow}
					setCurrentRow={setCurrentRow}
					teamTeachingAgree={teamTeachingAgree}
				/>
			</Box>
		</div>
	);
}
