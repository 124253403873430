import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState, useEffect } from "react";

export default function ResolveDatesTable(props) {
	const [rows, setRows] = useState([]);

	useEffect(() => {
		if (props.noEndDatesBool) {
			setRows(props.noEndDates);
		} else if (props.noExamDatesBool) {
			setRows(props.noExamDates);
		}
	}, [
		props.noEndDatesBool,
		props.noExamDatesBool,
		props.noEndDates,
		props.noExamDates,
	]);

	const updateEndDate = (classId, endDate) => {
		const updatedRows = [...rows];
		const rowIndex = updatedRows.findIndex((r) => r.classid === classId);
		const updatedNoExamDates = [...props.noExamDates];
		const updatedGoodClassIds = [...props.goodClassIds];
		const updatedNoEndDates = [...props.noEndDates];
		const noEndDatesIndex = updatedNoEndDates.findIndex(
			(r) => r.classid === classId
		);
		updatedGoodClassIds.forEach((row) => {
			if (row.classid === classId) {
				row.c_edate = dayjs(endDate).format("YYYY-MM-DD");
				if (row.examdate === null) {
					row.noEndDate = false;
					row.noExamDate = true;
					if (!updatedNoExamDates.includes(row)) {
						updatedNoExamDates.push(row);
					}
					if (props.noEndDatesBool) {
						updatedRows.splice(rowIndex, 1);
						updatedNoEndDates.splice(noEndDatesIndex, 1);
					}
					if (!updatedGoodClassIds.includes(row)) {
						updatedGoodClassIds.push(row);
					}
				} else {
					row.noEndDate = false;
					row.noExamDate = false;
					if (props.noEndDatesBool) {
						updatedRows.splice(rowIndex, 1);
						updatedNoEndDates.splice(noEndDatesIndex, 1);
					}
					if (!updatedGoodClassIds.includes(row)) {
						updatedGoodClassIds.push(row);
					}
				}
			}
		});
		setRows(updatedRows);
		props.setNoExamDates(updatedNoExamDates);
		props.setGoodClassIds(updatedGoodClassIds);
		props.setNoEndDates(updatedNoEndDates);
	};

	const updateExamDate = (classId, examDate) => {
		const updatedGoodClassIds = [...props.goodClassIds];
		const updatedRows = [...rows];
		const index = updatedGoodClassIds.findIndex((r) => r.classid === classId);
		updatedRows.forEach((row) => {
			if (row.classid === classId) {
				row.examdate = dayjs(examDate).format("YYYY-MM-DD");
				row.noExamDate = false;
				updatedGoodClassIds[index] = row;
			}
		});
		setRows(updatedRows);
		props.setGoodClassIds(updatedGoodClassIds);
	};

	const CustomFooter = () => {
		return (
			<GridFooterContainer
				sx={{ display: "flex", justifyContent: "flex-start" }}
			>
				<GridFooter sx={{ position: "absolute", right: "10px" }} />
			</GridFooterContainer>
		);
	};

	const renderExamDates = (params) => {
		if (props.noExamDatesBool) {
			if (params.row.examdate === null) {
				return <Button>Dbl Click</Button>;
			}
			return dayjs(params.row.examdate).format("YYYY-MM-DD");
		} else {
			return null;
		}
	};

	const renderEndDates = (params) => {
		if (params.row.c_edate === null) {
			return <Button>Dbl Click</Button>;
		}
		return dayjs(params.row.c_edate).format("YYYY-MM-DD");
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class Id",
			width: 70,
		},
		{
			field: "c_sdate",
			headerName: "Start Date",
			renderCell: (params) => {
				return dayjs(params.row.c_sdate).format("YYYY-MM-DD");
			},
			width: 92,
		},
		{
			field: "c_edate",
			type: "date",
			headerName: "End Date",
			renderCell: renderEndDates,
			valueSetter: (params) => {
				const updatedRows = [...rows];
				const index = updatedRows.findIndex(
					(r) => r.classid === params.row.classid
				);
				let newDate = params.value;
				updatedRows[index].c_edate = dayjs(newDate);
				setRows(updatedRows);

				return {
					...params.row,
					c_edate: new dayjs(newDate),
				};
			},
			width: 92,
			editCellProps: {
				style: { textAlign: "center" },
			},
			editable: true,
		},
		{
			field: "examdate",
			type: "date",
			headerName: "Exam Date",
			renderCell: renderExamDates,
			valueSetter: (params) => {
				const updatedRows = [...rows];
				const index = updatedRows.findIndex(
					(r) => r.classid === params.row.classid
				);
				updatedRows[index].examdate = params.value;
				setRows(updatedRows);

				return {
					...params.row,
					examdate: params.value,
				};
			},

			width: 92,
			editCellProps: {
				style: { textAlign: "center" },
			},
			editable: true,
		},
		{
			field: "ctitle",
			headerName: "Title",
			width: 120,
			flex: 1,
		},
		{
			field: "name",
			headerName: "Instructor Name",
			width: 120,
			flex: 1,
			renderCell: (params) => {
				if (params.row.roles.length === 1) {
					if (!params.row.name) {
						return params.row.roles[0].name;
					}
				} else if (params.row.roles.length > 1) {
					return "(Several)";
				}

				return params.row.name;
			},
		},
		{
			field: "teamsize",
			headerName: "Team Size",
			align: "center",
			width: 80,
		},
		{
			field: "csize",
			headerName: "Enrolled",
			align: "center",
			width: 80,
		},
	];

	return (
		<div style={{ display: "flex", height: "50%", width: "100%" }}>
			<DataGrid
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "Nne !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},
					backgroundColor:
						props.noEndDatesBool && rows.length > 0 ? "#f5a6ae" : "#ffffff",

					borderRadius: "1px 1px 4px 4px",
				}}
				disableSelectionOnClick
				columnVisibilityModel={{}}
				components={{ Footer: CustomFooter }}
				onCellEditStop={(params) => {
					const { id, field, props } = params;
					const { classid } = params.row;
					const { c_edate, examdate } = params.row;
					if (field === "c_edate") {
						updateEndDate(classid, c_edate);
					} else if (field === "examdate") {
						updateExamDate(classid, examdate);
					}
				}}
				rows={rows}
				density="compact"
				slots={{}}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: { pageSize: 100, page: 0 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.classid}
			/>
		</div>
	);
}
