import React, {useEffect, useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DownloadIcon from "@mui/icons-material/Download";
// import LinearProgress from "@mui/material/LinearProgress";
import {CustomPagination, CustomNoRowsOverlay} from "../SecondaryFunctions";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { SearchablePDF } from '../Reports/Report Download/SearchablePDF';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';




// datagrid columns
const columns = [
    {field: "term", headerName: "TERM", width: 100, align: "center", headerAlign: "center"},
    {field: "reqnum", headerName: "REQNUM", width: 100, align: "center", headerAlign: "center"},
    {field: "classes", headerName: "# OF CLASSES", width: 150, align: "center", headerAlign: "center"},
    {field: "loaded", headerName: "CLASSES LOADED", width: 150, align: "center", headerAlign: "center"},
    {field: "username", headerName: "USER", width: 250, align: "center", headerAlign: "center"},
    {field: "email", headerName: "EMAIL", width: 250, align: "center", headerAlign: "center"},
    {field: "department", headerName: "DEPARTMENT", width: 430, align: "center", headerAlign: "center"},
];


export default function ReportGeneration(){

    const [loadingBar, setLoadingBar] = useState(true); // To show/hide loading bar
    const [loaded,setLoaded] = useState(false)
    const [termsFetched, setTermsFetched] = useState(false)
    const [requisitionsFetched,setRequisitionsFetched] = useState(false)
    const [terms, setTerms] = useState([])
    const [requisitions, setRequisitions] = useState([])
    const [filteredReqnums, setFilteredReqnums] = useState([])
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [selectedReqnum, setSelectedReqnum] = useState([])
    const [openWindow, setOpenWindow] = useState(false); // Control the visibility of a dialog window
    const [checked, setChecked] = useState([true,true,true,true]);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [pdfGrouping, setPdfGrouping] = useState(1); 


    const handleClickOpen = () => {
        if (selectedReqnum.length > 0){setOpenWindow(true);}
    };

    const handleClose = () => {
        setOpenWindow(false);
    };

    const handleCheck0 = (event) => {
        setChecked([event.target.checked, checked[1], checked[2],checked[3]]);
      };
    
    const handleCheck1 = (event) => {
        setChecked([checked[0], event.target.checked, checked[2],checked[3]]);
    };

    const handleCheck2 = (event) => {
        setChecked([checked[0], checked[1], event.target.checked,checked[3]]);
    }

    const handleCheck3 = (event) => {
        setChecked([checked[0], checked[1],checked[2], event.target.checked]);
    }

    const handlePDFgrouping = (event) => {
        setPdfGrouping(event.target.value);
    }



    useEffect(() => {
        let url = process.env.REACT_APP_BACKEND_HOST
        async function fetch_terms() {
            const response = await fetch(url.concat('/spot/terms_list'), {
                headers: { Authorization: `Token ${sessionStorage.token}` },
              });
            const json = await response.json();

            let apiData = [];
            for (let i = 0; i < json.length; i++) {
                apiData.push(json[i].term);
            }
          setTermsFetched(true)
          return apiData
        }

        async function fetch_requistitions() {
            const response = await fetch(url.concat('/spot/term_requisitions/',selectedTerm,'/'), {
                headers: { Authorization: `Token ${sessionStorage.token}` },
              });
            const json = await response.json();

            let apiData = [];
            let idValue = 0;
            for (let i = 0; i < json.length; i++) {
                let entry = {
                    id: idValue,
                    term: selectedTerm,
                    reqnum: json[i].reqnum.toString(),
                    classes: json[i].classes.toString(),
                    loaded: json[i].loaded.toString(),
                    email: json[i].userid.concat('@ualberta.ca'),
                    username: json[i].username,
                    department: json[i].department
                  };
                  apiData.push(entry);
                  idValue++;
            }
          setRequisitions(true)
          return apiData
        }
        
        if (!termsFetched) {
            let apiData = [fetch_terms()];
        
              Promise.all(apiData).then((values) => {
                let data = values[0].slice().sort((a, b) => b - a).map(item => item.toString())
                setTerms(data)
                setSelectedTerm(data[0])
              });
              
        }
        if (termsFetched && !requisitionsFetched){
            let apiData = [fetch_requistitions()];
        
              Promise.all(apiData).then((values) => {
                let data = values[0]
                setRequisitions(data)
                setFilteredReqnums(data)
              });
              // Mark the data as loaded to prevent repeated fetching
              setLoaded(true);
              setRequisitionsFetched(true)
        }

        if (loaded && loadingBar) {
            setTimeout(() => {
                setLoadingBar(false);
            }, 3000);
        }
    
    },[loaded, terms, termsFetched, loadingBar,requisitionsFetched,requisitions,filteredReqnums,selectedTerm])

   

    const handleTermChange = (event, newValue) => {
        // Update the selectedTerm state when the value changes
        setRequisitionsFetched(false)
        setLoaded(false)
        setLoadingBar(true)
        setSelectedTerm(newValue);
    };



    const handleReqnumChange = (event, newValue) => {
        // Update the selectedTerm state when the value changes
        if (newValue.length > 0){
            const filteredList = requisitions.filter(item => newValue.includes(item.reqnum));
            setFilteredReqnums(filteredList)
        }else{
            setFilteredReqnums(requisitions)
        }
    };


    function select_requisitions(items){
        let selected_items = []
        for (let i = 0; i < items.length; i++) {
            let entry = filteredReqnums.filter((x) => x.id === items[i]);
            selected_items.push(entry[0].reqnum.toString())
        }
        setSelectedReqnum(selected_items)
    }



    async function PrintReport(){
        function allFalse(arr) {
            for (let i = 0; i < arr.length; i++) {
              if (arr[i] === true) {
                return false;
              }
            }
            return true;
        }
        if (!allFalse(checked)){
            if (pdfGrouping === 1){
                setPdfLoading(true)
                const selectedIDs = ['R',`(${selectedReqnum.join(',')})`]
                await SearchablePDF(selectedIDs,checked,'SPOT_Report.pdf')
                setPdfLoading(false)
            }
            
            else if (pdfGrouping === 2) {
                setPdfLoading(true)
                let url = process.env.REACT_APP_BACKEND_HOST
                const response = await fetch(url.concat('/spot/get_req_ids/',selectedReqnum.join(','),'/'), {
                    headers: { Authorization: `Token ${sessionStorage.token}` },
                });
                const selectedIDs = await response.json();
                const uniqueEmpIds = [...new Set(selectedIDs.map(entry => entry.empid))];
                for (let empid of uniqueEmpIds) {
                    let selected_data = selectedIDs.filter(item => item.empid === empid).map(item => item.req_id);
                    let filename = selectedIDs.find(item => item.empid === empid).inst_name.concat('.pdf');
                    await SearchablePDF(selected_data, checked, filename);
                }
                setPdfLoading(false)
            }
        }
    }


    return(
        <div>
            <Box 
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '15px',
                }}
                >
                <Autocomplete
                    value={selectedTerm}
                    onChange={handleTermChange}
                    size="small"
                    options={terms}
                    sx={{ width: 250, paddingRight:'12px'}}
                    renderInput={(params) => (
                    <TextField 
                        {...params} 
                        label="TERM" 
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )}}
                    />
                    )}
                />
                <Autocomplete
                    multiple
                    limitTags={7}
                    onChange={handleReqnumChange}
                    size="small"
                    options={requisitions.map(item => item.reqnum)}
                    disableCloseOnSelect
                    sx={{ width: 750, paddingLeft:'12px' }}
                    renderInput={(params) => (
                        <TextField 
                            {...params} 
                            label="REQNUMS" 
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )}}
                        />
                        )}
                />
            </Box>
            <Box 
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '15px',
                    paddingLeft: '205px',
                    paddingRight: '205px'
                }}
                >
                <DataGrid
                        getRowHeight={() => 'auto'}
                        style={{ textAlign: "center" }}
                        columns={columns}
                        rows={filteredReqnums}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        getRowClassName={(params) => {
                            return params.row.classes !== params.row.loaded ? "highlight" : "";
                          }}
                        checkboxSelection
                        onSelectionModelChange={(item) => select_requisitions(item)}
                        density={"compact"}
                        slots={{
                            Pagination: CustomPagination,
                            // LoadingOverlay: LinearProgress,
                            LoadingOverlay: CircularProgress,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        loading={loadingBar} 
                        sx={{
                            ".highlight": {
                            color: "red"
                            },
                            height: '65vh', width:'100%'}}
                        />

            
            </Box>
            <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: 'center',
                        marginTop: '15px',
                        // outline: '2px solid black'
                        }}>
                        <Button
                            style={{ backgroundColor: "#275d38",width:'45%',maxWidth:'400px'}}
                            variant="contained"
                            endIcon={<DownloadIcon />}
                            onClick={handleClickOpen}
                            >
                            <Typography style ={{fontSize: '16px'}}>PDF Download</Typography>
                        </Button>
                        <Dialog open={openWindow} onClose={handleClose} 
                        fullWidth={true} maxWidth={'lg'}>
                        <DialogTitle sx={{ textAlign:'center',fontWeight: 'bold'}}>
                            SPOT REPORT
                        </DialogTitle>
                        {pdfLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', 
                            alignItems: 'center', paddingBottom: '20px'}}>
                                <CircularProgress size={80}/>
                            </Box>
                        ):(
                            <div>
                            <DialogContent>
                                <Box sx={{ display: 'flex', flexDirection:'column',
                                        justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography>
                                            Select which elements you would like included in the PDF report:
                                    </Typography>
                                    <br></br>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox 
                                                                        checked={checked[0]} 
                                                                        onChange={handleCheck0}
                                                                        />} 
                                            label="Standard SPOT Questions" />
                                        <FormControlLabel control={<Checkbox 
                                                                    checked={checked[1]} 
                                                                    onChange={handleCheck1}
                                                                    />} 
                                        label="Supplementary Questions" />
                                        <FormControlLabel control={<Checkbox 
                                                                    checked={checked[2]} 
                                                                    onChange={handleCheck2}
                                                                    />} 
                                        label="Summary Data" />
                                        <FormControlLabel control={<Checkbox 
                                                                    checked={checked[3]} 
                                                                    onChange={handleCheck3}
                                                                    />} 
                                        label="Comments" />
                                        <br></br>
                                        <FormControl variant="filled">
                                            <InputLabel>PDF Format</InputLabel>
                                            <Select
                                                value={pdfGrouping}
                                                label="PDF Format"
                                                onChange={handlePDFgrouping}
                                                >
                                                <MenuItem value={1}>Single PDF</MenuItem>
                                                <MenuItem value={2}>PDF per Instructor</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Box>
                            </DialogContent>
                        
                            <DialogActions sx={{justifyContent:'center'}}>
                                <Button onClick={handleClose}>Close</Button>
                                <Button onClick={PrintReport}>Generate</Button>
                            </DialogActions>
                            </div>
                        )}
                    </Dialog>
            </div>
        </div>
    )
}