import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

export default function ListSelect(props) {
	return (
		<div>
			<TextField
				select
				required={props.required}
				size={props.size}
				disabled={props.disabled}
				error={props.error}
				value={props.value || ""}
				name={props.name}
				label={props.label}
				multiple={props.multiple}
				onChange={props.onChange}
				variant={props.variant ? props.variant : "outlined"}
				sx={{
					width: props.width ? props.width : "100%",
					marginBottom: props.marginBottom ? props.marginBottom : "20px",
					marginTop: props.marginTop ? props.marginTop : "0px",
				}}
				SelectProps={{
					multiple: props.multiple,
					value: props.value || "",
					onChange: props.onChange,
				}}
			>
				{props.list ? (
					props.list.map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))
				) : (
					<MenuItem key="1" value={""}></MenuItem>
				)}
			</TextField>
		</div>
	);
}
