import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmEvaluationDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				maxWidth="xl"
			>
				<DialogTitle textAlign={"center"}>Attention:</DialogTitle>
				{props.evaluationDialogState === "evaluated" && (
					<>
						<DialogContent
							sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
						>
							<DialogContentText
								sx={{ mx: "auto", maxWidth: "600px", color: "black" }}
							>
								Course: {props.classForEvaluatedDialog.classid} -{" "}
								{props.classForEvaluatedDialog.ctitle}
							</DialogContentText>
							<p className="text-red-700 font-semibold max-w-[600px] mx-auto">
								This course was previously submitted to be evaluated. Changing
								the status of this course now will undo all of the changes made
								prior to submitting the course for evaluation (instructor
								modifications, date ranges, the chosen block), and reset it to
								its original state from campus solutions.
							</p>

							<DialogContentText sx={{ maxWidth: "600px", color: "black" }}>
								If you are sure you want to proceed, please click "
								<span className="text-red-700">Confirm Removal</span>" below.
							</DialogContentText>
						</DialogContent>

						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>

							<Button
								onClick={() =>
									props.handleEvaluatedDialogConfirmation(
										props.classForEvaluatedDialog,
										"evaluated"
									)
								}
								variant="contained"
								autoFocus
								color="error"
							>
								Confirm Removal
							</Button>
						</DialogActions>
					</>
				)}
				{props.evaluationDialogState === "excluded" && (
					<>
						<DialogContent
							sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
						>
							<DialogContentText
								sx={{ mx: "auto", maxWidth: "600px", color: "black" }}
							>
								Course: {props.classForEvaluatedDialog.classid} -{" "}
								{props.classForEvaluatedDialog.ctitle}
							</DialogContentText>
							<p className="text-red-700 font-semibold max-w-[600px] mx-auto">
								This course was previously excluded from SPOT evaluations.
								Changing the status of this course now will submit the course
								for evaluation.
							</p>

							<DialogContentText sx={{ maxWidth: "600px", color: "black" }}>
								If you are sure you want to proceed, please click "
								<span className="text-red-700">Confirm Evaluation</span>" below.
							</DialogContentText>
						</DialogContent>

						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>

							<Button
								onClick={() =>
									props.handleEvaluatedDialogConfirmation(
										props.classForEvaluatedDialog,
										"excluded"
									)
								}
								variant="contained"
								autoFocus
								color="error"
							>
								Confirm Evaluation
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
		</div>
	);
}
