import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Button } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";

function SPOTFacultyDepartmentSearch() {
	// Define state variables using the useState hook
	const [departments, setDepartments] = useState([]); // Store fetched departments data
	const [faculties, setFaculties] = useState([]); // Store fetched faculties data
	const [didFetch, setDidFetch] = useState(false); // Track if data has been fetched
	const [filteredDepts, setFilteredDepts] = useState([]); // Store filtered departments
	const [selectedDept, setSelectedDept] = useState([]); // Store selected department
	var [isError, setIsError] = useState(false); // Flag for error handling
	const navigate = useNavigate(); // Hook for navigation

	// Function to retrieve reports based on selected department or faculty
	function getReports() {
		// Check if neither selectedDept nor filteredDepts contain any items
		if (selectedDept.length < 1 && filteredDepts.length < 1) {
			// Set isError to true to indicate there is an error
			setIsError(true);
		} else {
			if (selectedDept.length < 1) {
				// Navigate to "/DepartmentReports" with the search query parameter "depts"
				// containing an array of the first elements (index 0) from the filteredDepts array

				navigate({
					pathname: "/SPOT-Faculty-Department-Reports",
					search: createSearchParams({
						depts: filteredDepts.map((item) => item.dept_id).toString(),
					}).toString(),
				});
			} else {
				// Navigate to "/DepartmentReports" with the search query parameter "depts"
				// containing an array of the first elements (index 0) from the selectedDept array

				navigate({
					pathname: "/SPOT-Faculty-Department-Reports",
					search: createSearchParams({
						depts: selectedDept[0].toString(),
					}).toString(),
				});
			}
		}
	}

	/**
	 * useEffect hook to fetch faculties and departments data from the backend API.
	 * The data fetching is triggered when the component mounts or when 'didFetch', 'departments', or 'faculties' state changes.
	 */
	useEffect(() => {
		// Get the base URL from the environment variable
		let url = process.env.REACT_APP_BACKEND_HOST;

		// Function to fetch faculties data from the specified endpoint
		function getFaculties() {
			fetch(url.concat("/spot/fetch_faculties"), {
				headers: { Authorization: `Token ${sessionStorage.token}` },
			})
				.then((response) => response.json())
				.then((faculties) => {
					setFaculties(faculties); // Store the fetched faculties data in the 'faculties' state
				});
		}

		// Function to fetch departments data from the specified endpoint
		function getDeparments() {
			fetch(url.concat("/spot/fetch_departments"), {
				headers: { Authorization: `Token ${sessionStorage.token}` },
			})
				.then((response) => response.json())
				.then((departments) => {
					setDepartments(departments); // Store the fetched departments data in the 'departments' state
				});
		}

		// If 'didFetch' is false, it means the data has not been fetched yet
		if (!didFetch) {
			setDidFetch(true); // Set 'didFetch' to true to prevent re-fetching the data on subsequent renders
			getDeparments(); // Fetch departments data
			getFaculties(); // Fetch faculties data
		}
	}, [didFetch, departments, faculties]);

	/**
	 * This function is called when a faculty is selected. It takes the selected faculty name as input.
	 * It filters the 'faculties' array to find the faculty object matching the selected faculty name.
	 * If a matching faculty object is found, it proceeds to filter the 'departments' array to get all departments associated with the selected faculty.
	 * The filtered departments are then stored in the 'filteredDepts' state.
	 *
	 * @param {string} selected_faculty - The name of the selected faculty.
	 */
	function selectFaculty(selected_faculty) {
		// Find the faculty object corresponding to the selected faculty name in the 'faculties' array
		const selectedFacultyObj = faculties.find(
			(faculty) => faculty.fname === selected_faculty
		);

		// If a matching faculty object is found
		if (selectedFacultyObj) {
			// Filter the 'departments' array to get all departments associated with the selected faculty
			const fac_depts = departments.filter(
				(entry) => entry.faculty === selectedFacultyObj.faculty
			);

			// Store the filtered departments in the 'filteredDepts' state
			setFilteredDepts(fac_depts);
		}
	}

	/**
	 * This function is called when a department is selected.
	 * It takes the selected department name as input and performs the following actions:
	 * - If 'selected_dept' is null (no department selected), it sets the 'selectedDept' state to an empty array.
	 * - If 'selected_dept' is not null, it attempts to find the department object in the 'filteredDepts' array that matches the selected department name.
	 *   If a matching department object is found, it sets the 'selectedDept' state to an array containing the department ID and name.
	 *   Otherwise, if 'selected_dept' is not a valid department name (or no match found), it does not update the 'selectedDept' state.
	 *
	 * @param {string|null} selected_dept - The name of the selected department or null if no department is selected.
	 */
	function selectDepartment(selected_dept) {
		// If 'selected_dept' is null (no department selected), set the 'selectedDept' state to an empty array.
		if (selected_dept === null) {
			setSelectedDept([]);
		} else {
			// Attempt to find the department object in the 'filteredDepts' array that matches the selected department name.
			const selectedDepartment = filteredDepts.find(
				(dept) => dept.dname === selected_dept
			);

			// If a matching department object is found
			if (selectedDepartment) {
				// Set the 'selectedDept' state to an array containing the department ID and name.
				const deptValues = [
					selectedDepartment.dept_id,
					selectedDepartment.dname,
				];
				setSelectedDept(deptValues);
			}
		}
	}

	return (
		<div>
			<div className="Bread Crumbs" style={{ paddingLeft: "10px" }}>
				<Breadcrumbs
					separator=">"
					style={{ marginTop: "10px", marginBottom: "20px" }}
				>
					<Link underline="always" color="#275d38" href="/">
						<Typography
							color="#275d38"
							sx={{ display: "flex", alignItems: "center" }}
						>
							<HomeIcon sx={{ mr: 0.5 }} />
							Home
						</Typography>
					</Link>
					<Typography color="text.primary">Reporting</Typography>
					<Typography color="text.primary">
						SPOT Reports by Faculty/Department
					</Typography>
				</Breadcrumbs>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography variant="h2">SPOT Reports by Faculty/Department</Typography>
				<Typography variant="subtitle1" style={{ padding: "20px" }}>
					Explore all publicly available SPOT reports filtered by Faculty and
					Department
				</Typography>

				<div
					style={{
						paddingTop: "10px",
						alignItems: "center",
						flexDirection: "column",
						width: "375px",
					}}
				>
					<Autocomplete
						sx={{ width: "100%", paddingBottom: "10px" }}
						disablePortal
						id="faculty-select"
						options={faculties.map((option) => option.fname)}
						ListboxProps={{ style: { maxHeight: 200, overflowY: "scroll" } }}
						className="dept-home-course"
						onChange={(event, value) => selectFaculty(value)}
						renderInput={(params) => <TextField {...params} label="Faculty" />}
					/>

					<Autocomplete
						sx={{ width: "100%", paddingBottom: "15px" }}
						disablePortal
						clearText="Close"
						id="department-select"
						options={filteredDepts.map((option) => option.dname)}
						ListboxProps={{ style: { maxHeight: 200, overflowY: "scroll" } }}
						className="dept-home-department"
						onChange={(event, value) => {
							selectDepartment(value);
						}}
						renderInput={(params) => (
							<TextField {...params} label="Department (Optional)" />
						)}
					/>

					<div
						style={{
							width: "375px",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<Button
							variant="contained"
							sx={{
								backgroundColor: "#275d38",
								fontSize: "16px",
								width: "100%",
							}}
							onClick={getReports}
						>
							View Reports
						</Button>
						{isError && (
							<p
								style={{
									color: "red",
									textAlign: "center",
									paddingTop: "10px",
								}}
							>
								Please select a valid Faculty from the Faculty drop menu
								(Department is optional)
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default SPOTFacultyDepartmentSearch;
