import styles from "../styles/Footer.module.css";
import Box from "@mui/material/Box";

function Footer() {
	return (
		<footer className={styles.footer}>
			<Box
				sx={{
					width: "100%",
					height: { sm: "100px", md: "100px", lg: "100px", xl: "100px" },
					backgroundColor: "primary.main",
					textAlign: "center",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<font color="white">
					If you have any questions, please contact TSS at 780.492.2741 or by
					email at test.scoring@ualberta.ca
				</font>
				<br />
				<font color="white">Copyright © 2022 University of Alberta</font>
			</Box>
		</footer>
	);
}

export default Footer;
