import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { useState } from "react";

export default function ConfirmNoExamDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} maxWidth="lg" sx={{ textAlign: "center" }}>
				<DialogTitle textAlign={"center"}>
					{props.classId && props.classId.ctitle && (
						<DialogContentText sx={{ color: "black" }}>
							<Typography color="error" component={"span"}>
								Course: {props.classId.ctitle}
							</Typography>
						</DialogContentText>
					)}
					{props.start && props.end && (
						<>
							<DialogContentText sx={{ color: "black" }}>
								<Typography color="error" component={"span"}>
									Survey Start: {props.start}
								</Typography>
							</DialogContentText>
							<DialogContentText sx={{ color: "black" }}>
								<Typography color="error" component={"span"}>
									Survey End: {props.end}
								</Typography>
							</DialogContentText>
						</>
					)}
				</DialogTitle>

				<DialogContent>
					<DialogContentText sx={{ color: "black" }}>
						You are confirming that this class does not have a final exam or final assessment. The
						SPOT survey for this course will be set up with the default survey window for this term.
						If you are sure about this, press "Agree" to continue.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="error" onClick={props.handleDisagree}>
						Cancel
					</Button>
					<Button variant="contained" onClick={() => props.handleAgree(props.classId)} autoFocus>
						Agree
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
