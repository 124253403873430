import { useQuery } from "react-query";
import { getRequisitionsByCCID, getRequisitionsAllData } from "../components/Api/Getters";

export const useRequisitionQuery = (token, ccid, termNumber, options = {}) => {
	const minutes = 1000 * 60;
	const seconds = 1000;
	const queryOptions = {
		refetchInterval: 4 * minutes,
		staleTime: 10 * seconds,
		...options, // spread the options object here
	};

	return useQuery(
		["requisitions", token, ccid, termNumber],
		() => getRequisitionsAllData(token, ccid, termNumber),
		queryOptions
	);
};
