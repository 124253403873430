import { jsPDF } from "jspdf";
import { img2 } from "../../../Requisition/components/images";
import dayjs from "dayjs";

export default function PdfGeneration(classesList, departmentInfo, webOrNot) {
	let deptName = departmentInfo.deptName;
	let term = departmentInfo.term;
	function generatePDF() {
		const doc = new jsPDF();
		doc.addImage(img2, "PNG", 170, 15, 25, 25);
		doc.setDrawColor("#275d38");
		doc.setFontSize(14);
		doc.setFont(undefined, "normal");
		doc.rect(10, 10, 190, 270);
		doc.text("Department:", 15, 25);
		doc.text("Term:", 15, 35);
		doc.setFont(undefined, "bold");
		doc.text(" " + deptName, 50, 25);
		doc.text(" " + term, 50, 35);

		if (webOrNot === "web") {
			doc.setTextColor("#275d38");
			doc.text("Displaying Classes Already In Surveys", 15, 50);
			doc.setTextColor("#000000");
		} else {
			doc.setTextColor("#d32f2f");
			doc.text("Displaying Classes Not In Surveys", 15, 50);
			doc.setTextColor("#000000");
		}

		doc.setFont(undefined, "normal");
		doc.setFontSize(12);

		doc.setDrawColor("#000000");
		doc.rect(12, 60, 186, 8);
		doc.setFontSize(10);
		doc.setFont(undefined, "bold");
		doc.text("Class ID", 16, 65);
		doc.rect(40, 60, 60, 8);
		doc.text("Course Title", 58, 65);
		doc.rect(100, 60, 25, 8);
		doc.text("Enrolled", 105, 65);
		doc.rect(125, 60, 30, 8);
		doc.text("Team Size", 131, 65);
		doc.rect(155, 60, 43, 8);
		doc.text("Instructor", 165, 65);
		doc.setFont(undefined, "normal");

		if (classesList.length > 26 && classesList.length <= 59) {
			let array1 = classesList.slice(0, 26);
			let array2 = classesList.slice(26, classesList.length);
			array1.forEach((cls, index) => {
				let instructorFirstName = "";
				let instructorLastName = "";
				if (cls.name && cls.name !== "") {
					instructorFirstName = cls.name.split(" ")[0];
					instructorLastName = cls.name.split(" ")[1];
				}
				let y = 68 + index * 8;
				doc.rect(12, y, 28, 8);
				let classid = String(cls.classid);
				doc.text(classid, 18, y + 5);
				doc.rect(40, y, 60, 8);
				let ctitle = String(cls.ctitle);
				doc.text(ctitle, 42, y + 5);
				doc.rect(100, y, 25, 8);
				if (cls.csize < 10) {
					doc.text(String(cls.csize), 111, y + 6);
				} else if (cls.csize >= 10 && cls.csize < 100) {
					doc.text(String(cls.csize), 110, y + 6);
				} else {
					doc.text(String(cls.csize), 109, y + 6);
				}

				doc.rect(125, y, 30, 8);

				doc.text(String(cls.teamsize), 139, y + 6);

				doc.rect(155, y, 43, 8);
				doc.setFontSize(9);

				if (cls.teamsize > 1) {
					doc.text("(Several)", 160, y + 5);
				} else {
					const combinedName = instructorFirstName + " " + instructorLastName;
					if (combinedName.length > 22) {
						doc.text(String(instructorLastName), 160, y + 3);
						doc.text(String(instructorFirstName), 160, y + 7);
					} else {
						doc.text(String(combinedName), 160, y + 5);
					}
				}

				doc.setFontSize(10);
			});
			doc.addPage();
			doc.setDrawColor("#275d38");
			doc.rect(10, 10, 190, 270);
			doc.setDrawColor("#000000");
			array2.forEach((cls, index) => {
				let instructorFirstName = "";
				let instructorLastName = "";
				if (cls.name && cls.name !== "") {
					instructorFirstName = cls.name.split(" ")[0];
					instructorLastName = cls.name.split(" ")[1];
				}
				let y = 12 + index * 8;
				doc.rect(12, y, 28, 8);
				let classid = String(cls.classid);
				doc.text(classid, 18, y + 5);
				doc.rect(40, y, 60, 8);
				let ctitle = String(cls.ctitle);
				doc.text(ctitle, 42, y + 5);
				doc.rect(100, y, 25, 8);
				if (cls.csize < 10) {
					doc.text(String(cls.csize), 111, y + 6);
				} else if (cls.csize >= 10 && cls.csize < 100) {
					doc.text(String(cls.csize), 110, y + 6);
				} else {
					doc.text(String(cls.csize), 109, y + 6);
				}

				doc.rect(125, y, 30, 8);

				doc.text(String(cls.teamsize), 139, y + 6);

				doc.rect(155, y, 43, 8);
				doc.setFontSize(9);

				if (cls.teamsize > 1) {
					doc.text("(Several)", 160, y + 5);
				} else {
					const combinedName = instructorFirstName + " " + instructorLastName;
					if (combinedName.length > 22) {
						doc.text(String(instructorLastName), 160, y + 3);
						doc.text(String(instructorFirstName), 160, y + 7);
					} else {
						doc.text(String(combinedName), 160, y + 5);
					}
				}

				doc.setFontSize(10);
			});
		} else if (classesList.length > 59) {
			let firstPage = 26;
			let consecutivePages = 33;
			let extraPages = Math.ceil((classesList.length - 26) / 30);
			let arrayOfArrays = [];
			let array1 = classesList.slice(0, firstPage);
			arrayOfArrays.push(array1);
			for (let i = 1; i <= extraPages; i++) {
				let array = classesList.slice(firstPage + (i - 1) * consecutivePages, firstPage + i * consecutivePages);
				arrayOfArrays.push(array);
			}
			arrayOfArrays.forEach((array, index) => {
				if (index === 0) {
					array.forEach((cls, index) => {
						let instructorFirstName = "";
						let instructorLastName = "";
						if (cls.name && cls.name !== "") {
							instructorFirstName = cls.name.split(" ")[0];
							instructorLastName = cls.name.split(" ")[1];
						}
						let y = 68 + index * 8;
						doc.rect(12, y, 28, 8);
						let classid = String(cls.classid);
						doc.text(classid, 18, y + 5);
						doc.rect(40, y, 60, 8);
						let ctitle = String(cls.ctitle);
						doc.text(ctitle, 42, y + 5);
						doc.rect(100, y, 25, 8);
						if (cls.csize < 10) {
							doc.text(String(cls.csize), 111, y + 6);
						} else if (cls.csize >= 10 && cls.csize < 100) {
							doc.text(String(cls.csize), 110, y + 6);
						} else {
							doc.text(String(cls.csize), 109, y + 6);
						}

						doc.rect(125, y, 30, 8);

						doc.text(String(cls.teamsize), 139, y + 6);

						doc.rect(155, y, 43, 8);
						doc.setFontSize(9);

						if (cls.teamsize > 1) {
							doc.text("(Several)", 160, y + 5);
						} else {
							const combinedName = instructorFirstName + " " + instructorLastName;
							if (combinedName.length > 22) {
								doc.text(String(instructorLastName), 160, y + 3);
								doc.text(String(instructorFirstName), 160, y + 7);
							} else {
								doc.text(String(combinedName), 160, y + 5);
							}
						}

						doc.setFontSize(10);
					});
				} else {
					doc.addPage();
					doc.setDrawColor("#275d38");
					doc.rect(10, 10, 190, 270);
					doc.setDrawColor("#000000");
					array.forEach((cls, index) => {
						let instructorFirstName = "";
						let instructorLastName = "";
						if (cls.name && cls.name !== "") {
							instructorFirstName = cls.name.split(" ")[0];
							instructorLastName = cls.name.split(" ")[1];
						}
						let y = 12 + index * 8;
						doc.rect(12, y, 28, 8);
						let classid = String(cls.classid);
						doc.text(classid, 18, y + 5);
						doc.rect(40, y, 60, 8);
						let ctitle = String(cls.ctitle);
						doc.text(ctitle, 42, y + 5);
						doc.rect(100, y, 25, 8);
						if (cls.csize < 10) {
							doc.text(String(cls.csize), 111, y + 6);
						} else if (cls.csize >= 10 && cls.csize < 100) {
							doc.text(String(cls.csize), 110, y + 6);
						} else {
							doc.text(String(cls.csize), 109, y + 6);
						}

						doc.rect(125, y, 30, 8);

						doc.text(String(cls.teamsize), 139, y + 6);

						doc.rect(155, y, 43, 8);
						doc.setFontSize(9);

						if (cls.teamsize > 1) {
							doc.text("(Several)", 160, y + 5);
						} else {
							const combinedName = instructorFirstName + " " + instructorLastName;
							if (combinedName.length > 22) {
								doc.text(String(instructorLastName), 160, y + 3);
								doc.text(String(instructorFirstName), 160, y + 7);
							} else {
								doc.text(String(combinedName), 160, y + 5);
							}
						}

						doc.setFontSize(10);
					});
				}
			});
		} else {
			classesList.forEach((cls, index) => {
				let instructorFirstName = "";
				let instructorLastName = "";
				if (cls.name && cls.name !== "") {
					instructorFirstName = cls.name.split(" ")[0];
					instructorLastName = cls.name.split(" ")[1];
				}
				let y = 68 + index * 8;
				doc.rect(12, y, 28, 8);
				let classid = String(cls.classid);
				doc.text(classid, 18, y + 5);
				doc.rect(40, y, 60, 8);
				let ctitle = String(cls.ctitle);
				doc.text(ctitle, 42, y + 5);
				doc.rect(100, y, 25, 8);
				if (cls.csize < 10) {
					doc.text(String(cls.csize), 111, y + 6);
				} else if (cls.csize >= 10 && cls.csize < 100) {
					doc.text(String(cls.csize), 110, y + 6);
				} else {
					doc.text(String(cls.csize), 109, y + 6);
				}

				doc.rect(125, y, 30, 8);

				doc.text(String(cls.teamsize), 139, y + 6);

				doc.rect(155, y, 43, 8);
				doc.setFontSize(9);

				if (cls.teamsize > 1) {
					doc.text("(Several)", 160, y + 5);
				} else {
					const combinedName = instructorFirstName + " " + instructorLastName;
					if (combinedName.length > 22) {
						doc.text(String(instructorLastName), 160, y + 3);
						doc.text(String(instructorFirstName), 160, y + 7);
					} else {
						doc.text(String(combinedName), 160, y + 5);
					}
				}

				doc.setFontSize(10);
			});
		}
		if (webOrNot === "web") {
			doc.save(deptName + "_in_surveys.pdf");
		} else {
			doc.save(deptName + "_not_in_surveys.pdf");
		}
	}

	generatePDF();
}
