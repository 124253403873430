import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Modal, Typography } from "@mui/material";
import RequisitionCard from "./RequisitionCard";
import { postRequisitions, postClasses, postItemList } from "../../../../components/Api/Post";
import SubmitAutoDialog from "./Dialogs/SubmitAutoDialog";
import dayjs from "dayjs";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1000,
	height: 800,
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 4,
	display: "flex",
	flexDirection: "column",
};

export default function RequisitionDisplay(props) {
	const [submittedOpen, setSubmittedOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const token = sessionStorage.getItem("token");

	async function handleClose(event, reason) {
		if (reason && reason == "backdropClick") return;
		if (event.target.name == "cancel") {
			props.setOpen(false);
			return;
		}
		if (event.target.name == "submit") {
			setLoading(true);
			//consolidate requisitions that have the same block, end date, and team teaching status
			//block.blkid, end_date,rptits > 0
			//then post to the database

			try {
				await props.requisitions.forEach((req) => {
					const classArray = req.classes;
					const itemsArray = req.items;
					const requisition = req;
					postReqToDB(requisition, classArray, itemsArray);
				});
				setLoading(false);
				setSubmittedOpen(true);
			} catch (err) {
				console.log(err);
			}
		}
	}
	const TTClsnums = [
		11, 21, 31, 41, 51, 61, 71, 81, 91, 101, 111, 121, 131, 141, 151, 161, 171, 181, 191, 201, 211,
		221, 231, 241, 251, 261, 271, 281, 291, 301, 311, 321, 331, 341, 351, 361, 371, 381, 391, 401,
		411, 421, 431, 441, 451, 461, 471, 481, 491, 501, 511, 521, 531, 541, 551, 561, 571, 581, 591,
		601, 611, 621, 631, 641, 651, 661, 671, 681, 691, 701, 711, 721, 731, 741, 751, 761, 771, 781,
		791, 801, 811, 821, 831, 841, 851, 861, 871, 881, 891, 901, 911, 921, 931, 941, 951, 961, 971,
		981, 991,
	];

	const consolidateReqs = () => {
		const consolidatedRequisitions = [];

		for (const req of props.requisitions) {
			const existingReq = consolidatedRequisitions.find(
				(consolidatedReq) =>
					consolidatedReq.block.blkid === req.block.blkid &&
					consolidatedReq.end_date === req.end_date &&
					consolidatedReq.rptits > 0 === req.rptits > 0 &&
					consolidatedReq.rptits === req.rptits
			);

			if (existingReq) {
				//get proper clsnum for new classes to be added
				const isTeamTeaching = req.rptits > 0;
				let clsnum = 0;
				let listOfTTClsNums = [];
				existingReq.classes.forEach((cls) => {
					if (!isTeamTeaching) {
						if (cls.clsnum > clsnum) {
							clsnum = cls.clsnum;
						}
					}
					if (TTClsnums.includes(cls.clsnum)) {
						listOfTTClsNums.push(cls.clsnum);
					}
				});
				if (isTeamTeaching) {
					clsnum = TTClsnums.filter((clsnum) => !listOfTTClsNums.includes(clsnum))[0];
				} else {
					clsnum += 1;
				}
				const sameClassIDDict = {};
				req.classes.forEach((cls) => {
					if (isTeamTeaching) {
						console.log(clsnum);
						//group classes that have the same classid together

						if (sameClassIDDict[cls.classid]) {
							sameClassIDDict[cls.classid].push(cls);
						} else {
							sameClassIDDict[cls.classid] = [cls];
						}
					} else {
						cls.clsnum = clsnum;
						clsnum += 1;
					}
					cls.reqnum = existingReq.reqnum;
				});
				console.log(sameClassIDDict);
				if (Object.keys(sameClassIDDict).length > 0) {
					Object.keys(sameClassIDDict).forEach((classid) => {
						//assign clsnum to each class
						//teamTeachingKey === "true" ? (index1 + 1) * 10 + (index2 + 1) : index1 + 1,
						sameClassIDDict[classid].forEach((cls, index) => {
							cls.clsnum = clsnum + index;
						});
						//bring clsnum to the next value in TTClsnums
						clsnum = TTClsnums[TTClsnums.indexOf(clsnum) + 1];
					});
				}
				//add sameClassIDDict classes to existingReq
				///change reqnum of added classes to existingReq.reqnum
				//add items to existingReq

				existingReq.classes.push(...req.classes);
			} else {
				consolidatedRequisitions.push({ ...req });
			}
		}
		props.setRequisitions(consolidatedRequisitions);
		console.log(consolidatedRequisitions);
	};

	const handleEvent = (params, event) => {
		event.stopPropagation();
	};

	const postReqToDB = async (requisition, classArray, itemsArray) => {
		try {
			postRequisitions(token, requisition)
				.then(
					setTimeout(() => {
						postClasses(token, classArray);
					}, 1000)
				)
				.then(
					setTimeout(() => {
						postItemList(token, itemsArray);
					}, 1000)
				)
				.catch((err) => {
					console.log(err);
				});
		} catch (err) {
			console.log(err);
		}
	};

	const deleteRequisition = (reqnum) => {
		const tempRequisitions = props.requisitions.filter((req) => req.reqnum != reqnum);
		props.setRequisitions(tempRequisitions);
	};

	const updateRequisition = (reqnum, newStartDate, newEndDate) => {
		props.setRequisitions((prevRequisitions) =>
			prevRequisitions.map((req) =>
				req.reqnum === reqnum
					? {
							...req,
							start_date: dayjs(newStartDate).format("YYYY-MM-DD"),
							end_date: dayjs(newEndDate).format("YYYY-MM-DD"),
					  }
					: req
			)
		);
	};

	const deleteClasses = (reqnum, classids) => {
		const tempRequisitions = props.requisitions.map((req) => {
			if (req.reqnum === reqnum) {
				req.classes = req.classes.filter((cls) => !classids.includes(cls.classid));
			}
			return req;
		});
		props.setRequisitions(tempRequisitions);
	};

	return (
		<div>
			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-evenly",
							gap: "5px",
							flexWrap: "wrap",
							overflowY: "scroll",
							height: "100%",
							paddingBottom: "10px",
						}}
					>
						{props.requisitions.map((req) => {
							return (
								<RequisitionCard
									key={req.reqnum}
									reqnum={req.reqnum}
									block={req.block.blkid}
									rptits={req.rptits}
									startDate={req.start_date}
									endDate={req.end_date}
									items={req.items}
									classes={req.classes}
									requisition={req}
									deleteRequisition={deleteRequisition}
									updateRequisition={updateRequisition}
									deleteClasses={deleteClasses}
								/>
							);
						})}
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: "5px",
							width: "98%",
							marginLeft: "2%",
							marginBottom: "-15px",
							marginTop: "20px",
						}}
					>
						<Button variant="contained" onClick={handleClose} name="cancel" color="error" fullWidth>
							Cancel and Return
						</Button>
						<Button variant="contained" onClick={consolidateReqs} fullWidth>
							Consolidate Requisitions
						</Button>
						<Button variant="contained" onClick={handleClose} fullWidth name="submit">
							Finalize and Submit All
						</Button>
					</Box>
				</Box>
			</Modal>
			<SubmitAutoDialog open={submittedOpen} setOpen={setSubmittedOpen} loading={loading} />
		</div>
	);
}
