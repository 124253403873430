import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Box, FormControl, Typography } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { ClassIdsContext } from "../../../../../../context/RequisitionContext";
import isAuthenticated from "../../../../../../components/Utils/isAuthenticated";
import {
	useFacultyRank,
	useAddInstructorToFacultyRank,
} from "../../../../../../Hooks/useFacultyRankQuery";
import { useRolesAddInstructor } from "../../../../../../Hooks/useRolesQuery";
import { useUpdateClassIdTeamSize } from "../../../../../../Hooks/useClassIdsQuery";
import RolesList from "../RolesList";
import FacultyRankAutocomplete from "../FacultyRankAutocomplete";
import ListSelect from "../../../../../../components/Utils/ListSelect";

const roles = ["PI", "TA", "CC", "CO", "LA", "GL", "AI"];

export default function AddInstructorDialog(props) {
	let auth = isAuthenticated();
	const token = auth.token;
	const { data: facultyRank } = useFacultyRank(token);

	const { classIds, setClassIds } = useContext(ClassIdsContext);
	const [deptId, setDeptId] = useState("");
	const [instructorRole, setInstructorRole] = useState("");
	const [existingRoles, setExistingRoles] = useState([]);
	const [newRoleValue, setNewRoleValue] = useState("");

	useEffect(() => {
		if (props.currentRow.dept) setDeptId(props.currentRow.dept);
	}, [props.currentRow]);

	const { addInstructorToFacultyRank, status: statusPostFacultyRank } =
		useAddInstructorToFacultyRank(token, deptId);
	const { addInstructorToRoles, status: statusPostRoles } = useRolesAddInstructor(token);

	const addRoles = async (role) => {
		const success = await addInstructorToRoles(role);
		return success;
	};

	const addInstructor = async (instructorToAdd) => {
		const success = await addInstructorToFacultyRank(instructorToAdd);
		return success;
	};

	const handleClose = () => {
		setExistingRoles([]);
		props.setOpen(false);
	};

	const handleChangeRole = (event) => {
		setInstructorRole(event.target.value);
	};

	const mutation = useUpdateClassIdTeamSize(token);

	const handleAddInstructor = async () => {
		let currentRow = props.currentRow;
		let tempClassIds = classIds;
		let instructorToAdd = newRoleValue;
		let newRole = {};
		const tempExistingRoles = [...existingRoles];
		if (props.inInstance) {
			props.rows.forEach((classObj) => {
				if (!classObj.new || classObj.new === false) {
					const classid = currentRow.classid;
					newRole = {
						ccid: instructorToAdd.ccid,
						class_role: instructorRole,
						rank: instructorRole,
						checked: false,
						classid: classid,
						key: Number(instructorToAdd.empid) + classid,
						disabled: false,
						empid: instructorToAdd.empid,
						name: instructorToAdd.name,
						role_description: "Primary Instructor",
						roleid: Number(instructorToAdd.empid) + classid,
						term: classObj.term,
						import_status: "MANUAL",
					};
					const rowsCopy = [...props.rows];
					rowsCopy.forEach((row) => {
						if (row.classid === currentRow.classid) {
							//if row.roles already has this same role, don't add it
							const foundRole = row.roles.find((role) => role.empid === instructorToAdd.empid);
							if (foundRole) {
								return;
							} else {
								row.name = instructorToAdd.name;
								row.empid = instructorToAdd.empid;
								row.rank = instructorRole;
								row.teamsize += 1;
								row.comb_classes = [];
								row.roles.push(newRole);
								tempExistingRoles.push(newRole);
							}
						}
					});

					setExistingRoles(tempExistingRoles);
					props.setRows(rowsCopy);
				}
			});
		} else {
			tempClassIds.forEach((classObj) => {
				if (classObj.classid === currentRow.classid) {
					const classid = classObj.classid;
					newRole = {
						ccid: instructorToAdd.ccid,
						class_role: instructorRole,
						rank: instructorRole,
						checked: false,
						classid: classid,
						disabled: false,
						empid: instructorToAdd.empid,
						name: instructorToAdd.name,
						role_description: "Primary Instructor",
						roleid: Number(instructorToAdd.empid) + classid,
						term: classObj.term,
						import_status: "MANUAL",
					};
					if (classObj.teamsize === 0 || (classObj.teamsize !== 0 && classObj.roles.length === 0)) {
						classObj.name = instructorToAdd.name;
						classObj.empid = instructorToAdd.empid;
						classObj.rank = instructorRole;
						classObj.teamsize = 1;
						classObj.comb_classes = [];
						classObj.roles = [newRole];
						mutation.mutate({ classid: classid, teamsize: 1 });
					} else {
						classObj.roles.push(newRole);
						const teamNames = classObj.roles.map((role) => "'" + role.name + "'");
						const teamName = teamNames.join(", ");
						classObj.teamName = teamName;
						classObj.teamsize = classObj.teamsize += 1;
						mutation.mutate({ classid: classid, teamsize: classObj.teamsize });
					}
				}
			});
			setClassIds(tempClassIds);
			setInstructorRole("");
			await addRoles(newRole);
			props.setOpen(false);
		}

		//update classIds
		//maybe refetch classids and roles?
	};

	const checkExistingFacultyRank = (empid) => {
		let exists = false;
		facultyRank.forEach((rank) => {
			if (rank.empid === empid) {
				exists = true;
			}
		});
		return exists;
	};

	const handleCloseInInstance = () => {
		if (props.currentRow.roles.length === 0) {
			//delete from rows
			const rowsCopy = [...props.rows];
			const newRows = rowsCopy.filter((row) => row.classid !== props.currentRow.classid);
			props.setRows(newRows);
			setExistingRoles([]);
			props.setOpen(false);
		} else {
			setExistingRoles([]);
			props.setOpen(false);
		}
	};

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={props.inInstance ? handleClose : handleCloseInInstance}
				maxWidth="lg"
			>
				<FormControl component="fieldset" sx={{ p: 2 }}>
					<Typography fontSize={24} fontWeight={600}>
						{props.currentRow.classid} - {props.currentRow.ctitle}
					</Typography>
				</FormControl>
				{props.inInstance ? (
					<IconButton
						aria-label="close"
						onClick={handleCloseInInstance}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				) : (
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				)}

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						p: 2,
						justifyContent: "center",
					}}
				>
					{existingRoles.length > 0 && <RolesList list={existingRoles} />}
					<br />
					<Typography fontSize={18} fontWeight={500}>
						Search for an instructor to add them to this class. If you need to remove an instructor
						for the purpose of the survey: close this popup, add the class normally, and refrain
						from selecting that instructor.
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "20px" }}>
						<FacultyRankAutocomplete
							roles={roles}
							options={facultyRank}
							currentRow={props.currentRow}
							setExistingRoles={setExistingRoles}
							newRoleValue={newRoleValue}
							setNewRoleValue={setNewRoleValue}
							checkExistingFacultyRank={checkExistingFacultyRank}
							addInstructorToFacultyRank={addInstructor}
						/>
						<ListSelect
							required
							label="Role"
							value={instructorRole}
							onChange={handleChangeRole}
							list={roles}
							width={100}
						/>
					</Box>
					<Button
						disabled={instructorRole === ""}
						variant="contained"
						onClick={handleAddInstructor}
					>
						Add
					</Button>
					{props.inInstance && (
						<Button
							variant="outlined"
							/* color="error" */
							onClick={handleCloseInInstance}
							sx={{ marginTop: "5px" }}
						>
							Done
						</Button>
					)}
				</Box>
			</Dialog>
		</div>
	);
}
