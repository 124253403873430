import { useQuery } from "react-query";
import { getAutoRolesByDept } from "../components/Api/Getters";

export const useAutoRolesQuery = (token, dept_id, options = {}) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		enabled: !!token && !!dept_id,
		...options, // spread the options object here
	};
	return useQuery(
		["autoRolesQuery", token],
		() => getAutoRolesByDept(token, dept_id),
		queryOptions
	);
};
