import * as React from "react";
import { TextField } from "@mui/material";

export default function TextInput(props) {
	return (
		<div>
			<TextField
				autoComplete={props.autoComplete}
				type={props.type}
				multiline={props.multiline}
				scrollable={props.scrollable}
				minRows={props.minRows}
				maxRows={props.maxRows}
				error={props.error}
				required={props.required}
				helperText={props.helperText}
				disabled={props.disabled}
				value={props.value || [""]}
				name={props.name}
				label={props.label}
				onChange={props.onChange}
				variant="outlined"
				inputProps={props.inputProps}
				sx={{
					width: props.width ? props.width : "100%",
					marginBottom: props.marginBottom ? props.marginBottom : "20px",
				}}
			></TextField>
		</div>
	);
}
