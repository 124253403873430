import React from "react";
import styles from "../styles/info.module.css";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import {
	Select,
	FormHelperText,
	FormLabel,
	FormControl,
	Switch,
	Stack,
	Typography,
	InputLabel,
	ListSubheader,
} from "@mui/material";
import dayjs from "dayjs";

// Define a color mapping for terms
const termColors = {
	"(current)": "black",
	"(future)": "gray",
};

function ClassDropDown(props) {
	// Group classes by term
	const today = dayjs();
	const groupedClasses = props.listValues.reduce((groups, item) => {
		let term = item.term || "Unknown Term";
		const term_start = dayjs(item.term_start);
		const term_end = dayjs(item.term_end);
		if (today.isBefore(term_start)) {
			term += " (future)";
		} else if (today.isAfter(term_start) && today.isBefore(term_end)) {
			term += " (current)";
		}

		if (!groups[term]) {
			groups[term] = {
				classes: [],
				term_start,
				term_end,
			};
		}
		groups[term].classes.push(item);
		return groups;
	}, {});

	// Sort the terms in descending order based on term_start and term_end
	const sortedTerms = Object.keys(groupedClasses).sort((a, b) => {
		const termAStart = dayjs(groupedClasses[a].term_start);
		const termBStart = dayjs(groupedClasses[b].term_start);

		//get largest term_start
		if (termAStart.isBefore(termBStart)) {
			return -1;
		} else if (termAStart.isAfter(termBStart)) {
			return 1;
		}

		// Sort by term_start first, and if equal, by term_end
	});

	const renderMenuItems = () => {
		return sortedTerms.flatMap((term) => [
			<ListSubheader key={`header-${term}`}>{term}</ListSubheader>,
			...groupedClasses[term].classes.map((option) => (
				<MenuItem
					key={option.key}
					value={option.value}
					style={{ color: termColors[term.split(" ")[2]] || "black" }}
				>
					{option.label} : {option.name} : {option.value}
				</MenuItem>
			)),
		]);
	};

	return (
		<div>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "baseline",
				}}
			>
				<div className={props.className}>
					<Typography sx={{ marginLeft: "20px" }}>{props.question}</Typography>
					<Stack direction="row" alignItems="center" sx={{ marginLeft: "0px" }}>
						<Switch
							checked={props.checked}
							onChange={props.onChangeSwitch}
							disabled={props.disabled}
						/>
						<Typography>Multiple Classes</Typography>
					</Stack>
					<div>
						<FormControl>
							<InputLabel sx={{ marginLeft: "10px" }}>{props.inputlabel}</InputLabel>
							<Select
								value={props.value}
								name={props.name}
								error={props.error}
								label={props.inputlabel}
								multiple={props.multiple}
								onChange={props.onChange}
								variant="outlined"
								sx={{
									color: "theme.palette.secondary",
									marginRight: "20px",
									marginLeft: "10px",
									minWidth: "150px",
									width: "300px",
								}}
							>
								{renderMenuItems()}
							</Select>
							<FormHelperText error sx={{ marginLeft: "30px" }}>
								{props.helperText}
							</FormHelperText>
						</FormControl>
					</div>
				</div>
			</Box>
		</div>
	);
}

export default ClassDropDown;
