import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import dayjs from "dayjs";

export default function NoInstructorTable(props) {
	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "flex-start" }}>
				<GridFooter sx={{ position: "absolute", right: "10px" }} />
			</GridFooterContainer>
		);
	};

	const renderAddInstructor = (params) => {
		return (
			<Button
				/* disabled={params.row.noInstructor === false} */
				variant="contained"
				size="small"
				sx={{ padding: "1px" }}
				onClick={() => {
					props.setOpen(true);
					props.setSelectedRow(params.row);
				}}
			>
				Add
			</Button>
		);
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class Id",
			width: 70,
		},
		{
			field: "c_sdate",
			headerName: "Start Date",
			renderCell: (params) => {
				return dayjs(params.row.c_sdate).format("DD-MM-YYYY");
			},
			width: 92,
		},
		{
			field: "c_edate",
			headerName: "End Date",
			renderCell: (params) => {
				return dayjs(params.row.c_edate).format("DD-MM-YYYY");
			},
			width: 92,
		},
		{
			field: "examdate",
			headerName: "Exam Date",
			renderCell: (params) => {
				if (params.row.examdate === null) {
					return null;
				}
				return dayjs(params.row.examdate).format("DD-MM-YYYY");
			},
			width: 92,
		},
		{
			field: "ctitle",
			headerName: "Title",
			width: 120,
			flex: 1,
		},
		{
			field: "name",
			headerName: "Instructor Name",
			width: 120,
			renderCell: (params) => {
				if (params.row.roles.length === 1) {
					if (!params.row.name) {
						return params.row.roles[0].name;
					}
				} else if (params.row.roles.length > 1) {
					return "(Several)";
				} else if (params.row.rolesFiltered) {
					return <span style={{ color: "red" }}>Instructors Filtered Out By Rules</span>;
				}

				return params.row.name;
			},
			flex: 1,
		},
		{
			field: "c_location",
			headerName: "Location",
			align: "center",
			width: 80,
		},
		{
			field: "teamsize",
			headerName: "Team Size",
			align: "center",
			width: 80,
		},
		{
			field: "csize",
			headerName: "Enrolled",
			align: "center",
			width: 80,
		},
		{
			field: "addinstructor",
			headerName: "Add",
			headerAlign: "center",
			width: 90,
			align: "center",
			renderCell: renderAddInstructor,
		},
	];

	return (
		<div style={{ display: "flex", height: "100%", width: "100%" }}>
			<DataGrid
				sx={{
					"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
						outline: "Nne !important",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
						width: "0.4em",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
						background: "#f1f1f1",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
						backgroundColor: "#888",
					},
					"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
						background: "#555",
					},

					borderRadius: "1px 1px 4px 4px",
				}}
				disableSelectionOnClick
				columnVisibilityModel={{}}
				components={{ Footer: CustomFooter }}
				rows={props.rows}
				density="compact"
				slots={{}}
				columns={columns}
				initialState={{
					pagination: {
						paginationModel: { pageSize: 100, page: 0 },
					},
				}}
				pageSizeOptions={[5, 10]}
				getRowId={(row) => row.classid}
			/>
		</div>
	);
}
