import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function UndoCombineDialog(props) {
	const handleClickOpen = () => {
		props.setOpen(true);
	};

	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<React.Fragment>
			<Dialog
				open={props.open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
			>
				<DialogTitle id="alert-dialog-title">{"Confirm Undo Combination"}</DialogTitle>
				<DialogContent className="flex flex-col gap-4">
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to undo the combination of these classes?
					</DialogContentText>
					<DialogContentText id="alert-dialog-description" color="error">
						IMPORTANT: Any changes made to the original child classes of this combined class will be
						lost. This includes end dates, exam dates, instructor additions/removals, etc.
					</DialogContentText>
					<DialogContentText id="alert-dialog-description">
						Please confirm that you want to proceed with the undo combination.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Disagree</Button>
					<Button onClick={props.handleUndo} autoFocus>
						Agree and Save
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
