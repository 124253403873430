import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { Zoom } from "@mui/material";
//9 answer keys
//there are cases where multiple keys but C=0. Factor into advanced. E with weights

function NumKeysDropdown(props) {
	return (
		<div>
			<Box
				sx={{
					// border: "1px dotted grey",
					// borderRadius: "25px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					width: "400px",
				}}
			>
				<Tooltip TransitionComponent={Zoom} title={props.tooltipText} placement="right-start">
					<Box sx={{ width: "100%" }}>
						<InputLabel sx={{ display: "flex", justifyContent: "space-between", color: "black" }}>
							{props.question}{" "}
							<HelpIcon
								sx={{
									color: "gray",
								}}
							/>
						</InputLabel>
						<TextField
							select
							helperText={props.helpText}
							value={props.value || ""}
							onChange={props.onChange}
							name={props.name}
							defaultValue={props.defaultValue}
							variant="outlined" //set width
							sx={{
								color: "theme.palette.secondary",
								width: "100%",
							}}
						>
							{props.listValues.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Box>
				</Tooltip>
			</Box>
		</div>
	);
}

export default NumKeysDropdown;
