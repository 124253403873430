import { useQuery, useMutation, useQueryClient } from "react-query";
import { postRoles } from "../components/Api/Post";

export function useRolesAddInstructor(token) {
	const queryClient = useQueryClient();

	const mutation = useMutation(
		(role) => {
			return postRoles({ token, role });
		},
		{
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries("rolesFromClassIdList");
			},
		}
	);

	const addInstructorToRoles = async (role) => {
		const response = await mutation.mutateAsync(role);
		return response.status === 201;
	};

	return { addInstructorToRoles, status: mutation.status };
}
