import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';


function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}


function organizeData(data){
    const organized_data = []
    for (let i = 0; i < data.length; i++) {
        let inserted = false;

        for (let j = 0; j < organized_data.length; j++) {
            if (organized_data[j][0].category === data[i].category && arraysAreEqual(organized_data[j][0].scale,data[i].scale)) {
                organized_data[j].push(data[i]);
                inserted = true;
                break;
            }
        }

        if (!inserted) {
            organized_data.push([data[i]]);
        }
    }

    return organized_data
}


function SupplementaryTable(data){

    const organized_data = organizeData(data) 

	const getRowSpan = (data, index) => {
        let count = 1;
		while (
			index + count < data.length &&
			data[index].category === data[index + count].category
		) {
			count++;
		}
		return count;
    };

	return (
        <div>
            {organized_data.map((group, index) => (
                <React.Fragment key={index}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead style={{ borderRight: '2px solid #ccc', borderLeft: '2px solid #ccc',
                                                borderTop: '2px solid #ccc', backgroundColor: 'rgba(39, 93, 56,1)'}}>
                                <TableRow >
                                    <TableCell style={{ borderRight: '1px solid #ccc',width:'15%'}} align="center" >
                                        <Typography variant="body2" fontWeight="bold" style={{ color: 'white'}}>
                                            SPOT DOMAIN
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ borderRight: '1px solid #ccc',width:'40%'}} align="center">
                                        <Typography variant="body2" fontWeight="bold" style={{color: 'white'}}>
                                            Question
                                        </Typography>
                                    </TableCell>
                                    {group[0].scale.map((response, index) => (
                                    <TableCell style={{ borderRight: '1px solid #ccc', width: "9%" }} align="center" key={index}>
                                        <Typography variant="body2" fontWeight="bold" style={{color: 'white'}}>
                                            {response}
                                        </Typography>
                                    </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody style={{ borderRight: '2px solid #ccc', borderLeft: '2px solid #ccc',
                                                borderBottom: '2px solid #ccc'}}>
                                
                                {group.map((row,index2) => (
                                    <TableRow key={index2}>
                                        {index2 === 0 || group[index2].category !== group[index2 - 1].category ? (
                                        <TableCell align="center" style={{ borderRight: '1px solid #ccc'}} 
                                            rowSpan={getRowSpan(group, index2)}>
                                            {row.category}
                                        </TableCell>
                                        ) : null}
                                        <TableCell align="left" style={{ borderRight: '1px solid #ccc'}}>
                                            {row.question}</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid #ccc'}}>
                                            {row.rsp1}</TableCell >
                                        <TableCell align="center" style={{ borderRight: '1px solid #ccc'}}>
                                            {row.rsp2}</TableCell>
                                        <TableCell align="center" style={{ borderRight: '1px solid #ccc'}}>
                                            {row.rsp3}</TableCell>
                                        <TableCell  align="center" style={{ borderRight: '1px solid #ccc'}}>
                                            {row.rsp4}</TableCell>
                                        <TableCell  align="center">
                                            {row.rsp5}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br></br>
                </React.Fragment>
            ))}
           
        </div>
    );
}

export {SupplementaryTable}