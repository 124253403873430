import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";

export default function RolePicker(props) {
	return (
		<div>
			<TextField
				select
				required={props.required}
				size={props.size}
				disabled={props.disabled}
				error={props.error}
				value={props.value || ""}
				name={props.name}
				// label={props.label}
				multiple={props.multiple}
				onChange={props.onChange}
				variant="standard"
				sx={{}}
				SelectProps={{
					multiple: props.multiple,
					value: props.value || "",
					onChange: props.onChange,
				}}
			>
				{props.list ? (
					props.list.map((option) => (
						<MenuItem
							key={option}
							value={option}
						>
							{option}
						</MenuItem>
					))
				) : (
					<MenuItem
						key="1"
						value={""}
					></MenuItem>
				)}
			</TextField>
		</div>
	);
}
