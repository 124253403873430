import axios from "axios";

const url = process.env.REACT_APP_BACKEND_HOST.concat("/spot/");

export async function deleteAdjustedClassId({ token, classid }) {
	return new Promise((resolve, reject) => {
		axios({
			method: "delete",
			url: url + "delete_adjusted_classid_class_adjust/".concat(`${classid}`),

			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function deleteItemList(token, reqnum) {
	return new Promise((resolve, reject) => {
		axios({
			method: "delete",
			url: url + "delete_itemlist".concat(`/${reqnum}`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function deleteItemsUnique(token, reqnum) {
	return new Promise((resolve, reject) => {
		axios({
			method: "delete",
			url: url + "delete_items_unique".concat(`/${reqnum}`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function deleteClasses(token, reqnum) {
	return new Promise((resolve, reject) => {
		axios({
			method: "delete",
			url: url + "delete_classes".concat(`/${reqnum}`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export async function deleteRequisition(token, reqnum) {
	return new Promise((resolve, reject) => {
		axios({
			method: "delete",
			url: url + "delete_requisition".concat(`/${reqnum}`),
			headers: {
				Authorization: `Token ${token}`,
			},
		})
			.then((response) => {
				if (response.status === 204) {
					resolve(response.data);
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}
