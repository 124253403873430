//All imports happen at the top of the file
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
	RequisitionsContext,
	ClassIdsContext,
	WebClassesContext,
	TermContext,
} from "../../../../context/RequisitionContext";
import ModifyRequisitionsTable from "./components/Tables/ModifyRequisitionsTable";
import ViewReqModal from "./components/Modals/ViewReqModal";
import { useClassIdObjectsByDeptAndTerm } from "../../../../Hooks/useClassIdsQuery";
import { useRequisitionQuery } from "../../../../Hooks/useRequisitionQuery";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import { useTermsQueryFull } from "../../../../Hooks/useTermsQuery";

export default function ModifyRequisition() {
	const auth = isAuthenticated();
	const token = auth.token;
	const ccid = auth.ccid;
	const [requisitions, setRequisitions] = useState([]);
	const [openViewReqModal, setOpenViewReqModal] = useState(false);
	const [rowData, setRowData] = useState({});
	const [deptId, setDeptId] = useState(null);
	const [classIds, setClassIds] = useState([]);
	const [itemListSet, setItemListSet] = useState(false);
	const [userInteracting, setUserInteracting] = useState(false);
	const [termNumber, setTermNumber] = useState(null);

	const [termValue, setTermValue] = useState(null);
	const [terms, setTerms] = useState([]);

	const { data: termsList } = useTermsQueryFull(token);

	useEffect(() => {
		if (termsList && termsList.length > 0) {
			//concat term number and term description
			const termDescriptions = termsList.map((term) => term.desc);
			const termNumbers = termsList.map((term) => term.term);
			const terms = termNumbers.map((term, index) => {
				return termDescriptions[index] + " (" + term + ")";
			});
			setTerms(terms);
			//get highest term number and set as default
			const highestTermNumber = Math.max(...termNumbers);
			const highestTermIndex = termNumbers.indexOf(highestTermNumber);
			const highestTerm = terms[highestTermIndex];
			setTermValue(highestTerm);
		}
	}, [termsList]);

	useEffect(() => {
		//get term number from term description
		if (
			termsList &&
			termsList.length > 0 &&
			termValue &&
			termValue.length > 0
		) {
			//get value in brackets of termValue
			const termNumber = termValue.match(/\(([^)]+)\)/)[1];
			setTermNumber(Number(termNumber));
		}
	}, [termsList, termValue]);

	const {
		data: requisitionData,
		status: requisitionStatus,
		isLoading: requisitionsLoading,
		isFetching: requisitionsFetching,
	} = useRequisitionQuery(token, ccid, termNumber, {
		enabled: userInteracting !== true && !!termNumber && !!ccid && !!token,
		refetchOnWindowFocus: false,
	});

	const { data: fetchedClassIds, status: fetchedClassIdsStatus } =
		useClassIdObjectsByDeptAndTerm(token, deptId, termNumber, {
			enabled: !!token && !!deptId && !!termNumber,
			staleTime: 1000 * 5,
			refetchOnWindowFocus: false,
		});

	const [openEditItems, setOpenEditItems] = useState(false);
	const [openEditClasses, setOpenEditClasses] = useState(false);

	useEffect(() => {
		if (openEditItems || openEditClasses) {
			setUserInteracting(true);
		} else {
			setUserInteracting(false);
		}
	}, [openEditItems, openEditClasses]);

	useEffect(() => {
		if (requisitionStatus === "success") {
			setRequisitions(requisitionData);
		}
	}, [requisitionData, requisitionStatus]);
	useEffect(() => {
		if (fetchedClassIdsStatus === "success") {
			setClassIds(fetchedClassIds);
		}
	}, [token, fetchedClassIds, fetchedClassIdsStatus]);

	return (
		<RequisitionsContext.Provider value={{ requisitions, setRequisitions }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100vw",
					height: "100vh",
				}}
			>
				<Box
					sx={{
						display: "flex",
						width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
						height: { xs: "100%", sm: "100%", md: "80%", lg: "80%" },
						marginTop: "50px",
						paddingRight: "20px",
					}}
				>
					<ModifyRequisitionsTable
						setOpenViewReqModal={setOpenViewReqModal}
						rowData={rowData}
						setRowData={setRowData}
						itemListSet={itemListSet}
						terms={terms}
						termValue={termValue}
						setTermValue={setTermValue}
						termsList={termsList}
						termNumber={termNumber}
						requisitions={requisitions}
						setRequisitions={setRequisitions}
						requisitionsLoading={requisitionsLoading}
						requisitionsFetching={requisitionsFetching}
					/>
					<ClassIdsContext.Provider value={{ classIds, setClassIds }}>
						<ViewReqModal
							open={openViewReqModal}
							setOpen={setOpenViewReqModal}
							rowData={rowData}
							setRowData={setRowData}
							deptId={deptId}
							setDeptId={setDeptId}
							setItemListSet={setItemListSet}
							termValue={termValue}
							setTermValue={setTermValue}
							requisitionsLoading={requisitionsLoading}
							requisitionsFetching={requisitionsFetching}
							userInteracting={userInteracting}
							terms={terms}
							termsList={termsList}
							openEditItems={openEditItems}
							setOpenEditItems={setOpenEditItems}
							openEditClasses={openEditClasses}
							setOpenEditClasses={setOpenEditClasses}
						/>
					</ClassIdsContext.Provider>
				</Box>
			</Box>
		</RequisitionsContext.Provider>
	);
}
