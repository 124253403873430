import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent, Radio } from "@mui/material";
import { FormControl, FormControlLabel, FormGroup, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { Typography, Checkbox } from "@mui/material";
import TextInput from "../../../../components/Utils/TextInput";

export default function ClassAdjustCombineDialog(props) {
	const [validChoices, setValidChoices] = useState(false);
	const [rolePicked, setRolePicked] = useState(null);
	const [chosenCTitle, setChosenCTitle] = useState(null);
	const [suggestedCtitle, setSuggestedCTitle] = useState(null);
	const [commonInstructors, setCommonInstructors] = useState([]);
	const [newClass, setNewClass] = useState(null);

	const [lecCoursesCheck, setLecCoursesCheck] = useState([]);
	const [otherCoursesCheck, setOtherCoursesCheck] = useState([]);

	useEffect(() => {
		if (props.list && props.list.length > 1) {
			const commonNames = props.list[0].roles.filter((role) => {
				return props.list.every((instructor) => {
					return instructor.roles.some((role2) => {
						const firstUser =
							role.name === null || role.name === undefined
								? role.user.last_name.concat(", ", role.user.first_name)
								: role.name;
						const secondUser =
							role2.name === null || role2.name === undefined
								? role2.user.last_name.concat(", ", role2.user.first_name)
								: role2.name;

						return firstUser === secondUser;
					});
				});
			});
			const commonNames2 = commonNames.map((role) => {
				if (role.name === null || role.name === undefined) {
					return {
						...role,
						checked: false,
						name: role.user.last_name.concat(", ", role.user.first_name),
					};
				}
				return { ...role, checked: false };
			});
			console.log("commonNames", commonNames2);
			setCommonInstructors(commonNames2);
		}
	}, [props.list]);

	useEffect(() => {
		if (rolePicked && chosenCTitle) {
			setValidChoices(true);
		} else {
			setValidChoices(false);
		}
	}, [rolePicked, chosenCTitle]);

	useEffect(() => {
		if (props.list && props.list.length > 1 && props.selectedClass !== null) {
			let subject_id = props.selectedClass.subject_id;
			let cnumber = props.selectedClass.cnumber;
			let ctypeForCtitle = props.selectedClass.ctype;
			let section = props.selectedClass.section;
			let ctype = "";
			let comb_classes = [];

			const sortedClasses = [...props.list].sort((a, b) => {
				return a.cnumber - b.cnumber;
			});

			if (sortedClasses.some((c) => c.ctype === "LEC")) {
				ctype = "LEC";
			} else if (
				sortedClasses.some((c) => c.ctype === "LAB") &&
				!sortedClasses.some((c) => c.ctype === "LEC")
			) {
				ctype = "LAB";
			} else {
				ctype = props.selectedClass.ctype;
			}

			const sameSubjectId = sortedClasses.every((c) => c.subject_id === subject_id);
			const sameCnumber = sortedClasses.every((c) => c.cnumber === cnumber);
			const sameCtype = sortedClasses.every((c) => c.ctype === ctype);
			const sameSection = sortedClasses.every((c) => c.section === section);
			if (!sameSubjectId) {
				const subject_idArray = sortedClasses.map((c) => c.subject_id);
				subject_idArray.filter((subject_id, index) => {
					return subject_idArray.indexOf(subject_id) === index;
				});
				//remove duplicates
				const subject_idArray2 = [...new Set(subject_idArray)];
				subject_id = subject_idArray2.join("/");
			}
			if (!sameCnumber) {
				const cnumberArray = sortedClasses.map((c) => c.cnumber);
				cnumberArray.filter((cnumber, index) => {
					return cnumberArray.indexOf(cnumber) === index;
				});
				//remove duplicates
				const cnumberArray2 = [...new Set(cnumberArray)];
				cnumber = cnumberArray2.join("/");
			}
			if (!sameCtype) {
				const ctypeArray = sortedClasses.map((c) => c.ctype);
				ctypeArray.filter((ctype, index) => {
					return ctypeArray.indexOf(ctype) === index;
				});
				//remove duplicates
				const ctypeArray2 = [...new Set(ctypeArray)];
				ctypeForCtitle = ctypeArray2.join("/");
			}
			if (!sameSection) {
				const sectionArray = sortedClasses.map((c) => c.section);
				sectionArray.filter((section, index) => {
					return sectionArray.indexOf(section) === index;
				});
				//remove duplicates
				const sectionArray2 = [...new Set(sectionArray)];
				section = sectionArray2.join("/");
			}

			let lecCounter = 0;
			let lecCourses = [];
			let otherCourses = [];
			sortedClasses.forEach((c) => {
				if (c.ctype === "LEC") {
					lecCounter += 1;
					lecCourses.push(c);
				} else {
					otherCourses.push(c);
				}
			});

			setLecCoursesCheck(lecCourses);
			setOtherCoursesCheck(otherCourses);

			if (lecCounter === 1) {
				const lecCnumber = lecCourses[0].cnumber;
				const lecSubjectId = lecCourses[0].subject_id;
				otherCourses.forEach((c) => {
					if (
						c.cnumber === lecCnumber &&
						c.subject_id === lecSubjectId &&
						(c.ctype === "LAB" || c.ctype === "SEM")
					) {
						props.setUnwantedCombination(true);
					} else {
						props.setUnwantedCombination(false);
					}
				});
			}

			let newCtitle = "";
			newCtitle = `${subject_id} ${cnumber} ${ctypeForCtitle} ${section}`;
			setSuggestedCTitle(newCtitle);
			let newCsize = 0;

			sortedClasses.forEach((c) => {
				newCsize += c.csize;
			});

			props.list.forEach((c) => {
				comb_classes.push(c.classid);
			});

			//remove selectedClass.classid from comb_classes

			comb_classes = comb_classes.filter((c) => c !== props.selectedClass.classid);

			//convert comb classes to comma separated string

			const comb_class_string = comb_classes.join(",");

			if (props.selectedClass.combine && props.selectedClass.combine.length > 0) {
				comb_classes.push(props.selectedClass.combine);
			}

			let empid = null;
			if (rolePicked) {
				if (!props.teamTeachingAgree) {
					empid = props.selectedClass.roles.find((instructor) => {
						return instructor.name
							? instructor.name === rolePicked
							: instructor.user.last_name.concat(", ", instructor.user.first_name) === rolePicked;
					}).empid;
				} else {
					empid = [];
					rolePicked.forEach((role) => {
						empid.push(
							props.selectedClass.roles.find((instructor) => {
								return instructor.name
									? instructor.name === role
									: instructor.user.last_name.concat(", ", instructor.user.first_name) === role;
							}).empid
						);
					});
				}
			}

			let class_role = null;
			if (rolePicked) {
				if (!props.teamTeachingAgree) {
					class_role = props.selectedClass.roles.find((instructor) => {
						return instructor.name
							? instructor.name === rolePicked
							: instructor.user.last_name.concat(", ", instructor.user.first_name) === rolePicked;
					}).class_role;
				} else {
					class_role = [];
					rolePicked.forEach((role) => {
						class_role.push(
							props.selectedClass.roles.find((instructor) => {
								return instructor.name
									? instructor.name === role
									: instructor.user.last_name.concat(", ", instructor.user.first_name) === role;
							}).class_role
						);
					});
				}
			}

			let roles = [];
			if (rolePicked) {
				if (!props.teamTeachingAgree) {
					roles.push(
						props.selectedClass.roles.find((instructor) => {
							return instructor.name
								? instructor.name === rolePicked
								: instructor.user.last_name.concat(", ", instructor.user.first_name) === rolePicked;
						})
					);
				} else {
					rolePicked.forEach((role) => {
						roles.push(
							props.selectedClass.roles.find((instructor) => {
								return instructor.name
									? instructor.name === role
									: instructor.user.last_name.concat(", ", instructor.user.first_name) === role;
							})
						);
					});
				}
			}

			let newId = 0;

			if (sortedClasses.length > 1) {
				sortedClasses.forEach((c) => {
					newId += c.classid;
				});
			}

			const newClass = {
				subject_id: subject_id,
				cnumber: cnumber,
				ctype: ctype,
				section: section,
				acyear: props.selectedClass.acyear,
				c_edate: props.selectedClass.c_edate, //how handle for combine classes? If same, or within 4 days, then keep, else dont combine at all
				c_sdate: props.selectedClass.c_sdate,
				catalog: props.selectedClass.catalog,
				classid: props.selectedClass.classid,
				combined: true,
				combine: comb_class_string, //I think
				description: props.selectedClass.description,
				c_location: props.selectedClass.c_location,
				csize: newCsize,
				ctitle: chosenCTitle,
				faculty: props.selectedClass.faculty,
				dept: props.selectedClass.dept,
				examdate: props.selectedClass.examdate, //how handle for combine classes? If conflict with survey window, do not allow combine at all. Lock in dates before classes are picked
				id: newId,
				name: rolePicked,
				original: props.selectedClass.original,
				program: props.selectedClass.program,
				roles: roles,
				schedule: props.selectedClass.schedule,
				teamsize: roles.length,
				survey_start: props.selectedClass.survey_start,
				survey_end: props.selectedClass.survey_end,
				evaluate: props.selectedClass.evaluate
					? props.selectedClass.evaluate
					: props.selectedClass.exclude_class,
				term: props.selectedClass.term,
			};

			setNewClass(newClass);
		} else {
			props.setUnwantedCombination(false);
		}
	}, [props.list, rolePicked, chosenCTitle, props.teamTeachingAgree]);

	const handleClose = (event, reason) => {
		if (reason && reason === "backdropClick") return;
		props.setOpen(false);
		setRolePicked(null);
		setChosenCTitle(null);
		setCommonInstructors((prev) => {
			const updatedInstructors = [...prev];
			updatedInstructors.forEach((instructor) => {
				instructor.checked = false;
			});
			return updatedInstructors;
		});
	};

	const onChange = (event) => {
		if (!props.teamTeachingAgree) {
			console.log("event.target.value", event.target.value);
			setRolePicked(event.target.value);
		} else {
			setCommonInstructors((prev) => {
				const updatedInstructors = [...prev];
				const instructorIndex = updatedInstructors.findIndex(
					(instructor) => instructor.name === event.target.value
				);
				updatedInstructors[instructorIndex].checked = !updatedInstructors[instructorIndex].checked
					? true
					: false;
				return updatedInstructors;
			});
			if (rolePicked) {
				setRolePicked((prev) => {
					const updatedRoles = [...prev];
					if (updatedRoles.includes(event.target.value)) {
						const index = updatedRoles.indexOf(event.target.value);
						updatedRoles.splice(index, 1);
						return updatedRoles;
					}

					updatedRoles.push(event.target.value);
					return updatedRoles;
				});
			} else {
				setRolePicked([event.target.value]);
			}
		}
	};

	const handleSuggestion = () => {
		let suggestedTest = suggestedCtitle;
		setChosenCTitle(suggestedTest);
	};

	const textOnChange = (event) => {
		setChosenCTitle(event.target.value);
	};

	const handleSubmit = () => {
		const updatedClasses = [...props.rows];
		//find the class in the list of chosen classes
		const classIndex = updatedClasses.findIndex((c) => c.classid === newClass.classid);
		console.log("classIndex", classIndex);
		//if the class is already in the list, update it
		if (classIndex !== -1) {
			updatedClasses[classIndex] = newClass;
		}

		props.setUnsavedChanges(true);

		props.setRows(updatedClasses);
		props.setCombiningMode(false);
		props.setOpen(false);
		setRolePicked(null);
		setChosenCTitle(null);
		setCommonInstructors((prev) => {
			const updatedInstructors = [...prev];
			if (updatedInstructors.length > 0) {
				updatedInstructors.forEach((instructor) => {
					instructor.checked = false;
				});
			}

			return updatedInstructors;
		});
		props.setList([]);
	};

	return (
		<div>
			{props.teamTeachingAgree && props.list.length > 0 && (
				<Dialog
					open={props.open}
					onClose={handleClose}
					/* PaperProps={{ variant: "outlined" }} */
					maxWidth="xl"
				>
					<DialogTitle textAlign={"center"}>{"Choose Instructors"}</DialogTitle>

					<DialogContent sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
						<DialogContentText sx={{ marginBottom: "20px" }}>
							These instructors are common across all selected courses.
						</DialogContentText>
						<DialogContentText sx={{ marginBottom: "20px" }}>
							WARNING: It seems like the selected classes might not be suitable for combining.
							Please do not combine LAB or SEM components with a LEC component that has the same
							students enrolled.
						</DialogContentText>
						{commonInstructors.length > 0 ? (
							<FormControl component="fieldset">
								<FormGroup>
									{commonInstructors.map((role) => {
										return (
											<FormControlLabel
												key={role.empid}
												control={
													<Checkbox
														key={role.empid}
														disabled={role.disabled}
														checked={role.checked}
														onChange={onChange}
														name={
															role.name
																? role.name
																: role.user.last_name.concat(", ", role.user.first_name)
														}
														value={
															role.name
																? role.name
																: role.user.last_name.concat(", ", role.user.first_name) || ""
														}
													/>
												}
												label={
													role.name
														? role.name
														: role.user.last_name.concat(", ", role.user.first_name) +
														  " (" +
														  role.role_description +
														  ")"
												}
											/>
										);
									})}
								</FormGroup>
							</FormControl>
						) : (
							<Typography sx={{ marginBottom: "20px" }}>
								There are no common instructors across all courses.
							</Typography>
						)}

						{rolePicked && rolePicked.length > 0 && (
							<Typography fontSize={18} textAlign={"center"}>
								Set Combined Class Name
							</Typography>
						)}
						{rolePicked && rolePicked.length > 0 && (
							<TextInput
								label="Combined Class Name"
								value={chosenCTitle || ""}
								onChange={textOnChange}
							/>
						)}
						{rolePicked && rolePicked.length > 0 && (
							<Typography>Suggested Name: (Click to autofill, you can edit after.) </Typography>
						)}
						{rolePicked && rolePicked.length > 0 && suggestedCtitle && (
							<Card
								sx={{
									height: "30px",
									display: "flex",
									alignItems: "center",
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "#e0e0e0",
									},
								}}
								onClick={handleSuggestion}
							>
								<CardContent>
									<Typography sx={{ marginTop: "8px" }}>{suggestedCtitle}</Typography>
								</CardContent>
							</Card>
						)}
					</DialogContent>
					<DialogActions>
						<Button color="error" onClick={handleClose}>
							Cancel
						</Button>

						<Button onClick={handleSubmit} autoFocus disabled={!validChoices}>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{!props.teamTeachingAgree && props.list && props.list.length > 0 && (
				<Dialog open={props.open} onClose={handleClose} maxWidth="xl">
					<DialogTitle textAlign={"center"}>{"Choose One Instructor"}</DialogTitle>

					<DialogContent sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
						<DialogContentText sx={{ mx: "auto" }}>
							These instructors are common across all selected courses.
						</DialogContentText>
						{props.unwantedCombination && (
							<>
								<DialogContentText sx={{ maxWidth: "600px", color: "red" }}>
									WARNING: It seems like the selected classes might not be suitable for combining.
									Please do not combine LAB or SEM components with a LEC component that has the same
									students enrolled. Combining these sections causes issues with duplicate
									enrollments in the survey platform.
								</DialogContentText>
								<DialogContentText sx={{ maxWidth: "600px", color: "red" }}>
									Example: COURSE 201 LEC 100 and COURSE 201 LAB 100 should not be combined if the
									same students are enrolled in both and the same instructor teaches both
									components.
								</DialogContentText>
								<DialogContentText sx={{ maxWidth: "600px", color: "red" }}>
									Courses in Question:{" "}
								</DialogContentText>
								<DialogContentText sx={{ maxWidth: "600px", color: "red" }}>
									{lecCoursesCheck.length > 0 &&
										`${lecCoursesCheck[0].ctitle} - ${lecCoursesCheck[0].csize} students`}
								</DialogContentText>
								{otherCoursesCheck.length > 0 &&
									otherCoursesCheck.map((c) => {
										return (
											<DialogContentText
												key={c.classid}
												sx={{
													maxWidth: "400px",
													color: "red",
												}}
											>
												{`${c.ctitle} - ${c.csize} students`}
											</DialogContentText>
										);
									})}
								<DialogContentText sx={{ maxWidth: "600px", color: "primary.main" }}>
									If you would still like to evaluate both sections, please submit them on separate
									requisitions (preferably with different questions).
								</DialogContentText>
							</>
						)}

						{commonInstructors.length > 0 ? (
							<FormControl component="fieldset">
								<FormGroup>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										name="radio-buttons-group"
										value={rolePicked || ""}
										onChange={onChange}
										sx={{ marginBottom: "20px" }}
									>
										{commonInstructors.map((role) => {
											return (
												<FormControlLabel
													key={role.empid.toString() + props.selectedClass?.classid?.toString()}
													control={<Radio name={role.name} value={role.name} />}
													label={role.name + " - " + role.class_role}
												/>
											);
										})}
									</RadioGroup>
								</FormGroup>
							</FormControl>
						) : (
							<Typography sx={{ marginBottom: "20px" }}>
								There are no common instructors across all courses.
							</Typography>
						)}
						{rolePicked && (
							<Typography fontSize={18} textAlign={"center"}>
								Set Combined Class Name
							</Typography>
						)}
						{rolePicked && (
							<TextInput
								label="Combined Class Name"
								value={chosenCTitle || ""}
								onChange={textOnChange}
							/>
						)}
						{rolePicked && (
							<Typography>Suggested Name: (Click to autofill, you can edit after.) </Typography>
						)}
						{rolePicked && suggestedCtitle && (
							<Card
								sx={{
									height: "30px",
									display: "flex",
									alignItems: "center",
									cursor: "pointer",
									"&:hover": {
										backgroundColor: "#e0e0e0",
									},
								}}
								onClick={handleSuggestion}
							>
								<CardContent>
									<Typography sx={{ marginTop: "8px" }}>{suggestedCtitle}</Typography>
								</CardContent>
							</Card>
						)}
					</DialogContent>
					<DialogActions>
						<Button color="error" onClick={handleClose}>
							Cancel
						</Button>

						<Button onClick={handleSubmit} autoFocus disabled={!validChoices}>
							Submit
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
}
