import React from 'react';
import './HelpFormat.css'


function Help() {
    return (
        <div className="home-container">
          <div id="process" className="home-services">
            <div className="home-heading-container">
              <h1 className="home-text section-Heading">
                Are SPOT results made public?
              </h1>
              <span className="home-text001 section-Text">
                <span className="home-text002">
                  On October 12, 1993, the General Faculties Council (GFC) of the
                  University of Alberta modified its policy concerning Teaching
                  Evaluation and Student Evaluation of Instruction to include the
                  requirement for the collection of students&apos; ratings of
                  instruction on a University-wide basis using a basic set of
                  mandated questions. The policy also made provision for releasing
                  the associated results to the Students&apos; Union and the
                  Graduate Students&apos; Association. Currently, results are not
                  made &quot;public&quot; unless there have been at least 10
                  completed questionnaires for a class.
                </span>
                <br></br>
                <span className="home-text003"></span>
                <br></br>
                <span className="home-text004">
                  In 2011, online access to results was restricted to registered
                  students. This was followed by allowing instructors to see results
                  for their own classes and, later, providing access to Deans,
                  Directors, and Chairs to view results for their employees.
                  Beginning in July 2012, Deans, Directors and Chairs may extend
                  this access to individuals whom they designate by sending an
                  e-mail to
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a href="mailto:test.scoring@ualberta.ca?subject=">
                  <span className="home-text005">test.scoring@ualberta.ca</span>
                </a>
                <span className="home-text006"> which provides:</span>
                <br></br>
                <span className="home-text007"></span>
                <br></br>
                <span className="home-text008">
                  {' '}
                  - Their name and employee number
                </span>
                <br></br>
                <span className="home-text009">
                  {' '}
                  - The name and employee number of the person to whom they wish to
                  grant designated access
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
                <br></br>
                <span className="home-text014">
                  The current version of this policy may be viewed by linking to
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://www.ualberta.ca/governance/resources/policies-standards-and-codes-of-conduct/gfc-policy-manual/111-teaching-and-learning-and-teaching-evaluation.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text015">
                    Section 111 of the GFC Policy Manual
                  </span>
                </a>
                <span className="home-text016"></span>
                <span className="home-text017">
                  . Included in this policy is the following cautionary note
                  concerning the results obtained from students&apos; ratings:
                </span>
                <br></br>
                <span className="home-text018"></span>
                <br></br>
                <span className="home-text019">
                  “Student questionnaires form an important part of evaluating
                  teaching effectiveness but cannot be taken alone as a complete
                  assessment of an instructor or course. Factors other than an
                  instructor&apos;s teaching ability may influence ratings. These
                  factors include class size, class level, Faculty, time of class,
                  required versus optional course, grade expectations, student GPA,
                  gender, race, ethnicity, age of both students and instructors.
                  Small differences in evaluation should not be considered as
                  meaningful.”
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
          <div id="process" className="home-services1">
            <div className="home-heading-container1">
              <h1 className="home-text024 section-Heading">
                What does the SPOT report consist of?
              </h1>
              <span className="home-text025 section-Text">
                <span className="home-text026">
                  On October 12, 1993, the General Faculties Council (GFC) of the
                  University of Alberta modified its policy concerning Teaching
                  Evaluation and Student Evaluation of Instruction to include the
                  requirement for the collection of students&apos; ratings of
                  instruction on a University-wide basis using a basic set of
                  mandated questions. The policy also made provision for releasing
                  the associated results to the Students&apos; Union and the
                  Graduate Students&apos; Association. Currently, results are not
                  made &quot;public&quot; unless there have been at least 10
                  completed questionnaires for a class.
                </span>
                <span>
                  A one-page report is generated for each class from which
                  students&apos; ratings have been collected. The Instructor Report
                  contains the text of each of the rating questions appearing on the
                  questionnaire. The questions are reported in the sequence that
                  they were printed on the questionnaire. Following the text of each
                  question, the number of students responding to the rating scale
                  Strongly Disagree (SD), Disagree (D), Neutral (N), Agree (A), and
                  Strongly Agree (SA) are reported. These frequencies are followed
                  by the
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://uofaprod.service-now.com/sp?id=kb_article&amp;sys_id=d51706214f880a008a3b00fe9310c7a6"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text028">median of the responses</span>
                </a>
                <span>
                  {' '}
                  and
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text030"></span>
                <span></span>
                <a
                  href="https://uofaprod.service-now.com/sp?id=kb_article&amp;sys_id=e04c20684fc20200b1ec2f9ca310c70b"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text032">reference data</span>
                  <span className="home-text033">.</span>
                </a>
                <br></br>
                <span></span>
                <br></br>
                <a
                  href="https://uofaprod.service-now.com/sp?id=kb_article&amp;sys_id=e04c20684fc20200b1ec2f9ca310c70b"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text035">
                    Additional SPOT reports available.
                  </span>
                </a>
                <span className="home-text036"></span>
                <a href="mailto:test.scoring@ualberta.ca?subject="> </a>
                <a
                  href="https://www.ualberta.ca/governance/resources/policies-standards-and-codes-of-conduct/gfc-policy-manual/111-teaching-and-learning-and-teaching-evaluation.html"
                  target="_blank"
                  rel="noreferrer noopener"
                > </a>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
              </span>
            </div>
          </div>
          <div id="process" className="home-services2">
            <div className="home-heading-container2">
              <h1 className="home-text041 section-Heading">
                What does the SPOT instrument look like?
              </h1>
              <span className="home-text042 section-Text">
                <span className="home-text043"></span>
                <span className="home-text044"></span>
                <a
                  href="https://uofaprod.service-now.com/sp?id=kb_article&amp;sys_id=d51706214f880a008a3b00fe9310c7a6"
                  target="_blank"
                  rel="noreferrer noopener"
                > </a>
                <a
                  href="https://uofaprod.service-now.com/sp?id=kb_article&amp;sys_id=e04c20684fc20200b1ec2f9ca310c70b"
                  target="_blank"
                  rel="noreferrer noopener"
                > </a>
                <a
                  href="https://uofaprod.service-now.com/sp?id=kb_article&amp;sys_id=e04c20684fc20200b1ec2f9ca310c70b"
                  target="_blank"
                  rel="noreferrer noopener"
                > </a>
                <a href="mailto:test.scoring@ualberta.ca?subject="> </a>
                <a
                  href="https://www.ualberta.ca/governance/resources/policies-standards-and-codes-of-conduct/gfc-policy-manual/111-teaching-and-learning-and-teaching-evaluation.html"
                  target="_blank"
                  rel="noreferrer noopener"
                > </a>
                <span></span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
                <br></br>
                <span className="home-text048"></span>
                <span className="home-text049"></span>
                <span className="home-text050"></span>
                <a
                  href="https://www.ualberta.ca/information-services-and-technology/media-library/pdf/single-instructor-sample.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text051">Single Instructor Sample</span>
                  <br className="home-br21"></br>
                </a>
                <span className="home-text052"></span>
                <a
                  href="https://www.ualberta.ca/information-services-and-technology/media-library/pdf/team-teaching-sample.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text053">Team Teaching Sample</span>
                  <br className="home-text054"></br>
                </a>
                <span></span>
              </span>
            </div>
          </div>
          <div id="process" className="home-services3">
            <div className="home-heading-container3">
              <h1 className="home-text056 section-Heading">SPOT Reference Data</h1>
              <span className="home-text057 section-Text">
                <a
                  href="https://www.ualberta.ca/information-services-and-technology/media-library/pdf/reference-groups-for-comparative-ratings.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text058">
                    Reference Groups for Comparative Ratings
                  </span>
                </a>
                <span className="home-text059"></span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  The columns of reference data display statistics from Tukey&apos;s
                  box-and-whisker plot analysis (John W. Tukey, Exploratory Data
                  Analysis, Addison-Wesley Publishing Company, Inc. 1977). The
                  values displayed are derived from all classes in the indicated
                  reference group. These statistics are chosen to achieve two main
                  objectives:
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>1.). To summarize skewed distributions of data, and</span>
                <br></br>
                <span>
                  2.). To identify outliers from the general population, if they
                  exist.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  The median value (middle of a ranked set of numbers) is generally
                  preferred over the mean to identify the centre of a skewed
                  distribution of scores. This is the value below which 50 percent
                  of the medians from other classes lie. Please note that data for
                  the items in the current set of mandated questions are accumulated
                  from Academic Year 2005/06 and beyond. If an item (question) has
                  not been used at least 15 times by the indicated reference group
                  since then, the reference data cells will be filled with the text:
                  too few uses. It is theoretically possible for all median scores
                  in a single year to be above, or below, the Reference Group
                  median.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  The 25th and 75th percentiles provide information about the spread
                  of scores around the median. By definition, 25 percent of the
                  scores are above the 75th percentile and 25 percent are below the
                  25th percentile. Since this occurs by definition, these values
                  should not be used to determine whether a particular score is good
                  or bad.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  The lower Tukey Fence, which is the 25th percentile minus 1.5
                  times the distance from the 25th to the 75th percentile, defines a
                  reasonable limit beyond which a score can be considered an
                  outlier. Outliers are scores that appear to be outside the usual
                  distribution of scores for the population being tabulated (i.e.
                  for the indicated reference group.) Given the nature of the SPOT
                  data, the upper Fence will usually be above 5.0 and, therefore,
                  need not be reported.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  Please note that some items can be expected to elicit higher
                  ratings because they are closer to apple pie types of items (i.e.
                  we would expect the item to be rated quite positively.)
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>​</span>
                <span>
                  This suggests that the median obtained for the first item in a
                  particular class can be expected to be 0.5 of a rating above that
                  for the second item simply because that has been found to be the
                  case in results from thousands of classes surveyed at the
                  University of Alberta. Note that the 25th percentile for the first
                  item corresponds to the 75th percentile for the second item.
                </span>
                <br></br>
                <span></span>
                <br></br>
                <span>
                  Also, the reference group used for a particular class consists of
                  all classes in the indicated department or faculty. One of the
                  most consistent findings of researchers studying students&apos;
                  ratings of instruction is that the ratings obtained for items such
                  as those addressing general satisfaction with a course or
                  instructor, depend on the discipline in which the course is
                  taught. Franklin and Theall (1995) reported that &quot;professors
                  in fine arts, humanities, and health-related professions are more
                  highly rated than their science, engineering and math-related
                  colleagues.&quot; There appears to be a combination of reasons for
                  these differences including diversity in the characteristics of
                  the students, in the nature of the subject matter, and in the
                  course objectives that are emphasized in different disciplines.
                  The sizes of the differences and the conclusion that they are not
                  necessarily related to characteristics of the instructors in the
                  different disciplines, leads to the advice that &quot;we must
                  continue to be very cautious about —if not prohibited from —using
                  the results of student evaluations to make comparisons across
                  disciplines.&quot; (Marincovich, 1995).
                </span>
                <span></span>
              </span>
            </div>
          </div>
          <div className="home-team">
            <div className="home-heading-container4">
              <h1 className="home-text084 section-Heading">
                <span>Contact Us</span>
                <br></br>
                <span></span>
              </h1>
              <span className="home-text087 section-Text">
                <span>
                  Support staff can be reached by emailing
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://test.scoring@ualberta.ca"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text089">test.scoring@ualberta.ca</span>
                </a>
                <span> or calling</span>
                <a href="tel:7804922741">
                  <span className="home-text091"> (780) 492-2741</span>
                </a>
                <span>
                  . The TSQS office is located at
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <a
                  href="https://www.ualberta.ca/maps.html?l=53.5259467219551,-113.52898140544892&amp;z=18&amp;campus=north_campus&amp;b=gsb"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="home-text093">
                    240 General Services Building
                  </span>
                </a>
                <span>.</span>
              </span>
            </div>
            <div className="home-cards-container">
              <div className="home-team-card">
                <div className="home-avatar-container">
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"></path>
                  </svg>
                </div>
                <span className="home-name card-Heading">
                  <span>Margarita Stewart</span>
                  <br></br>
                  <span></span>
                </span>
                <span className="home-position card-Text">
                  <span>Team Leader/Analyst</span>
                  <br></br>
                  <span></span>
                </span>
              </div>
              <div className="home-team-card1">
                <div className="home-avatar-container1">
                  <svg viewBox="0 0 1024 1024" className="home-icon2">
                    <path d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"></path>
                  </svg>
                </div>
                <span className="home-name1 card-Heading">
                  <span>Mitra Mohseni</span>
                  <br></br>
                  <span></span>
                </span>
                <span className="home-position1 card-Text">
                  <span className="home-text101">Analyst/Consultant</span>
                  <br></br>
                  <span></span>
                </span>
              </div>
              <div className="home-team-card2">
                <div className="home-avatar-container2">
                  <svg viewBox="0 0 1024 1024" className="home-icon4">
                    <path d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"></path>
                  </svg>
                </div>
                <span className="home-name2 card-Heading">
                  <span>Johnathan Babic</span>
                  <br></br>
                  <span></span>
                </span>
                <span className="home-position2 card-Text">
                  <span className="home-text105">Analyst/Consultant</span>
                  <br></br>
                  <span></span>
                </span>
              </div>
              <div className="home-team-card3">
                <div className="home-avatar-container3">
                  <svg viewBox="0 0 1024 1024" className="home-icon6">
                    <path d="M576 706.612v-52.78c70.498-39.728 128-138.772 128-237.832 0-159.058 0-288-192-288s-192 128.942-192 288c0 99.060 57.502 198.104 128 237.832v52.78c-217.102 17.748-384 124.42-384 253.388h896c0-128.968-166.898-235.64-384-253.388z"></path>
                  </svg>
                </div>
                <span className="home-name3 card-Heading">
                  <span>George Nassar</span>
                  <br></br>
                  <span></span>
                </span>
                <span className="home-position3 card-Text">
                  <span className="home-text109">Analyst/Consultant</span>
                  <br></br>
                  <span></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

export default Help