export default function isAuthenticated() {
	const token = sessionStorage.getItem("token");
	const ccid = sessionStorage.getItem("ccid");
	const access = sessionStorage.getItem("access");
	const dept_ids = sessionStorage.getItem("dept_ids");
	const firstName = sessionStorage.getItem("first_name");
	const lastName = sessionStorage.getItem("last_name");
	const id = sessionStorage.getItem("id");

	return {
		token: token,
		ccid: ccid,
		access: access,
		dept_ids: dept_ids,
		firstName: firstName,
		lastName: lastName,
		empid: id,
	};
}
