import React, { useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField, Button, Box, Checkbox } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
// import {BsSearch} from "react-icons/bs";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
// import Divider from '@mui/material/Divider';
import CircularProgress from "@mui/material/CircularProgress";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import { useSTDUsriSearchQuery } from "../../../../Hooks/useSTDUsriSearchQuery";

export default function USRIStudentSearch() {
	const auth = isAuthenticated();
	const token = auth.token;
	const [loadingBar, setLoadingBar] = useState(true); // To show/hide loading bar
	const [terms, setTerms] = useState([]);
	const [depts, setDepts] = useState([]);
	const [insts, setInsts] = useState([]);
	const [selectedTerms, setSelectedTerms] = useState([]);
	const [selectedDepts, setSelectedDepts] = useState([]);
	const [selectedInsts, setSelectedInsts] = useState([]);
	const [isError, setIsError] = useState(false);
	const navigate = useNavigate();
	const [openSearch, setOpenSearch] = useState(false);
	const [selectedInstsInputValue, setSelectedInstsInputValue] = useState("");
	const [checked, setChecked] = useState(false);

	const handleChange = (event) => {
		event.preventDefault();
		if (selectedDepts.length === 0) return;
		setChecked(!checked);
	};

	const { data: allSearchParams, isFetched } = useSTDUsriSearchQuery(token);

	const [filteredInsts, setFilteredInsts] = useState([]);

	const filterOptions = createFilterOptions({
		ignoreCase: true,
		limit: 5,
	});

	useEffect(() => {
		if (insts && insts.length > 0) {
			if (checked) {
				const selectedDeptUnits = selectedDepts.map((dept) => {
					const matchingObject = depts.find((obj) => obj.dept_id === dept);
					return matchingObject ? matchingObject.unit : null;
				});
				const filtered = insts.filter((inst) => selectedDeptUnits.includes(inst.unit));
				setFilteredInsts(filtered);
			} else {
				setFilteredInsts(insts);
			}
		}
	}, [checked, selectedDepts, depts, insts]);

	function searchReports() {
		if (selectedTerms.length > 0 && selectedDepts.length > 0) {
			setIsError(false);
			navigate({
				pathname: "/USRI-Student-Search-Results",
				search: createSearchParams({
					term: selectedTerms.toString(),
					dept: selectedDepts.toString(),
					empid: selectedInsts.toString(),
				}).toString(),
			});
		} else {
			setIsError(true);
		}
	}

	useEffect(() => {
		if (isFetched) {
			setLoadingBar(false);
			let data = allSearchParams;
			let term_info = [];
			let dept_info = [];
			let inst_info = [];

			const terms = data.terms;
			const depts = data.depts;
			const insts = data.faculty_rank;
			terms.forEach((term) => {
				const termObj = {
					term: term.term,
					season: term.desc_term.split(" ")[0],
					year: term.desc_term.split(" ")[1],
					desc_term: term.desc_term.concat(" (", term.term, ")"),
				};
				term_info.push(termObj);
			});
			depts.forEach((dept) => {
				dept_info.push({
					dept_id: dept.dept,
					dept_name: dept.dname.concat(" (", dept.faclty.trim(), ") (", dept.dept.trim(), ")"),
					unit: dept.unit,
				});
			});
			insts.forEach((inst) => {
				inst_info.push({
					empid: inst.empid,
					name: inst.name,
					unit: inst.unit,
				});
			});
			setTerms(term_info.sort((a, b) => b.term - a.term));
			setDepts(dept_info.sort((a, b) => a.dept_name.localeCompare(b.dept_name)));
			setInsts(inst_info.sort((a, b) => a.name.localeCompare(b.name)));
		}
	}, [isFetched, allSearchParams]);

	const handleTermChange = (event, newValue) => {
		if (newValue.length > 0) {
			const selections = newValue.map((termObj) => {
				const matchingObject = terms.find((obj) => obj.desc_term === termObj.desc_term);
				return matchingObject ? matchingObject.term : null;
			});
			setSelectedTerms(selections);
		} else {
			setSelectedTerms([]);
		}
	};

	const seasonOrder = {
		Fall: 4,
		Winter: 3,
		Spring: 2,
		Summer: 1,
	};

	return (
		<div style={{ height: "100%", maxHeight: "calc(100% - 160px)" }}>
			<div className="Bread Crumbs" style={{ paddingLeft: "10px" }}>
				<Breadcrumbs separator=">" style={{ marginTop: "10px", marginBottom: "20px" }}>
					<Link underline="always" color="#275d38" href="/">
						<Typography color="#275d38" sx={{ display: "flex", alignItems: "center" }}>
							<HomeIcon sx={{ mr: 0.5 }} />
							Home
						</Typography>
					</Link>
					<Typography color="text.primary">Reporting</Typography>
					<Typography color="text.primary">Student USRI Report Search</Typography>
				</Breadcrumbs>
			</div>

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography variant="h2">Student USRI Report Search</Typography>

				<Box
					sx={{
						textAlign: "center",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						paddingTop: "15px",
						width: "60dvw",
					}}
				>
					<Autocomplete
						freeSolo
						multiple
						limitTags={3}
						onChange={handleTermChange}
						size="small"
						options={terms.sort((a, b) => {
							// First sort by season using the seasonOrder dictionary
							if (seasonOrder[a.season] < seasonOrder[b.season]) return 1;
							if (seasonOrder[a.season] > seasonOrder[b.season]) return -1;

							// If seasons are the same, sort by year
							return b.year - a.year;
						})}
						groupBy={(option) => option.season}
						getOptionLabel={(option) => option.desc_term}
						disableCloseOnSelect
						sx={{ width: "100%", paddingTop: "5px", paddingBottom: "10px" }}
						renderInput={(params) => (
							<TextField
								{...params}
								label="TERM (REQUIRED)"
								required
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
					<Autocomplete
						freeSolo
						multiple
						limitTags={2}
						onChange={(event, newValue) => {
							if (newValue.length > 0) {
								const selections = newValue.map((dept_name) => {
									const matchingObject = depts.find((obj) => obj.dept_name === dept_name);
									return matchingObject ? matchingObject.dept_id : null;
								});
								setSelectedDepts(selections);
							} else {
								setSelectedDepts([]);
							}
						}}
						size="small"
						options={depts.map((item) => item.dept_name)}
						disableCloseOnSelect
						sx={{ width: "100%", paddingTop: "5px", paddingBottom: "10px" }}
						renderInput={(params) => (
							<TextField
								{...params}
								label="DEPARTMENT (REQUIRED)"
								required
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: "100%",
							paddingTop: "5px",
							paddingBottom: "10px",
							gap: "10px",
						}}
					>
						<Box
							sx={{
								width: "100%",
								display: "flex",
								alignItems: "center",
								border: "1px solid #C9c9c9",
								px: 1.8,
								gap: "10px",
								backgroundColor: selectedDepts.length > 0 ? "" : "#f1f1f1",
								borderRadius: "5px",
								":hover": {
									cursor: selectedDepts.length === 0 ? "default" : "pointer",
									backgroundColor: selectedDepts.length > 0 ? "#f5f5f5" : "",
								},
							}}
							onClick={handleChange}
						>
							<Typography variant="body1" color={selectedDepts.length > 0 ? "#636363" : "#d1d1d1"}>
								Filter Instructors by Selected Department
							</Typography>
							<Checkbox
								checked={checked}
								disabled={selectedDepts.length === 0}
								inputProps={{ "aria-label": "controlled" }}
								sx={{ width: "" }}
							/>
						</Box>

						<Autocomplete
							freeSolo
							multiple
							limitTags={2}
							size="small"
							options={filteredInsts.map((item) => item.name)}
							filterOptions={filterOptions}
							disableCloseOnSelect
							open={openSearch}
							value={selectedInsts.map((empid) => {
								const matchingObject = insts.find((obj) => obj.empid === empid);
								return matchingObject ? matchingObject.name : null;
							})}
							inputValue={selectedInstsInputValue}
							onChange={(event, newValue) => {
								if (newValue.length > 0) {
									const selections = newValue.map((name) => {
										const matchingObject = insts.find((obj) => obj.name === name);
										return matchingObject ? matchingObject.empid : null;
									});
									setSelectedInsts(selections);
								} else {
									setSelectedInsts([]);
								}
							}}
							onInputChange={(event, newInputValue) => {
								setSelectedInstsInputValue(newInputValue);
								setOpenSearch(newInputValue.length > 0);
							}}
							sx={{ width: "100%", paddingTop: "5px", paddingBottom: "10px" }}
							renderInput={(params) => (
								<TextField
									{...params}
									label="SEARCH INSTRUCTOR NAME (OPTIONAL)"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{loadingBar ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
								/>
							)}
						/>
					</Box>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#275d38",
							fontSize: "16px",
							width: "100%",
							paddingTop: "5px",
							paddingBottom: "10px",
						}}
						onClick={searchReports}
					>
						View Reports
					</Button>
					{isError && (
						<p style={{ color: "red", textAlign: "center", paddingTop: "5px" }}>
							PLEASE SELECT AT LEAST ONE TERM AND DEPARTMENT
						</p>
					)}
				</Box>
			</div>
		</div>
	);
}
