import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';




function CommentsTable(data){
  
	function calculatePercentage(value, total) {
        // Check if both parameters are numbers
        if (typeof value !== 'number' || typeof total !== 'number') {
          return '0.0%';
        }
        
        // Calculate the percentage and round it to one decimal place
        const percentage = (value / total) * 100;
        const roundedPercentage = percentage.toFixed(1);

        // Format the result as a percentage string
        return `${roundedPercentage}%`;
    }

    if (Object.keys(data).length > 0){
        return (
            <div>
                {Object.keys(data.results).map(key => (
                    <React.Fragment key={key}>
                        <TableContainer >
                            <Table size="small">
                                <TableHead style={{ borderRight: '2px solid #ccc', borderLeft: '2px solid #ccc',
                                                    borderTop: '2px solid #ccc'}}>
                                    <TableRow >
                                        <TableCell style={{ borderRight: '1px solid #ccc', width:'200px'}} align="left" >
                                            <Typography variant="body2" fontWeight="bold">
                                                Question:
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ borderRight: '1px solid #ccc'}} align="left">
                                            <Typography variant="body2" >
                                                {data.results[key].q_text}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell style={{ borderRight: '1px solid #ccc', width:'200px'}} align="left" >
                                            <Typography variant="body2" fontWeight="bold">
                                                Response Rate:
                                            </Typography>
                                        </TableCell>
                                        {data.results[key].responses[0] === null ? (
                                            <TableCell style={{ borderRight: '1px solid #ccc'}} align="left">
                                                {data.qtally === null ? (
                                                    <Typography variant="body2" >
                                                        0.0%
                                                        {' '}({0} of {0})
                                                    </Typography>
                                                ):(
                                                    <Typography variant="body2" >
                                                        {calculatePercentage(0,data.qtally)}
                                                        {' '}({0} of {data.qtally})
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        ):(
                                            <TableCell style={{ borderRight: '1px solid #ccc'}} align="left">
                                                <Typography variant="body2" >
                                                    {calculatePercentage(data.results[key].responses.length,data.qtally)}
                                                    {' '}({data.results[key].responses.length} of {data.qtally})
                                                </Typography>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>

                                <TableBody style={{ borderRight: '2px solid #ccc', borderLeft: '2px solid #ccc',
                                                    borderBottom: '2px solid #ccc'}}>
                                    {data.results[key].responses[0] === null ? (
                                        <TableRow>
                                            <TableCell style={{ borderRight: '1px solid #ccc'}} align="center" colSpan={2}>
                                                <Typography variant="body2" style={{ color: 'red', fontStyle: 'italic' }}>
                                                    No participants responded to this question.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ):(
                                        data.results[key].responses.map((response, index) => (
                                            <TableRow key={index}>
                                                <TableCell style={{ borderRight: '1px solid #ccc'}} align="left" colSpan={2}>
                                                    <Typography variant="body2">
                                                        {response}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br></br>
                    </React.Fragment>
                ))}
            </div>
        );
    }else{
        return(
            <Typography variant="body2" align="center">
                No Comment Data
            </Typography>
        )
    }
}

export {CommentsTable}