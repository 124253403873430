import React from "react";
import styles from "../styles/info.module.css";

export default function Assign2InstructorInstructions(props) {
	let upload = props.upload;
	if (upload === "upload") {
		return (
			<div className={styles.uploadinstructions}>
				<div>
					If you would like us to upload the scanned booklets to Assign2, you
					will need to assign one of the TSQS team members to your class as an
					instructor.
				</div>
				<div>
					Instructions on how to perform this change can be found{" "}
					<a
						className={styles.hyperlinktext}
						href="https://support.eclass.ualberta.ca/index.php?/Knowledgebase/Article/View/23/14/adding-additional-users-to-my-eclass-course"
					>
						here
					</a>
					. Please send an email to TSQS at opscan@ualberta.ca when you have
					finished doing this.
				</div>
				<div>
					You may now press submit. Once we have received your email we will
					begin uploading your exams as soon as possible. Thank you.
				</div>
			</div>
		);
	} else if (upload === "doNotUpload") {
		return (
			<div className={styles.uploadinstructions}>
				<div>
					The option you have selected will have the .pdf files generated by our
					scanner software sent to you by either e-mail or a google drive link.
				</div>
				<div>
					The preferred method is by email, but if the .pdf files are too large
					we will need to upload them to google drive (private) and send you a
					link.
				</div>
				<div>
					You may now press submit. Your email has been stored from the field
					above and you will be contacted when your files are ready. Thank you.
				</div>
			</div>
		);
	}
}
