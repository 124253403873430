import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";

/**
 * This function renders a table displaying survey data for an instructor.
 *
 * Functionality:
 * - The function takes 'data' as input, which is an array containing survey data for the instructor.
 * - It calculates the 'rowSpan' for cells that represent the category of questions, ensuring that cells
 *   with the same category span multiple rows for better visual representation.
 * - The table is structured with columns representing the following survey aspects:
 *   - Spot Domain
 *   - Question
 *   - Strongly Disagree
 *   - Disagree
 *   - Neither Agree Nor Disagree
 *   - Agree
 *   - Strongly Agree
 * - Each row in the table corresponds to a question in the survey data.
 * - The 'data' array contains objects with properties like 'category', 'question', 'rsp1', 'rsp2', 'rsp3',
 *   'rsp4', and 'rsp5', representing the question category, the question itself, and response options for
 *   the survey.
 * - The function conditionally renders the 'Spot Domain' cell only if it is the first question in a new
 *   category, to avoid redundancy and improve readability.
 * - The cell style includes border settings for the table.
 * - The 'rowSpan' value is determined using the 'getRowSpan' function to make cells in the 'Spot Domain'
 *   column span multiple rows with the same category.
 * - The 'backgroundColor' property is set based on the background color for each category in the table.
 *   The background color is specified in the 'data' array for each question object.
 *
 * Note:
 * - Ensure that the 'data' array is properly structured and contains valid survey data for the instructor.
 * - The 'getRowSpan' function calculates the number of rows a cell should span, based on consecutive questions
 *   with the same category.
 * - The 'TableRow' and 'TableCell' components used in this function are likely from a UI library Material-UI.
 * - The table structure and cell styles may vary depending on the CSS or style settings in the application.
 */

function InstuctorTable(data) {
	const getRowSpan = (data, index) => {
		let count = 1;
		while (
			index + count < data.length &&
			data[index].category === data[index + count].category
		) {
			count++;
		}
		return count;
	};

	return (
		<div>
			<TableContainer>
				<Table
					size="small"
					style={{
						borderTop: "2px solid #ccc",
						borderBottom: "2px solid #ccc",
						borderLeft: "2px solid #ccc",
						borderRight: "2px solid #ccc",
					}}
				>
					<TableHead sx={{ borderBottom: "2px solid #ccc", backgroundColor: 'rgba(39, 93, 56,1)'}}>
						<TableRow>
							<TableCell
								sx={{ borderRight: "1px solid #ccc", width: "15%" }}
								align="center"
							>
								<Typography variant="body2" fontWeight="bold" sx={{ color: 'white' }}>
									SPOT DOMAIN
								</Typography>
							</TableCell>
							<TableCell
								sx={{ borderRight: "1px solid #ccc", width: "40%" }}
								align="center"
							>
								<Typography variant="body2" fontWeight="bold" sx={{ color: 'white' }}>
									Question
								</Typography>
							</TableCell>
							<TableCell sx={{ borderRight: "1px solid #ccc", width: "9%" }} align="center">
								<Typography variant="body2" fontWeight="bold" style={{ color: 'white' }}>
									Strongly Disagree 
								</Typography>
							</TableCell>
							<TableCell sx={{ borderRight: "1px solid #ccc", width: "9%" }} align="center">
								<Typography variant="body2" fontWeight="bold" style={{ color: 'white' }}>
									Disagree
								</Typography>
							</TableCell>
							<TableCell sx={{ borderRight: "1px solid #ccc", width: "9%"  }} align="center">
								<Typography variant="body2" fontWeight="bold" style={{ color: 'white' }}>
									Neither Agree Nor Disagree
								</Typography>
							</TableCell>
							<TableCell sx={{ borderRight: "1px solid #ccc", width: "9%"  }} align="center">
								<Typography variant="body2" fontWeight="bold" style={{ color: 'white' }}>
									Agree
								</Typography>
							</TableCell>
							<TableCell align="center" sx={{width: "9%" }}>
								<Typography variant="body2" fontWeight="bold" style={{ color: 'white' }}>
									Strongly Agree
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{data.map((row, index) => (
							<TableRow key={index}>
								{index === 0 ||
								data[index].category !== data[index - 1].category ? (
									<TableCell
										align="center"
										style={{
											borderRight: "1px solid #ccc",
											borderLeft: "1px solid #ccc",
											borderBottom: "1px solid #ccc",
											backgroundColor: row.backgroundColor,
										}}
										rowSpan={getRowSpan(data, index)}
									>
										{row.category}
									</TableCell>
								) : null}
								<TableCell
									align="left"
									style={{
										borderRight: "1px solid #ccc",
										borderBottom: "1px solid #ccc",
										backgroundColor: row.backgroundColor,
									}}
								>
									{row.question}
								</TableCell>
								<TableCell
									align="center"
									style={{
										borderRight: "1px solid #ccc",
										borderBottom: "1px solid #ccc",
										backgroundColor: row.backgroundColor,
									}}
								>
									{row.rsp1}
								</TableCell>
								<TableCell
									align="center"
									style={{
										borderRight: "1px solid #ccc",
										borderBottom: "1px solid #ccc",
										backgroundColor: row.backgroundColor,
									}}
								>
									{row.rsp2}
								</TableCell>
								<TableCell
									align="center"
									style={{
										borderRight: "1px solid #ccc",
										borderBottom: "1px solid #ccc",
										backgroundColor: row.backgroundColor,
									}}
								>
									{row.rsp3}
								</TableCell>
								<TableCell
									align="center"
									style={{
										borderRight: "1px solid #ccc",
										borderBottom: "1px solid #ccc",
										backgroundColor: row.backgroundColor,
									}}
								>
									{row.rsp4}
								</TableCell>
								<TableCell
									align="center"
									style={{
										borderRight: "1px solid #ccc",
										borderBottom: "1px solid #ccc",
										backgroundColor: row.backgroundColor,
									}}
								>
									{row.rsp5}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<div style={{display: 'flex', flexDirection: 'column'}}>
				<br />
				<Typography variant='caption' sx={{fontStyle: 'italic'}}>
					Student surveys are an important part of providing feedback about their 
					perspectives of teaching, but cannot be taken in isolation as a complete 
					evaluation of a course or instructor. Factors outside of an instructor’s 
					control may influence the results. These factors include, but are not 
					limited to: 
				</Typography>
				<br />
				<Typography variant='caption' sx={{fontStyle: 'italic',marginLeft: '40px'}}>
					a. completion rate of the survey;
				</Typography>
				<Typography variant='caption' sx={{fontStyle: 'italic',marginLeft: '40px'}}>
					b. class size, class level, the Faculty and program in which the course is 
					developed, timing of the class, delivery mode, required versus optional 
					course, accredited program requirements, practicum or clinical contexts, 
					grade expectations, student GPA, age of both students and instructors; and,
				</Typography>
				<Typography variant='caption' sx={{fontStyle: 'italic',marginLeft: '40px'}}>
					c. perceived race, gender, age, religion, ability, sexual orientation, 
					and/or ethnicity of the instructor. 
				</Typography>
				<br />
				<Typography variant='caption' sx={{fontStyle: 'italic'}}>
					Small differences in results should not be considered meaningful. Results 
					will be interpreted using the defined scale: Strongly Disagree (SD); 
					Disagree (D); Neither Agree nor Disagree (N); Agree (A); and, 
					Strongly Agree (SA).
				</Typography>
            </div>
		</div>
	);
}

export { InstuctorTable };
