import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { borderColor } from '@mui/system';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

var responses = 'something'

export const generateOptions = () => {
	return{
        responsive: true,
        plugins: {
            datalabels: {
                anchor: 'start',
                align: 'start',
                // color: 'black',
                // font: {
                // 	// size: 16,
                //   	weight: 'bold'
                // },
                formatter: function(value, context) {
                    let array = ['SD','D','N','A','SA']
                    return array[context.datasetIndex]
                }
            },
            legend: {
                display: false,
                position: 'bottom',
            },
            title: {
                display: true,
                text: ['SPOT Frequency Distributions',responses],
            },
        },
        scales: {
            x: {
                ticks: {
                    padding: 20
                },
                grid: {
                    color: '#D3D3D3' 
                }
            },
            y: {
                grid: {
                    color: '#D3D3D3' 
                }
            }
        }
    };
}

const colors = {
    'Strongly Disagree' : 'rgba(231,64,53,255)', //red
    'Disagree' : 'rgba(253,109,0,255)', //orange
    'Neither Agree Nor Disagree' : 'rgba(248,190,0,255)', //yellow
    'Agree': 'rgba(0,192,187,255)',//SKY BLUE:'rgba(10,184,233,255)', //blue
    'Strongly Agree' : 'rgba(48,169,85,255)', //green
}



/**
 * This function creates a Bar Chart component to visualize SPOT survey data for the University of Alabama (UA).
 *
 * Functionality:
 * - The function takes 'spotData' as input, which is an array containing survey data for UA SPOT evaluations.
 * - The 'getCategories' function extracts unique categories from the 'spotData' array, which will be used as labels
 *   for the Bar Chart.
 * - The 'collectData' function processes the 'spotData' array to prepare the data in a format suitable for rendering
 *   the Bar Chart.
 * - The 'collectData' function sets up the 'datasets' array with different response options ('Strongly Disagree',
 *   'Disagree', 'Neither Agree Nor Disagree', 'Agree', 'Strongly Agree') and their corresponding background colors.
 * - For each survey entry in 'spotData', the function collects the responses and arranges them in the respective
 *   dataset in the 'datasets' array.
 * - The 'data' object with 'labels' (categories) and 'datasets' is returned for rendering the Bar Chart.
 * - The Bar Chart is rendered using the 'Bar' component from a chart library (e.g., Chart.js).
 *
 * Note:
 * - The 'options' variable is not defined within the function, but it is expected to contain the configuration for
 *   customizing the appearance and behavior of the Bar Chart.
 * - The 'colors' object, containing the background colors for each response option, is assumed to be defined outside
 *   the function.
 * - Ensure that the 'spotData' array is properly structured and contains valid survey data for UA SPOT evaluations.
 * - The 'Bar' component used in this function is likely from a chart library like Chart.js or similar.
 */


function UAspotBarChart(courseInfo,spotData){

    function calculatePercentage(value, total) {

        // Check if both parameters are numbers
        if (typeof value !== 'number' || typeof total !== 'number') {
          return '0.0%';
        }
        
        // Calculate the percentage and round it to one decimal place
        const percentage = (value / total) * 100;
        const roundedPercentage = percentage.toFixed(1);

        // Format the result as a percentage string
        return `${roundedPercentage}%`;
      }

    function getCategories(spotData){
        const categories = spotData.map(item => item.category);
        const uniqueCategoriesSet = new Set(categories);
        const uniqueCategories = [...uniqueCategoriesSet];
        return uniqueCategories
    }

    function collectData(spotData){
       
        let responses = ['Strongly Disagree', 'Disagree','Neither Agree Nor Disagree','Agree','Strongly Agree']
        let data = {labels:getCategories(spotData),datasets:[]}
        for (let i = 0; i < responses.length; i++ ){
            let entry = {
                label: responses[i],
                data: [],
                backgroundColor: colors[responses[i]]
            }
            data.datasets.push(entry)
        }
       
        for (let i = 0;i<spotData.length;i++){
            let entry = [spotData[i].rsp1,spotData[i].rsp2,spotData[i].rsp3,spotData[i].rsp4,spotData[i].rsp5]
            for (let j = 0 ; j<entry.length;j++){
                data.datasets[j].data.push(entry[j])
            }
        }
        return data
    }

    responses = 'Responses : '.concat(courseInfo[8]," of ",courseInfo[7]," (",calculatePercentage(courseInfo[8], courseInfo[7]),")")
    const options = generateOptions()
    
    return(
        <Bar options={options} data={collectData(spotData)} height={70}/>
    )
}

export {UAspotBarChart}