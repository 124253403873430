import styles from "./styles/info.module.css";
import Button from "@mui/material/Button";
import Banner from "./components/Banner.js";
import Box from "@mui/material/Box";
import Footer from "./components/Footer.js";
import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
	{
		field: "reqnum",
		headerName: "Request Number",
		type: "string",
		valueGetter: ({ value }) => value && new String(value),
		width: 160,
	},
	{
		field: "rqdate",
		headerName: "Request Date",
		type: "date",
		valueGetter: ({ value }) => value && new Date(value),
		width: 140,
	},

	{ field: "instructor_name", headerName: "Instructor Name", width: 150 },
	{
		field: "coursetitle",
		headerName: "Course",
		width: 200,
	},
	{
		field: "status",
		headerName: "Status",
		width: 100,
	},
];

export default function DisplayRequests() {
	const [rowList, setRowList] = React.useState([]);
	const [fetched, setFetched] = React.useState(false);

	useEffect(() => {
		let token = sessionStorage.getItem("token");
		let ccid = sessionStorage.getItem("ccid");
		if (!fetched) {
			setFetched(true);
			refreshList(token, ccid);
		}
	}, [fetched]);

	async function refreshList(token, ccid) {
		let url = process.env.REACT_APP_BACKEND_HOST.concat(
			"/scanner/list_by_ccid/" + ccid
		);
		const response = await fetch(url, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Token ${token}`,
			},
		});

		let output = await response.json();
		setRowList(output);
	}
	return (
		<div className={styles.container}>
			<main className={styles.main}>
				<Box
					sx={{
						width: "80%",
						height: "80%",
						display: "flex",
						flexDirection: "column",

						justifyContent: "center",
						alignItems: "center",
						gap: "10px",
						borderRadius: "25px",
						backgroundColor: "white",
						outline: "3px solid #275d38",
					}}
				>
					<div style={{ height: 700, width: "100%", padding: "10px" }}>
						<DataGrid
							initialState={{
								sorting: {
									sortModel: [{ field: "reqnum", sort: "desc" }],
								},
							}}
							rows={rowList}
							columns={columns}
							pageSize={10}
							rowsPerPageOptions={[]}
							getRowId={(row) => row.reqnum}
						/>
					</div>
				</Box>
				<div className="buttondiv">
					<Button
						variant="contained"
						href="newordisplay"
						sx={{
							width: "200px",
							height: "50px",
							marginTop: "20px",
						}}
					>
						Done
					</Button>
				</div>
			</main>
			<Footer />
		</div>
	);
}
