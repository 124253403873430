import { jsPDF } from "jspdf";
import { img2 } from "../../pages/Requisition/components/images";
import dayjs from "dayjs";

//bold totnum, totopn,tt more obvious

export default function PdfGeneration(requisition, multi) {
	let reqnum = requisition.reqnum;
	function generatePDF(multi) {
		const doc = new jsPDF();
		let dateCreated = requisition.rqdate;
		let blockIds = requisition.blkid1 + ", " + requisition.blkid2 + ", " + requisition.blkid3;
		let ttItemList = requisition.itemList
			.filter((item) => item.repeat === "Yes")
			.map((item) => item.itemno);
		let ttItemCount = ttItemList.length;
		let ttItemString = ttItemList.join(",");
		let totalNumerical = requisition.totrtg;
		let totalOpenEnded = requisition.totopn;
		let startDate = dayjs(requisition.start_date).format("DD/MMM/YY").toUpperCase();
		let endDate = dayjs(requisition.end_date).format("DD/MMM/YY").toUpperCase();
		let itemListStringYMod = 7;
		doc.addImage(img2, "PNG", 170, 15, 25, 25);
		doc.setDrawColor("#275d38");
		doc.setFontSize(14);
		doc.setFont(undefined, "normal");
		doc.rect(10, 10, 190, 270);
		doc.text("Requisition number:", 15, 25);
		doc.setFont(undefined, "bold");
		doc.text(" " + reqnum, 60, 25);

		doc.setFont(undefined, "normal");
		doc.setFontSize(12);

		doc.text("Date Created: " + dateCreated, 15, 32);
		doc.text("Block Ids: " + blockIds, 15, 39);
		doc.text("Total Numerical Questions: " + totalNumerical, 15, 46);
		doc.text("Total Open Ended Questions: " + totalOpenEnded, 15, 53);
		if (requisition.itemListString.length > 35 && requisition.itemListString.length < 80) {
			let commaIndex = requisition.itemListString.indexOf(",", 35);
			itemListStringYMod = 12;
			doc.text(
				"Item List: " +
					requisition.itemListString.slice(0, commaIndex) +
					"," +
					"\n" +
					requisition.itemListString.slice(commaIndex + 1),
				15,
				60
			);
		} else if (requisition.itemListString.length > 80) {
			let commaIndex = requisition.itemListString.indexOf(",", 35);
			let commaIndex2 = requisition.itemListString.indexOf(",", 80);
			itemListStringYMod = 17;
			doc.text(
				"Item List: " +
					requisition.itemListString.slice(0, commaIndex) +
					"," +
					"\n" +
					requisition.itemListString.slice(commaIndex + 1, commaIndex2) +
					"," +
					"\n" +
					requisition.itemListString.slice(commaIndex2 + 1),
				15,
				60
			);
		} else {
			itemListStringYMod = 7;
			doc.text("Item List: " + requisition.itemListString, 15, 60);
		}
		if (ttItemString.length > 23) {
			let commaIndex = ttItemString.indexOf(",", 23);
			doc.text(
				"Team Teaching Items: " +
					"(" +
					ttItemCount +
					")" +
					": " +
					ttItemString.slice(0, commaIndex) +
					"," +
					"\n" +
					ttItemString.slice(commaIndex + 1),
				15,
				60 + itemListStringYMod
			);
		} else {
			doc.text(
				"Team Teaching Items: " + "(" + ttItemCount + ")" + ": " + ttItemString,
				15,
				60 + itemListStringYMod
			);
		}
		doc.text("Created by: " + requisition.raddr1, 120, 56);
		doc.text("Department: " + requisition.raddr2.substring(30, 0), 120, 63);
		doc.text("Start Date: " + startDate, 120, 70);
		doc.text("End Date: " + endDate, 120, 77);
		doc.setDrawColor("#000000");
		doc.rect(12, 85, 186, 10);
		doc.setFontSize(10);
		doc.setFont(undefined, "bold");
		doc.rect(12, 85, 18, 10);
		doc.text("Class", 16, 89);
		doc.text("Number", 14, 93);
		doc.rect(30, 85, 35, 10);
		doc.text("Class ID", 40, 91);
		doc.rect(65, 85, 35, 10);
		doc.text("Combined", 73, 89);
		doc.text("Classes", 75, 93);
		doc.rect(100, 85, 45, 10);
		doc.text("Course Title", 112, 91);
		doc.rect(145, 85, 20, 10);
		doc.text("Enrolled", 148, 91);
		doc.rect(165, 85, 33, 10);
		doc.text("Instructor", 174, 91);
		let arrayForSort = [...requisition.classes];
		arrayForSort.sort((a, b) => {
			return a.clsnum - b.clsnum;
		});
		doc.setFont(undefined, "normal");

		if (arrayForSort.length > 18 && arrayForSort.length <= 44) {
			let array1 = arrayForSort.slice(0, 18);
			let array2 = arrayForSort.slice(18, arrayForSort.length);
			array1.forEach((cls, index) => {
				let instructorFirstName = cls.name.split(" ")[0];
				let instructorLastName = cls.name.split(" ")[1];
				let tempCtitle = "";
				if (cls.ctitle.length > 17) {
					let spaceIndex = 17;
					tempCtitle =
						cls.ctitle.slice(0, spaceIndex) +
						"\n" +
						cls.ctitle.slice(spaceIndex, cls.ctitle.length);
				}
				let y = 95 + index * 10;
				doc.rect(12, y, 18, 10);
				let clsnum = String(cls.clsnum);
				doc.text(clsnum, 19, y + 6);
				doc.rect(30, y, 35, 10);
				let classid = String(cls.classid);
				doc.text(classid, 42, y + 6);
				doc.rect(65, y, 35, 10);
				let comb_classes = String(cls.comb_classes);
				if (comb_classes.length > 11) {
					let spaceIndex = 12;
					doc.text(comb_classes.slice(0, spaceIndex), 70, y + 4);
					doc.text(comb_classes.slice(spaceIndex), 70, y + 8);
				} else {
					doc.text(comb_classes, 77, y + 6);
				}
				doc.rect(100, y, 45, 10);
				if (tempCtitle !== "") {
					doc.text(String(tempCtitle), 105, y + 4);
				} else {
					doc.text(String(cls.ctitle), 105, y + 6);
				}
				doc.rect(145, y, 20, 10);
				if (cls.csize < 10) {
					doc.text(String(cls.csize), 154, y + 6);
				} else {
					doc.text(String(cls.csize), 153, y + 6);
				}
				doc.rect(165, y, 33, 10);
				doc.text(String(instructorFirstName), 168, y + 4);
				doc.text(String(instructorLastName), 168, y + 8);
			});
			doc.addPage();
			doc.setDrawColor("#275d38");
			doc.rect(10, 10, 190, 270);
			doc.setDrawColor("#000000");
			array2.forEach((cls, index) => {
				let instructorFirstName = cls.name.split(" ")[0];
				let instructorLastName = cls.name.split(" ")[1];
				let tempCtitle = "";
				if (cls.ctitle.length > 17) {
					let spaceIndex = 17;
					tempCtitle =
						cls.ctitle.slice(0, spaceIndex) +
						"\n" +
						cls.ctitle.slice(spaceIndex, cls.ctitle.length);
				}
				let y = 15 + index * 10;
				doc.rect(12, y, 18, 10);
				let clsnum = String(cls.clsnum);
				doc.text(clsnum, 19, y + 6);
				doc.rect(30, y, 35, 10);
				let classid = String(cls.classid);
				doc.text(classid, 42, y + 6);
				doc.rect(65, y, 35, 10);
				let comb_classes = String(cls.comb_classes);
				if (comb_classes.length > 11) {
					let spaceIndex = 12;
					doc.text(comb_classes.slice(0, spaceIndex), 70, y + 4);
					doc.text(comb_classes.slice(spaceIndex), 70, y + 8);
				} else {
					doc.text(comb_classes, 77, y + 6);
				}
				doc.rect(100, y, 45, 10);
				if (tempCtitle !== "") {
					doc.text(String(tempCtitle), 105, y + 4);
				} else {
					doc.text(String(cls.ctitle), 105, y + 6);
				}
				doc.rect(145, y, 20, 10);
				if (cls.csize < 10) {
					doc.text(String(cls.csize), 154, y + 6);
				} else {
					doc.text(String(cls.csize), 153, y + 6);
				}
				doc.rect(165, y, 33, 10);
				doc.text(String(instructorFirstName), 168, y + 4);
				doc.text(String(instructorLastName), 168, y + 8);
			});
		} else if (arrayForSort.length > 44) {
			let firstPage = 18;
			let consecutivePages = 26;
			let extraPages = Math.ceil((arrayForSort.length - 18) / 26);
			let arrayOfArrays = [];
			let array1 = arrayForSort.slice(0, firstPage);
			arrayOfArrays.push(array1);
			for (let i = 1; i <= extraPages; i++) {
				let array = arrayForSort.slice(
					firstPage + (i - 1) * consecutivePages,
					firstPage + i * consecutivePages
				);
				arrayOfArrays.push(array);
			}
			arrayOfArrays.forEach((array, index) => {
				if (index === 0) {
					array.forEach((cls, index) => {
						let instructorFirstName = cls.name.split(" ")[0];
						let instructorLastName = cls.name.split(" ")[1];
						let tempCtitle = "";
						if (cls.ctitle.length > 17) {
							let spaceIndex = 17;
							tempCtitle =
								cls.ctitle.slice(0, spaceIndex) +
								"\n" +
								cls.ctitle.slice(spaceIndex, cls.ctitle.length);
						}
						let y = 95 + index * 10;
						doc.rect(12, y, 18, 10);
						let clsnum = String(cls.clsnum);
						doc.text(clsnum, 19, y + 6);
						doc.rect(30, y, 35, 10);
						let classid = String(cls.classid);
						doc.text(classid, 42, y + 6);
						doc.rect(65, y, 35, 10);
						let comb_classes = String(cls.comb_classes);
						if (comb_classes.length > 11) {
							let spaceIndex = 12;
							doc.text(comb_classes.slice(0, spaceIndex), 70, y + 4);
							doc.text(comb_classes.slice(spaceIndex), 70, y + 8);
						} else {
							doc.text(comb_classes, 77, y + 6);
						}
						doc.rect(100, y, 45, 10);
						if (tempCtitle !== "") {
							doc.text(String(tempCtitle), 105, y + 4);
						} else {
							doc.text(String(cls.ctitle), 105, y + 6);
						}
						doc.rect(145, y, 20, 10);
						if (cls.csize < 10) {
							doc.text(String(cls.csize), 154, y + 6);
						} else {
							doc.text(String(cls.csize), 153, y + 6);
						}
						doc.rect(165, y, 33, 10);
						doc.text(String(instructorFirstName), 168, y + 4);
						doc.text(String(instructorLastName), 168, y + 8);
					});
				} else {
					doc.addPage();
					doc.setDrawColor("#275d38");
					doc.rect(10, 10, 190, 270);
					doc.setDrawColor("#000000");
					array.forEach((cls, index) => {
						let instructorFirstName = cls.name.split(" ")[0];
						let instructorLastName = cls.name.split(" ")[1];
						let tempCtitle = "";
						if (cls.ctitle.length > 17) {
							let spaceIndex = 17;
							tempCtitle =
								cls.ctitle.slice(0, spaceIndex) +
								"\n" +
								cls.ctitle.slice(spaceIndex, cls.ctitle.length);
						}
						let y = 15 + index * 10;
						doc.rect(12, y, 18, 10);
						let clsnum = String(cls.clsnum);
						doc.text(clsnum, 19, y + 6);
						doc.rect(30, y, 35, 10);
						let classid = String(cls.classid);
						doc.text(classid, 42, y + 6);
						doc.rect(65, y, 35, 10);
						let comb_classes = String(cls.comb_classes);
						if (comb_classes.length > 11) {
							let spaceIndex = 12;
							doc.text(comb_classes.slice(0, spaceIndex), 70, y + 4);
							doc.text(comb_classes.slice(spaceIndex), 70, y + 8);
						} else {
							doc.text(comb_classes, 77, y + 6);
						}
						doc.rect(100, y, 45, 10);
						if (tempCtitle !== "") {
							doc.text(String(tempCtitle), 105, y + 4);
						} else {
							doc.text(String(cls.ctitle), 105, y + 6);
						}
						doc.rect(145, y, 20, 10);
						if (cls.csize < 10) {
							doc.text(String(cls.csize), 154, y + 6);
						} else {
							doc.text(String(cls.csize), 153, y + 6);
						}
						doc.rect(165, y, 33, 10);
						doc.text(String(instructorFirstName), 168, y + 4);
						doc.text(String(instructorLastName), 168, y + 8);
					});
				}
			});
		} else {
			arrayForSort.forEach((cls, index) => {
				let instructorFirstName = cls.name.split(" ")[0];
				let instructorLastName = cls.name.split(" ")[1];
				let tempCtitle = "";
				if (cls.ctitle.length > 17) {
					let spaceIndex = 17;
					tempCtitle =
						cls.ctitle.slice(0, spaceIndex) +
						"\n" +
						cls.ctitle.slice(spaceIndex, cls.ctitle.length);
				}
				let y = 95 + index * 10;
				doc.rect(12, y, 18, 10);
				let clsnum = String(cls.clsnum);
				doc.text(clsnum, 19, y + 6);
				doc.rect(30, y, 35, 10);
				let classid = String(cls.classid);
				doc.text(classid, 42, y + 6);
				doc.rect(65, y, 35, 10);
				let comb_classes = String(cls.comb_classes);
				if (comb_classes.length > 11) {
					let spaceIndex = 12;
					doc.text(comb_classes.slice(0, spaceIndex), 70, y + 4);
					doc.text(comb_classes.slice(spaceIndex), 70, y + 8);
				} else {
					doc.text(comb_classes, 77, y + 6);
				}
				doc.rect(100, y, 45, 10);
				if (tempCtitle !== "") {
					doc.text(String(tempCtitle), 105, y + 4);
				} else {
					doc.text(String(cls.ctitle), 105, y + 6);
				}
				doc.rect(145, y, 20, 10);
				if (cls.csize < 10) {
					doc.text(String(cls.csize), 154, y + 6);
				} else {
					doc.text(String(cls.csize), 153, y + 6);
				}
				doc.rect(165, y, 33, 10);
				doc.text(String(instructorFirstName), 168, y + 4);
				doc.text(String(instructorLastName), 168, y + 8);
			});
		}
		if (multi) {
			doc.save(reqnum + ".pdf");
		} else {
			return doc.output("blob");
		}
	}

	async function savePDFToChosenDirectory(multi) {
		if (multi) {
			generatePDF(multi);
		} else {
			try {
				const opts = {
					suggestedName: reqnum + ".pdf",
					types: [
						{
							description: "PDF Documents",
							accept: {
								"application/pdf": [".pdf"],
							},
						},
					],
				};
				const fileHandle = await window.showSaveFilePicker(opts);
				const pdfBlob = await generatePDF(multi);
				const writable = await fileHandle.createWritable();
				await writable.write(pdfBlob);
				await writable.close();
			} catch (err) {
				console.error("Error saving the PDF:", err);
			}
		}
	}

	savePDFToChosenDirectory(multi);
}
