import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import CircularProgress from "@mui/material/CircularProgress";

export default function BlockIdSelect(props) {
	return (
		<div>
			<TextField
				select
				required={props.required}
				size={props.size}
				disabled={props.disabled}
				error={props.error}
				value={props.value || ""}
				name={props.name}
				label={props.label}
				multiple={props.multiple}
				onChange={props.onChange}
				variant={props.variant ? props.variant : "outlined"}
				sx={{
					width: props.width ? props.width : "100%",
					marginBottom: props.marginBottom ? props.marginBottom : "20px",
					marginTop: props.marginTop ? props.marginTop : "0px",
				}}
				SelectProps={{
					multiple: props.multiple,
					value: props.value || "",
					onChange: props.onChange,
				}}
			>
				{props.blockIds &&
					Object.keys(props.blockIds).map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
			</TextField>
		</div>
	);
}

/* export default function ListSelect(props) {
	return (
		<div>
			<TextField
				select
				required={props.required}
				size={props.size}
				disabled={props.disabled}
				error={props.error}
				value={props.value || ""}
				name={props.name}
				label={props.label}
				multiple={props.multiple}
				onChange={props.onChange}
				variant={props.variant ? props.variant : "outlined"}
				sx={{
					width: props.width ? props.width : "100%",
					marginBottom: props.marginBottom ? props.marginBottom : "20px",
					marginTop: props.marginTop ? props.marginTop : "0px",
				}}
				SelectProps={{
					multiple: props.multiple,
					value: props.value || "",
					onChange: props.onChange,
				}}
			>
				{props.list &&
					props.list.map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
			</TextField>
		</div>
	);
}


export default function GroupedSelect() {
  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel htmlFor="grouped-select">Grouping</InputLabel>
        <Select defaultValue="" id="grouped-select" label="Grouping">
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <ListSubheader>Category 1</ListSubheader>
          <MenuItem value={1}>Option 1</MenuItem>
          <MenuItem value={2}>Option 2</MenuItem>
          <ListSubheader>Category 2</ListSubheader>
          <MenuItem value={3}>Option 3</MenuItem>
          <MenuItem value={4}>Option 4</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
} */
