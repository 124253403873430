import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function CustomScaleUnique(props) {
	const handleAddScalePoint = () => {
		props.setScalePoints([...props.scalePoints, props.scalePoints.length + 3]);
		const newScaleValues = { ...props.scaleValues };
		newScaleValues["scale" + (props.scalePoints.length + 3)] = "";
		props.setScaleValues(newScaleValues);
	};
	const handleRemoveScalePoint = () => {
		if (props.scalePoints.length > 0) {
			props.setScalePoints(props.scalePoints.slice(0, props.scalePoints.length - 1));
			const newScaleValues = { ...props.scaleValues };
			delete newScaleValues["scale" + (props.scalePoints.length + 2)];
			props.setScaleValues(newScaleValues);
		}
	};

	const handleChangeScale = (event) => {
		const newScaleValues = { ...props.scaleValues };
		newScaleValues[event.target.name] = event.target.value;
		props.setScaleValues(newScaleValues);
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					marginTop: "10px",
					gap: "10px",
				}}
			>
				<TextField
					key={1}
					size="small"
					variant="outlined"
					label="Scale 1"
					name="scale1"
					value={props.scaleValues.scale1}
					onChange={handleChangeScale}
					focused
					placeholder="Yes..."
				/>
				<TextField
					key={2}
					size="small"
					variant="outlined"
					label="Scale 2"
					name="scale2"
					value={props.scaleValues.scale2}
					onChange={handleChangeScale}
					focused
					placeholder="No..."
				/>
				{props.scalePoints.map((scalePoint) => {
					return (
						<TextField
							key={scalePoint}
							size="small"
							variant="outlined"
							label={"Scale " + scalePoint}
							name={"scale" + scalePoint}
							value={props.scaleValues["scale" + scalePoint]}
							onChange={handleChangeScale}
							focused
						/>
					);
				})}
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					marginTop: "10px",
				}}
			>
				<Button variant="contained" onClick={handleAddScalePoint}>
					+Add Scale Point
				</Button>
				<Button
					color="error"
					variant="contained"
					onClick={handleRemoveScalePoint}
					disabled={props.scalePoints.length === 0}
				>
					-Remove Scale Point
				</Button>
				<Button
					variant="outlined"
					sx={{}}
					onClick={() => props.setOpenCustomScale(!props.openCustomScale)}
				>
					Done With Scale
				</Button>
			</Box>
		</Box>
	);
}
