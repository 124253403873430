import FormulaScoringCheckbox from "./FormulaScoringCheckbox";
import ExamWeightCheckbox from "./ExamWeightCheckbox";
import WeightList from "./WeightList";
import styles from "../styles/info.module.css";
import { FormControlLabel, Switch, Box, Button } from "@mui/material";

function AdvancedChecked(props) {
	let advancedChecked = props.advancedChecked;
	let examWeightsChecked = props.examWeightsChecked;
	let greaterThan = props.greaterThan;

	return (
		<div>
			{advancedChecked && (
				<div>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							minHeight: "20px",
							maxHeight: "60px",
							width: "100%",
						}}
					>
						<FormulaScoringCheckbox
							sx={{ marginRight: "40px" /* outline: "1px dotted grey" */ }}
							questionText="Apply formula scoring?"
							tooltipDescription="Scores may be computed for as many as 4 keys with formula scoring. The formula used is (R - W)/d where 
            R is the sum of correct responses, 
            W is the sum of incorrect responses (omits are not counted), and
            d is the number of response alternatives minus 1"
							formulaScoringChecked={props.formulaScoringChecked}
						/>
					</Box>
					<br />
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							minHeight: "20px",
							maxHeight: "60px",
						}}
					>
						<ExamWeightCheckbox
							disabled
							questionText="Do you have exam weights?"
							tooltipDescription="Weights apply a multiplier to the score of each answer key. For example, if an exam has 1 key with 50 questions and the instructor wishes for the exam to be out of 100 marks, they would add a weight of '2' to the answer key."
							checked={props.checked}
							onChange={props.onChange}
							numAnswerKeys={props.numAnswerKeys}
							sx={{ marginRight: "40px" /* outline: "1px dotted grey"  */ }}
						/>
					</Box>

					{examWeightsChecked && (
						<div className={styles.examWeightsList}>
							<WeightList numAnswerKeys={props.numAnswerKeys} />
						</div>
					)}

					{greaterThan && (
						<div className={styles.uploadbuttongap}>
							<Button
								variant="outlined"
								color="primary"
								sx={{ width: "250px" }}
								component="label"
							>
								Reorder Sheet Upload
								<input
									hidden
									accept=".pdf,.doc,.csv,.xlsx"
									multiple
									type="file"
								/>
							</Button>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default AdvancedChecked;
