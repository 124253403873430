import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import isAuthenticated from "../../Utils/isAuthenticated";

export default function ReportNotificationMenu(props) {
	const auth = isAuthenticated();
	const access = auth.access;
	const adminUser = access.includes("ADMIN");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [urgentReports, setUrgentReports] = useState([]);

	const [badgeCountUrgent, setBadgeCountUrgent] = useState(0);
	const [urgentReportsCount, setUrgentReportsCount] = useState(0);

	const [openUrgentReports, setOpenUrgentReports] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		if (event.target.innerText === "Logout") {
			props.Logout();
		}
		setAnchorEl(null);
	};

	useEffect(() => {
		if (props.notificationData) {
			const urgentReportsCount = props.notificationData.length;

			setUrgentReportsCount(urgentReportsCount);

			const badgeCountUrgent = urgentReportsCount;

			setBadgeCountUrgent(badgeCountUrgent);

			const sortedUrgentReportsByEndDate = props.notificationData.sort(
				(a, b) => {
					const dateA = new Date(a.end_date);
					const dateB = new Date(b.end_date);
					return dateA - dateB;
				}
			);

			setUrgentReports(sortedUrgentReportsByEndDate);
		}
	}, [props.notificationData]);

	const handleOpenUrgentReports = (event) => {
		event.stopPropagation();
		setOpenUrgentReports(!openUrgentReports);
	};

	if (adminUser) {
		return (
			<React.Fragment>
				<Box
					sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
				>
					<Tooltip title="Notifications">
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								height: "50%",
								marginRight: ".5rem",
							}}
							aria-controls={open ? "account-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={open ? "true" : undefined}
						>
							<Badge
								badgeContent={badgeCountUrgent}
								color="error"
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
							>
								<NotificationsIcon fontSize="large" color="#000000" />
							</Badge>
						</IconButton>
					</Tooltip>
				</Box>
				<Menu
					anchorEl={anchorEl}
					id="notification-menu"
					open={open}
					onClose={handleClose}
					slotProps={{
						paper: {
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: "background.paper",
									transform: "translateY(-50%) rotate(45deg)",
									zIndex: 0,
								},
							},
						},
					}}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				>
					{urgentReportsCount > 0 ? (
						<MenuItem onClick={handleOpenUrgentReports}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<Typography color="error">
									{urgentReportsCount} Urgent Reports
								</Typography>
								<ListItemIcon sx={{}}>
									{openUrgentReports ? (
										<ArrowDropDownIcon />
									) : (
										<ArrowDropUpIcon />
									)}
								</ListItemIcon>
							</Box>
						</MenuItem>
					) : null}
					{openUrgentReports ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								maxHeight: "250px",
								overflow: "auto",
							}}
						>
							<Divider />
							{urgentReports.map((req) => {
								return (
									<Typography
										sx={{ p: 1, marginLeft: "20px" }}
										key={req.reqnum}
									>
										{req.reqnum} - {req.end_date}
									</Typography>
								);
							})}
						</Box>
					) : null}
				</Menu>
			</React.Fragment>
		);
	}
}
