import React from "react";
import { useSearchParams } from "react-router-dom";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { TabInterface } from "./TabInterface";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home'


/**
 * This component represents a report view with tab navigation to display different reports for specific courses and requirements.
 *
 * Functionality:
 * - The component uses the 'useSearchParams' hook to access and parse the search parameters from the URL.
 * - It uses the 'React.useState' hook to manage the 'value' state, which represents the current active tab value.
 * - The 'handleChange' function is responsible for updating the 'value' state when the user switches tabs.
 * - The component renders a Box container with a row layout to hold the tab navigation and report content.
 * - It uses 'TabContext' from Material-UI to provide the context for the tab navigation.
 * - The tab navigation appears on the left side (vertical orientation) with a border separating it from the report content.
 * - The report view displays Breadcrumbs for navigation links, indicating the Home, Reporting, and Report View.
 * - The tab navigation is dynamically generated based on the 'searchParams' from the URL, which contains course names.
 * - For each course, a 'Tab' is rendered with the course name as its label, and its value represents the tab index.
 * - The report content is dynamically generated based on the 'searchParams' containing 'reqID', which represents
 *   requirements for each course.
 * - The 'TabPanel' component is used to display the content for each requirement, and 'TabInterface' renders
 *   the report content for the specific requirement.
 *
 * Note:
 * - Ensure that the 'useSearchParams' hook is imported from the appropriate package (e.g., 'react-router-dom')
 *   and set up properly to handle the search parameters in the URL.
 * - The appearance and styles are defined using the 'sx' prop from Material-UI.
 * - 'TabContext', 'Box', 'Tabs', 'Tab', 'Breadcrumbs', 'Link', 'Typography', 'HomeIcon', and 'TabPanel' are likely
 *   components from Material-UI or similar UI libraries.
 * - The 'TabInterface' function, responsible for rendering the report content, should be defined and imported
 *   separately to ensure the proper functionality of this component.
 */



export default function ReportView() {

    const [searchParams] = useSearchParams();
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box sx={{display: 'flex', flexDirection: 'row', /*border: '5px solid black'*/}}>
            <TabContext value={value}>

                <Box sx ={{width:'370px',borderRight:2, borderColor: 'divider'}}>

                    <Breadcrumbs separator=">" style={{margin:'10px'}}>
                        <Link underline="always" color='#275d38' href='/'>
                            <Typography color="#275d38" sx={{display: 'flex', alignItems: 'center' }}> 
                                <HomeIcon sx={{ mr: 0.5 }} />
                                Home
                            </Typography>
                        </Link>
                        <Typography color="text.primary">Reporting</Typography>
                        <Typography color="text.primary">Report View</Typography>
                    </Breadcrumbs>

                    <Tabs onChange={handleChange} value={value} orientation="vertical">
                        {searchParams.get('cname').split(',').map((course,index) => (
                            <Tab label={course} value={(index+1).toString()} key={index}/>
                        ))}
                    </Tabs>

                </Box>
                {searchParams.get('reqID').split(',').map((reqID,index) => (    
                    <TabPanel value={(index+1).toString()} sx ={{width:'90%'}} key={index}>
                        {TabInterface(reqID)}
                    </TabPanel>
                ))}
            </TabContext> 
        </Box>
    );
}

