import styles from "./styles/info.module.css";
import Button from "@mui/material/Button";
import Banner from "./components/Banner.js";
import Footer from "./components/Footer.js";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { Typography } from "@mui/material";

export default function PostSubmission() {
	return (
		<div className={styles.container}>
			<main className={styles.main}>
				<Box
					sx={{
						width: { sm: "80%", md: "60%", lg: "50%" },
						height: 520,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "10px",
						borderRadius: "25px",
						backgroundColor: "white",
						flexWrap: "nowrap",
						outline: "2px solid #275d38",
					}}
				>
					<div className={styles.newordisplaytext}>
						Would you like to create a new request, or display your existing
						requests?
					</div>
					<div>
						<Button
							href="request-for-service"
							variant="contained"
							color="primary"
							sx={{
								width: { sm: "100px", md: "125px", lg: "150px", xl: "200px" },
								height: { sm: "100px", md: "125px", lg: "150px", xl: "200px" },
								marginRight: "10px",
								marginLeft: "10px",
							}}
						>
							<Typography textAlign="center">NEW REQUEST</Typography>
						</Button>

						<Button
							href="display"
							variant="contained"
							color="primary"
							sx={{
								width: { sm: "100px", md: "125px", lg: "150px", xl: "200px" },
								height: { sm: "100px", md: "125px", lg: "150px", xl: "200px" },
								marginLeft: "20px",
								marginRight: "10px",
							}}
						>
							<Typography textAlign="center">
								DISPLAY EXISTING REQUESTS
							</Typography>
						</Button>
					</div>
				</Box>
			</main>
			<Footer />
		</div>
	);
}
