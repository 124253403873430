import {
	gridPageCountSelector,
	gridPageSelector,
	useGridApiContext,
	useGridSelector,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridOverlay
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import { createSearchParams } from "react-router-dom";


// This function returns a sorting function that compares two objects based on two keys
function rankingSorter(firstKey, secondKey) {
	return function (a, b) {
		// Compare the values of the first key
		if (a[firstKey] > b[firstKey]) {
			return -1;
		} else if (a[firstKey] < b[firstKey]) {
			return 1;
		}
		// If the values of the first key are equal, compare the values of the second key
		else {
			if (a[secondKey] > b[secondKey]) {
				return 1;
			} else if (a[secondKey] < b[secondKey]) {
				return -1;
			} else {
				return 0;
			}
		}
	};
}

function commentsPDF(reqnum, clsnum) {
	for (let i = 0; i < reqnum.length; i++) {
		let code = reqnum[i].toString().concat("-", clsnum[i].toString());
		console.log(code);
	}
}

// This function fetches a PDF and downloads it
function numericalPDF(reqnum, clsnum) {
	let course = "SPOT_numerical";
	var url = process.env.REACT_APP_BACKEND_HOST.concat(
		"/print/miketest/?req_num=",
		reqnum
	)
		.concat("&class_number=")
		.concat(clsnum)
		.concat("&ref_group=D")
		.concat("&report_type=pub");
	fetch(url, {
		headers: {
			Authorization: `Token ${sessionStorage.token}`,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			const incoming = `data:application/pdf;base64,${data["data"]}`;
			const dl = document.createElement("a");
			dl.href = incoming;
			dl.download = course.concat(".pdf");
			dl.click();
		});
}

// This component displays a pagination element for the grid
function CustomPagination() {
	const apiRef = useGridApiContext();
	const page = useGridSelector(apiRef, gridPageSelector);
	const pageCount = useGridSelector(apiRef, gridPageCountSelector);

	return (
		<Pagination
			color="primary"
			count={pageCount}
			page={page + 1}
			onChange={(event, value) => apiRef.current.setPage(value - 1)}
		/>
	);
}

function CustomNoRowsOverlay() {
	return (
	  <GridOverlay>
		<Typography variant="5">NO REPORTS AVAILABLE</Typography>
	  </GridOverlay>
	);
  }

// This component displays a toolbar for the grid
function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarFilterButton />
		</GridToolbarContainer>
	);
}

// This component opens the results in new tab
const getResults = (data) => {
	const search = createSearchParams({
		name: data.name,
		reqnum: data.reqnum,
		clsnum: data.clsnum,
		// ref_group: data.ref_group,
		reportID: data.reportID,
		course: data.course,
	}).toString();
	window.open("/Results?".concat(search), "_blank");
};


const getInstResults = (data) => {
	const search = createSearchParams({
		name: data.name,
	}).toString();
	window.open("/InstResults?".concat(search), "_blank");
};




export {
	rankingSorter,
	commentsPDF,
	numericalPDF,
	CustomPagination,
	CustomToolbar,
	getResults,
	getInstResults,
	CustomNoRowsOverlay
};
