import styles from "../styles/info.module.css";
import TextField from "@mui/material/TextField";
import React from "react";

function WeightList(props) {
	var listItem = [];
	for (var i = 1; i <= props.numAnswerKeys; i++) {
		listItem.push(
			<TextField
				key={i}
				label={"Weight"}
				helperText={"Key " + i}
				sx={{ padding: "2px" }}
			/>
		);
	}
	return listItem;
}

export default WeightList;
