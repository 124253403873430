import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box } from "@mui/material";
import ListSelect from "../../../components/Utils/ListSelect";
import TextInput from "../../../components/Utils/TextInput";
import AutocompleteComponent from "../../../components/Utils/AutocompleteComponent";
import { postBlockId, postBlockItems } from "../../../components/Api/Post";
import BlockItemsTransfer from "./BlockItemsTransfer";
import { useDepartmentsQuery } from "../../../Hooks/useDepartmentsQuery";
import isAuthenticated from "../../../components/Utils/isAuthenticated";

const courseTypes = ["LEC", "LAB", "CLN", "LEC:LAB", "LEC/LAB/SEM"];
const locationTypes = ["In-person", "On-line"];
const blkid3Types = ["3SPOT", "3STA", "3OPN", "3CSJ", "3CSJO", "NA"];
const originTypes = ["BASIC", "CUSTOM"];
const langTypes = ["EN", "FR"];
const csizeTypes = ["SMALL", "LARGE", "EITHER"];

export default function AddBlocks() {
	const auth = isAuthenticated();
	const token = auth.token;

	const [autocompleteValue, setAutocompleteValue] = useState("");

	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

	// sets the state of the block object
	const [blockState, setBlockState] = useState({
		catalog: "GFC",
		blkid: "",
		baddr1: "",
		baddr2: "",
		status: "C",
		ctype: "",
		faculty: "",
		dept_id: "",
		c_location: "",
		course: "",
		blkid3: "",
		origin: "CUSTOM",
		lang: "EN",
		csize: "EITHER",
	});

	const [chosenItems, setChosenItems] = useState([]);

	useEffect(() => {
		if (autocompleteValue) {
			const deptid = autocompleteValue.key;
			const deptName = autocompleteValue.display_name;

			setBlockState((prevblockState) => ({
				...prevblockState,
				deptId: deptid,
				department: deptName,
			}));
		}
	}, [autocompleteValue]);

	const { data: departments } = useDepartmentsQuery(token);

	const handleChange = (event) => {
		const value = event.target.value;
		setBlockState({ ...blockState, [event.target.name]: value });
	};

	async function asyncPostBlockId(token, block, blockItems) {
		postBlockId(token, block).then(() => {
			postBlockItems(token, blockItems);
		});
	}

	const handleSubmit = () => {
		const block = {
			catalog: blockState.catalog,
			blkid: blockState.blkid,
			baddr1: autocompleteValue.display_name,
			baddr2: blockState.baddr2,
			status: blockState.status,
			ctype: blockState.ctype,
			faculty: autocompleteValue.faculty,
			dept_id: autocompleteValue.key,
			c_location: blockState.c_location,
			course: blockState.course,
			blkid3: blockState.blkid3,
			origin: blockState.origin,
			lang: blockState.lang,
			csize: blockState.csize,
		};
		const blockItems = [];

		chosenItems.forEach((item, index) => {
			const newItem = {
				blkid: "",
				q_order: "",
				itemno: "",
			};
			newItem.blkid = blockState.blkid;
			newItem.q_order = index + 1;
			newItem.itemno = item.itemno;
			blockItems.push(newItem);
		});
		//get res.status from asyncPostBlockId
		asyncPostBlockId(token, block, blockItems);

		setBlockState({
			catalog: "GFC",
			blkid: "",
			baddr1: "",
			baddr2: "",
			status: "C",
			ctype: "",
			faculty: "",
			dept_id: "",
			c_location: "",
			course: "",
			blkid3: "",
			origin: "CUSTOM",
			lang: "EN",
			csize: "EITHER",
		});
		setChosenItems([]);
		setAutocompleteValue("");
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "flex-start",
				width: "100%",
			}}
		>
			<Box
				sx={{
					marginTop: "2%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						width: "100%",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<AutocompleteComponent
						options={departments && departments.length > 0 ? departments : []}
						value={autocompleteValue}
						setValue={setAutocompleteValue}
						inputValue={autocompleteInputValue}
						setInputValue={setAutocompleteInputValue}
						label="Department"
					/>

					<TextInput value={blockState.blkid || ""} name="blkid" label="Block ID" onChange={handleChange} />

					<ListSelect value={blockState.ctype || ""} name="ctype" label="Type" onChange={handleChange} list={courseTypes} />

					<ListSelect value={blockState.c_location || ""} name="c_location" label="Location" onChange={handleChange} list={locationTypes} />

					<TextInput value={blockState.course || ""} name="course" label="course" onChange={handleChange} />

					<ListSelect
						value={blockState.blkid3 || ""}
						/* error={itemError.blkid3} */
						name="blkid3"
						label="Standard Block"
						onChange={handleChange}
						list={blkid3Types}
					/>

					<ListSelect
						value={blockState.origin || ""}
						/* error={itemError.blkid3} */
						name="origin"
						label="Origin"
						onChange={handleChange}
						list={originTypes}
					/>

					<ListSelect
						value={blockState.lang || ""}
						/* error={itemError.blkid3} */
						name="lang"
						label="Language"
						onChange={handleChange}
						list={langTypes}
					/>

					<ListSelect
						value={blockState.csize || ""}
						/* error={itemError.blkid3} */
						name="csize"
						label="CSize"
						onChange={handleChange}
						list={csizeTypes}
					/>
					<Box sx={{ display: "flex", flexDirection: "row", marginTop: "10px", marginBottom: "10px" }}>
						<BlockItemsTransfer chosenItems={chosenItems} setChosenItems={setChosenItems} />
					</Box>
					<Button variant="contained" sx={{ display: "flex", width: "100%", marginBottom: "20px" }} onClick={handleSubmit}>
						Submit
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
