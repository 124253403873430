//All imports happen at the top of the file
import React, { useEffect, useState } from "react";
import { Typography, Button, Box, List, ListItem, ListItemText, Paper, Link } from "@mui/material";
import { useTermsQueryFull } from "../../Hooks/useTermsQuery";
import { useClassIdByEmpId } from "../../Hooks/useClassIdsQuery";
import { useGetFacultyRankByCCID } from "../../Hooks/useFacultyRankQuery";
import isAuthenticated from "../../components/Utils/isAuthenticated";
import { RequisitionCalendar } from "../../components/Utils/RequisitionCalendar";
import dayjs from "dayjs";
import { updateConfirmExamDate } from "../../components/Api/Updates";
import ConfirmNoExamDialog from "./components/ConfirmNoExamDialog";
import CircularProgress from "@mui/material/CircularProgress";

export default function ConfirmExamDates() {
	const auth = isAuthenticated();
	const token = auth.token;
	const ccid = auth.ccid;
	const [openNoExamDialog, setOpenNoExamDialog] = useState(false);
	const { data: facultyRank, status: facultyRankStatus } = useGetFacultyRankByCCID(token, ccid);

	const { data: terms, status: termsStatus } = useTermsQueryFull(token);
	const [evaluationStart, setEvaluationStart] = useState(null);
	const [evaluationEnd, setEvaluationEnd] = useState(null);
	const [originalClassesState, setOriginalClassesState] = useState([]);
	const [modifiedClassesState, setModifiedClassesState] = useState([]);
	const [examDates, setExamDates] = useState({});
	const [chosenClass, setChosenClass] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");

	const {
		data: classIds,
		status: classIdsStatus,
		isSuccess,
		isFetching,
		isLoading,
	} = useClassIdByEmpId(token, facultyRank);

	useEffect(() => {
		if (isSuccess) {
			if (classIds && classIds.length > 0) {
				const initialExamDates = classIds.reduce((acc, classId) => {
					return { ...acc, [classId.classid]: classId.examdate };
				}, {});
				setExamDates(initialExamDates);
			} else {
				console.log("No classes found. Please contact TSS for assistance.");
				setErrorMessage("No classes found. Please contact TSS for assistance.");
			}
		}
	}, [classIds, isSuccess]);

	useEffect(() => {
		if (terms && terms.length > 0) {
			const termNumbers = terms.map((term) => term.term);
			const highestTermNumber = Math.max(...termNumbers);
			const highestTermIndex = termNumbers.indexOf(highestTermNumber);
			const highestTerm = terms[highestTermIndex];
			const highestTermsurveyEndDate = highestTerm?.end;
			const evaluationStart = dayjs(highestTermsurveyEndDate).subtract(2, "week");
			const evaluationEnd = dayjs(highestTermsurveyEndDate).add(2, "day");
			setEvaluationStart(evaluationStart);
			setEvaluationEnd(evaluationEnd);
		}
	}, [terms]);

	useEffect(() => {
		setOriginalClassesState(classIds);
	}, [classIds]);

	useEffect(() => {
		if (originalClassesState && originalClassesState.length > 0) {
			const updatedClassesState = originalClassesState.map((classId) => {
				let surveyStartDate = classId.surveyStartDate;
				let surveyEndDate = classId.surveyEndDate;

				const examDate = examDates[classId.classid] || classId.examdate;

				if (examDate === null || (examDate && examDate.length === 0)) {
					surveyStartDate = evaluationStart;
					surveyEndDate = evaluationEnd;
				} else {
					if (dayjs(examDate).isBefore(evaluationEnd) || dayjs(examDate).isSame(evaluationEnd)) {
						surveyEndDate = dayjs(examDate).subtract(1, "day");
						surveyStartDate = dayjs(surveyEndDate).subtract(2, "week");
					} else {
						surveyEndDate = evaluationEnd;
						surveyStartDate = evaluationStart;
					}
				}
				return { ...classId, surveyStartDate, surveyEndDate };
			});

			setModifiedClassesState(updatedClassesState);
		}
	}, [originalClassesState, evaluationStart, evaluationEnd, examDates]);

	async function handleConfirm(classId) {
		if (examDates[classId.classid] !== null && examDates[classId.classid] !== undefined) {
			const examDate = dayjs(examDates[classId.classid]).format("YYYY-MM-DD");
			await updateConfirmExamDate(token, classId.classid, examDate);
			const updatedClassesState = modifiedClassesState.map((modifiedClass) => {
				if (modifiedClass.classid === classId.classid) {
					classId.exam_confirmed = "Y";
					classId.examdate = examDate;
					return classId;
				} else {
					return modifiedClass;
				}
			});
			setModifiedClassesState(updatedClassesState);
			setOriginalClassesState(updatedClassesState);
		} else {
			setChosenClass(classId);
			setOpenNoExamDialog(true);
		}
	}

	async function handleAgree(classId) {
		await updateConfirmExamDate(token, classId.classid, "none");
		const updatedClassesState = modifiedClassesState.map((modifiedClass) => {
			if (modifiedClass.classid === classId.classid) {
				classId.exam_confirmed = "Y";
				return classId;
			} else {
				return modifiedClass;
			}
		});
		setModifiedClassesState(updatedClassesState);
		setOriginalClassesState(updatedClassesState);
		setOpenNoExamDialog(false);
	}

	const handleDisagree = () => {
		setOpenNoExamDialog(false);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				width: "100vw",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",

					gap: "10px",
					alignItems: "center",
				}}
			>
				<Box sx={{ p: 16 }}>
					<Paper
						sx={{
							mb: 2,
							pl: 4,
							pr: 4,
							display: "flex",
							flexDirection: "column",
							gap: "20px",
						}}
					>
						<Typography sx={{ pt: 4 }} variant="h5" component="div" textAlign={"center"}>
							Confirm Your Exam Dates
						</Typography>
						<Typography sx={{}} variant="h6" component="div">
							Below are the exam dates for your courses as per Campus Solutions, please make the
							necessary changes to reflect your actual final exam or final assessment date. If the
							listed date is correct for a course, you can simply click "confirm".
						</Typography>
						<Typography sx={{}} variant="h6" component="div">
							If your course does not have a final exam or assessment, please click (the red)
							"confirm" and then "agree" to confirm that there is no final exam or assessment for
							the course.
						</Typography>

						{/* <Typography sx={{}} variant="h6" component="div">
							Please confirm the exam dates for the following courses. If a course is missing from
							the list below, please contact TSS for assistance.
						</Typography> */}

						<List>
							{modifiedClassesState &&
								modifiedClassesState.length > 0 &&
								modifiedClassesState.map((classId) => (
									<ListItem
										key={classId.classid}
										sx={{
											outline: "1px solid grey",
											borderRadius: "5px",
											m: 1,
											gap: "5px",
											alignItems: "center",
											backgroundColor: classId.exam_confirmed === "Y" ? "lightgreen" : "white",
										}}
									>
										<ListItemText
											primary={classId.ctitle}
											secondary={
												classId.description.length > 30
													? classId.description.substring(30, 0).concat("...")
													: classId.description
											}
										/>
										{examDates[classId.classid] ? (
											<>
												<RequisitionCalendar
													name="examDate"
													label="Exam or Final Assessment Date"
													value={dayjs(examDates[classId.classid])}
													onChange={(newValue) => {
														setExamDates({
															...examDates,
															[classId.classid]: newValue,
														});
													}}
												/>
												<RequisitionCalendar
													name="surveyStartDate"
													disabled
													label="Survey Start Date"
													value={classId.surveyStartDate}
												/>
												<RequisitionCalendar
													name="surveyEndDate"
													disabled
													label="Survey End Date"
													value={classId.surveyEndDate}
												/>
												{examDates[classId.classid] !== null &&
												examDates[classId.classid] !== undefined &&
												dayjs(classId.examdate).format("YYYY-MM-DD") !==
													dayjs(examDates[classId.classid]).format("YYYY-MM-DD") ? (
													<Button
														variant="contained"
														sx={{ height: "80px", width: "100px" }}
														onClick={() => handleConfirm(classId)}
													>
														Confirm
													</Button>
												) : classId.exam_confirmed === "Y" ? (
													<Button
														disabled
														variant="contained"
														sx={{ height: "80px", width: "100px" }}
														onClick={() => handleConfirm(classId)}
													>
														Confirmed
													</Button>
												) : (
													<Button
														variant="contained"
														sx={{ height: "80px", width: "100px" }}
														onClick={() => handleConfirm(classId)}
													>
														Confirm
													</Button>
												)}
											</>
										) : (
											<>
												<RequisitionCalendar
													name="examDate"
													label="Exam or Final Assessment Date"
													confirm
													value={dayjs(classId.examdate)}
													onChange={(newValue) => {
														setExamDates({
															...examDates,
															[classId.classid]: dayjs(newValue).format("YYYY-MM-DD"),
														});
													}}
												/>
												<RequisitionCalendar
													name="surveyStartDate"
													disabled
													label="Survey Start Date"
													value={classId.surveyStartDate}
												/>
												<RequisitionCalendar
													name="surveyEndDate"
													disabled
													label="Survey End Date"
													value={classId.surveyEndDate}
												/>
												{classId.exam_confirmed === "Y" ? (
													<Button
														variant="contained"
														disabled
														sx={{ height: "80px", width: "100px" }}
														color="error"
														onClick={() => handleConfirm(classId)}
													>
														Confirmed
													</Button>
												) : (
													<Button
														variant="contained"
														sx={{ height: "80px", width: "100px" }}
														color="error"
														onClick={() => handleConfirm(classId)}
													>
														Confirm
													</Button>
												)}
											</>
										)}
									</ListItem>
								))}
						</List>
					</Paper>
					{isFetching && (
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<CircularProgress />
						</Box>
					)}
					<Typography
						sx={{ display: "flex", justifyContent: "center" }}
						color="error"
						variant="h6"
						component="div"
					>
						{errorMessage}
					</Typography>
				</Box>
			</Box>
			<ConfirmNoExamDialog
				open={openNoExamDialog}
				setOpen={setOpenNoExamDialog}
				classId={chosenClass}
				start={dayjs(evaluationStart).format("DD/MMM/YY")}
				end={dayjs(evaluationEnd).format("DD/MMM/YY")}
				handleAgree={handleAgree}
				handleDisagree={handleDisagree}
			/>
		</Box>
	);
}
