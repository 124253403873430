import * as React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import {
	useFacultyRank,
	useAddInstructorToFacultyRank,
} from "../../../../Hooks/useFacultyRankQuery";
import { useRolesAddInstructor } from "../../../../Hooks/useRolesQuery";
import { useUpdateClassIdTeamSize } from "../../../../Hooks/useClassIdsQuery";
import FacultyRankAutocomplete from "../../Manual/AddRequisition/components/FacultyRankAutocomplete";
import ListSelect from "../../../../components/Utils/ListSelect";

const roles = [
	"PI",
	"TA",
	"CC",
	"CO",
	"LA",
	"GL",
	"AI",
	"PG",
	"SG",
	"TC",
	"DA",
];

export default function ClassAdjustAddInstructor(props) {
	let auth = isAuthenticated();
	const token = auth.token;
	const { data: facultyRank } = useFacultyRank(token);

	const [deptId, setDeptId] = useState("");
	const [instructorRole, setInstructorRole] = useState("");
	const [existingRoles, setExistingRoles] = useState([]);
	const [newRoleValue, setNewRoleValue] = useState("");

	useEffect(() => {
		if (props.selectedClass.dept) setDeptId(props.selectedClass.dept);
	}, [props.selectedClass]);

	const { addInstructorToFacultyRank, status: statusPostFacultyRank } =
		useAddInstructorToFacultyRank(token, deptId);

	const addInstructor = async (instructorToAdd) => {
		const success = await addInstructorToFacultyRank(instructorToAdd);
		return success;
	};

	const handleChangeRole = (event) => {
		setInstructorRole(event.target.value);
	};

	const handleAddInstructor = async () => {
		const { selectedClass, rows, setRows, originalRows, setUnsavedChanges } =
			props;
		const instructorToAdd = newRoleValue;

		let newRole = {};

		const updatedRows = rows.map((classObj) => {
			if (classObj.classid === selectedClass.classid) {
				newRole = {
					ccid: instructorToAdd.ccid,
					class_role: instructorRole,

					checked: false,
					classid: classObj.classid,
					disabled: false,
					empid: instructorToAdd.empid,
					name: instructorToAdd.name,
					role_description: "Primary Instructor",
					roleid: Number(instructorToAdd.empid) + classObj.classid,
					term: classObj.term,
					import_status: "MANUAL",
				};

				if (
					classObj.teamsize === 0 ||
					(classObj.teamsize !== 0 && classObj.roles.length === 0)
				) {
					return {
						...classObj,
						name: instructorToAdd.name,
						empid: instructorToAdd.empid,

						class_role: instructorRole,
						teamsize: 1,
						comb_classes: [],
						roles: [newRole],
					};
				} else {
					const updatedRoles = [...classObj.roles, newRole];
					const teamNames = updatedRoles
						.map((role) => `'${role.name}'`)
						.join(", ");
					return {
						...classObj,
						roles: updatedRoles,
						teamName: teamNames,
						teamsize: classObj.teamsize + 1,
					};
				}
			}
			return classObj;
		});

		// Create a new array instance to trigger re-render
		setRows([...updatedRows]);

		const isChanged =
			JSON.stringify(originalRows) !== JSON.stringify(updatedRows);
		setUnsavedChanges(isChanged);

		props.setAddInstructor(false);

		setInstructorRole("");
		/* await addRoles(newRole); */
		//if successful reset the search and role
	};

	const checkExistingFacultyRank = (empid) => {
		let exists = false;
		facultyRank.forEach((rank) => {
			if (rank.empid === empid) {
				exists = true;
			}
		});
		return exists;
	};

	return (
		<div>
			<div className="flex  w-full items-center gap-2 py-2">
				<FacultyRankAutocomplete
					roles={roles}
					options={facultyRank}
					currentRow={props.selectedClass}
					setExistingRoles={setExistingRoles}
					newRoleValue={newRoleValue}
					setNewRoleValue={setNewRoleValue}
					checkExistingFacultyRank={checkExistingFacultyRank}
					addInstructorToFacultyRank={addInstructor}
				/>
				<ListSelect
					required
					marginBottom="0"
					label="Role"
					value={instructorRole}
					onChange={handleChangeRole}
					list={roles}
					width={100}
				/>
			</div>
			<div className="flex items-center w-full">
				<button
					className="bg-green-800 text-white px-4 py-2 rounded-md  hover:bg-green-900 w-1/2"
					onClick={handleAddInstructor}
				>
					Add
				</button>
				<button
					variant="contained"
					className="bg-red-500 text-white px-4 py-2 rounded-md ml-2 hover:bg-red-600 w-1/2"
					onClick={() => props.setAddInstructor(false)}
				>
					Cancel
				</button>
			</div>
		</div>
	);
}
