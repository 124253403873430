import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";

export default function AutoCompleteComponent(props) {
	if (props.options.length < 1) {
		return (
			<FormControl>
				<Autocomplete
					id={props.id}
					options={props.options}
					autoHighlight
					disabled
					key={props.key}
					value={props.value || ""}
					name={props.name}
					sx={{
						paddingBottom: { sm: "40px", md: "40px", lg: "40px", xl: "116px" },
					}}
					onChange={props.onChange}
					renderInput={(params) => <TextField {...params} label={props.label} name={props.name} key={props.key} helperText={props.helperText} error={props.error} id={props.id} />}
				/>
			</FormControl>
		);
	} else {
		return (
			<FormControl>
				<Autocomplete
					id={props.id}
					options={props.options}
					autoHighlight
					key={props.key}
					value={props.value || ""}
					name={props.name}
					sx={{
						minWidth: 220,
						/* paddingBottom: { sm: "40px", md: "40px", lg: "40px", xl: "116px" }, */
					}}
					onChange={props.onChange}
					isOptionEqualToValue={(option, value) => {
						const optionValue = option.label;
						const optionValueNoParen = optionValue.replace(/ *\([^)]*\) */g, "");
						const valueNoParen = value.replace(/ *\([^)]*\) */g, "");
						if (valueNoParen.substring(0, 32) === optionValueNoParen.substring(0, 32)) {
							return true;
						} else {
							return false;
						}
					}}
					renderInput={(params) => <TextField {...params} label={props.label} required name={props.name} key={props.key} helperText={props.helperText} error={props.error} id={props.id} />}
				/>
			</FormControl>
		);
	}
}
