import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { TextField, Typography, MenuItem } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import CustomScaleUnique from "../../../../components/CustomScaleUnique";
import SimilarItemTable from "../../../../components/SimilarItemTable";
import { Grid } from "@mui/material";
import { useAllItemsQuery } from "../../../../../../Hooks/useAllItemsQuery";
import isAuthenticated from "../../../../../../components/Utils/isAuthenticated";
import { postItemsUnique } from "../../../../../../components/Api/Post";
import dayjs from "dayjs";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 1000,
	minHeight: 750,
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 2,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
};

const itemTypes = ["RATE", "OPEN"];

export default function AddUniqueItemsModal(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const [openCustomScale, setOpenCustomScale] = useState(false);
	const [scaleValues, setScaleValues] = useState({ scale1: "", scale2: "" });
	const [scalePoints, setScalePoints] = useState([]);
	const [readyToSubmit, setReadyToSubmit] = useState(false);
	const [similarQuestions, setSimilarQuestions] = useState([]);
	const [itemState, setItemState] = useState({
		itemType: "",
		questionText: "",
		questionTextError: false,
		questionTextHelperText: "",
		questionTextErrorText: "",
		scale: "",
	});

	const { data: allItemsData } = useAllItemsQuery(token);

	useEffect(() => {
		if (scaleValues.scale1 !== "" && scaleValues.scale2 !== "") {
			const scaleValuesArray = Object.values(scaleValues);
			let itemScale = "";
			scaleValuesArray.forEach((scale, index) => {
				if (scale.includes(";")) {
					scale = scale.replace(/;/g, "");
				}
				if (index !== scaleValuesArray.length - 1) {
					itemScale += scale + ";";
				} else {
					itemScale += scale;
				}
			});

			setItemState({
				...itemState,
				scale: itemScale,
			});
		}
	}, [scaleValues, itemState]);

	const [itemChecked, setItemChecked] = useState(false);
	const [tooSimilar, setTooSimilar] = useState(false);
	const [itemError, setItemError] = useState({
		itemText: false,
	});
	const [itemTextHelperText, setItemTextHelperText] = useState("");

	useEffect(() => {
		if (
			itemState.questionText.length > 0 &&
			itemState.itemType !== "" &&
			itemChecked === true &&
			tooSimilar === false
		) {
			setReadyToSubmit(true);
		} else {
			setReadyToSubmit(false);
		}
	}, [itemState.questionText, itemState.itemType, itemChecked, tooSimilar]);

	const handleChange = (event) => {
		setSimilarQuestions([]);
		setItemTextHelperText("");
		setItemError({ itemText: false });
		setItemChecked(false);
		setTooSimilar(false);
		setItemState({
			...itemState,
			[event.target.name]: event.target.value,
			questionTextError: false,
		});
	};

	const handleOpen = () => props.setOpen(true);
	const handleClose = (event, reason) => {
		setItemState({
			itemType: "",
			questionText: "",
			questionTextError: false,
			questionTextHelperText: "",
			questionTextErrorText: "",
			scale: "",
		});
		setScaleValues({ scale1: "", scale2: "" });
		setScalePoints([]);
		setReadyToSubmit(false);
		setItemChecked(false);
		setTooSimilar(false);

		setOpenCustomScale(false);

		setItemTextHelperText("");
		setSimilarQuestions([]);

		props.setOpen(false);
	};
	const checkForSimilarity = (event) => {
		event.preventDefault();
		const allItemsNoCustom = allItemsData.filter(
			(item) => (item.itemno > 0 && item.itemno < 1000) || item.itemno > 2000
		);

		if (allItemsNoCustom.length > 0) {
			let tooSimilar = false;
			let item = allItemsNoCustom.find(
				(item) => item.text === itemState.questionText
			);

			let similarItems = [];
			allItemsNoCustom.forEach((item) => {
				if (compareStrings(item.text, itemState.questionText, 0.5, 6)) {
					tooSimilar = true;
					if (!similarItems.includes(item)) {
						similarItems.push(item);
					}
				}
			});
			if (tooSimilar) {
				setTooSimilar(true);
				setItemState({ ...itemState, questionTextError: true });
				setItemChecked(true);
				setSimilarQuestions(similarItems);
				setItemTextHelperText(
					"Existing items are too similar. See table below for catalog items."
				);
			} else {
				setItemChecked(true);
				setItemState({ ...itemState, questionTextError: false });
				setItemTextHelperText("Ready to add");

				setTooSimilar(false);
			}
		}
	};

	function getNGrams(str, n) {
		const nGrams = [];
		for (let i = 0; i < str.length - n + 1; i++) {
			const nGram = str.slice(i, i + n);
			nGrams.push(nGram);
		}
		return nGrams;
	}

	function jaccardSimilarity(str1, str2, n) {
		const set1 = new Set(getNGrams(str1, n));
		const set2 = new Set(getNGrams(str2, n));
		const intersection = new Set([...set1].filter((gram) => set2.has(gram)));
		const union = new Set([...set1, ...set2]);
		const similarity = intersection.size / union.size;
		return similarity;
	}

	function compareStrings(str1, str2, threshold, n) {
		const similarity = jaccardSimilarity(str1, str2, n);
		if (similarity >= threshold) {
			return true;
		} else {
			return false;
		}
	}

	const handleAddUniqueItem = async () => {
		let uniqueCount = 0;
		let uniqueItemNo = 0;
		props.chosenItems.forEach((item) => {
			if (item.sub_type === "U") {
				uniqueCount++;
			}
		});
		if (uniqueCount > 0) {
			uniqueItemNo = 1000 + uniqueCount + 1;
		} else {
			uniqueItemNo = 1001;
		}

		//add to
		let list = [];
		const newItem = {
			cat_id: 10,
			catalog: "GFC",
			sub_type: "U",
			reqnum: props.reqnum,
			itemno: uniqueItemNo,
			itmtyp: itemState.itemType,
			addate: dayjs().format("YYYY-MM-DD"),
			text: itemState.questionText,
			team_teaching: "N",
			scale: itemState.scale,
			repeat: false,
		};
		if (props.inModifySecond) {
			list.push(newItem);
			await postItemsUnique(token, list);
		}
		const chosenItemsCopy = [...props.chosenItems];
		chosenItemsCopy.push(newItem);
		props.setChosenItems(chosenItemsCopy);
		props.setModifiedItems(true);
		if (props.fromModify === true) {
			props.setModified(true);
		}
		props.setOpen(false);
	};

	return (
		<div>
			<Button
				variant="outlined"
				onClick={handleOpen}
			>
				Add Unique Items
			</Button>
			<Modal
				open={props.open}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box
						sx={{
							height: "600px",
							overflow: "none",
							marginBottom: "20px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Typography
							variant="h5"
							textAlign="center"
							sx={{}}
						>
							Add Unique Item
						</Typography>
						<Typography
							variant="h6"
							textAlign="center"
							sx={{ marginTop: "10px" }}
						>
							Unique items are items that are not added to the permanent catalog
							of questions. Unique items should be used sparingly as reference
							data does not exist for them, and as such no validity exists for
							them either.
						</Typography>
						<Typography
							variant="h6"
							textAlign="center"
							sx={{ marginTop: "10px", marginBottom: "50px" }}
						>
							The most common use case for unique items is courses that have a
							large number of instructors who teach the course in tandem.
						</Typography>
						<Box
							sx={{
								height: "100px",
								borderRadius: "5px",
								display: "flex",
								alignItems: "center",
							}}
						>
							<TextField
								sx={{ marginLeft: "10px", width: "120px" }}
								required
								label="Item Type"
								select
								SelectProps={{}}
								onChange={handleChange}
								name="itemType"
								value={itemState.itemType || ""}
							>
								{itemTypes.map((option) => (
									<MenuItem
										key={option}
										value={option}
									>
										{" "}
										{option}{" "}
									</MenuItem>
								))}
							</TextField>
							<TextField
								sx={{ marginLeft: "10px", flex: "1", marginRight: "5px" }}
								label="Question Text"
								required
								error={itemState.questionTextError}
								helperText={itemState.questionTextHelperText}
								onChange={handleChange}
								name="questionText"
							/>

							<Button
								variant="outlined"
								sx={{ marginRight: "5px", height: "55px" }}
								disabled={openCustomScale || itemState.questionText.length < 5}
								onClick={() => setOpenCustomScale(!openCustomScale)}
							>
								Custom Scale
							</Button>

							<Button
								variant="contained"
								disabled={itemState.questionText.length < 5}
								sx={{ marginRight: "5px", height: "55px" }}
								onClick={checkForSimilarity}
							>
								Check
							</Button>
							<Button
								variant="contained"
								disabled={!readyToSubmit}
								sx={{ marginRight: "10px", height: "55px" }}
								onClick={handleAddUniqueItem}
							>
								Add
							</Button>
						</Box>
						<Box
							sx={{
								height: "600px",
								width: "100%",
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							{openCustomScale && (
								<CustomScaleUnique
									scaleValues={scaleValues}
									setScaleValues={setScaleValues}
									setOpenCustomScale={setOpenCustomScale}
									openCustomScale={openCustomScale}
									scalePoints={scalePoints}
									setScalePoints={setScalePoints}
								/>
							)}
							{!openCustomScale && itemState.scale !== "" && (
								<Box
									sx={{
										marginTop: "10px",
										height: "55px",
										width: "100%",
									}}
								>
									<Typography variant="h7">Scale: {itemState.scale}</Typography>
								</Box>
							)}
							<Typography
								variant="h7"
								color={tooSimilar ? "error" : "primary"}
								sx={{ mx: "auto" }}
							>
								{itemTextHelperText}
							</Typography>
							{similarQuestions.length > 0 ? (
								<Grid
									sx={{
										display: similarQuestions.length > 0 ? "flex" : "none",
										mx: "auto",
										height: "300px",
									}}
								>
									<SimilarItemTable rows={similarQuestions} />
								</Grid>
							) : null}
						</Box>
					</Box>

					<Button
						variant="contained"
						onClick={handleClose}
						sx={{
							width: "400px",
							alignSelf: "center",
						}}
					>
						Cancel
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
