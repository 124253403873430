import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";

export default function NavButton(props) {
	return (
		<Button
			sx={{
				justifyContent: "left",
				"&:hover": {
					cursor: "pointer",
					backgroundColor: "#FFFFFF",
					"& .button-text": {
						textDecoration: "underline",
					},
				},
			}}
			color="inherit"
			disabled={props.disabled}
			component={RouterLink}
			to={props.link}
		>
			<Typography
				className="button-text"
				color={props.disabled ? "#cfcfd1" : "black"}
				fontWeight={500}
			>
				{props.navTo}
			</Typography>
		</Button>
	);
}
