import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Typography, Box, Select, Tooltip } from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState, useEffect, useCallback, useRef } from "react";
import { darken, lighten, styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import isAuthenticated from "../../../../components/Utils/isAuthenticated";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import ListSelect from "../../../../components/Utils/ListSelect";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DatePickerComponent } from "./DatePickerComponent";
import { roles } from "../../../../components/Utils/Descriptions";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { useMutation, useQueryClient } from "react-query";
import ClassAdjustCombineDialog from "./ClassAdjustCombineDialog";
import ToggleExclude from "./ToggleExclude";
import TabsClassAdjustment from "./TabsClassAdjustment";
import { deleteAdjustedClassId } from "../../../../components/Api/Deletes";
import UndoCombineDialog from "./undoCombineDialog";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import ConfirmEvaluationDialog from "./ConfirmEvaluationDialog";
import { postAllClassesClassAdjust } from "../../../../components/Api/Post";
import SubmitAllSuitableCoursesDialog from "./SubmitAllSuitableCoursesDialog";
import WarningIcon from "@mui/icons-material/Warning";
import BatchUpdateDialog from "./BatchUpdateDialog";
import ConfirmCancelDialog from "./ConfirmCancelDialog.";

const getBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
const getHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .super-app-theme--noEvaluation": {
		backgroundColor: getBackgroundColor(theme.palette.george.main, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.george.main, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.george.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.george.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--evaluation": {
		backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.success.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.success.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Mt": {
		backgroundColor: getBackgroundColor(theme.palette.mitra.main, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.mitra.main, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.mitra.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.mitra.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--J": {
		backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(theme.palette.info.main, theme.palette.mode),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.info.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .MuiDataGrid-overlay": {
		backgroundColor: "rgba(255, 255, 255, 0.7) !important",
	},
}));
export default function ClassAdjustTable(props) {
	//add column for notes
	let auth = isAuthenticated();
	const token = auth.token;
	const selectedClass = props.selectedClass;
	const setSelectedClass = props.setSelectedClass;
	const [checkboxSelectionState, setCheckboxSelectionState] = useState([]);
	const customCheckboxSelection = props.customCheckboxSelection;
	const setCustomCheckboxSelection = props.setCustomCheckboxSelection;
	const [combiningMode, setCombiningMode] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [addInstructor, setAddInstructor] = useState(false);
	const [openClassAdjustCombineDialog, setOpenClassAdjustCombineDialog] = useState(false);
	const [undoDialogOpen, setUndoDialogOpen] = useState(false);
	const [classesForCombining, setClassesForCombining] = useState([]);
	const [teamTeachingAgree, setTeamTeachingAgree] = useState(false);
	const [unwantedCombination, setUnwantedCombination] = useState(false);
	const queryClient = useQueryClient();
	const previousRowsRef = useRef(props.rows);
	const [evaluationDialogState, setEvaluationDialogState] = useState("");
	const [openEvaluationDialog, setOpenEvaluationDialog] = useState(false);
	const [classForEvaluatedDialog, setClassForEvaluatedDialog] = useState(null);
	const [clickedAddNotAllowed, setClickedAddNotAllowed] = useState(false);
	const [listOfUnsuitableCourses, setListOfUnsuitableCourses] = useState([]);
	const [listOfSuitableCourses, setListOfSuitableCourses] = useState([]);
	const [openSubmitAllSuitableCoursesDialog, setOpenSubmitAllSuitableCoursesDialog] =
		useState(false);
	const [submitAllLoading, setSubmitAllLoading] = useState(false);
	const [listOfExcludedCourses, setListOfExcludedCourses] = useState([]);
	const [filterKey, setFilterKey] = useState("");
	const [reviewingClasses, setReviewingClasses] = useState(false);
	const [prevRowsState, setPrevRowsState] = useState([]);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const [isDragging, setIsDragging] = useState(false);
	const [startRowIndex, setStartRowIndex] = useState(null);
	const [openBatchUpdateDialog, setOpenBatchUpdateDialog] = useState(false);
	const batchUpdated = props.batchUpdated;
	const setBatchUpdated = props.setBatchUpdated;

	const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState(false);

	useEffect(() => {
		const handleMouseUp = () => {
			setIsDragging(false);
			setStartRowIndex(null);
		};

		window.addEventListener("mouseup", handleMouseUp);
		return () => {
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, []);

	const handleMouseDown = (event, rowIndex) => {
		setIsDragging(true);
		setStartRowIndex(rowIndex);
		// Initially select the row where mouse is pressed down
		handleSelectionChange(rowIndex);
	};

	const handleMouseEnter = (event, rowIndex) => {
		if (isDragging && startRowIndex !== null) {
			handleSelectionChange(rowIndex);
		}
	};

	useEffect(() => {
		//if a row has pending but is not in customCheckboxSelection, remove pending
		const updatedRows = props.rows.map((r) => {
			if (r.evaluate === "Pending" && !customCheckboxSelection.includes(r.classid)) {
				return { ...r, evaluate: "" };
			}
			return r;
		});
		props.setRows(updatedRows);
	}, [customCheckboxSelection]);

	const handleSelectionChange = (rowIndex) => {
		// Update the checkbox selection state
		setCustomCheckboxSelection((prevSelection) => {
			const newSelection = [...prevSelection];
			// Toggle selection for the row
			if (newSelection.includes(rowIndex)) {
				newSelection.splice(newSelection.indexOf(rowIndex), 1);
			} else {
				//update row with evaluate: "Pending"
				const updatedRows = props.rows.map((r) => {
					if (r.classid === rowIndex) {
						return { ...r, evaluate: "Pending" };
					}
					return r;
				});
				props.setRows(updatedRows);
				newSelection.push(rowIndex);
			}
			return newSelection;
		});
	};

	useEffect(() => {
		if (checkboxSelectionState.length === 1 && customCheckboxSelection.length === 0) {
			const selectedClass = props.rows.find((row) => row.classid === checkboxSelectionState[0]);
			setSelectedClass(selectedClass);
		}
	}, [checkboxSelectionState]);

	useEffect(() => {
		if (customCheckboxSelection.length > 0) {
			setSelectedClass(null);
		}
	}, [customCheckboxSelection]);

	useEffect(() => {
		if (customCheckboxSelection.length === 0) {
			setSelectedClass(props.rows.find((row) => row.classid === checkboxSelectionState[0]));
		}
	}, [props.rows]);

	useEffect(() => {
		if (selectedClass) {
			const selectedClassId = String(selectedClass.classid);
			const combinedWith = selectedClass.combine;
			let combinedWithArray = [];

			if (combinedWith.length > 5) {
				combinedWithArray = combinedWith.split(",");
			} else {
				combinedWithArray = [combinedWith];
			}
			const allClassesExist = combinedWithArray.every((classid) =>
				props.rowsFromClassIds.some((row) => row.classid === Number(classid))
			);
			let combinedRowList = [];
			let notCombinedRowList = [];
			let combiningDict = {};
			props.rows.forEach((row) => {
				if (row.combine.length > 0) {
					const combinedList = row.combine.split(",").map((classid) => classid.trim());
					combiningDict[row.classid] = {
						listOfCombined: combinedList,
						row: row,
					};
					combinedRowList.push(row);
				} else {
					notCombinedRowList.push(row);
				}
			});
			let updatedRows = props.rows.map((row) => {
				const selectedClassId = String(row.classid);
				let combinedWithValue = row.classid;
				Object.keys(combiningDict).forEach((key) => {
					const combinedList = combiningDict[key].listOfCombined;
					const parentClassId = key;

					if (combinedList.includes(selectedClassId)) {
						combinedWithValue = parentClassId;
						row = {
							...row,
							isCombined: true,
							combinedWith: combinedWithValue,
						};
					}
				});
				if (allClassesExist && row.classid === selectedClass.classid) {
					row = { ...row, allowUndo: true };
				}
				return row;
			});
			if (JSON.stringify(updatedRows) !== JSON.stringify(previousRowsRef.current)) {
				props.setRows(updatedRows);
				previousRowsRef.current = updatedRows;
			}
		}
	}, [selectedClass, props.rowsFromClassIds, props.rows]);

	useEffect(() => {
		if (checkboxSelectionState.length > 0 && props.rows.length > 0 && combiningMode) {
			let classes = props.rows.filter((row) => checkboxSelectionState.includes(row.classid));
			let commonInstructors = classes[0].roles;
			classes.forEach((classObj) => {
				commonInstructors = commonInstructors.filter((role) =>
					classObj.roles.some((r) => Number(r.empid) === Number(role.empid))
				);
			});
			if (commonInstructors.length < 1) {
				setSnackbarMessage("Classes must have at least one instructor in common to combine.");
				setSnackbarOpen(true);

				setCheckboxSelectionState([checkboxSelectionState[0]]);
				setClassesForCombining([]);
			} else {
				let teamTeaching = false;
				classes.forEach((classObj) => {
					if (classObj.roles.length > 1) {
						teamTeaching = true;
					}
				});

				setTeamTeachingAgree(teamTeaching);
				setClassesForCombining(classes);
			}
		}
	}, [checkboxSelectionState, props.rows]);

	useEffect(() => {
		if (props.unsavedChanges && clickedAddNotAllowed) {
			props.setWarnUnsavedChanges(true);
		} else {
			props.setWarnUnsavedChanges(false);
		}
	}, [props.unsavedChanges, clickedAddNotAllowed]);

	const handleSelectionModelChange = (newSelection) => {
		//limit of one selection
		if (combiningMode) {
			setCheckboxSelectionState(newSelection);
		} else {
			if (newSelection.length > 1) {
				newSelection.shift();
			}
		}
		setCheckboxSelectionState(newSelection);
	};

	const handleSearch = useCallback(
		(e) => {
			const value = e.target.value;
			setSearchValue(value);

			// Always filter from the original data
			if (value === "") {
				// If the search bar is cleared, reset to the original rows
				props.setRows(props.rowsForTableOriginal);
			} else {
				const filteredRows = props.rowsForTableOriginal.filter((row) => {
					return (
						row.classid.toString().includes(value) ||
						row.ctitle.toLowerCase().includes(value.toLowerCase()) ||
						row.roles.some((role) =>
							role.name
								? role.name.toLowerCase().includes(value.toLowerCase())
								: `${role.user.last_name}, ${role.user.first_name}`
										.toLowerCase()
										.includes(value.toLowerCase())
						)
					);
				});
				props.setRows(filteredRows);
			}
		},
		[props.rowsForTableOriginal] // Filter using the original dataset
	);

	const deleteClassMutation = useMutation(
		async ({ token, classid }) => {
			await deleteAdjustedClassId({ token, classid });
		},
		{
			onSuccess: () => {
				console.log("Successfully deleted class");
				// Invalidate and refetch
				queryClient.invalidateQueries([
					"adjustedClassIdObjectsByDeptAndTerm",
					props.deptId,
					props.termNumber,
				]);
				queryClient.invalidateQueries([
					"classIdObjectsByDeptAndTerm",
					props.deptId,
					props.termNumber,
				]);
			},
			onError: (error) => {
				console.error("Failed to update class:", error);
			},
		}
	);

	const handleTeamVisibility = (row) => {
		const teamNames = row.roles.map((role) => {
			// Corrected logic to ensure proper ternary operation
			return role.name ? `(${role.name})` : `(${role.user.last_name}, ${role.user.first_name})`;
		});
		const teamName = teamNames.join(", ");

		const updatedRows = [...props.rows];
		const index = updatedRows.findIndex((r) => r.classid === row.classid);
		updatedRows[index].teamName = teamName;
		updatedRows[index].nameExpanded = !updatedRows[index].nameExpanded;

		props.setRows(updatedRows);
	};

	const handleChangeRole = (e, instructor, row) => {
		const role = e.target.value;

		const updatedRows = [...props.rows];

		const index = updatedRows.findIndex((r) => r.classid === row.classid);

		const roleIndex = updatedRows[index].roles.findIndex((r) => r.name === instructor.name);
		updatedRows[index].roles[roleIndex].class_role = role;

		props.setUnsavedChanges(true);

		props.setRows(updatedRows);
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackbarOpen(false);
	};

	const handleDateChangeEndDate = (date) => {
		setSelectedClass((prev) => ({
			...prev,
			c_edate: date.format("YYYY-MM-DD"),
		}));

		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, c_edate: date.format("YYYY-MM-DD") };
			}
			return row;
		});

		props.setRows(updatedRows);

		const isChanged = JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const handleDateChangeSurveyStart = (date) => {
		setSelectedClass((prev) => ({
			...prev,
			survey_start: date.format("YYYY-MM-DD"),
		}));

		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, survey_start: date.format("YYYY-MM-DD") };
			}
			return row;
		});

		props.setRows(updatedRows);

		const isChanged = JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const handleDateChangeSurveyEnd = (date) => {
		setSelectedClass((prev) => ({
			...prev,
			survey_end: date.format("YYYY-MM-DD"),
		}));

		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, survey_end: date.format("YYYY-MM-DD") };
			}
			return row;
		});

		props.setRows(updatedRows);

		const isChanged = JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const handleDateChangeExamDate = (date) => {
		setSelectedClass((prev) => ({
			...prev,
			examdate: date.format("YYYY-MM-DD"),
		}));

		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, examdate: date.format("YYYY-MM-DD") };
			}
			return row;
		});
		//get the index of the selected class
		props.setRows(updatedRows);
		const isChanged = JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const handleDiscardChanges = () => {
		props.setRows(props.originalRows);
		queryClient.invalidateQueries([
			"adjustedClassIdObjectsByDeptAndTerm",
			props.deptId,
			props.termNumber,
		]);
		queryClient.invalidateQueries(["classIdObjectsByDeptAndTerm", props.deptId, props.termNumber]);

		props.setUnsavedChanges(false);
	};

	const handleSaveChanges = async () => {
		const updatedSelectedClass = selectedClass;
		if (!selectedClass.evaluate) {
			updatedSelectedClass.evaluate = "Yes";
			await props.updateClassMutation.mutateAsync({
				token,
				selectedClass: updatedSelectedClass,
			});
		} else {
			await props.updateClassMutation.mutateAsync({ token, selectedClass });
		}

		props.setUnsavedChanges(false);
	};

	const handleUndo = async () => {
		const classid = selectedClass.classid;
		await deleteClassMutation.mutateAsync({ token, classid });
		setUndoDialogOpen(false);
	};

	const handleToggleExclude = (value) => {
		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				return { ...row, evaluate: value ? "No" : "Yes" };
			}
			return row;
		});
		props.setRows(updatedRows);
		const isChanged = JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
	};

	const deleteInstructor = (event, instructor) => {
		const updatedRows = props.rows.map((row) => {
			if (row.classid === selectedClass.classid) {
				const updatedRoles = row.roles.filter((role) => role.empid !== instructor.empid);
				return { ...row, roles: updatedRoles, teamsize: updatedRoles.length };
			}
			return row;
		});

		props.setRows(updatedRows);
		const isChanged = JSON.stringify(props.originalRows) !== JSON.stringify(updatedRows);
		props.setUnsavedChanges(isChanged);
		//add api call to delete from roles table
	};

	const undoCombine = async () => {
		const combinedWith = selectedClass.combine;
		let combinedWithArray = [];
		if (combinedWith.length > 5) {
			combinedWithArray = combinedWith.split(",");
		} else {
			combinedWithArray = [combinedWith];
		}
		const allClassesExist = combinedWithArray.every((classid) =>
			props.rowsFromClassIds.some((row) => row.classid === Number(classid))
		);
		if (selectedClass.allowUndo && allClassesExist) {
			setUndoDialogOpen(true);
		}
	};

	const renderInstructorNameTable = (params) => {
		const { row } = params;
		const instructorNamesTooltip = row.roles
			.map((role) =>
				role.name
					? `(${role.name})`
					: role.user && role.user.last_name && role.user.first_name
					? `(${role.user.last_name}, ${role.user.first_name})`
					: ""
			)
			.join(", ");
		if (row.roles && row.roles.length === 1) {
			const role = row.roles[0];
			return (
				<p className="text-sm  font-semibold">
					{role.name
						? role.name
						: role.user && role.user.last_name && role.user.first_name
						? `${role.user.last_name}, ${role.user.first_name}`
						: ""}
				</p>
			);
		} else if (row.roles && row.roles.length > 1 && !row.nameExpanded) {
			return (
				<strong title={instructorNamesTooltip}>
					<Button onClick={() => handleTeamVisibility(row)}>
						<p className="text-md  font-semibold mr-2 -ml-2">(Several)</p>

						<VisibilityIcon />
					</Button>
				</strong>
			);
		} else if (row.roles && row.roles.length > 1 && row.nameExpanded) {
			return (
				<button
					onClick={() => handleTeamVisibility(row)}
					title={instructorNamesTooltip}
					className="text-[10pt] flex flex-col items-start tracking-tightest font-semibold max-h-[32px] overflow-y-auto overflow-x-hidden"
				>
					{row.teamName
						.split(/[\(\)]/)
						.filter((name) => name.trim() && name !== "," && name !== ", ")
						.map((name, index) => (
							<p key={index}>{name.trim()}</p>
						))}
				</button>
			);
		} else {
			return (
				<strong>
					<Typography sx={{ marginRight: "10px", fontSize: "11pt" }} color="red">
						No Instructor Found
					</Typography>
				</strong>
			);
		}
	};

	const handleClick = async (event, row, state) => {
		event.stopPropagation();
		if (state === "evaluated") {
			setEvaluationDialogState("evaluated");
			setClassForEvaluatedDialog(row);
			setOpenEvaluationDialog(true);
		} else if (state === "excluded") {
			setEvaluationDialogState("excluded");
			setClassForEvaluatedDialog(row);
			setOpenEvaluationDialog(true);
		} else if (state === "pending") {
			//remove from customCheckboxSelection
			setCustomCheckboxSelection(customCheckboxSelection.filter((id) => id !== row.classid));
			const updatedRows = await Promise.all(
				props.rows.map(async (r) => {
					if (r.classid === row.classid) {
						return { ...r, evaluate: "" };
					}
					return r;
				})
			);
			props.setRows(updatedRows);
		} else if (state === "neither") {
			//add class to customCheckboxSelection
			setCustomCheckboxSelection([...customCheckboxSelection, row.classid]);
			const updatedRows = await Promise.all(
				props.rows.map(async (r) => {
					if (r.classid === row.classid) {
						return { ...r, evaluate: "Pending" };
					}
					return r;
				})
			);

			props.setRows(updatedRows);
		}
	};

	const handleEvaluatedDialogConfirmation = async (classForConfirm, state) => {
		const classid = classForConfirm.classid;
		const updatedRows = await Promise.all(
			props.rows.map(async (r) => {
				if (r.classid === classid) {
					if (state === "evaluated") {
						await deleteClassMutation.mutateAsync({
							token,
							classid: r.classid,
						});
						return { ...r, evaluate: "" };
					} else if (state === "excluded") {
						//change evaluate on classForConfirm to yes before api call
						await props.updateClassMutation.mutateAsync({
							token,
							selectedClass: { ...r, evaluate: "Yes" },
						});
						return { ...r, evaluate: "Yes" };
					}
				}

				return r;
			})
		);
		props.setRows(updatedRows);
		setOpenEvaluationDialog(false);
	};

	const handleClickAddClassCheckbox = (event) => {
		if (props.unsavedChanges) {
			setClickedAddNotAllowed(true);
			setTimeout(() => {
				setClickedAddNotAllowed(false);
			}, 4000);
		}
	};
	const columnsRequisitions = [
		{
			field: "classid",
			headerName: "Class ID",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Class ID</p>
					</div>
				);
			},
			renderCell: (params) => {
				return (
					<div>
						<p className="text-sm text-black font-semibold">{params.row.classid}</p>
					</div>
				);
			},
			width: 60,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			type: "string",
			flex: 1,
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Class Title</p>
					</div>
				);
			},
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.ctitle}</p>
					</div>
				);
			},
			sortComparator: (v1, v2) => {
				// Helper function to split the ctitle into parts
				const parseCtitle = (title) => {
					const parts = title.split(" ");
					if (parts.length === 5) {
						// For 5-part titles, merge the first two parts to treat as SUBJECT
						return [parts[0] + " " + parts[1], parts[2], parts[3], parts[4]];
					}
					return parts;
				};

				const parts1 = parseCtitle(v1);
				const parts2 = parseCtitle(v2);

				// Compare each part in order
				for (let i = 0; i < parts1.length; i++) {
					if (parts1[i] < parts2[i]) return -1;
					if (parts1[i] > parts2[i]) return 1;
				}

				// If all parts are equal
				return 0;
			},
			width: 150,
		},
		{
			field: "csize",
			editable: true,
			headerName: "# Std",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold"># Std</p>
					</div>
				);
			},
			align: "center",
			width: 55,
		},
		/* {
			field: "teamsize",
			headerName: "Team",
			width: 40,
			align: "center",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold"># Inst</p>
					</div>
				);
			},
			disableClickEventBubbling: true,
		}, */
		{
			field: "blkid",
			headerName: "Block",
			width: 65,
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.blkid}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Block</p>
					</div>
				);
			},
			align: "center",

			disableClickEventBubbling: true,
		},
		{
			field: "name",
			headerName: "Instructor(s)",
			width: 150,
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Instructor(s)</p>
					</div>
				);
			},
			renderCell: renderInstructorNameTable,
		},
		{
			field: "evaluate",
			headerName: "Evaluate",
			width: 60,
			disableSelectionOnClick: true,
			disableClickEventBubbling: true,
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Evaluate</p>
					</div>
				);
			},
			align: "center",
			sortComparator: (v1, v2) => {
				const order = ["Yes", "Pending", "No", "Caution", null, ""];

				const index1 = order.indexOf(v1);
				const index2 = order.indexOf(v2);

				if (index1 < index2) {
					return -1;
				}
				if (index1 > index2) {
					return 1;
				}
				return 0;
			},
			renderCell: (params) => {
				if (params.row.evaluate === "Yes") {
					return (
						<Checkbox
							disabled={props.unsavedChanges || combiningMode}
							//confirm here as the class will be deleted from AdjustedClassIds
							onClick={(event) => handleClick(event, params.row, "evaluated")}
							checked={params.row.evaluate === "Yes" ? true : false}
						/>
					);
				} else if (params.row.evaluate === "Pending") {
					return (
						<Tooltip
							title="Pending. Please submit the currently selected courses."
							placement="right"
						>
							<Checkbox
								sx={{ color: "#FFB600", "&.Mui-checked": { color: "#FFB600" } }}
								//confirm here as the class will be deleted from AdjustedClassIds
								onClick={(event) => handleClick(event, params.row, "pending")}
								checked={params.row.evaluate === "Pending" ? true : false}
							/>
						</Tooltip>
					);
				} else if (params.row.evaluate === "No") {
					return (
						<Checkbox
							disabled={
								props.unsavedChanges ||
								(params.row.combinedWith && params.row.combinedWith.length > 0)
							}
							sx={{
								[`&, &.${checkboxClasses.checked}`]: {
									color: props.unsavedChanges ? "gray" : "red",
								},
							}}
							checkedIcon={<DisabledByDefaultIcon />}
							onClick={(event) => handleClick(event, params.row, "excluded")}
							checked={params.row.evaluate === "No" ? true : false}
						/>
					);
				} else {
					if (
						hasNoRolesCheck(params.row) ||
						hasNoBlockIdCheck(params.row) ||
						isClassSizeTooSmallCheck(params.row) ||
						isClassSizeZeroCheck(params.row)
					) {
						return (
							<Tooltip placement="right" title={validateClass(params.row).message}>
								<WarningIcon sx={{ color: "#FFB600" }} />
							</Tooltip>
						);
					} else {
						const rowIndex = props.rows.indexOf(params.row);
						return (
							<div onClick={handleClickAddClassCheckbox}>
								<Checkbox
									onMouseDown={(event) => handleMouseDown(event, params.row.classid)}
									onMouseEnter={(event) => handleMouseEnter(event, params.row.classid)}
									disabled={
										(props.unsavedChanges && customCheckboxSelection.length === 0) ||
										combiningMode ||
										!params.row.blkid ||
										(params.row.roles && params.row.roles.length === 0) ||
										params.row.csize === 0
									}
									onClick={(event) => handleClick(event, params.row, "neither")}
									checked={params.row.evaluate === "No" ? true : false}
								/>
							</div>
						);
					}
				}
			},
		},

		{
			field: "c_edate",
			headerName: "Class End Date",
			width: 80,
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.c_edate}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">End Date</p>
					</div>
				);
			},
			headerAlign: "center",
			disableClickEventBubbling: true,
			align: "center",
		},

		{
			field: "examdate",
			headerName: "Exam Date",
			align: "center",
			headerAlign: "center",
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.examdate}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Exam Date</p>
					</div>
				);
			},
			width: 80,
			disableClickEventBubbling: true,
		},

		{
			field: "survey_start",
			headerName: "Survey Start",
			align: "center",
			headerAlign: "center",
			width: 80,
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.survey_start}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold mr-2">Survey Start</p>
					</div>
				);
			},
			disableClickEventBubbling: true,
		},

		{
			field: "survey_end",
			headerName: "Survey End",
			align: "center",
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.survey_end}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold mr-2">Survey End</p>
					</div>
				);
			},
			width: 80,
			disableClickEventBubbling: true,
		},
	];

	const getRowClassName = useCallback((params) => {
		return `super-app-theme--${
			params.row.evaluate === "Yes"
				? "evaluation"
				: params.row.evaluate === "No"
				? "noEvaluation"
				: ""
		}`;
	}, []);

	const CustomFooter = () => {
		return (
			<GridFooterContainer sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}>
				<GridFooter sx={{ position: "absolute", right: "10px" }} />
			</GridFooterContainer>
		);
	};

	const slots = {
		Footer: CustomFooter,
		// Toolbar: CustomToolbar,
		LoadingOverlay: LinearProgress,
	};

	const postAllSuitableClassesMutation = useMutation(
		async ({ token, classesToSubmit }) => {
			await postAllClassesClassAdjust({ token, classesToSubmit });
		},
		{
			onSuccess: () => {
				setSubmitAllLoading(false);
				setOpenSubmitAllSuitableCoursesDialog(false);
				setReviewingClasses(false);
				setListOfSuitableCourses([]);
				setListOfUnsuitableCourses([]);
				setListOfExcludedCourses([]);
				setFilterKey((prev) => prev + 1);
				setCustomCheckboxSelection([]);
				setConfirmSubmitAllSelected(false);
				queryClient.invalidateQueries([
					"adjustedClassIdObjectsByDeptAndTerm",
					props.deptId,
					props.termNumber,
				]);
				queryClient.invalidateQueries([
					"classIdObjectsByDeptAndTerm",
					props.deptId,
					props.termNumber,
				]);
				//Set unsaved changes to false only if status code is 204
				props.setUnsavedChanges(false);
			},
			onError: (error) => {
				setSubmitAllLoading(false);
				setReviewingClasses(false);
				setListOfSuitableCourses([]);
				setListOfUnsuitableCourses([]);
				setListOfExcludedCourses([]);
				alert("Error submitting classes. Please contact TSS by phone or email.");
				console.error("Failed to update class:", error);

				// Handle error appropriately
			},
		}
	);

	const [confirmSubmitAllSelected, setConfirmSubmitAllSelected] = useState(false);

	const handleReviewAllSelected = async () => {
		setPrevRowsState(props.rows);
		//remove all classes that are not in customCheckboxSelection
		const updatedFilteredRows = props.rows.filter((row) =>
			customCheckboxSelection.includes(row.classid)
		);
		props.setRows(updatedFilteredRows);

		setConfirmSubmitAllSelected(true);
		setFilterKey((prev) => prev + 1);
	};

	const handleSubmitAllSelected = async () => {
		//get rows from customCheckboxSelection and submit
		let selectedClassesList = [];
		setSubmitAllLoading(true);
		customCheckboxSelection.forEach((classid) => {
			const selectedClass = props.rows.find((row) => row.classid === classid);
			selectedClassesList.push(selectedClass);
		});
		await postAllSuitableClassesMutation.mutateAsync({
			token,
			classesToSubmit: selectedClassesList,
		});
	};

	const handleSubmitAllSuitable = async () => {
		let suitableCoursesList = [];
		let existingCoursesList = [];
		let excludedCoursesList = [];
		setPrevRowsState(props.rows);
		const updatedRows = props.rows.map((row) => {
			if (row.evaluate === "" || !row.evaluate) {
				if (row.roles.length > 0 && row.csize >= 4 && row.blkid && row.blkid.length > 0) {
					suitableCoursesList.push(row);
					return { ...row, evaluate: "Yes" };
				} else {
					excludedCoursesList.push(row);
					return { ...row, evaluate: "Caution" };
				}
			} else if (row.evaluate === "Yes" || row.evaluate === "No") {
				existingCoursesList.push(row);
				return row;
			}
			return row;
		});
		if (suitableCoursesList.length < 1) {
			alert("There are no suitable classes to submit that have not already been submitted.");
			return;
		}
		/* const filteredRows = updatedRows.filter((row) => row.evaluate === "Yes"); */
		setCheckboxSelectionState([]);
		props.setSelectedClass(null);
		setReviewingClasses(true);
		setListOfSuitableCourses(suitableCoursesList);
		//sort updated rows based on evaluate no = first, excluded true = second, evaluate yes = third
		updatedRows.sort((a, b) => {
			const order = ["Yes", "Pending", "No", "Caution", null, ""];

			const index1 = order.indexOf(a.evaluate);
			const index2 = order.indexOf(b.evaluate);

			if (index1 < index2) {
				return -1;
			}
			if (index1 > index2) {
				return 1;
			}
			return 0;
		});
		setFilterKey((prev) => prev + 1);
		props.setRows(updatedRows);
	};

	const handleCancelSubmitAllSuitable = () => {
		setReviewingClasses(false);
		props.setRows(prevRowsState);
	};

	const handleCancelSubmitAllSelected = () => {
		if (batchUpdated) {
			setOpenConfirmCancelDialog(true);
		} else {
			setConfirmSubmitAllSelected(false);
			//set pending to false for all classes
			queryClient.invalidateQueries([
				"adjustedClassIdObjectsByDeptAndTerm",
				props.deptId,
				props.termNumber,
			]);
			queryClient.invalidateQueries([
				"classIdObjectsByDeptAndTerm",
				props.deptId,
				props.termNumber,
			]);

			setCustomCheckboxSelection([]);
		}
	};

	const handleConfirmSubmitAllSuitable = async () => {
		setSubmitAllLoading(true);

		await postAllSuitableClassesMutation.mutateAsync({
			token,
			classesToSubmit: listOfSuitableCourses,
		});
	};

	const handleSaveAttempt = (event) => {
		const { message, shouldPrevent } = validateClass(props.selectedClass);

		if (shouldPrevent) {
			event.preventDefault();
			setSnackbarMessage(message);
			setSnackbarOpen(true);
		}
	};

	const hasNoRolesCheck = (course) => {
		return !course.roles || course.roles.length < 1;
	};

	const hasNoBlockIdCheck = (course) => {
		return !course.blkid || course.blkid.length === 0;
	};

	const isClassSizeTooSmallCheck = (course) => {
		return course.csize < 4;
	};

	const isClassSizeZeroCheck = (course) => {
		return course.csize === 0;
	};

	const hasNoRoles = !props.selectedClass?.roles || props.selectedClass.roles?.length < 1;
	const hasNoBlockId = !props.selectedClass?.blkid || props.selectedClass.blkid?.length === 0;
	const isClassSizeTooSmall = props.selectedClass?.csize < 4;
	const isClassSizeZero = props.selectedClass?.csize === 0;

	const validateClass = (selectedClass) => {
		if (!selectedClass?.roles || selectedClass.roles.length < 1) {
			return {
				message: "An assigned instructor is necessary to save a course.",
				shouldPrevent: true,
			};
		}
		if (selectedClass?.csize < 4) {
			return {
				message: "Classes with less than 4 students must be combined.",
				shouldPrevent: true,
			};
		}
		if (!selectedClass?.blkid || selectedClass.blkid.length === 0) {
			return {
				message: "A question block is necessary to save a course.",
				shouldPrevent: true,
			};
		}
		if (selectedClass?.csize === 0) {
			return {
				message: "A class size of 4 or more is necessary to save a course.",
				shouldPrevent: true,
			};
		}

		// If no validation errors
		return { message: "", shouldPrevent: false };
	};

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 50,
		page: 1,
	});

	const [previousRowsCombine, setPreviousRowsCombine] = useState([]);

	const handleCombineClass = async (row) => {
		setPreviousRowsCombine(props.rows);

		//filter out classes that do not have a common instructor
		const classesForCombining = props.rows.filter((r) =>
			r.roles.some((role) => row.roles.some((r) => Number(r.empid) === Number(role.empid)))
		);
		props.setRows(classesForCombining);
		setCombiningMode(true);
	};

	const handleCancelCombine = () => {
		props.setRows(previousRowsCombine);
		queryClient.invalidateQueries([
			"adjustedClassIdObjectsByDeptAndTerm",
			props.deptId,
			props.termNumber,
		]);
		queryClient.invalidateQueries(["classIdObjectsByDeptAndTerm", props.deptId, props.termNumber]);

		setCombiningMode(false);
	};

	return (
		<div className="flex h-full w-full  min-h-[80dvh] max-h-screen text-zinc-900 ">
			<ConfirmCancelDialog
				rows={props.rows}
				setRows={props.setRows}
				setCustomCheckboxSelection={props.setCustomCheckboxSelection}
				open={openConfirmCancelDialog}
				setOpen={setOpenConfirmCancelDialog}
				setUnsavedChanges={props.setUnsavedChanges}
				setBatchUpdated={setBatchUpdated}
				setConfirmSubmitAllSelected={setConfirmSubmitAllSelected}
			/>
			<BatchUpdateDialog
				open={openBatchUpdateDialog}
				setOpen={setOpenBatchUpdateDialog}
				rows={props.rows}
				setRows={props.setRows}
				setUnsavedChanges={props.setUnsavedChanges}
				customCheckboxSelection={customCheckboxSelection}
				setBatchUpdated={setBatchUpdated}
			/>

			<SubmitAllSuitableCoursesDialog
				open={openSubmitAllSuitableCoursesDialog}
				setOpen={setOpenSubmitAllSuitableCoursesDialog}
				listOfSuitableCourses={listOfSuitableCourses}
				listOfUnsuitableCourses={listOfUnsuitableCourses}
				postAllSuitableClassesMutation={postAllSuitableClassesMutation}
				renderInstructorNameTable={renderInstructorNameTable}
				submitAllLoading={submitAllLoading}
				setSubmitAllLoading={setSubmitAllLoading}
				token={token}
			/>
			<ConfirmEvaluationDialog
				open={openEvaluationDialog}
				setOpen={setOpenEvaluationDialog}
				evaluationDialogState={evaluationDialogState}
				classForEvaluatedDialog={classForEvaluatedDialog}
				handleEvaluatedDialogConfirmation={handleEvaluatedDialogConfirmation}
			/>
			<ClassAdjustCombineDialog
				open={openClassAdjustCombineDialog}
				setOpen={setOpenClassAdjustCombineDialog}
				list={classesForCombining}
				setList={setClassesForCombining}
				rows={props.rows}
				setRows={props.setRows}
				setCombiningMode={setCombiningMode}
				teamTeachingAgree={teamTeachingAgree}
				setUnsavedChanges={props.setUnsavedChanges}
				unwantedCombination={unwantedCombination}
				setUnwantedCombination={setUnwantedCombination}
				selectedClass={selectedClass}
			/>
			<UndoCombineDialog
				open={undoDialogOpen}
				handleUndo={handleUndo}
				setOpen={setUndoDialogOpen}
			/>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={5000}
				onClose={handleCloseSnackbar}
				message={snackbarMessage}
			/>
			<div className="flex  h-full w-full items-center justify-center px-4">
				<div
					className={`flex flex-col  items-center w-[310px] h-full border-2 ${
						selectedClass ? (props.unsavedChanges ? "border-red-800 " : "border-green-800") : ""
					} rounded-s-md overflow-x-hidden`}
				>
					<div className="w-full bg-white flex flex-col h-full justify-between relative">
						{selectedClass ? (
							<>
								{selectedClass.isCombined && (
									<div>
										<div className="border-red-600 border-2 rounded-md bg-red-100 text-red-900 text-sm max-w-[90%] max-h-[70%] p-4 absolute m-auto left-0 right-0 top-[25%] z-20 flex flex-col items-center justify-center gap-4 ">
											<div className="max-w-[90%] flex flex-col gap-4">
												<p className="">
													This class is currently combined with{" "}
													<span className="font-bold">{selectedClass.combinedWith}</span>
												</p>
												<p className="">
													Classes that have been combined with another cannot be modified as they
													will not be surveyed.
												</p>
												<p className="">
													If you would like to modify this class and evaluate it separately, please
													undo the combine operation on the parent class{" "}
													<span className="font-bold">{selectedClass.combinedWith}</span>
												</p>
												<p className="">
													This can be done by selecting the parent class and clicking the "Undo
													Combine" button under the "Combining" tab.
												</p>
											</div>
										</div>
									</div>
								)}
								<div className="flex flex-col justify-between h-full border-b-2 px-2 py-1">
									<div className="flex flex-col justify-between h-[50%]   ">
										<div className="w-full mx-auto  flex gap-2" onClick={handleSaveAttempt}>
											{hasNoRoles || hasNoBlockId || isClassSizeTooSmall || isClassSizeZero ? (
												<Tooltip
													title={validateClass(props.selectedClass).message}
													placement="bottom"
												>
													<Button
														variant="contained"
														/* onClick={handleSaveChanges} */
														color="warning"
														className="flex items-center gap-1 w-full"
													>
														SAVE <WarningIcon />
													</Button>
												</Tooltip>
											) : (
												<Button
													variant="contained"
													disabled={!props.unsavedChanges}
													onClick={handleSaveChanges}
													className="flex items-center gap-1 w-full"
												>
													SAVE
													<SaveIcon />
												</Button>
											)}

											<Button
												disabled={!props.unsavedChanges}
												variant="contained"
												color="error"
												onClick={handleDiscardChanges}
												className="flex items-center gap-1 w-full"
											>
												RESET
												<DeleteForever />
											</Button>
										</div>
										<h1 className="text-lg flex justify-between">
											Title: <span className="font-bold">{selectedClass.ctitle}</span>
										</h1>
										<div className="w-full border-b-2"></div>
										<p className="text-sm flex justify-between ">
											Class ID: <span className="font-bold">{selectedClass.classid}</span>
										</p>
										<div className="w-full border-b-2"></div>
										<div className="grid grid-cols-9 ">
											<p className="text-sm flex justify-between col-span-4">
												# of students: <span className="font-bold">{selectedClass.csize}</span>
											</p>
											<div className="h-full w-full col-span-1 grid grid-cols-2">
												<div className="h-full w-full border-r-2 col-span-1"></div>
												<div className="h-full w-full col-span-1"></div>
											</div>
											<p className=" text-sm flex justify-between col-span-4">
												# of instructors:{" "}
												<span className="font-bold">{selectedClass.teamsize}</span>
											</p>
										</div>
										<div className="w-full border-b-2"></div>
										<div className="flex w-full gap-2">
											<DatePickerComponent
												disabled={selectedClass.isCombined}
												width="100%"
												label="Class End Date"
												value={dayjs(selectedClass.c_edate)}
												onChange={handleDateChangeEndDate}
											/>
											<DatePickerComponent
												disabled={selectedClass.isCombined}
												width="100%"
												label="Exam Date"
												value={dayjs(selectedClass.examdate)}
												onChange={handleDateChangeExamDate}
												confirm
											/>
										</div>
										{/* <div className="w-full border-b-2"></div> */}
										<div className="flex w-full gap-2">
											<DatePickerComponent
												disabled={selectedClass.isCombined}
												width="100%"
												label="Survey Start Date"
												value={dayjs(selectedClass.survey_start)}
												maxDate={dayjs(selectedClass.survey_end)}
												onChange={handleDateChangeSurveyStart}
											/>
											<DatePickerComponent
												disabled={selectedClass.isCombined}
												width="100%"
												label="Survey End Date"
												value={dayjs(selectedClass.survey_end)}
												maxDate={
													dayjs(selectedClass.examdate)
														? dayjs(selectedClass.examdate).subtract(1, "day")
														: null
												}
												onChange={handleDateChangeSurveyEnd}
											/>
										</div>
									</div>
									{/* <div className="w-full border-b-2"></div> */}
									<div className="h-[5%] ">
										<ToggleExclude
											row={selectedClass}
											handleToggleExclude={handleToggleExclude}
											disabled={selectedClass.isCombined}
										/>
									</div>

									<TabsClassAdjustment
										handleCancelCombine={handleCancelCombine}
										handleCombineClass={handleCombineClass}
										handleChangeBlkId={props.handleChangeBlkId}
										disabled={selectedClass.isCombined}
										blockIdsByDept={props.blockIdsByDept}
										blockIds={props.allBlockItems}
										selectedClass={selectedClass}
										row={selectedClass}
										rows={props.rows}
										setRows={props.setRows}
										setAddInstructor={setAddInstructor}
										addInstructor={addInstructor}
										handleChangeRole={handleChangeRole}
										deleteInstructor={deleteInstructor}
										roles={roles}
										setUnsavedChanges={props.setUnsavedChanges}
										combiningMode={combiningMode}
										setCombiningMode={setCombiningMode}
										classesForCombining={classesForCombining}
										setClassesForCombining={setClassesForCombining}
										setOpenClassAdjustCombineDialog={setOpenClassAdjustCombineDialog}
										teamTeachingAgree={teamTeachingAgree}
										setTeamTeachingAgree={setTeamTeachingAgree}
										checkboxSelectionState={checkboxSelectionState}
										undoCombine={undoCombine}
									/>
								</div>
							</>
						) : (
							<div className="w-full h-full bg-white flex flex-col justify-center items-center border-b-2 py-2">
								<div className="border-2 rounded-md p-2 text-center border-green-800">
									<h1 className="text-lg">Modify Class Data</h1>
									<p className="text-sm px-2">Select a class from the table to modify it.</p>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="flex flex-col h-full w-[900px]">
					<div className="flex flex-col items-center justify-between w-full border-2 px-2 rounded-r-md rounded-b-none">
						<div className="flex items-center justify-between w-full ">
							<Box sx={{ display: "flex", gap: "10px" }}>
								<ListSelect
									disabled={props.unsavedChanges}
									className="termSelect"
									list={props.terms}
									label="Term"
									width="200px"
									size="small"
									marginBottom="0px"
									value={props.termValue || ""}
									onChange={(e) => props.setTermValue(e.target.value)}
								/>
								<ListSelect
									disabled={props.unsavedChanges}
									className="deptSelect"
									list={props.departmentsList}
									label="Department"
									width="400px"
									size="small"
									marginBottom="0px"
									value={props.selectedDept || ""}
									onChange={props.handleDepartmentChange}
								/>
							</Box>
							<Box sx={{ p: 1 }}>
								<Button
									onClick={props.handleRefresh}
									disabled={props.unsavedChanges}
									variant="outlined"
									sx={{ marginLeft: "5px" }}
								>
									REFRESH
									{props.loading ? (
										<CircularProgress size={20} sx={{ marginLeft: "9px" }} />
									) : (
										<RefreshIcon sx={{ marginLeft: "5px" }} />
									)}
								</Button>
							</Box>
						</div>
						<div className="h-10 w-full mb-2">
							<label htmlFor="search" className="sr-only">
								Search
							</label>
							<input
								id="search"
								type="text"
								placeholder="Search for a Class ID, Class Title, or Instructor Name"
								className="h-10 w-full border-[1px] border-gray-300 focus:outline-green-800 py-1 px-3 rounded-md text-black placeholder-gray-600"
								value={searchValue}
								onChange={handleSearch}
							/>
						</div>
					</div>
					<StyledDataGrid
						key={filterKey}
						loading={props.loading}
						disabled={props.unsavedChanges}
						sx={{
							"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
								outline: "None !important",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
								width: "0.4em",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
								background: "#f1f1f1",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
								backgroundColor: "#888",
							},
							"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
								background: "#555",
							},
							"& .MuiDataGrid-row:hover": {
								cursor: props.unsavedChanges ? "default" : "pointer",
								backgroundColor: props.unsavedChanges && "inherit", // Or 'transparent' or whatever color you'd like
							},
							borderRadius: "0px 4px 4px 0px",
						}}
						className="requisitions-table"
						paginationModel={paginationModel}
						onPaginationModelChange={setPaginationModel}
						disableSelectionOnClick={props.unsavedChanges || reviewingClasses}
						checkboxSelection={combiningMode}
						initialState={
							!reviewingClasses && {
								sorting: {
									sortModel: [{ field: "ctitle", sort: "asc" }],
								},
							}
						}
						onSelectionModelChange={handleSelectionModelChange}
						getRowClassName={getRowClassName}
						components={slots}
						rows={props.rows}
						density="compact"
						columns={columnsRequisitions}
						getRowId={(row) => row.classid}
					/>
					{reviewingClasses ? (
						<div className="flex gap-2">
							<Button
								disabled={props.unsavedChanges}
								onClick={handleCancelSubmitAllSuitable}
								variant="contained"
								color="error"
								className="w-full h-10  text-white font-semibold rounded-br-md"
							>
								Cancel
							</Button>
							<Button
								disabled={props.unsavedChanges}
								onClick={handleConfirmSubmitAllSuitable}
								variant="contained"
								className="w-full h-10 bg-green-800 hover:bg-green-900 text-white font-semibold rounded-br-md"
							>
								{submitAllLoading ? (
									<div className="flex justify-center gap-2">
										<p>Please Wait</p> <CircularProgress size={20} sx={{ color: "white" }} />
									</div>
								) : (
									"Confirm Submission"
								)}
							</Button>
						</div>
					) : customCheckboxSelection.length > 0 ? (
						confirmSubmitAllSelected ? (
							<div className="flex gap-2" onClick={handleClickAddClassCheckbox}>
								<Button
									onClick={handleCancelSubmitAllSelected}
									variant="contained"
									color="error"
									className="w-full h-10  text-white font-semibold rounded-br-md"
								>
									Cancel
								</Button>
								<Button
									sx={{ backgroundColor: "#ca8a04" }}
									onClick={handleSubmitAllSelected}
									variant="contained"
									className="w-full h-10 bg-green-800 hover:bg-green-900 text-white font-semibold rounded-br-md"
								>
									{submitAllLoading ? (
										<div className="flex justify-center gap-2">
											<p>Please Wait</p> <CircularProgress size={20} sx={{ color: "white" }} />
										</div>
									) : (
										"Submit Selected Courses"
									)}
								</Button>
							</div>
						) : (
							<div onClick={handleClickAddClassCheckbox} className="flex ">
								<Button
									onClick={handleCancelSubmitAllSelected}
									variant="contained"
									color="error"
									sx={{ borderRadius: "0px" }}
									className="w-full h-10  text-white font-semibold "
								>
									Clear Selection
								</Button>
								<Button
									className="w-full h-10  text-white font-semibold "
									variant="contained"
									sx={{ borderRadius: "0px" }}
									onClick={() => setOpenBatchUpdateDialog(true)}
								>
									Update Multiple Survey Dates ({customCheckboxSelection.length})
								</Button>
								<Button
									onClick={handleReviewAllSelected}
									variant="contained"
									sx={{ backgroundColor: "#ca8a04", borderRadius: "0px" }}
									className="w-full h-10 bg-green-800  text-white font-semibold"
								>
									Review/Submit Selected Courses ({customCheckboxSelection.length})
								</Button>
							</div>
						)
					) : (
						<div onClick={handleClickAddClassCheckbox}>
							<Button
								disabled={props.unsavedChanges || combiningMode}
								onClick={handleSubmitAllSuitable}
								variant="contained"
								sx={{ borderRadius: "0px 0px 5px 0px" }}
								className="w-full h-10 bg-green-800 hover:bg-green-900 text-white font-semibold "
							>
								Submit All Suitable Courses
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
