import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

export default function TeamTeachingDialog(props) {
	const navigate = useNavigate();
	const handleClose = () => {
		props.setOpen(false);
		if (props.requisitionSubmission) {
			window.location.reload();
		}
	};

	const handleRouteToManage = () => {
		navigate("/ManageRequisitions");
	};

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} maxWidth="lg" sx={{ textAlign: "center" }}>
				<DialogTitle textAlign={"center"}>{props.dialogTitleString}</DialogTitle>
				<DialogTitle textAlign={"center"}>{props.dialogMessageString}</DialogTitle>
				<DialogActions>
					{props.confirmItemsModified && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								width: "100%",
								gap: "5px",
							}}
						>
							<Button
								sx={{
									width: "50%",
									height: "100px",
								}}
								variant="contained"
								onClick={props.handleAgreeAndContinue}
							>
								Agree and Continue
							</Button>
							<Button
								sx={{
									width: "50%",
									height: "100px",
								}}
								color="error"
								variant="contained"
								onClick={props.handleCancelAndReturn}
							>
								Cancel and Return
							</Button>
						</Box>
					)}
					{props.requisitionSubmission && (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								width: "100%",
								gap: "5px",
							}}
						>
							<Button
								sx={{
									width: "50%",
									height: "100px",
									display: "flex",
									flexDirection: "column",
								}}
								variant="contained"
								onClick={handleClose}
							>
								<PostAddIcon />
								Create Another Requisition
							</Button>
							<Button
								sx={{
									width: "50%",
									height: "100px",
									display: "flex",
									flexDirection: "column",
								}}
								color="secondary"
								variant="contained"
								onClick={handleRouteToManage}
							>
								<CallMissedOutgoingIcon />
								Go to Manage Requisitions
							</Button>
						</Box>
					)}
				</DialogActions>
			</Dialog>
		</div>
	);
}
