import { useEffect, useContext } from "react";
import {
	getDepartments,
	getTerms,
	getClassIdsByDeptIdAndTerm,
	getRolesByClassIds,
	getInstancedClassesByClassId,
} from "../../components/Api/Getters";

const rolesDescription = [
	{
		id: "AI",
		name: "Assistant Instructor",
	},
	{
		id: "CC",
		name: "Course Coordinator",
	},
	{
		id: "CO",
		name: "Co-Instructor",
	},
	{
		id: "DA",
		name: "Department Administrator",
	},
	{
		id: "GL",
		name: "Guest Lecturer",
	},
	{
		id: "LA",
		name: "Lab Coordinator",
	},
	{
		id: "PG",
		name: "Primary Grade Approver",
	},
	{
		id: "PI",
		name: "Primary Instructor",
	},
	{
		id: "SG",
		name: "Secondary Grade Approver",
	},
	{
		id: "TA",
		name: "Teaching Assistant",
	},
	{
		id: "TC",
		name: "Technician",
	},
];

export async function fetchDepartments(token) {
	if (token) {
		const departments = await getDepartments(token);
		const dnameArray = [];
		const departmentsArray = [];
		if (departments.length > 0) {
			departments.forEach((department) => {
				if (department.dname.includes("Department of ")) {
					department.dname = department.dname.replace("Department of ", "");
				}
				if (department.dname.includes("Departments of ")) {
					department.dname = department.dname.replace("Departments of ", "");
				}
				if (department.dname.includes("Division of ")) {
					department.dname = department.dname.replace("Division of ", "");
				}
				dnameArray.push({
					department: department.dname + " (" + department.faculty.trim() + ")",
					key: department.dept_id,
					display_name: department.display_name,
				});
				departmentsArray.push(department);
			});
		}
		dnameArray.sort((a, b) => {
			if (a.department < b.department) {
				return -1;
			}
			if (a.department > b.department) {
				return 1;
			}
			return 0;
		});
		return dnameArray;
	}
}

export async function fetchTerms(token) {
	if (token) {
		const terms = await getTerms(token);
		const termArray = [];
		if (terms.length > 0) {
			terms.forEach((term) => {
				termArray.push(term.term);
			});
		}

		return termArray;
	}
}

export async function fetchClassIds(token, deptId, termValue) {
	if (token) {
		if (deptId !== null && termValue !== null) {
			const classIdsList = await getClassIdsByDeptIdAndTerm(
				token,
				deptId,
				termValue
			);
			classIdsList.forEach((classid) => {
				classid.id = classid.classid;
			});
			const classIdsArray = [];
			if (classIdsList.length > 0) {
				classIdsList.forEach((classId) => {
					classIdsArray.push(classId.classid);
				});
				return [classIdsArray, classIdsList];
			}
		}
	}
}

export async function fetchInstancedClassIds(token, classId) {
	if (token) {
		if (classId !== null) {
			const classIdsList = await getInstancedClassesByClassId(token, classId);
			classIdsList.forEach((classid) => {
				classid.id = classid.classid;
			});
			const classIdsArray = [];
			if (classIdsList.length > 0) {
				classIdsList.forEach((classId) => {
					classIdsArray.push(classId.classid);
				});
				return [classIdsArray, classIdsList];
			}
		}
	}
}

export async function fetchRolesByClassIds(
	token,
	classIdsArray,
	fetchedClassIds
) {
	if (token) {
		if (classIdsArray.length > 0) {
			const roles = await getRolesByClassIds(token, classIdsArray);
			if (roles.length > 0) {
				const classIdsWithRoles = fetchedClassIds.filter((classid) => {
					return roles.some((role) => role.classid === classid.classid);
				});
				const noInstructorCourses = fetchedClassIds.filter((classid) => {
					return (
						!roles.some((role) => role.classid === classid.classid) &&
						classid.csize > 0
					);
				});
				noInstructorCourses.forEach((classid) => {
					classid.roles = [];
					classid.name = "";
					classid.class_role = "";
					classid.empid = "";
				});
				classIdsWithRoles.forEach((classid) => {
					const rolesForClassId = roles.filter(
						(role) => role.classid === classid.classid
					);
					classid.roles = rolesForClassId;
				});
				classIdsWithRoles.forEach((classid) => {
					//PI, CO, AI, TA, PG, SG,
					const piRole = classid.roles.find((role) => role.class_role === "PI");
					const coRole = classid.roles.find((role) => role.class_role === "CO");
					const aiRole = classid.roles.find((role) => role.class_role === "AI");
					const taRole = classid.roles.find((role) => role.class_role === "TA");
					const pgRole = classid.roles.find((role) => role.class_role === "PG");
					const sgRole = classid.roles.find((role) => role.class_role === "SG");
					if (piRole) {
						classid.name = piRole.name;
						classid.class_role = piRole.class_role;
						classid.empid = piRole.empid;
					} else if (coRole) {
						classid.name = coRole.name;
						classid.class_role = coRole.class_role;
						classid.empid = coRole.empid;
					} else if (aiRole) {
						classid.name = aiRole.name;
						classid.class_role = aiRole.class_role;
						classid.empid = aiRole.empid;
					} else if (taRole) {
						classid.name = taRole.name;
						classid.class_role = taRole.class_role;
						classid.empid = taRole.empid;
					} else if (pgRole) {
						classid.name = pgRole.name;
						classid.class_role = pgRole.class_role;
						classid.empid = pgRole.empid;
					} else if (sgRole) {
						classid.name = sgRole.name;
						classid.class_role = sgRole.class_role;
						classid.empid = sgRole.empid;
					}
				});
				classIdsWithRoles.forEach((classid) => {
					classid.combined = false;
					classid.comb_classes = [];
					classid.roles.forEach((role) => {
						role.checked = false;
						const roleDescription = rolesDescription.find(
							(r) => r.id === role.class_role
						);
						role.role_description = roleDescription.name;
					});
				});
				const updatedClassIdsWithRoles = [
					...classIdsWithRoles,
					...noInstructorCourses,
				];
				return updatedClassIdsWithRoles;
			}
		}
	}
}
