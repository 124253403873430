import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Typography, Grid, Button } from "@mui/material";
import { TokenContext } from "../../context/TokenContext";
import {
	getClassIdsByTerm,
	getUsersForUsersTable,
	propagateDDCTable,
	propagateClassIDsAndRoles,
	getStudentEnrollment,
	propagateRoles,
} from "../../components/Api/Getters";
import BlueDataErrorLog from "./components/BlueDataErrorLog";

export default function BlueUsers() {
	const token = useContext(TokenContext);
	const [classIds, setClassIds] = useState([]);
	const classIdsRef = useRef();
	classIdsRef.current = classIds;
	const [exceptions, setExceptions] = useState([]);

	async function updateUsersTable() {
		const users = await getUsersForUsersTable(token);
		console.log(users);
	}

	async function propagateDDCs() {
		const DDCs = await propagateDDCTable(token);
		setExceptions(DDCs);
		console.log(DDCs);
	}

	async function propagateClasses() {
		const classes = await propagateClassIDsAndRoles(token);
		console.log(classes);
	}

	async function updateStudentEnrollment() {
		const student_enrollment = await getStudentEnrollment(token, "1880");
		console.log(student_enrollment);
	}

	async function updateRoles() {
		const roles = await propagateRoles(token);
		console.log(roles);
	}

	useEffect(() => {
		async function fetchClassIds() {
			if (token) {
				const classIds = await getClassIdsByTerm(token, "1880");
				setClassIds(classIds);
			}
		}
		fetchClassIds();
	}, [token]);

	return (
		<div className="w-full flex flex-col items-center justify-center">
			<Typography variant="h4" sx={{ marginTop: "50px" }}>
				Blue User Upload
			</Typography>

			<Button onClick={updateUsersTable}>Update Users</Button>
			<Button onClick={propagateDDCs}>Propagate DDCs to Users</Button>
			<Button onClick={propagateClasses}>Propagate ClassIDs and Roles</Button>
			<Button onClick={updateStudentEnrollment}>Update Student Enrollment</Button>
			{/* <Button onClick={updateRoles}>Update Roles</Button> */}
			<BlueDataErrorLog rows={exceptions} />
		</div>
	);
}
