import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';


/**
 * This function creates a table to display summarized SPOT survey data for the University of Alabama (UA).
 *
 * Functionality:
 * - The function takes 'data' as input, which is an array containing survey data for UA SPOT evaluations.
 * - It defines two utility functions:
 *    - 'createData': It creates an object with properties for each row in the table.
 *    - 'summarizeData': It summarizes the 'data' array to consolidate responses for each category.
 * - The 'summarizeData' function processes the 'data' array to combine and calculate the total responses for
 *   each category. It returns an array of summarized objects with properties for each category's responses.
 * - The table is structured with columns representing survey aspects: 'SPOT DOMAIN', 'Strongly Disagree',
 *   'Disagree', 'Neither Agree Nor Disagree', 'Agree', 'Strongly Agree'.
 * - The 'data' array contains objects with properties like 'category', 'rsp1', 'rsp2', 'rsp3', 'rsp4', and 'rsp5',
 *   representing the question category and the number of responses for each response option for that category.
 * - The function uses the 'summarizeData' function to prepare the summarized data for rendering the table.
 * - The cell style includes border settings for the table.
 * - The 'backgroundColor' property is used to set the background color for the 'SPOT DOMAIN' cell based on the
 *   background color from the 'data' array for each category.
 * - The table is rendered using Material-UI-like components.
 *
 * Note:
 * - Ensure that the 'data' array is properly structured and contains valid survey data for UA SPOT evaluations.
 * - The 'TableContainer', 'Table', 'TableHead', 'TableBody', 'TableRow', and 'TableCell' components used in this
 *   function are likely from a UI library like Material-UI or similar.
 * - The appearance of the table may vary depending on the CSS or style settings in the application.
 */



function UAspotTable(data){

    function createData(category, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor) {
        return {category, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor};
    }

    function summarizeData(data) {
        const summary = data.reduce((acc, row) => {
          if (row.category in acc) {
            acc[row.category][0] += Number(row.rsp1);
            acc[row.category][1] += Number(row.rsp2);
            acc[row.category][2] += Number(row.rsp3);
            acc[row.category][3] += Number(row.rsp4);
            acc[row.category][4] += Number(row.rsp5);
          } else {
            acc[row.category] = [
              Number(row.rsp1),
              Number(row.rsp2),
              Number(row.rsp3),
              Number(row.rsp4),
              Number(row.rsp5),
              row.backgroundColor,
            ];
          }
          return acc;
        }, {});
      
        const output = Object.keys(summary).map((key) => {
          const [rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor] = summary[key];
          return createData(key, rsp1, rsp2, rsp3, rsp4, rsp5, backgroundColor);
        });
      
        return output;
    }

	return (
		<TableContainer >
			<Table size="small" style={{borderTop: '2px solid #ccc',borderBottom: '2px solid #ccc',
										borderLeft: '2px solid #ccc', borderRight: '2px solid #ccc'}}>
				<TableHead style={{ borderBottom: '2px solid #ccc', backgroundColor: 'rgba(39, 93, 56,1)'}}>
					<TableRow >
						<TableCell style={{ borderRight: '1px solid #ccc',width: "25%"}} align="center" >
							<Typography variant="body2" fontWeight="bold" style={{ color: 'white'}}>
								SPOT DOMAIN
							</Typography>
						</TableCell>
						<TableCell style={{ borderRight: '1px solid #ccc',width: "15%"}} align="center">
							<Typography variant="body2" fontWeight="bold" style={{ color: 'white'}}>
									Strongly Disagree (SD)
							</Typography>
						</TableCell>
						<TableCell style={{ borderRight: '1px solid #ccc',width: "15%"}} align="center">
							<Typography variant="body2" fontWeight="bold" style={{ color: 'white'}}>
									Disagree (D)
							</Typography>
						</TableCell>
						<TableCell style={{ borderRight: '1px solid #ccc',width: "15%"}} align="center">
							<Typography variant="body2" fontWeight="bold" style={{ color: 'white'}}>
									Neither Agree Nor Disagree (N)
							</Typography>
						</TableCell>
						<TableCell style={{ borderRight: '1px solid #ccc',width: "15%"}} align="center">
							<Typography variant="body2" fontWeight="bold" style={{ color: 'white'}}>
									Agree (A)
							</Typography>
						</TableCell>
						<TableCell align="center" style={{width: "15%"}}>
							<Typography variant="body2" fontWeight="bold" style={{ color: 'white'}}>
									Strongly Agree (SA)
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					
					{summarizeData(data).map((row,index) => (
						<TableRow key={index} >
							<TableCell align='center' style={{ borderRight: '1px solid #ccc', borderLeft: '1px solid #ccc',
							borderBottom: '1px solid #ccc', backgroundColor: row.backgroundColor}} >
								{row.category}
							</TableCell> 
							<TableCell  align="center" style={{ borderRight: '1px solid #ccc',
							borderBottom: '1px solid #ccc',backgroundColor: row.backgroundColor}}>
								{row.rsp1}</TableCell >
							<TableCell  align="center" style={{ borderRight: '1px solid #ccc',
							borderBottom: '1px solid #ccc',backgroundColor: row.backgroundColor}}>
								{row.rsp2}</TableCell>
							<TableCell  align="center" style={{ borderRight: '1px solid #ccc',
							borderBottom: '1px solid #ccc',backgroundColor: row.backgroundColor}}>
								{row.rsp3}</TableCell>
							<TableCell  align="center" style={{ borderRight: '1px solid #ccc',
							borderBottom: '1px solid #ccc',backgroundColor: row.backgroundColor}}>
								{row.rsp4}</TableCell>
							<TableCell  align="center" style={{ borderRight: '1px solid #ccc',
							borderBottom: '1px solid #ccc',backgroundColor: row.backgroundColor}}>
								{row.rsp5}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
    );
}

export {UAspotTable}