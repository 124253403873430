import React from "react";
import { Box, Typography } from "@mui/material";

function ReportHeader(courseINFO){

    /**
     * Calculate the percentage of a value in relation to a total.
     *
     * @param {number} value - The value for which the percentage is calculated.
     * @param {number} total - The total value against which the percentage is calculated.
     * @returns {string} A formatted string representing the calculated percentage.
     */
    function calculatePercentage(value, total) {

        // Check if both parameters are numbers
        if (typeof value !== 'number' || typeof total !== 'number') {
          return '0.0%';
        }
        
        // Calculate the percentage and round it to one decimal place
        const percentage = (value / total) * 100;
        const roundedPercentage = percentage.toFixed(1);

        // Format the result as a percentage string
        return `${roundedPercentage}%`;
      }

    return (
        <Box id='report-header' sx={{width:'100%',display: 'flex', flexDirection: 'row',
            justifyContent:'space-between',borderBottom: '2px solid grey',paddingBottom:'10px'}}>
            <div>
                <Typography variant="h4">Student Perspectives of Teaching (SPOT)</Typography> 
                <Typography variant="body2">{courseINFO[3]}</Typography>
                <Typography variant="body2">{courseINFO[4]}</Typography>
                {courseINFO[8]===null ? (
                <Typography variant="body2">
                    Number of students responding to questionnaire: 0 of {courseINFO[7]} ({calculatePercentage(courseINFO[8], courseINFO[7])})
                </Typography>
                ):(
                <Typography variant="body2">
                    Number of students responding to questionnaire: {courseINFO[8]} of {courseINFO[7]} ({calculatePercentage(courseINFO[8], courseINFO[7])})
                </Typography>
                )}
            </div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                    <Typography variant="body2">Term : &emsp;</Typography> 
                    <Typography variant="body2">Class : &emsp;</Typography> 
                    <Typography variant="body2">Instructor : &emsp;</Typography> 
                    <Typography variant="body2">Reqnum : &emsp;</Typography>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                    <Typography variant="body2">
                        <span style={{ fontWeight: 'bold' }}>{courseINFO[2]}</span>
                    </Typography> 
                    <Typography variant="body2">
                        <span style={{ fontWeight: 'bold' }}>{courseINFO[0]}</span>
                    </Typography> 
                    <Typography variant="body2">
                        <span style={{ fontWeight: 'bold' }}>{courseINFO[1]}</span>
                    </Typography> 
                    <Typography variant="body2">
                        <span style={{ fontWeight: 'bold' }}>{courseINFO[5]}-{courseINFO[6]}</span>
                    </Typography>
                </div>
            </div>
        </Box>
    )
}

export {ReportHeader}