import { FormControlLabel } from "@mui/material";
import { FormGroup } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Typography } from "@mui/material";
import styles from "../styles/info.module.css";
import { Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { Zoom } from "@mui/material";

function ExamWeightCheckbox(props) {
	return (
		<FormGroup sx={props.sx}>
			<FormControlLabel
				control={
					<Checkbox
						checked={props.checked}
						onChange={props.onChange}
						color="secondary"
						aria-label="outlined primary button group"
					></Checkbox>
				}
				label={
					<Typography
						className={styles["question-text"]}
						sx={{ paddingRight: "" }}
					>
						{props.questionText}
					</Typography>
				}
				labelPlacement="start"
				sx={{ paddingBottom: "" }}
			/>
			<Tooltip
				TransitionComponent={Zoom}
				title={props.tooltipDescription}
				placement="top"
			>
				<HelpIcon
					sx={{
						marginLeft: "10px",
						marginTop: "9px",
						color: "gray",
					}}
				/>
			</Tooltip>
		</FormGroup>
	);
}

export default ExamWeightCheckbox;
