import * as React from "react";
import { Button, Box, Typography } from "@mui/material";
import ExcludeClassesTable from "../Tables/ExcludeClassesTable";
import TableHeader from "../Tables/TableHeader";

//before start, confirm that all combined classes are in surveys already
//warn that every class will have a survey created for it
//exclude classes
//grab blocks that belong to this dept as well as generalized ones
//group courses in dicts by end date, and assign survey dates to those
//assign relevant block to each group of courses/dates
//post to DB

export default function ExcludeClasses(props) {
	return (
		<Box
			sx={{
				display: "flex",
				width: "100%",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Box
				sx={{
					width: "100%",
					height: "15%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography
					variant="h6"
					fontWeight={600}
					sx={{ display: "flex", marginBottom: "20px" }}
					textAlign={"center"}
				>
					Step 1: Exclude Classes. Click the "exclude" button on a class below
					to exclude it from the auto-requisition process.
				</Typography>
				{/* <Typography variant="h7" fontWeight={600} textAlign={"center"} sx={{ display: "flex", marginBottom: "20px" }}>
					Note: Classes with no instructor are not displayed in the table. To exclude those classes, do not assign an instructor to them in the next step.
				</Typography> */}
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "80%",
					/* outline: "1px solid green", */
				}}
			>
				<TableHeader
					classIds={props.classIds}
					webClasses={props.webClasses}
					goodClassIds={props.goodClassIds}
					noEndDates={props.noEndDates}
					suitableClassIds={props.suitableClassIds}
					stagedClasses={props.stagedClasses}
					setStagedClasses={props.setStagedClasses}
					removedForCombine={props.removedForCombine}
				/>

				<ExcludeClassesTable
					rows={props.goodClassIds}
					setRows={props.setGoodClassIds}
				/>
			</Box>
			<Button
				disabled={props.step2disabled}
				variant="contained"
				fullWidth
				sx={{ marginTop: "5px", marginBottom: "5px" }}
				onClick={() => props.setStep2(true)}
			>
				Continue
			</Button>
		</Box>
	);
}
