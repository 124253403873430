import { Button, Box, Typography } from "@mui/material";
import ResolveInstructorsTable from "../Tables/ResolveInstructorsTable";
import TableHeader from "../Tables/TableHeader";
export default function ResolveInstructors(props) {
	return (
		<Box
			sx={{
				display: "flex",
				width: "100%",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Box
				sx={{
					width: "100%",
					height: "15%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography variant="h6" fontWeight={600} sx={{ display: "flex", marginBottom: "20px" }}>
					Step 2: The following classes have no instructor assigned. Assign instructors to these classes by clicking the "Add" button on the right.
				</Typography>
				<Typography variant="h6" fontWeight={600} color="red" sx={{ display: "flex", marginBottom: "20px" }}>
					Note: If you do not assign an instructor, the class will be excluded from Auto-Requisitioning.
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "75%",
				}}
			>
				<TableHeader
					classIds={props.classIds}
					webClasses={props.webClasses}
					goodClassIds={props.goodClassIds}
					noEndDates={props.noEndDates}
					suitableClassIds={props.suitableClassIds}
					stagedClasses={props.stagedClasses}
					setStagedClases={props.setStagedClases}
					removedForCombine={props.removedForCombine}
				/>

				<ResolveInstructorsTable
					classIds={props.classIds}
					setClassIds={props.setClassIds}
					rows={props.noInstructorClassIds}
					setOpen={props.setOpen}
					goodClassIds={props.goodClassIds}
					setGoodClassIds={props.setGoodClassIds}
					selectedRow={props.selectedRow}
					setSelectedRow={props.setSelectedRow}
					noInstructor
				/>
			</Box>
			<Box sx={{ display: "flex", gap: "10px" }}>
				<Button variant="contained" fullWidth onClick={() => props.setStep2(false)}>
					{"<-"}Back to Exclude Classes
				</Button>
				{props.noInstructorClassIds.length === 0 && !props.step3disabled ? (
					<Button
						disabled={props.step3disabled}
						variant="contained"
						fullWidth
						/* sx={{
							marginTop: "5px",
							marginBottom: "5px",
						}} */
						onClick={() => props.setStep3(true)}
					>
						Proceed{"->"}
					</Button>
				) : (
					<Button
						disabled={props.step3disabled}
						color="error"
						variant="contained"
						fullWidth
						/* sx={{
							marginTop: "5px",
							marginBottom: "5px",
						}} */
						onClick={() => props.setStep3(true)}
					>
						Continue Without Resolving{"->"}
					</Button>
				)}
			</Box>
		</Box>
	);
}
