import * as React from "react";
import { Box, Button, Typography, Modal, TextField, InputLabel } from "@mui/material/";
import { useState, useEffect } from "react";
import isAuthenticated from "../../components/Utils/isAuthenticated";
import ListSelect from "../../components/Utils/ListSelect";
import { useDepartmentsQuery } from "../../Hooks/useDepartmentsQuery";
import AutocompleteComponent from "../../components/Utils/AutocompleteComponent";
import { postTempAccess } from "../../components/Api/Post";
import { useNavigate } from "react-router-dom";

const roles = ["Grad Student", "Faculty", "Support Staff"];

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 600,
	bgcolor: "background.paper",
	border: "2px solid #000",
	borderRadius: 8,
	boxShadow: 24,
	p: 8,
};

export default function GiveRoleForm(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const access = auth.access;
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const navigate = useNavigate();

	const [autocompleteValue, setAutocompleteValue] = useState(null);
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

	const { data: departments } = useDepartmentsQuery(token);

	const [userState, setUserState] = useState({
		ccid: "",
		empid: "",
		name: "",
		role: "",
		dept_id: "",
	});

	useEffect(() => {
		if (autocompleteValue) {
			setUserState({ ...userState, dept_id: autocompleteValue.key });
		}
	}, [autocompleteValue]);

	useEffect(() => {
		setUserState({
			ccid: auth.ccid,
			empid: auth.empid,
			name: `${auth.firstName} ${auth.lastName}`,
			role: "",
			dept_id: "",
		});
	}, []);

	async function handleSubmit() {
		if (userState.name.length < 4 || userState.role === "" || userState.dept_id === "") {
			alert("Please fill out all required fields.");
			return;
		}
		const response = await postTempAccess(token, userState);
		if (response.status === 201) {
			let combinedAccess = access + " TEMP";
			sessionStorage.setItem("access", combinedAccess);
			sessionStorage.setItem("dept_ids", userState.dept_id);
			navigate("/request-for-service");
		}
	}

	return (
		<div>
			<Button
				variant={props.buttonVariant}
				onClick={handleOpen}
				sx={{ width: `${props.buttonWidth}`, my: "auto" }}
			>
				{props.buttonTitle}
			</Button>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						This form will grant you access to submit exam grading requests.
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Please only proceed if you are a grad student, faculty member, or support staff.
					</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Activity for CCID's submitted in this form will be monitored.
					</Typography>
					<Box
						sx={{
							mt: 2,
							gap: "5px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<InputLabel id="ccid-label">CCID</InputLabel>

						<TextField
							disabled
							autoComplete="off"
							type="text"
							error={false}
							value={userState.ccid}
							onChange={(e) => setUserState({ ...userState, ccid: e.target.value })}
							variant="outlined"
							sx={{
								width: "100%",
							}}
						/>
						<InputLabel id="empid-label">Student/Employee ID</InputLabel>
						<TextField
							disabled
							autoComplete="off"
							type="text"
							error={false}
							value={userState.empid}
							onChange={(e) => setUserState({ ...userState, empid: e.target.value })}
							variant="outlined"
							sx={{
								width: "100%",
							}}
						/>
						<InputLabel id="name-label">Name (Required)</InputLabel>
						<TextField
							autoComplete="off"
							type="text"
							error={false}
							value={userState.name}
							onChange={(e) => setUserState({ ...userState, name: e.target.value })}
							variant="outlined"
							sx={{
								width: "100%",
							}}
						/>
						<InputLabel id="role-label">Role (Required)</InputLabel>
						<ListSelect
							required={true}
							list={roles}
							value={userState.role}
							onChange={(e) => setUserState({ ...userState, role: e.target.value })}
							marginBottom="0px"
						/>
						<InputLabel id="dept_id-label">Department (Required)</InputLabel>
						<AutocompleteComponent
							required={true}
							options={departments ? departments : []}
							value={autocompleteValue}
							setValue={setAutocompleteValue}
							inputValue={autocompleteInputValue}
							setInputValue={setAutocompleteInputValue}
							width="100%"
							variant="outlined"
							marginTop="0px"
						/>
						<Button onClick={handleSubmit}>Submit</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
