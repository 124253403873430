import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function CustomQuestions(props) {
	const handleAddQuestionPoint = () => {
		props.setQuestionPoints({
			...props.questionPoints,
			[Object.keys(props.questionPoints).length + 1]: {
				text: "",
				customScale: false,
				scale: {
					1: "",
					2: "",
				},
			},
		});
	};

	const handleAddScalePoint = (event, questionPoint) => {
		props.setQuestionPoints({
			...props.questionPoints,
			[questionPoint]: {
				...props.questionPoints[questionPoint],
				scale: {
					...props.questionPoints[questionPoint].scale,
					[Object.keys(props.questionPoints[questionPoint].scale).length + 1]:
						"",
				},
			},
		});
	};

	const handleRemoveScalePoint = (event, questionPoint) => {
		const newScalePoints = { ...props.questionPoints[questionPoint].scale };
		if (Object.keys(props.questionPoints[questionPoint].scale).length > 2) {
			delete newScalePoints[
				Object.keys(props.questionPoints[questionPoint].scale).length
			];
			props.setQuestionPoints({
				...props.questionPoints,
				[questionPoint]: {
					...props.questionPoints[questionPoint],
					scale: newScalePoints,
				},
			});
		} else {
			alert("You must have at least 2 scale points!");
		}
	};

	const handleChangeScale = (event, questionPoint) => {
		if (
			event.target.value.includes("{") ||
			event.target.value.includes("}") ||
			event.target.value.includes(";") ||
			event.target.value.includes("=") ||
			event.target.value.includes("+")
		) {
			alert("You cannot use the following characters: { } ; =");
			return;
		} else {
			props.setQuestionPoints({
				...props.questionPoints,
				[questionPoint]: {
					...props.questionPoints[questionPoint],
					scale: {
						...props.questionPoints[questionPoint].scale,
						[event.target.name]: event.target.value,
					},
				},
			});
		}
	};

	const handleRemoveQuestionPoint = () => {
		const newQuestionPoints = { ...props.questionPoints };
		delete newQuestionPoints[Object.keys(props.questionPoints).length];
		props.setQuestionPoints(newQuestionPoints);
	};

	const handleChangeQuestion = (event) => {
		if (
			event.target.value.includes("{") ||
			event.target.value.includes("}") ||
			event.target.value.includes(";") ||
			event.target.value.includes("=") ||
			event.target.value.includes("+")
		) {
			alert("You cannot use the following characters: { } ; =");
			return;
		} else {
			props.setQuestionPoints({
				...props.questionPoints,
				[event.target.name]: {
					...props.questionPoints[event.target.name],
					text: event.target.value,
				},
			});
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: "10px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					width: "400px",
					height: "40px",
				}}
			>
				<Button
					variant="contained"
					onClick={handleAddQuestionPoint}
					sx={{ width: "50%" }}
				>
					+Add Question
				</Button>
				<Button
					color="error"
					variant="contained"
					sx={{ width: "50%" }}
					onClick={handleRemoveQuestionPoint}
					disabled={props.questionPoints.length === 0}
				>
					-Remove Question
				</Button>
			</Box>
			{Object.keys(props.questionPoints).map((questionPoint) => {
				return (
					<Box
						key={questionPoint + 2}
						sx={{
							outline: "3px solid #bdbdbd",
							padding: "5px",
							borderRadius: "5px",
						}}
					>
						<TextField
							key={questionPoint}
							size="small"
							variant="outlined"
							multiline
							placeholder={"Question " + questionPoint}
							sx={{ width: "79%", marginTop: "5px" }}
							label={"Question " + questionPoint}
							name={questionPoint}
							value={props.questionPoints[questionPoint].text}
							onChange={handleChangeQuestion}
							focused
						/>
						<Button
							variant="outlined"
							sx={{
								width: "20%",
								marginLeft: "5px",
								height: "40px",
								marginTop: "5px",
							}}
							//onclick set questionPoint to true
							onClick={() => {
								props.setQuestionPoints({
									...props.questionPoints,
									[questionPoint]: {
										...props.questionPoints[questionPoint],
										customScale:
											!props.questionPoints[questionPoint].customScale,
									},
								});
							}}
						>
							Custom Scale
						</Button>
						{props.questionPoints[questionPoint].customScale ? (
							<Box
								key={questionPoint + 1}
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: "10px",
									marginTop: "10px",
								}}
							>
								<Box
									key={questionPoint}
									sx={{
										display: "flex",
										flexDirection: "row",
										gap: "10px",
										maxWidth: "800px",
									}}
								>
									<TextField
										key={1}
										size="small"
										variant="outlined"
										label="Scale 1"
										name="1"
										value={props.questionPoints[questionPoint].scale[1]}
										onChange={(event) =>
											handleChangeScale(event, questionPoint)
										}
										color="secondary"
										focused
										placeholder="Yes..."
									/>
									<TextField
										key={2}
										size="small"
										variant="outlined"
										label="Scale 2"
										name="2"
										value={props.questionPoints[questionPoint].scale[2]}
										onChange={(event) =>
											handleChangeScale(event, questionPoint)
										}
										color="secondary"
										focused
										placeholder="No..."
									/>
									{
										//dynamically add more scale points based on the length of the scale object
										Object.keys(props.questionPoints[questionPoint].scale)
											.length > 2
											? Object.keys(
													props.questionPoints[questionPoint].scale
											  ).map((scalePoint, index) => {
													if (index > 1) {
														return (
															<TextField
																key={scalePoint}
																size="small"
																variant="outlined"
																label={"Scale " + scalePoint}
																name={scalePoint}
																value={
																	props.questionPoints[questionPoint].scale[
																		scalePoint
																	]
																}
																onChange={(event) =>
																	handleChangeScale(event, questionPoint)
																}
																color="secondary"
																focused
															/>
														);
													} else {
														return null;
													}
											  })
											: null
									}
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										gap: "10px",
										width: "600px",
										height: "35px",
									}}
								>
									<Button
										variant="outlined"
										onClick={(event) =>
											handleAddScalePoint(event, questionPoint)
										}
										sx={{ width: "33%" }}
										color="success"
									>
										+Add Scale Point
									</Button>
									<Button
										color="error"
										variant="outlined"
										onClick={(event) =>
											handleRemoveScalePoint(event, questionPoint)
										}
										/* disabled={props.scalePoints.length === 0} */
										sx={{ width: "33%" }}
									>
										-Remove Scale Point
									</Button>
								</Box>
							</Box>
						) : null}
					</Box>
				);
			})}
		</Box>
	);
}
