import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { getAllItems } from "../../../components/Api/Getters";
import { useContext, useEffect } from "react";
import { TokenContext } from "../../../context/TokenContext";
import { useAllItemsQuery } from "../../../Hooks/useAllItemsQuery";
import isAuthenticated from "../../../components/Utils/isAuthenticated";

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
	return [...a, ...not(b, a)];
}

export default function BlockItemsTransfer(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const [checked, setChecked] = React.useState([]);
	const [left, setLeft] = React.useState([0, 1, 2, 3]);

	const { data: allItemsData } = useAllItemsQuery(token);

	useEffect(() => {
		if (allItemsData && allItemsData.length > 0) {
			const itemsList = [];
			allItemsData.forEach((item) => {
				if (item.cat_id !== 10 && item.itemno < 20000) {
					itemsList.push(item);
				}
			});
			itemsList.sort(function (a, b) {
				return a.itemno - b.itemno;
			});
			setLeft(itemsList);
		}
	}, [allItemsData]);

	useEffect(() => {
		const tempLeft = [...left];
		tempLeft.sort(function (a, b) {
			return a.itemno - b.itemno;
		});
		setLeft(tempLeft);
	}, [props.chosenItems]);

	const leftChecked = intersection(checked, left);
	const rightChecked = intersection(checked, props.chosenItems);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const numberOfChecked = (items) => intersection(checked, items).length;

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items));
		} else {
			setChecked(union(checked, items));
		}
	};

	const handleCheckedRight = () => {
		props.setChosenItems(props.chosenItems.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		props.setChosenItems(not(props.chosenItems, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const customList = (title, items) => (
		<Card>
			<CardHeader
				sx={{ px: 2, py: 1 }}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={numberOfChecked(items) === items.length && items.length !== 0}
						indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
						disabled={items.length === 0}
						inputProps={{
							"aria-label": "all items selected",
						}}
					/>
				}
				title={title}
				subheader={`${numberOfChecked(items)}/${items.length} Items selected`}
			/>
			<Divider />
			<List
				sx={{
					width: 400,
					height: 430,
					bgcolor: "background.paper",
					overflow: "auto",
				}}
				dense
				component="div"
				role="list"
			>
				{items.map((value) => {
					const labelId = `transfer-list-all-item-${value.itemno}-label`;

					return (
						<ListItem key={value.item_id} role="listitem" button onClick={handleToggle(value)}>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										"aria-labelledby": labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={`Item ${value.itemno}: ${value.text}`} />
						</ListItem>
					);
				})}
			</List>
		</Card>
	);

	return (
		<Grid container spacing={2} justifyContent="center" alignItems="center">
			<Grid>{customList("Items", left)}</Grid>
			<Grid>
				<Grid container direction="column" alignItems="center">
					<Button sx={{ my: 0.5 }} variant="outlined" size="small" onClick={handleCheckedRight} disabled={leftChecked.length === 0} aria-label="move selected props.chosenItems">
						&gt;
					</Button>
					<Button sx={{ my: 0.5 }} variant="outlined" size="small" onClick={handleCheckedLeft} disabled={rightChecked.length === 0} aria-label="move selected left">
						&lt;
					</Button>
				</Grid>
			</Grid>
			<Grid>{customList("Items Chosen", props.chosenItems)}</Grid>
		</Grid>
	);
}
