import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

export default function LoggingList(props) {
	return (
		<List
			sx={{
				width: "100%",
				bgcolor: "paper.main",
				position: "relative",
				overflow: "auto",
				maxHeight: 300,
				display: "flex",
				"& ul": { padding: 0 },
			}}
			subheader={<li />}
		>
			{Object.keys(props.loggingDict).map((sectionId) => (
				<li key={`section-${sectionId}`} style={{ width: "100%" }}>
					<ul>
						<ListSubheader
							primaryTypographyProps={{
								color: "text.primary",
								fontWeight: "500",
							}}
							sx={{
								backgroundColor: "greys.main",
								fontWeight: 600,
							}}
						>{`${sectionId}`}</ListSubheader>

						{sectionId === "Teamsize Modified" &&
							props.loggingDict[sectionId].map((item) => (
								<ListItem key={`${sectionId}-${item.classid}`}>
									<ListItemText
										primary={`${item.classid}: ${item.ctitle}, Teamsize= ${item.teamsize}, Roles= ${item.roles.length}. Teamsize was edited down because roles did not match.`}
									/>
								</ListItem>
							))}

						{sectionId === "Role Removed" &&
							props.loggingDict[sectionId].map((item) => (
								<ListItem
									key={`${sectionId}-${item.classId.classid}-${item.role.empid}`}
								>
									<ListItemText
										primary={`${item.classId.classid} - ${item.classId.ctitle}: ${item.role.name}, ${item.role.class_role} - Role removed as per predefined roles settings.`}
									/>
								</ListItem>
							))}
						{sectionId === "No Roles" &&
							props.loggingDict[sectionId].map((item) => (
								<ListItem key={`${sectionId}-${item.classid}`}>
									<ListItemText
										primary={`${item.classid} - ${item.ctitle}: No instructor found for class. Class removed from auto process.`}
									/>
								</ListItem>
							))}
					</ul>
				</li>
			))}
		</List>
	);
}
