import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import TextInput from "../../components/Utils/TextInput";
import AutocompleteComponent from "../../components/Utils/AutocompleteComponent";
import { postWebUser } from "../../components/Api/Post";
import { useDepartmentsQuery } from "../../Hooks/useDepartmentsQuery";

export default function UserSettings() {
	const token = sessionStorage.getItem("token");
	const [autocompleteValue, setAutocompleteValue] = useState("");
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");

	const [userState, setUserState] = useState({
		lastName: "",
		firstName: "",
		id: "",
		department: "",
		deptId: "",
		catalogAccess: "",
		email: "",
	});

	useEffect(() => {
		if (autocompleteValue) {
			const deptid = autocompleteValue.key;
			const deptName = autocompleteValue.display_name;

			setUserState((prevUserState) => ({
				...prevUserState,
				deptId: deptid,
				department: deptName,
			}));
		}
	}, [autocompleteValue]);

	const { data: departments } = useDepartmentsQuery(token);

	const handleChange = (event) => {
		const value = event.target.value;
		setUserState({ ...userState, [event.target.name]: value });
	};

	const handleSubmit = () => {
		const webUser = {
			last_name: userState.lastName,
			first_name: userState.firstName,
			id: userState.id,
			department: userState.department,
			dept_id: userState.deptId,
			catalog_access: userState.catalogAccess,
			email: userState.email,
		};
		postWebUser(token, webUser);
		setUserState({
			lastName: "",
			firstName: "",
			id: "",
			department: "",
			deptId: "",
			catalogAccess: "",
			email: "",
		});
	};

	return (
		<Grid
			container
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "flex-start",
				width: "100%",
			}}
		>
			<Typography variant="h4" sx={{ marginTop: "2%" }}>
				Add Web User
			</Typography>
			<Grid
				sx={{
					marginTop: "2%",
					display: "flex",
				}}
			>
				<Grid
					sx={{
						width: { xs: "100%", sm: "100%", md: "100%", lg: "800px" },
					}}
				>
					<Grid item>
						<TextInput
							value={userState.firstName || ""}
							name="firstName"
							label="First Name"
							onChange={handleChange}
						/>
					</Grid>
					<Grid item>
						<TextInput
							value={userState.lastName || ""}
							name="lastName"
							label="Last Name"
							onChange={handleChange}
						/>
					</Grid>
					<Grid item>
						<TextInput value={userState.id || ""} name="id" label="CCID" onChange={handleChange} />
					</Grid>
					<Grid item>
						<TextInput
							value={userState.email || ""}
							name="email"
							label="UAlberta Email"
							onChange={handleChange}
						/>
					</Grid>
					<Grid item>
						<AutocompleteComponent
							options={departments ? departments : []}
							value={autocompleteValue}
							setValue={setAutocompleteValue}
							inputValue={autocompleteInputValue}
							setInputValue={setAutocompleteInputValue}
							label="Department"
						/>
					</Grid>
					<Grid item sx={{ marginTop: "20px" }}>
						<Button
							variant="contained"
							sx={{ display: "flex", width: "100%", marginBottom: "20px" }}
							onClick={handleSubmit}
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
