import { createContext } from "react";

export const ClassesContext = createContext(null);
export const ClassIdsContext = createContext(null);
export const TeamTeachingContext = createContext(null);
export const StartDateContext = createContext(null);
export const EndDateContext = createContext(null);
export const RequisitionsContext = createContext(null);
export const DeptIdContext = createContext(null);
export const TermContext = createContext(null);
export const SmallRequisitionContext = createContext(null);
export const WebClassesContext = createContext(null);
export const ItemsContext = createContext(null);
