import * as React from "react";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Tooltip } from "@mui/material";

export default function BasicSwitch(props) {
	return (
		<FormControl component="fieldset">
			<FormGroup aria-label="position" row>
				<FormControlLabel
					value="start"
					control={
						<Tooltip title={props.tooltip} placement={props.ttPlacement}>
							<Switch
								color="primary"
								checked={props.checked}
								onClick={props.onClick}
							/>
						</Tooltip>
					}
					label={props.label}
					labelPlacement="start"
				/>
			</FormGroup>
		</FormControl>
	);
}
