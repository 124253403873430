import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


export default function ViewBlocks() {

    let url = process.env.REACT_APP_BACKEND_HOST
    const [fetched,setFetched] = useState(false)
    const [blockIDs,setBlockIDs] = useState([]);
    const [selectedBlock, setSelectedBlock] = useState("")
    const [disableAccordian,setDisableAccordian] = useState(true)
    const [expandAccordian, setExpandAccordian] = useState(false)
    const [blockInfo, setBlockInfo] = useState([])
    const [blockPreview,setBlockPreview] = useState([])


    useEffect(() => {
    
        function getBlocks() {fetch(url.concat("/spot/fetch_blockids"),
          { headers: {'Authorization': `Token ${sessionStorage.token}`}})
          .then((response) => response.json())
          .then((blocks => {setBlockIDs(blocks.sort((a, b) => b.blkid.localeCompare(a.blkid))) }))
        }
        
        if(!fetched){
          setFetched(true)
          getBlocks()
        }
    
      },[fetched,blockIDs,url]);

    
    async function fetchData(url) {
        const response = await fetch(url, {
            headers: { Authorization: `Token ${sessionStorage.token}` },
        });
        const data = await response.json();
        return data;
    }

    async function selectBlock(selected){
        for (const block of blockIDs){
            if (block.blkid === selected){
                setBlockInfo(block)
            }
        }

        const block_data = await fetchData(url.concat("/spot/block_info/",selected,"/"))
        setBlockPreview(block_data)
        setDisableAccordian(false)
        setExpandAccordian(true)

    }



    return(
        <div style ={{ height: '100%', maxHeight: 'calc(100% - 160px)'}}>
            <div style={{display:'flex',flexDirection:'column', alignItems:'center',marginTop:'20px'}}>
                <Typography variant='h4'>View Blocks</Typography>

                <Box style={{paddingTop:'10px', alignItems: 'center',flexDirection: 'column',width: '500px'}}>

                    <Autocomplete
                        sx={{width:'100%',paddingBottom:'10px'}}
                        disablePortal
                        id="block-select"
                        options={blockIDs.map((option,index) => ({
                            id: index,
                            block: option.blkid, 
                            label: `${option.blkid} : ${option.ctype || "N/A"} : ${option.baddr1}` 
                        }))}
                        isOptionEqualToValue={(option, value) => option.block === value.block}
                        ListboxProps={{style: {maxHeight: 200, overflowY: 'scroll'}}}
                        className="dept-home-course"
                        onChange={((event,value) => selectBlock(value.block))}
                        renderInput={(params) => <TextField {...params} label="Blocks"/>}
                    />
                </Box>

                <Box style={{paddingTop:'10px', alignItems: 'center',flexDirection: 'column',width: '80%'}}>
                    <Accordion disabled={disableAccordian} expanded={expandAccordian}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="Block-info"
                            >
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}> 
                                Block Info 
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                            <Box justify="center" alignItems="flex-start"
                                style={{paddingLeft: "80px",paddingRight: "80px", textAlign: 'center'}}>
                                <Grid container style={{paddingBottom: "10px"}}>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Block ID:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {blockInfo.blkid}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Used For:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {(blockInfo.baddr1 === '' || blockInfo.baddr1 === null)  ? 'N/A' : blockInfo.baddr1}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Faculty:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {(blockInfo.faculty === '' || blockInfo.faculty === null) ? 'N/A' : blockInfo.faculty}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Department ID:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {(blockInfo.dept_id === '' || blockInfo.dept_id === null) ? 'N/A' :blockInfo.dept_id}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>language:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                        {(blockInfo.lang === '' || blockInfo.lang === null) ? 'N/A' :blockInfo.lang}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{paddingBottom: "10px", }}>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Blkid3:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {(blockInfo.blkid3 === '' || blockInfo.blkid3 === null) ? 'N/A' :blockInfo.blkid3}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Origin:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {(blockInfo.origin === '' || blockInfo.origin === null) ? 'N/A' :blockInfo.origin}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Class Types:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {(blockInfo.ctype === '' || blockInfo.ctype === null) ? 'N/A' :blockInfo.ctype}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Specific Course:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {(blockInfo.course === '' || blockInfo.course === null) ? 'N/A' :blockInfo.course}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2.4} style={{ paddingLeft: "35px",paddingRight: "15px",
                                                                paddingBottom: "5px"}}>
                                        <Typography component={"div"} sx={{fontSize: "1rem" }}>
                                            <b>Class Size:</b>
                                        </Typography>
                                        <Typography component={"div"}  variant="body2" sx={{fontSize: "1rem" }}>
                                            {(blockInfo.csize === '' || blockInfo.csize === null) ? 'N/A' :blockInfo.csize}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion disabled={disableAccordian} expanded={expandAccordian}>
                        <AccordionSummary
                            aria-controls="panel2a-content"
                            id="block-preivew"
                            >
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}> 
                                Block Preview
                            </Typography>
                           
                        </AccordionSummary>
                        <AccordionDetails style={{paddingLeft:'80px',paddingRight:'80px'}}>
                            {Object.keys(blockPreview).map(key => (
                                <Box key={key} style={{display:'flex',flexDirection:'column'}}>
                                    <Typography key={key} variant="body2" style={{ fontWeight: 'bold' }}>
                                        ({blockPreview[key].Itemno}) - {blockPreview[key].Q_Text}
                                    </Typography>
                                    {blockPreview[key].Q_Type === 'RATE' ?(
                                        <FormControl>
                                            {blockPreview[key].Scale === null ?(
                                                <RadioGroup row style={{paddingLeft:'80px',paddingRight:'80px'}}> 
                                                    <FormControlLabel value="1" 
                                                        control={<Radio size="small" />} 
                                                        label="I strongly disagree" 
                                                        style={{marginRight:'60px'}}
                                                    />
                                                    <FormControlLabel value="2" 
                                                        control={<Radio size="small"/>} 
                                                        label="I disagree" 
                                                        style={{marginRight:'60px'}}
                                                    />
                                                    <FormControlLabel value="3" 
                                                        control={<Radio size="small"/>} 
                                                        label=" I neither agree nor disagree"
                                                        style={{marginRight:'60px'}} 
                                                    />
                                                    <FormControlLabel value="4" 
                                                        control={<Radio size="small"/>} 
                                                        label=" I agree" 
                                                        style={{marginRight:'60px'}}
                                                    />
                                                    <FormControlLabel value="5" 
                                                        control={<Radio size="small"/>} 
                                                        label="I strongly agree " 
                                                        style={{marginRight:'60px'}}
                                                    />
                                                </RadioGroup>
                                            ):(
                                                <RadioGroup row style={{paddingLeft:'80px',paddingRight:'80px'}}> 
                                                    {blockPreview[key].Scale.split(";").map((value, index) => (
                                                        <FormControlLabel value={index} 
                                                            control={<Radio size="small" />} 
                                                            label={value}
                                                            style={{marginRight:'60px'}}
                                                        />
                                                    ))}
                                                </RadioGroup>
                                            )}
                                            <br/>
                                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                                ({blockPreview[key].Itemno}) - Comment (optional):
                                            </Typography>
                                            <TextField variant="outlined" />
                                        </FormControl>
                                        
                                    ):(
                                        <TextField  variant="outlined" />
                                    )}
                                    <br/>
                                    <br/>
                                    <br/>
                                </Box>
                            ))}
                            </AccordionDetails>
                        </Accordion>
                </Box>

            </div>
            
        </div>
    )

}