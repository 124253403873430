import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function SubmitAutoDialog(props) {
	const navigate = useNavigate();
	const handleClose = (event, reason) => {
		if (reason && reason == "backdropClick") return;
		props.setOpen(false);
		window.scrollTo(0, 0);
	};

	const handleRouteToManage = () => {
		navigate("/ManageRequisitions");
	};

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} maxWidth="lg" sx={{ textAlign: "center" }}>
				{props.loading ? (
					<CircularProgress />
				) : (
					<div>
						<DialogTitle textAlign={"center"}>{"Auto-Requisition Process Complete!"}</DialogTitle>
						<DialogTitle textAlign={"center"}>
							{"IMPORTANT: Your surveys will not be set up until classes in the surveys have been released. To release your classes, go to Manage Requisitions."}
						</DialogTitle>

						<DialogActions>
							<Button
								sx={{
									width: "100%",
									height: "100px",
									display: "flex",
									flexDirection: "column",
								}}
								color="secondary"
								variant="contained"
								onClick={handleRouteToManage}
							>
								<CallMissedOutgoingIcon />
								Go to Manage Requisitions
							</Button>
						</DialogActions>
					</div>
				)}
			</Dialog>
		</div>
	);
}
