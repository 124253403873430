import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useContext } from "react";
import { ClassesContext, TeamTeachingContext } from "../../../../../../context/RequisitionContext";
import { Typography } from "@mui/material";

export default function TeamTeachingDialog(props) {
	const { chosenClasses } = useContext(ClassesContext);
	const teamTeachingAgree = useContext(TeamTeachingContext);
	const [, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};

	if (teamTeachingAgree) {
		return (
			<div>
				<Dialog open={props.open} onClose={handleClose} maxWidth="lg" sx={{ textAlign: "center" }}>
					<DialogTitle textAlign={"center"}>{"Team Teaching"}</DialogTitle>

					<DialogContent>
						{chosenClasses.length > 0 ? (
							<DialogContentText>
								Unselecting this option will only allow you to add courses that are taught by a single instructor. If you are sure about this, press "Agree" to continue.
								<Typography color="error" component={"span"}>
									&nbsp;IMPORTANT: You have already selected classes. If you select this option, you will have to reselect your classes.
								</Typography>
							</DialogContentText>
						) : (
							<DialogContentText>
								Unselecting this option will only allow you to add courses that are taught by a single instructor. If you are sure about this, press "Agree" to continue.
							</DialogContentText>
						)}
					</DialogContent>
					<DialogActions>
						<Button color="error" onClick={props.handleDisagree}>
							Disagree
						</Button>
						<Button onClick={props.handleAgree} autoFocus>
							Agree
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	} else {
		return (
			<div>
				<Dialog open={props.open} onClose={handleClose} maxWidth="lg" sx={{ textAlign: "center" }}>
					<DialogTitle textAlign={"center"}>{"Team Teaching"}</DialogTitle>

					<DialogContent>
						{chosenClasses.length > 0 ? (
							<DialogContentText>
								Selecting this option will only allow you to add courses that are taught by multiple instructors. If you are sure about this, press "Agree" to continue.
								<Typography color="error" component={"span"}>
									&nbsp;IMPORTANT: You have already selected classes. If you select this option, you will have to reselect your classes.
								</Typography>
							</DialogContentText>
						) : (
							<DialogContentText>
								Selecting this option will only allow you to add courses that are taught by multiple instructors. If you are sure about this, press "Agree" to continue.
							</DialogContentText>
						)}
					</DialogContent>
					<DialogActions>
						<Button color="error" onClick={props.handleDisagree}>
							Disagree
						</Button>
						<Button onClick={props.handleAgree} autoFocus>
							Agree
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
