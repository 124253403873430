//All imports happen at the top of the file
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import TrackRequisitionsTable from "./components/TrackRequisitionsTable";

export default function TrackRequisitions() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "flex-start",
				width: "100%",
				height: "100vh",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: { xs: "100%", sm: "100%", md: "100%", lg: "95%" },
					justifyContent: "center",
					borderBottom: "none",
					mt: "2rem",
					borderRadius: "5px 5px 0px 0px",
					height: "90vh",
				}}
			>
				<TrackRequisitionsTable />
			</Box>
		</Box>
	);
}
