import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Typography, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import {
	GridFooterContainer,
	GridFooter,
	GridToolbarContainer,
	GridToolbarExport,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState, useEffect, useCallback } from "react";
import PdfGeneration from "../../../components/Utils/PdfGeneration";
import {
	updateRequisitionDates,
	updateTrackToggles,
} from "../../../components/Api/Updates";
import UploadIcon from "@mui/icons-material/Upload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ListSelect from "../../../components/Utils/ListSelect";
import { darken, lighten, styled } from "@mui/material/styles";
import { useMutation, useQueryClient } from "react-query";
import LinearProgress from "@mui/material/LinearProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import { useRequisitionQuery } from "../../../Hooks/useRequisitionQuery";
import isAuthenticated from "../../../components/Utils/isAuthenticated";
import { useTermsQuery } from "../../../Hooks/useTermsQuery";
import Snackbar from "@mui/material/Snackbar";
import TrackReqModal from "./TrackReqModal";
import CircularProgress from "@mui/material/CircularProgress";

const tssList = ["G", "J", "Mg", "Mt", "None"];
const getBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
const getHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
const getSelectedHoverBackgroundColor = (color, mode) =>
	mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	"& .super-app-theme--G": {
		backgroundColor: getBackgroundColor(
			theme.palette.george.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.george.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.george.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.george.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Mg": {
		backgroundColor: getBackgroundColor(
			theme.palette.success.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.success.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.success.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.success.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--Mt": {
		backgroundColor: getBackgroundColor(
			theme.palette.mitra.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.mitra.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.mitra.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.mitra.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .super-app-theme--J": {
		backgroundColor: getBackgroundColor(
			theme.palette.info.main,
			theme.palette.mode
		),
		"&:hover": {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.info.main,
				theme.palette.mode
			),
		},
		"&.Mui-selected": {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.info.main,
				theme.palette.mode
			),
			"&:hover": {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.info.main,
					theme.palette.mode
				),
			},
		},
	},
	"& .MuiDataGrid-overlay": {
		backgroundColor: "rgba(255, 255, 255, 0.7) !important",
	},
}));
export default function TrackRequisitionsTable(props) {
	//add column for notes
	let auth = isAuthenticated();
	const token = auth.token;
	const ccid = auth.ccid;
	const firstName = auth.firstName;
	const [requisitions, setRequisitions] = useState([]);
	const { data: termsList } = useTermsQuery(token);
	const [checkboxSelectionState, setCheckboxSelectionState] = useState([]);
	const [rows, setRows] = useState([]);
	const [changes, setChanges] = useState({});
	const [terms, setTerms] = useState([]);
	const queryClient = useQueryClient();
	const [termValue, setTermValue] = useState(0);
	const { isLoading, isError, data, error, isFetching, status } =
		useRequisitionQuery(token, ccid, termValue, {
			refetchOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			enabled: !!termValue,
		});
	const [filteredRowsLookup, setFilteredRowsLookup] = useState({});
	const [filterKey, setFilterKey] = useState("");
	const [numRows, setNumRows] = useState(0);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [openTrackReqModal, setOpenTrackReqModal] = useState(false);
	const [requisition, setRequisition] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isFetching) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [isFetching]);

	let tsqsUser = "";

	if (firstName === "George") {
		tsqsUser = "G";
	} else if (firstName === "Mitra") {
		tsqsUser = "Mt";
	} else if (firstName === "Johnathan") {
		tsqsUser = "J";
	} else if (firstName === "Margarita") {
		tsqsUser = "Mg";
	}
	useEffect(() => {
		if (data) {
			setRequisitions(data);
		}
	}, [data]);
	useEffect(() => {
		if (termsList) {
			//get max term
			const maxTerm = Math.max(...termsList);
			setTermValue(maxTerm);
			const termList = [];
			termsList.forEach((term) => {
				termList.push(term);
			});
			setTerms(termList);
		}
	}, [termsList]);
	if (requisitions.length > 0 && token) {
		requisitions.forEach((requisition) => {
			if (requisition.requisitionModified === true) {
				const requisitionsCopy = [...requisitions];
				updateRequisitionDates(
					token,
					requisition.reqnum,
					dayjs(requisition.start_date).format("YYYY-MM-DD"),
					dayjs(requisition.end_date).format("YYYY-MM-DD")
				);
				requisitionsCopy.forEach((requisitionCopy) => {
					if (requisitionCopy.reqnum === requisition.reqnum) {
						requisitionCopy.requisitionModified = false;
					}
				});
				setRequisitions(requisitionsCopy);
			}
			requisition.classes.forEach((cls) => {
				requisition.term = cls.term;
			});
			requisition.start_date = dayjs(requisition.start_date).format(
				"YYYY/MM/DD"
			);
			requisition.end_date = dayjs(requisition.end_date).format("YYYY/MM/DD");
		});
	}
	const handlePrintAllSelected = () => {
		let multi = true;
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			printRow(selectedReq, multi);
		});
	};

	const handleMarkAllReqAssigned = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.req_assigned = tsqsUser;
			const date = dayjs().format("YYYY-MM-DD HH:mm:ss");
			selectedReq.req_assigned_date = date;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					req_assigned: tsqsUser,
					req_assigned_date: date,
					reqnum: req,
				},
			}));
		});
	};

	const handleMarkAllRepAssigned = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.rep_assigned = tsqsUser;
			const date = dayjs().format("YYYY-MM-DD HH:mm:ss");
			selectedReq.rep_assigned_date = date;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					rep_assigned: tsqsUser,
					rep_assigned_date: date,
					reqnum: req,
				},
			}));
		});
	};

	const handleToggleDone = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.tss_done = !selectedReq.tss_done;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					tss_done: selectedReq.tss_done,
					reqnum: req,
				},
			}));
		});
	};

	const handleToggleIA = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.tss_ia = !selectedReq.tss_ia;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					tss_ia: selectedReq.tss_ia,
					reqnum: req,
				},
			}));
		});
	};

	const handleToggleSA = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.tss_sa = !selectedReq.tss_sa;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					tss_sa: selectedReq.tss_sa,
					reqnum: req,
				},
			}));
		});
	};

	const handleToggleLoaded = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.loaded = !selectedReq.loaded;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					loaded: selectedReq.loaded,
					reqnum: req,
				},
			}));
		});
	};

	const handleToggleReport = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.report = !selectedReq.report;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					report: selectedReq.report,
					reqnum: req,
				},
			}));
		});
	};

	const handleToggleContEmailed = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.cont_emailed = !selectedReq.cont_emailed;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					cont_emailed: selectedReq.cont_emailed,
					reqnum: req,
				},
			}));
		});
	};

	const handleToggleInstEmailed = () => {
		checkboxSelectionState.forEach((req) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === req;
			});
			const rowIndex = rows.findIndex((r) => r.reqnum === req);
			selectedReq.inst_emailed = !selectedReq.inst_emailed;
			setChanges((prevChanges) => ({
				...prevChanges,
				[rowIndex]: {
					...prevChanges[rowIndex],
					inst_emailed: selectedReq.inst_emailed,
					reqnum: req,
				},
			}));
		});
	};

	useEffect(() => {
		requisitions.forEach((requisition) => {
			let itemListString = "";
			requisition.itemList.forEach((item) => {
				itemListString += item.itemno + ",";
			});
			requisition.itemListString = itemListString.slice(0, -1);
		});
	}, [requisitions]);
	const [initialRows, setInitialRows] = useState([]);
	useEffect(() => {
		const transformProperty = (requisition, property) => {
			requisition[property] = [undefined, null, false, "", "N"].includes(
				requisition[property]
			)
				? false
				: true;
		};
		requisitions.forEach((requisition) => {
			transformProperty(requisition, "loaded");
			transformProperty(requisition, "report");
			transformProperty(requisition, "cont_emailed");
			transformProperty(requisition, "released");
			transformProperty(requisition, "inst_emailed");
			transformProperty(requisition, "tss_done");
			transformProperty(requisition, "tss_ia");
			transformProperty(requisition, "tss_sa");
		});
		setInitialRows([...requisitions]);
	}, [requisitions]);

	const [filteredRows, setFilteredRows] = useState([]);

	useEffect(() => {
		const filteredRows = initialRows
			.filter((row) => {
				if (row.term === termValue) {
					return true;
				} else {
					return false;
				}
			})
			.filter((row) => {
				if (row.classes.some((cls) => cls.cls_rel === "Y")) {
					return true;
				} else {
					return false;
				}
			});
		setFilteredRows(filteredRows);
		setRows(filteredRows);
	}, [termValue, initialRows]);
	useEffect(() => {
		if (requisitions && requisitions.length > 0) {
			requisitions.forEach((requisition) => {
				const fullyReleased = requisition.classes.every(
					(cls) => cls.rel_date !== null && cls.rel_date !== ""
				);
				const partiallyReleased = requisition.classes.some(
					(cls) => cls.rel_date !== null && cls.rel_date !== ""
				);
				const notReleased = requisition.classes.every(
					(cls) => cls.rel_date === null || cls.rel_date === ""
				);
				if (fullyReleased) {
					requisition.grades_released_status = "full";
				}
				if (partiallyReleased) {
					let fakePartial = true;
					requisition.classes.forEach((cls) => {
						if (
							(cls.rel_date === null || cls.rel_date === "") &&
							cls.qtally !== 0
						) {
							fakePartial = false;
						}
					});
					if (fakePartial) {
						requisition.grades_released_status = "full";
					} else {
						requisition.grades_released_status = "partial";
					}
				}
				if (notReleased) {
					requisition.grades_released_status = "none";
				}
			});
		}
	}, [requisitions]);
	const bulkUpdateMutation = useMutation(
		({ token, changes }) => updateTrackToggles({ token, changes }), // replace '/api/endpoint' with your API endpoint
		{
			onSuccess: () => {
				// Invalidate and refetch
				queryClient.invalidateQueries("requisitions");
			},
		}
	);
	const handleSave = async () => {
		await bulkUpdateMutation.mutateAsync({ token, changes });
		// After successful update, refresh rows from the server
		// and clear the changes
		/* fetchRows(); */
		setChanges({});
	};
	const createRenderToggle = (property, toggleHandler) => (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.reqnum === row.reqnum);
		return (
			<strong>
				<Checkbox
					checked={row[property] || false}
					sx={{}}
					onChange={() => toggleHandler(rowIndex)}
					inputProps={{ "aria-label": "controlled" }}
				/>
			</strong>
		);
	};
	const createToggleHandler = (property) => (rowIndex) => {
		const updatedList = [...rows];
		const reqnum = updatedList[rowIndex].reqnum;
		updatedList[rowIndex][property] = !updatedList[rowIndex][property];
		setChanges((prevChanges) => ({
			...prevChanges,
			[rowIndex]: {
				...prevChanges[rowIndex],
				[property]: rows[rowIndex][property],
				reqnum: reqnum,
			},
		}));
	};
	const handleLoadedToggle = createToggleHandler("loaded");
	const handleReportToggle = createToggleHandler("report");
	const handleContEmailedToggle = createToggleHandler("cont_emailed");
	const handleInstEmailedToggle = createToggleHandler("inst_emailed");
	const handleTSSDoneToggle = createToggleHandler("tss_done");
	const handleTSSIAToggle = createToggleHandler("tss_ia");
	const handleTSSSAToggle = createToggleHandler("tss_sa");
	const renderLoadedToggle = createRenderToggle("loaded", handleLoadedToggle);
	const renderReportToggle = createRenderToggle("report", handleReportToggle);
	const renderContEmailedToggle = createRenderToggle(
		"cont_emailed",
		handleContEmailedToggle
	);
	const renderInstEmailedToggle = createRenderToggle(
		"inst_emailed",
		handleInstEmailedToggle
	);
	const renderTSSDoneToggle = createRenderToggle(
		"tss_done",
		handleTSSDoneToggle
	);
	const renderTSSIAToggle = createRenderToggle("tss_ia", handleTSSIAToggle);
	const renderTSSSAToggle = createRenderToggle("tss_sa", handleTSSSAToggle);
	const renderReleasedToggle = (params) => {
		const { row } = params;
		if (row.grades_released_status === "full") {
			return (
				<strong>
					<CheckBoxIcon sx={{ color: "primary.main" }} />
				</strong>
			);
		} else if (row.grades_released_status === "partial") {
			return (
				<strong>
					<CheckBoxIcon sx={{ color: "yellow" }} />
				</strong>
			);
		} else if (row.grades_released_status === "none") {
			return (
				<strong>
					<CheckBoxOutlineBlankIcon sx={{ color: "greys.main" }} />
				</strong>
			);
		}
	};
	const renderHeader = (title, icon) => {
		return (
			<Box sx={{ mt: "10px", ml: "3px" }}>
				<Tooltip title={title} placement="top">
					{icon}
				</Tooltip>
			</Box>
		);
	};
	const renderLoadedHeader = () => {
		return renderHeader("Data Loaded", <UploadIcon />);
	};
	const renderReportHeader = () => {
		return renderHeader("Report Generated", <SummarizeIcon />);
	};
	const renderContEmailedHeader = () => {
		return renderHeader("Contact Emailed", <ContactMailIcon />);
	};
	const renderReleasedHeader = () => {
		return renderHeader("All Classes Released", <ChecklistIcon />);
	};
	const renderInstEmailedHeader = () => {
		return renderHeader("Instructor Email Scheduled", <ScheduleSendIcon />);
	};
	const handleChangeReportAssigned = (event, rowIndex) => {
		const updatedList = [...rows];
		if (event.target.value === "None") {
			updatedList[rowIndex].rep_assigned = null;
		} else {
			updatedList[rowIndex].rep_assigned = event.target.value;
		}
		const reqnum = updatedList[rowIndex].reqnum;
		setChanges((prevChanges) => ({
			...prevChanges,
			[rowIndex]: {
				...prevChanges[rowIndex],
				rep_assigned: rows[rowIndex].rep_assigned,
				reqnum: reqnum,
			},
		}));
	};
	const renderReportAssigned = (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.reqnum === row.reqnum);
		return (
			<div>
				<ListSelect
					list={tssList}
					width="70px"
					size="small"
					marginBottom="0px"
					value={row.rep_assigned || ""}
					onChange={(event) => handleChangeReportAssigned(event, rowIndex)}
				/>
			</div>
		);
	};
	const handleChangeRequisitionAssigned = (event, rowIndex) => {
		const date = dayjs().format("YYYY-MM-DD HH:mm:ss");
		const updatedList = [...rows];
		if (event.target.value === "None") {
			updatedList[rowIndex].req_assigned = null;
			updatedList[rowIndex].req_assigned_date = null;
		} else {
			updatedList[rowIndex].req_assigned = event.target.value;
			updatedList[rowIndex].req_assigned_date = date;
		}
		const reqnum = updatedList[rowIndex].reqnum;
		setChanges((prevChanges) => ({
			...prevChanges,
			[rowIndex]: {
				...prevChanges[rowIndex],
				req_assigned: rows[rowIndex].req_assigned,
				req_assigned_date: rows[rowIndex].req_assigned_date,
				reqnum: reqnum,
			},
		}));
	};
	const renderRequisitionAssigned = (params) => {
		const { row } = params;
		const rowIndex = rows.findIndex((r) => r.reqnum === row.reqnum);
		return (
			<div>
				<ListSelect
					list={tssList}
					size="small"
					width="70px"
					marginBottom="0px"
					value={row.req_assigned || ""}
					onChange={(event) => handleChangeRequisitionAssigned(event, rowIndex)}
				/>
			</div>
		);
	};
	const printRow = (row, multi) => {
		PdfGeneration(row, multi);
	};
	const setStartDate = (params) => {
		const startDateFromRow = params.value;
		const updatedRows = [...requisitions];
		const index = updatedRows.findIndex((r) => r.reqnum === params.row.reqnum);
		updatedRows[index].start_date = dayjs(startDateFromRow);
		updatedRows[index].requisitionModified = true;
		setRequisitions(updatedRows);
		return {
			...params.row,
			start_date: dayjs(startDateFromRow).format("YYYY-MM-DD"),
		};
	};
	const setEndDate = (params) => {
		const endDateFromRow = params.value;
		const updatedRows = [...requisitions];
		const index = updatedRows.findIndex((r) => r.reqnum === params.row.reqnum);
		updatedRows[index].end_date = dayjs(endDateFromRow);
		updatedRows[index].requisitionModified = true;
		setRequisitions(updatedRows);
		return {
			...params.row,
			end_date: dayjs(endDateFromRow).format("YYYY-MM-DD"),
		};
	};
	const handleRefresh = () => {
		queryClient.invalidateQueries("requisitions");
	};
	const renderChanged = (params) => {
		//if any class in params.row.classes has a cls_rel date that is after the params.row.date_assigned, return true
		const { row } = params;
		const changed = row.modified === "Y" ? true : false;
		if (changed) {
			return <Typography color="red"> CHECK </Typography>;
		} else {
			return <Typography> </Typography>;
		}
	};
	const renderNumberClasses = (params) => {
		//if any class in params.row.classes has a cls_rel date that is after the params.row.date_assigned, return true
		const { row } = params;
		const numberOfClasses = row.classes.length;
		const numberOfClassesReleased = row.classes.filter(
			(cls) => cls.cls_rel === "Y"
		).length;
		if (numberOfClassesReleased.length !== numberOfClasses.length) {
			return (
				<Typography color="red">
					{" "}
					{numberOfClasses} / {numberOfClassesReleased}{" "}
				</Typography>
			);
		}
		return (
			<Typography>
				{" "}
				{numberOfClasses} / {numberOfClassesReleased}{" "}
			</Typography>
		);
	};
	const renderNumberClassesReleased = (params) => {
		//if any class in params.row.classes has a cls_rel date that is after the params.row.date_assigned, return true
		const { row } = params;
		const numberOfClassesReleased = row.classes.filter(
			(cls) => cls.cls_rel === "Y"
		).length;
		return <Typography> {numberOfClassesReleased} </Typography>;
	};
	const [trackState, setTrackState] = useState("requisition");
	const handleTrackStateChange = (event) => {
		if (trackState === "requisition") {
			setTrackState("report");
		} else {
			setTrackState("requisition");
		}
	};

	const filterOnlyMeReq = () => {
		const filteredRows = rows.filter((row) => {
			if (row.req_assigned === tsqsUser) {
				return true;
			} else {
				return false;
			}
		});

		setRows(filteredRows);
	};

	const filterOnlyMeRep = () => {
		const filteredRows = rows.filter((row) => {
			if (row.rep_assigned === tsqsUser) {
				return true;
			} else {
				return false;
			}
		});

		setRows(filteredRows);
	};

	const filterNotDoneReq = () => {
		const filteredRows = rows.filter((row) => {
			if (
				row.req_assigned === tsqsUser &&
				(row.tss_done === false || row.tss_ia === false || row.tss_sa === false)
			) {
				return true;
			} else {
				return false;
			}
		});

		setRows(filteredRows);
	};

	const filterNotDoneRep = () => {
		const filteredRows = rows.filter((row) => {
			if (
				row.req_assigned === tsqsUser &&
				(row.loaded === false ||
					row.report === false ||
					row.cont_emailed === false ||
					row.inst_emailed === false)
			) {
				return true;
			} else {
				return false;
			}
		});

		setRows(filteredRows);
	};

	const CustomToolbar = () => {
		return (
			<GridToolbarContainer
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					borderBottom: "1px solid #e0e0e0",
				}}
			>
				<Box sx={{ display: "flex", gap: "10px" }}>
					<ListSelect
						className="termSelect"
						list={terms}
						width="100px"
						size="small"
						marginBottom="0px"
						value={termValue || ""}
						onChange={(event) => setTermValue(event.target.value)}
					/>
					<GridToolbarExport />
				</Box>
				<Box>
					<Button variant="outlined" sx={{}} onClick={handleTrackStateChange}>
						Toggle Requisition/Report View
					</Button>
				</Box>
				<Box sx={{ p: 1 }}>
					<Button
						variant="outlined"
						sx={{ marginLeft: "5px" }}
						onClick={handleRefresh}
					>
						REFRESH
						{loading ? (
							<CircularProgress size={20} sx={{ marginLeft: "9px" }} />
						) : (
							<RefreshIcon sx={{ marginLeft: "5px" }} />
						)}
					</Button>
					<Button
						variant="contained"
						sx={{ marginLeft: "5px" }}
						onClick={handleSave}
					>
						SAVE
						<SaveIcon sx={{ marginLeft: "5px" }} />
					</Button>
				</Box>
			</GridToolbarContainer>
		);
	};

	const clearCheckboxSelection = () => {
		setCheckboxSelectionState([]);
	};

	const clearFilters = () => {
		setRows(filteredRows);
	};

	const generateList = () => {
		let list = [];
		let nodupesList = [];

		// Iterate over the keys of checkboxSelectionState
		checkboxSelectionState.forEach((index) => {
			let selectedReq = requisitions.find((requisition) => {
				return requisition.reqnum === index;
			});
			if (selectedReq) {
				// Push the row data to the list
				list.push(selectedReq.reqnum);
			}
		});

		//remove dupes
		nodupesList = [...new Set(list)];
		const sortedList = nodupesList.sort((a, b) => a.reqnum - b.reqnum);
		//join into string
		list = sortedList.map((row) => JSON.stringify(row)).join(",");

		// Copy list to clipboard
		navigator.clipboard.writeText(list).then(
			function () {
				setSnackbarOpen(true);
			},
			function (err) {
				console.error("Could not copy text: ", err);
			}
		);

		return list;
	};
	const CustomFooter = () => {
		return (
			<GridFooterContainer
				sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}
			>
				<Button
					variant="outlined"
					sx={{ marginLeft: "5px" }}
					onClick={handlePrintAllSelected}
				>
					Download All Selected
				</Button>

				<GridFooter sx={{ position: "absolute", right: "10px" }} />
			</GridFooterContainer>
		);
	};

	const renderTrackModal = (params) => {
		const { row } = params;
		return (
			<Box>
				<Button
					variant="contained"
					onClick={() => {
						setOpenTrackReqModal(true);
						setRequisition(row);
					}}
					sx={{ p: 1, borderY: "1px solid #e0e0e0" }}
				>
					Track
				</Button>
			</Box>
		);
	};

	const columnsRequisitions = [
		{
			field: "changed",
			headerName: "!",
			headerAlign: "center",
			width: 60,
			align: "center",
			renderCell: renderChanged,
		},
		{
			field: "noCls",
			headerName: "#RelCls",
			width: 60,
			align: "center",
			renderCell: renderNumberClasses,
		},

		{
			field: "req_assigned",
			headerName: "Assigned",
			width: 80,
			align: "center",
			renderCell: renderRequisitionAssigned,
			sortComparator: (v1, v2, cellParams1, cellParams2) => {
				const order = ["None", "G", "J", "Mt", "Mg"];
				return (
					order.indexOf(cellParams1.value) - order.indexOf(cellParams2.value)
				);
			},
		},
		{
			field: "tss_done",
			headerName: "Done",
			width: 40,
			align: "center",
			renderCell: renderTSSDoneToggle,
		},
		{
			field: "tss_ia",
			headerName: "IA",
			width: 40,
			headerAlign: "center",
			align: "center",
			renderCell: renderTSSIAToggle,
		},
		{
			field: "tss_sa",
			headerName: "SA",
			width: 40,
			headerAlign: "center",
			align: "center",
			renderCell: renderTSSSAToggle,
		},
		{
			field: "reqnum",
			headerName: "ReqNum",
			width: 70,
		},
		{
			field: "rqdate",
			headerName: "Requested",
			renderCell: (params) => {
				return dayjs(params.row.rqdate).format("DD-MMM-YYYY");
			},
			width: 100,
		},
		{
			field: "userid",
			headerName: "User",
			flex: 0.3,
			width: 120,
		},
		{
			field: "raddr2",
			flex: 0.7,
			headerName: "Department",
			width: 120,
		},
		{
			field: "View",
			headerName: "View",
			width: 80,
			renderCell: renderTrackModal,
		},
		{ field: "term", headerName: "Term", width: 80 },
		{
			field: "start_date",
			headerName: "Start Date",
			type: "date",
			width: 100,
			valueSetter: (params) => setStartDate(params),
			renderCell: (params) => {
				return dayjs(params.row.start_date).format("DD-MMM-YYYY");
			},
			editable: true,
		},
		{
			field: "end_date",
			headerName: "End Date",
			type: "date",
			width: 100,
			valueSetter: (params) => setEndDate(params),
			renderCell: (params) => {
				return dayjs(params.row.end_date).format("DD-MMM-YYYY");
			},
			editable: true,
		},
	];
	const columnsReports = [
		{
			field: "reqnum",
			headerName: "ReqNum",
			width: 70,
		},
		{
			field: "rqdate",
			headerName: "Requested",
			renderCell: (params) => {
				return dayjs(params.row.rqdate).format("DD-MMM-YYYY");
			},
			width: 100,
		},
		{
			field: "userid",
			headerName: "User",
			flex: 0.3,
			width: 120,
		},
		{
			field: "raddr2",
			flex: 0.7,
			headerName: "Department",
			width: 120,
		},

		{ field: "term", headerName: "Term", width: 80 },
		{
			field: "start_date",
			headerName: "Start Date",
			type: "date",
			width: 100,
			valueSetter: (params) => setStartDate(params),
			renderCell: (params) => {
				return dayjs(params.row.start_date).format("DD-MMM-YYYY");
			},
			editable: true,
		},
		{
			field: "end_date",
			headerName: "End Date",
			type: "date",
			width: 100,
			valueSetter: (params) => setEndDate(params),
			renderCell: (params) => {
				return dayjs(params.row.end_date).format("DD-MMM-YYYY");
			},
			editable: true,
		},
		{
			field: "rep_assigned",
			headerName: "Report",
			width: 80,
			align: "center",
			headerAlign: "center",
			renderCell: renderReportAssigned,
			sortComparator: (v1, v2, cellParams1, cellParams2) => {
				const order = ["None", "G", "J", "Mt", "Mg"];
				return (
					order.indexOf(cellParams1.value) - order.indexOf(cellParams2.value)
				);
			},
		},
		{
			field: "loaded",
			headerName: "",
			align: "center",
			width: 20,
			renderCell: renderLoadedToggle,
			renderHeader: renderLoadedHeader,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
		},
		{
			field: "report",
			headerName: "",
			align: "center",
			width: 20,
			renderCell: renderReportToggle,
			renderHeader: renderReportHeader,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
		},
		{
			field: "cont_emailed",
			headerName: "",
			align: "center",
			width: 20,
			renderCell: renderContEmailedToggle,
			renderHeader: renderContEmailedHeader,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
		},
		{
			field: "grades_released_status",
			headerName: "",
			align: "center",
			width: 30,
			renderCell: renderReleasedToggle,
			renderHeader: renderReleasedHeader,
			disableColumnMenu: true,
			sortComparator: (v1, v2) => {
				if (v1 === "full" && v2 !== "full") {
					return -1;
				}
				if (v1 !== "full" && v2 === "full") {
					return 1;
				}
				if (v1 === "partial" && v2 === "none") {
					return -1;
				}
				if (v1 === "none" && v2 === "partial") {
					return 1;
				}
				return 0;
			},
		},
		{
			field: "inst_emailed",
			headerName: "",
			align: "center",
			width: 20,
			renderCell: renderInstEmailedToggle,
			renderHeader: renderInstEmailedHeader,
			/* sortComparator: (v1, v2, cellParams1, cellParams2) => {
				const  */
		},
	];
	const getRowClassName = useCallback((params) => {
		return `super-app-theme--${
			params.row.rep_assigned === "" || params.row.rep_assigned === null
				? params.row.req_assigned
				: params.row.rep_assigned
		}`;
	}, []);
	const handleSelectionModelChange = useCallback((newSelection) => {
		setCheckboxSelectionState(newSelection);
	}, []);
	const slots = {
		Footer: CustomFooter,
		Toolbar: CustomToolbar,
		LoadingOverlay: LinearProgress,
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackbarOpen(false);
	};

	return (
		<div style={{ display: "flex", height: "100%", width: "100%" }}>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={2000}
				onClose={handleCloseSnackbar}
				message="Copied to clipboard!"
			/>
			<TrackReqModal
				open={openTrackReqModal}
				setOpen={setOpenTrackReqModal}
				requisition={requisition}
			/>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Box
					sx={{
						border: "1px solid #d1d1d1 ",
						borderRadius: "4px 1px 1px 4px",
						borderRight: "none",
						width: "220px",
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							width: "100%",
							height: "58px",
							borderBottom: "1px solid #d1d1d1",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography fontSize={18}>Track Requisitions</Typography>
					</Box>
					<Box
						sx={{
							width: "100%",
							borderBottom: "1px solid #d1d1d1",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: "8px",
							paddingY: "20px",
						}}
					>
						{trackState === "requisition" ? (
							<Button
								color="greys"
								sx={{ width: "200px" }}
								variant="contained"
								onClick={filterOnlyMeReq}
							>
								Filter for "{tsqsUser}"
							</Button>
						) : (
							<Button
								color="greys"
								sx={{ width: "200px" }}
								variant="contained"
								onClick={filterOnlyMeRep}
							>
								Filter for "{tsqsUser}"
							</Button>
						)}

						<Button
							variant="contained"
							sx={{ width: "200px" }}
							onClick={generateList}
						>
							Get Reqnum String ({checkboxSelectionState.length})
						</Button>
					</Box>
					{trackState === "requisition" && (
						<Box
							sx={{
								width: "100%",
								borderBottom: "1px solid #d1d1d1",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "8px",
								paddingY: "20px",
							}}
						>
							<Button
								variant="contained"
								sx={{ width: "200px" }}
								color="primary"
								onClick={handleMarkAllReqAssigned}
							>
								Assign {checkboxSelectionState.length} to "{tsqsUser}"
							</Button>
							<Button
								variant="outlined"
								sx={{ width: "200px" }}
								onClick={handleToggleDone}
							>
								Toggle {checkboxSelectionState.length} "Done"
							</Button>

							<Button
								variant="outlined"
								sx={{ width: "200px" }}
								onClick={handleToggleIA}
							>
								Toggle {checkboxSelectionState.length} "IA"
							</Button>
							<Button
								variant="outlined"
								sx={{ width: "200px" }}
								onClick={handleToggleSA}
							>
								Toggle {checkboxSelectionState.length} "SA"
							</Button>
							<Button
								color="greys"
								variant="contained"
								sx={{ width: "200px" }}
								onClick={filterNotDoneReq}
							>
								Filter Incomplete
							</Button>
						</Box>
					)}

					{trackState === "report" && (
						<Box
							sx={{
								width: "100%",
								borderBottom: "1px solid #d1d1d1",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: "8px",
								paddingY: "20px",
							}}
						>
							<Button
								variant="contained"
								sx={{ width: "200px" }}
								color="primary"
								onClick={handleMarkAllRepAssigned}
							>
								Assign {checkboxSelectionState.length} to "{tsqsUser}"
							</Button>
							<Button
								variant="outlined"
								sx={{ width: "200px" }}
								onClick={handleToggleLoaded}
							>
								Toggle {checkboxSelectionState.length} "Loaded"
							</Button>
							<Button
								variant="outlined"
								sx={{ width: "200px" }}
								onClick={handleToggleReport}
							>
								Toggle {checkboxSelectionState.length} "Report"
							</Button>
							<Button
								variant="outlined"
								sx={{ width: "200px" }}
								onClick={handleToggleContEmailed}
							>
								Toggle {checkboxSelectionState.length} "Cont Emailed"
							</Button>
							<Button
								variant="outlined"
								sx={{ width: "200px" }}
								onClick={handleToggleInstEmailed}
							>
								Toggle {checkboxSelectionState.length} "Inst Emailed"
							</Button>
							<Button
								color="greys"
								variant="contained"
								sx={{ width: "200px" }}
								onClick={filterNotDoneRep}
							>
								Filter Incomplete
							</Button>
						</Box>
					)}

					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: "8px",
							paddingY: "20px",
						}}
					>
						<Button
							variant="contained"
							color="error"
							sx={{ width: "200px" }}
							onClick={clearCheckboxSelection}
						>
							Clear Checkbox Selection
						</Button>
						<Button
							variant="contained"
							color="error"
							sx={{ width: "200px" }}
							onClick={clearFilters}
						>
							Clear Filters
						</Button>
					</Box>
				</Box>
				<StyledDataGrid
					loading={loading}
					key={filterKey}
					sx={{
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "None !important",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
							width: "0.4em",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
							background: "#f1f1f1",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
							backgroundColor: "#888",
						},
						"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
							background: "#555",
						},

						borderRadius: "0px 4px 4px 0px",
					}}
					className="requisitions-table"
					autoPageSize
					onStateChange={(e) => {
						setFilteredRowsLookup(e.filter.filteredRowsLookup);
						setNumRows(e.pagination.rowCount);
					}}
					disableSelectionOnClick
					checkboxSelection
					getRowClassName={getRowClassName}
					onSelectionModelChange={handleSelectionModelChange}
					initialState={{
						sorting: {
							sortModel: [{ field: "start_date", sort: "asc" }],
						},
					}}
					components={slots}
					rows={rows}
					density="compact"
					columns={
						trackState === "requisition" ? columnsRequisitions : columnsReports
					}
					getRowId={(row) => row.reqnum}
				/>
			</Box>
		</div>
	);
}
