import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import TextInput from "../../components/Utils/TextInput";
import { useState, useEffect } from "react";
import ListSelect from "../../components/Utils/ListSelect";
import { RequisitionCalendar } from "../../components/Utils/RequisitionCalendar";
import {
	getFacultyRankByCCID,
	getClassIdsByFetchRolesByEmpId,
	getTerms,
} from "../../components/Api/Getters";
import { Typography, Button } from "@mui/material";
import CustomQuestions from "./components/CustomQuestions";
import dayjs from "dayjs";
import { postMidtermEvals } from "../../components/Api/Post";
import ExamplesModal from "./components/ExamplesModal";
import { useNavigate } from "react-router-dom";
import isAuthenticated from "../../components/Utils/isAuthenticated";

export default function MidtermRequest() {
	const auth = isAuthenticated();
	const [evaluationState, setEvaluationState] = useState({
		name: "",
		email: "",
		courses: [],
		startDate: "",
		endDate: "",
		questionSet: "",
		questions: [],
		teamTeaching: "",
		teamTeachingAll: "",
	});

	const ccid = auth.ccid;
	const firstName = auth.firstName;
	const lastName = auth.lastName;
	const token = auth.token;
	const [empId, setEmpId] = useState("");
	const [classIds, setClassIds] = useState([]);

	const [openCustomQuestion, setOpenCustomQuestion] = useState(true);

	const [questionPoints, setQuestionPoints] = useState([]);
	const navigate = useNavigate();
	const [term, setTerm] = useState("");

	const [windowStart, setWindowStart] = useState("");
	const [windowEnd, setWindowEnd] = useState("");

	const calcTermWindow = (termStart) => {
		const windowStartDate = termStart.add(5, "week");
		const windowEndDate = windowStartDate.add(4, "day");
		if (windowStartDate.isBefore(dayjs())) {
			const today = dayjs();
			const fourDaysFromNow = today.add(4, "day");
			setWindowStart(today);
			setWindowEnd(fourDaysFromNow);
			setEvaluationState({
				...evaluationState,
				name: `${firstName} ${lastName}`,
				email: `${ccid.concat("@ualberta.ca")}`,
				startDate: today,
				endDate: fourDaysFromNow,
			});
		} else {
			setWindowStart(windowStartDate);
			setWindowEnd(windowEndDate);
			setEvaluationState({
				...evaluationState,
				name: `${firstName} ${lastName}`,
				email: `${ccid.concat("@ualberta.ca")}`,
				startDate: windowStart,
				endDate: windowEnd,
			});
		}
	};

	useEffect(() => {
		async function fetchTerms() {
			const terms = await getTerms(token);
			if (terms && terms.length > 0) {
				//get maximum term
				const maxTerm = terms.reduce((prev, current) =>
					prev.term > current.term ? prev : current
				);
				const termStart = dayjs(maxTerm.s_date);
				const termDescription = maxTerm.desc_term;
				const termSeason = termDescription.split(" ")[0];
				if (termSeason === "Summer" || termSeason === "Spring") {
					alert(
						"Midterm evaluations are only offered in Fall and Winter terms. Please contact TSS with any further questions."
					);
					navigate("/");
				} else {
					calcTermWindow(termStart);
				}
				setTerm(maxTerm.term);
			}
		}
		fetchTerms();
	}, []);

	useEffect(() => {
		async function fetchFacultyRank() {
			const facultyRank = await getFacultyRankByCCID(token, ccid);
			if (facultyRank.length === 0) {
				/* console.log("Cannot find instructor. Please contact TSS for assistance."); */
				alert("Cannot find instructor. Please contact TSS for assistance.");
				/* navigate("/"); */
			} else {
				const employeeId = facultyRank[0].empid;
				setEmpId(employeeId);
			}
		}
		fetchFacultyRank();
	}, []);

	useEffect(() => {
		if (empId.length > 0) {
			async function fetchClassesByEmpId() {
				const classIds = await getClassIdsByFetchRolesByEmpId(token, empId);
				if (classIds.length === 0) {
					/* console.log("Cannot find any assigned classes. Please contact TSS for assistance."); */
					alert(
						"Cannot find any assigned classes. Please contact TSS for assistance."
					);
					/* navigate("/"); */
				} else {
					const classObj = classIds.map((classId) => {
						if (classId.csize > 0) {
							return classId.ctitle + ":" + classId.classid;
						} else {
							return null;
						}
					});
					setClassIds(classObj);
				}
			}
			fetchClassesByEmpId();
		}
	}, [empId]);

	const handleChange = (event) => {
		setEvaluationState({
			...evaluationState,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let coursesList = [];
		evaluationState.courses.forEach((course) => {
			coursesList.push(course.split(":")[1]);
		});
		//turn courseslist into a string
		coursesList = coursesList.join(",");
		let teamTeachingAll = null;
		if (evaluationState.teamTeaching === "Yes") {
			teamTeachingAll = evaluationState.teamTeachingAll.split(",")[0];
		} else {
			teamTeachingAll = "No";
		}

		if (evaluationState.questionSet.split(" ")[0] === "Custom") {
			let combinedQuestionScaleList = [];
			Object.keys(questionPoints).forEach((questionPoint) => {
				let questionPointText = questionPoints[questionPoint].text;
				questionPointText = questionPointText.replace(/;/g, "");
				questionPointText = questionPointText.replace(/=/g, "");
				questionPointText = questionPointText.replace(/}/g, "");
				questionPointText = questionPointText.replace(/{/g, "");
				Object.values(questionPoints[questionPoint].scale).forEach(
					(scalePoint) => {
						scalePoint = scalePoint.replace(/;/g, "");
						scalePoint = scalePoint.replace(/=/g, "");
						scalePoint = scalePoint.replace(/}/g, "");
						scalePoint = scalePoint.replace(/{/g, "");
					}
				);
				let scalepoints = Object.values(
					questionPoints[questionPoint].scale
				).join(";");

				if (
					scalepoints.length === 0 ||
					scalepoints === undefined ||
					scalepoints === null ||
					scalepoints === "" ||
					scalepoints === ";"
				) {
					scalepoints = "1;2;3;4;5";
					combinedQuestionScaleList.push(questionPointText + "=" + scalepoints);
				} else {
					combinedQuestionScaleList.push(questionPointText + "=" + scalepoints);
				}
			});

			combinedQuestionScaleList = combinedQuestionScaleList.join("+");

			const evaluation = {
				instructor_name: evaluationState.name,
				instructor_email: evaluationState.email,
				courses: coursesList,
				start_date: evaluationState.startDate.format("YYYY-MM-DD"),
				end_date: evaluationState.endDate.format("YYYY-MM-DD"),
				question_set: evaluationState.questionSet.split(" ")[0],
				team_teaching: teamTeachingAll,
				custom_questions: combinedQuestionScaleList,
				rqdate: dayjs().format("YYYY-MM-DD"),
				term: term,
			};
			if (
				evaluation.instructor_name !== "" &&
				evaluation.instructor_email !== "" &&
				evaluation.courses !== "" &&
				evaluation.start_date !== "" &&
				evaluation.end_date !== "" &&
				evaluation.question_set !== "" &&
				evaluation.custom_questions !== "" &&
				evaluation.team_teaching !== ""
			) {
				const response = await postMidtermEvals(token, evaluation);
				if (response.status === 201) {
					alert("Successfully submitted evaluation.");
					setEvaluationState({
						name: "",
						email: "",
						courses: [],
						startDate: windowStart,
						endDate: windowEnd,
						questionSet: "",
						questions: [],
						teamTeaching: "",
						teamTeachingAll: "",
					});
					setQuestionPoints([]);
					setOpenCustomQuestion(true);
				} else {
					alert("Something went wrong.");
				}
			} else {
				alert("Please fill out all required fields.");
			}
		} else {
			const evaluation = {
				instructor_name: evaluationState.name,
				instructor_email: evaluationState.email,
				courses: coursesList,
				start_date: evaluationState.startDate.format("YYYY-MM-DD"),
				end_date: evaluationState.endDate.format("YYYY-MM-DD"),
				question_set: evaluationState.questionSet,
				team_teaching: teamTeachingAll,
				rqdate: dayjs().format("YYYY-MM-DD"),
				term: term,
			};
			if (
				evaluation.instructor_name !== "" &&
				evaluation.instructor_email !== "" &&
				evaluation.courses !== "" &&
				evaluation.start_date !== "" &&
				evaluation.end_date !== "" &&
				evaluation.question_set !== "" &&
				evaluation.team_teaching !== ""
			) {
				const response = await postMidtermEvals(token, evaluation);
				if (response.status === 201) {
					alert("Successfully submitted evaluation.");
					setEvaluationState({
						name: "",
						email: "",
						courses: [],
						startDate: dayjs("October 10 2023"),
						endDate: dayjs("October 16 2023"),
						questionSet: "",
						questions: [],
						teamTeaching: "",
						teamTeachingAll: "",
					});
				} else {
					alert("Something went wrong.");
				}
			} else {
				alert("Please fill out all required fields.");
			}
		}
	};

	return (
		<Grid
			container
			spacing={2}
			sx={{
				/* outline: "1px solid red", */ height: "100dvh",
			}}
			direction={"column"}
			alignItems="center"
		>
			<Grid
				container
				spacing={2}
				direction={"column"}
				justifyContent="center"
				alignItems="center"
				gap={2}
			>
				<Grid xs={12} sx={{ py: 4 }}>
					<h1>Mid-term Course and Instruction Feedback</h1>
				</Grid>
			</Grid>
			<Grid
				container
				spacing={2}
				direction={"row"}
				justifyContent="center"
				alignItems="center"
			>
				<Grid
					container
					spacing={2}
					sx={{
						width: "550px",
					}}
					direction={"column"}
				>
					<Grid xs={12}>
						<TextInput
							autoComplete="on"
							required={true}
							name="name"
							label="Name"
							value={evaluationState.name}
							onChange={handleChange}
							width="500px"
						/>
						<TextInput
							autoComplete="on"
							required={true}
							name="email"
							label="Email"
							value={evaluationState.email}
							onChange={handleChange}
							width="500px"
						/>
						<ListSelect
							required={true}
							name="courses"
							label="Courses (You can select multiple)"
							value={evaluationState.courses}
							onChange={handleChange}
							list={classIds}
							multiple={true}
							width="500px"
						/>
						<RequisitionCalendar
							name="startDate"
							label="Start Date* (suggested date set)"
							minDate={dayjs()}
							value={evaluationState.startDate}
							onChange={(newValue) =>
								setEvaluationState({ ...evaluationState, startDate: newValue })
							}
						/>
						<RequisitionCalendar
							name="endDate"
							label="End Date* (suggested date set)"
							value={evaluationState.endDate}
							onChange={(newValue) =>
								setEvaluationState({ ...evaluationState, endDate: newValue })
							}
							marginLeft="10px"
						/>
						<ListSelect
							required={true}
							name="teamTeaching"
							label="Are any courses Team Taught?"
							value={evaluationState.teamTeaching}
							onChange={handleChange}
							list={["Yes", "No"]}
							width="500px"
							marginTop="20px"
						/>
						{evaluationState.teamTeaching === "Yes" && (
							<ListSelect
								required={true}
								name="teamTeachingAll"
								label="Do you want ALL instructors to receive the evaluation?"
								value={evaluationState.teamTeachingAll}
								onChange={handleChange}
								list={[
									"Yes, all of them (in every applicable course).",
									"No, only evaluate me.",
								]}
								width="500px"
							/>
						)}
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					sx={{
						width: "800px",
					}}
					direction={"column"}
				>
					<Grid xs={12}>
						<ExamplesModal />
					</Grid>
					<Grid xs={12}>
						<ListSelect
							name="questionSet"
							label="Question Set (Select one to see questions)"
							value={evaluationState.questionSet}
							onChange={handleChange}
							list={[
								"Suggested Questions",
								"Start-Stop-Continue",
								"Three Open Questions",
								"One Question",
								"Custom (If you do not set a custom scale, the default 1-5 likert scale will be applied)",
							]}
							width="800px"
						/>
						<Grid xs={12} sx={{}}>
							{evaluationState.questionSet === "Suggested Questions" && (
								<Typography sx={{ maxWidth: "800px" }}>
									1. The instructor is helpful in answering questions. <br /> 2.
									Class time is used effectively.
									<br /> 3. The classroom is a comfortable learning environment.
									<br /> 4. The instructor presents the material in an
									interesting and helpful manner. <br />
									5. The instructor gives clear explanations. <br />
									6. The instructor stresses important points in lectures or
									discussions. <br />
									7. How can the instructor improve the teaching of this course?{" "}
									<br />
									8. Which aspects of the course do you like the best? <br />
									9. Which aspects of the course do you like the least? <br />
									10. Additional comments for the instructor?
								</Typography>
							)}
							{evaluationState.questionSet === "Start-Stop-Continue" && (
								<Typography sx={{ maxWidth: "800px" }}>
									1. What should I (the instructor) start doing? <br />
									2. What should I (the instructor) stop doing? <br />
									3. What should I (the instructor) continue doing?
								</Typography>
							)}
							{evaluationState.questionSet === "Three Open Questions" && (
								<Typography sx={{ maxWidth: "800px" }}>
									1. Which aspects of the course do you like the best? <br />
									2. Which aspects of the course do you like the least?
									<br />
									3. How can the instructor improve the teaching of this course?
								</Typography>
							)}
							{evaluationState.questionSet === "One Question" && (
								<Typography sx={{ maxWidth: "800px" }}>
									Please share any feedback on how the course is going so far.
								</Typography>
							)}
							{evaluationState.questionSet.split(" ")[0] === "Custom" &&
							openCustomQuestion === true ? (
								<Grid xs={12}>
									<CustomQuestions
										questionPoints={questionPoints}
										setQuestionPoints={setQuestionPoints}
									/>
									<Button
										onClick={() => setOpenCustomQuestion(false)}
										variant="contained"
										disabled={
											questionPoints.length === 0 ||
											typeof questionPoints[1] === "undefined"
										}
										sx={{ width: "100%", marginTop: "10px" }}
									>
										Finish and Review Custom Questions
									</Button>
								</Grid>
							) : null}
							{evaluationState.questionSet.split(" ")[0] === "Custom" &&
							openCustomQuestion === false ? (
								<Grid xs={12}>
									<Typography component="div">
										{Object.keys(questionPoints).map((questionPoint) => {
											return (
												<div key={questionPoint}>
													{questionPoint}. {questionPoints[questionPoint].text}{" "}
													<br />
													{questionPoints[questionPoint].customScale
														? "Scale: " +
														  Object.values(
																questionPoints[questionPoint].scale
														  ).join(";")
														: "Scale: Default"}
												</div>
											);
										})}
									</Typography>
									<Button
										onClick={() => setOpenCustomQuestion(true)}
										variant="contained"
										sx={{ width: "100%", marginTop: "10px" }}
									>
										Edit Custom Questions
									</Button>
								</Grid>
							) : null}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Button
				variant="contained"
				sx={{ width: "500px" }}
				onClick={handleSubmit}
				disabled={
					evaluationState.questionSet.split(" ")[0] === "Custom"
						? typeof questionPoints[1] === "undefined" ||
						  Object.values(questionPoints).some(
								(questionPoint) => questionPoint.text === ""
						  ) ||
						  evaluationState.name === "" ||
						  evaluationState.email === "" ||
						  evaluationState.courses.length === 0 ||
						  evaluationState.startDate === "" ||
						  evaluationState.endDate === "" ||
						  evaluationState.questionSet === "" ||
						  evaluationState.teamTeaching === ""
						: evaluationState.name === "" ||
						  evaluationState.email === "" ||
						  evaluationState.courses.length === 0 ||
						  evaluationState.startDate === "" ||
						  evaluationState.endDate === "" ||
						  evaluationState.questionSet === "" ||
						  evaluationState.teamTeaching === ""
				}
			>
				Submit
			</Button>
		</Grid>
	);
}
