import { useQuery } from "react-query";
import { getItemCategories } from "../components/Api/Getters";

export const useItemCategoriesQuery = (token, options = {}) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 1000 * 5,
		...options, //
	};
	return useQuery(["itemCategories", token], () => getItemCategories(token), queryOptions);
};
