import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmDeleteRequisition(props) {
	const [, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} maxWidth="md" sx={{ textAlign: "center" }}>
				<DialogTitle textAlign={"center"}>{"Confirm Deletion"}</DialogTitle>

				<DialogContent>
					<DialogContentText>Are you sure you would like to delete requisition #{props.row.reqnum}? This action cannot be undone.</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="error" onClick={props.handleDeleteDisagree}>
						Disagree
					</Button>
					<Button onClick={props.handleDeleteAgree} autoFocus>
						Agree
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
