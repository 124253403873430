import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent, ImageList, Radio } from "@mui/material";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Typography, Checkbox } from "@mui/material";
import TextInput from "../../../../components/Utils/TextInput";

export default function InformationalDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				maxWidth="1200px"
			>
				<DialogTitle textAlign={"left"}>Instructions</DialogTitle>

				<>
					<DialogContent
						sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
					>
						<p>
							The time for requisitions has come to an end! Please read below
							for instructions on this new process.
						</p>
						<p className="  max-w-[1200px] ">
							On this screen you will see a list of all of the course belonging
							to your department. By default,{" "}
							<span className="font-bold">no courses</span> will be submitted
							for evaluations.
						</p>
						<p className="  max-w-[1200px] ">
							To submit the courses for evaluation, there are a few options.
						</p>

						<ol className="  max-w-[1200px]  gap-2 flex flex-col ml-10">
							<li>
								1. If the course requires <span className="font-bold">any</span>{" "}
								modifications (instructor modifications, date ranges, the chosen
								question block, combining), select the course from the table and
								it will appear in the left window. From here, you can make the
								appropriate changes. When you are satisfied with the course,
								click "Save" at the top of the left window.{" "}
								<span className="underline font-semibold">
									Saving the course with any modifications will automatically
									submit the course for evaluation.
								</span>
								<ul className="ml-10">
									<li>
										- You can still edit the course after it has been submitted,
										but not if the survey is already running for that course.
									</li>
									<li>
										- You cannot edit multiple courses at once. Please save and
										submit each course before attempting to edit another.
									</li>
								</ul>
							</li>
							<li>
								2. If the course{" "}
								<span className="underline font-semibold">
									does not require any modifications
								</span>
								, you can simply click the checkbox in the "Evaluate" column of
								the table. This will submit the course for evaluation.
							</li>
							<li>
								3. If you are sure that no additional changes are required for
								any courses within your department, you can click the "Submit
								All Suitable Courses" button to submit any courses that have not
								yet been submitted for an evaluation.
								<p className="">
									<span className="underline font-semibold">
										Suitable courses
									</span>{" "}
									are courses that:
								</p>
								<ul className="ml-10">
									<li>- Have at least one assigned instructor.</li>
									<li>- Have a student enrollment of 4 or more.</li>
									<li>
										- Have a question block assigned (this program takes an
										educated guess at which question block a course should have
										and initially assigns it automatically. In the event that
										there is no question block assigned or the wrong one is
										assigned, please modify the course and assign the correct
										block).
									</li>
								</ul>
							</li>
						</ol>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose}>Close</Button>
					</DialogActions>
				</>
			</Dialog>
		</div>
	);
}
