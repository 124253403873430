import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export default function RolesList(props) {
	return (
		<Box sx={{ flexGrow: 1, maxWidth: 752 }}>
			<Box sx={{ backgroundColor: "greys.light" }}>
				<Typography sx={{ mx: 1 }} variant="h6" component="div">
					Instructors associated with the course:
				</Typography>

				<List dense={true}>
					{props.list &&
						props.list.map((option) => (
							<ListItem key={option.key}>
								<ListItemText
									primary={option.name + " - " + option.role /* + "," + option.empid */}
									secondary={false}
								/>
							</ListItem>
						))}
				</List>
			</Box>
		</Box>
	);
}
