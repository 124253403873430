import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function ToggleButtonsAddItem(props) {
	return (
		<ToggleButtonGroup
			value={props.alignment}
			exclusive
			onChange={props.handleAlignment}
			aria-label="text alignment"
			sx={{ marginLeft: "10px" }}
		>
			<ToggleButton value="yes">Yes</ToggleButton>
			<ToggleButton value="no">No</ToggleButton>
		</ToggleButtonGroup>
	);
}
