import * as React from "react";
import { Box, Typography } from "@mui/material";

export default function TableHeader(props) {
	const allClasses = props.classIds.length;
	const emptyClasses = props.classIds.filter((classId) => classId.empty).length;
	const webClasses = props.webClasses.length;
	const tooSmallClasses = props.classIds.filter(
		(classId) => classId.tooSmall
	).length;
	const excludedClasses = props.goodClassIds.filter(
		(classId) => classId.exclude_class === true || classId.exclude_class === "Y"
	).length;
	const noEndDates = props.noEndDates.length;
	const noInstructor = props.classIds.filter(
		(classId) => classId.noInstructor && !classId.empty && !classId.tooSmall
	).length;
	const removedForCombine = props.removedForCombine
		? props.removedForCombine.length
		: 0;

	const difference =
		allClasses -
		emptyClasses -
		webClasses -
		tooSmallClasses -
		excludedClasses -
		noEndDates -
		noInstructor -
		removedForCombine;

	const suitableClasses = props.suitableClassIds.filter(
		(classId) => !classId.beenCombined
	).length;

	return (
		<Box
			sx={{
				backgroundColor: "#E0E0E0",
				width: "100%",
				border: "1px solid #E0E0E0",
				borderRadius: "5px 5px 5px 5px",
			}}
		>
			<Box sx={{ display: "flex", gap: "3px" }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						flex: 1,
						backgroundColor: "greysDark.main",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography
							variant="body"
							fontSize={16}
						>
							All Classes In Department:
						</Typography>

						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{props.classIds.length}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography
							variant="body"
							fontSize={16}
						>
							0 enrollment:
						</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{props.classIds.filter((classId) => classId.empty).length}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography
							variant="body"
							fontSize={16}
						>
							Under 4 Enrolled:
						</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{props.classIds.filter((classId) => classId.tooSmall).length}
						</Typography>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography
							variant="body"
							fontSize={16}
						>
							In Requisition:
						</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{props.webClasses.length}
						</Typography>
					</Box>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						flex: 1,
						backgroundColor: "#e84a57",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography
							variant="body"
							sx={{ display: "flex" }}
						>
							Excluded:
						</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{
								props.goodClassIds.filter(
									(classId) =>
										classId.exclude_class === true ||
										classId.exclude_class === "Y"
								).length
							}
						</Typography>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography
							variant="body"
							sx={{ display: "flex" }}
						>
							No End Date:
						</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{props.noEndDates.length}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography variant="body">No Instructor:</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{
								props.classIds.filter(
									(classId) =>
										classId.noInstructor && !classId.empty && !classId.tooSmall
								).length
							}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography variant="body">Removed for Combine:</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{props.removedForCombine && props.removedForCombine.length}
						</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						backgroundColor: "#9DC183",
						flex: 1,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography variant="body">
							Suitable for Auto-Generation:
						</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{
								props.suitableClassIds.filter(
									(classId) =>
										!classId.beenCombined &&
										!classId.noInstructor &&
										!classId.empty &&
										!classId.tooSmall &&
										!classId.exclude_class &&
										!classId.noEndDate
								).length
							}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography variant="body">Combined:</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{
								props.suitableClassIds.filter((classId) => classId.beenCombined)
									.length
							}
						</Typography>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							p: 0.5,
						}}
					>
						<Typography variant="body">
							SELECTED for Auto-Generation:
						</Typography>
						<Typography
							variant="body"
							fontSize={18}
							fontWeight={600}
						>
							{props.stagedClasses}
						</Typography>
					</Box>
					{props.groupClasses && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								p: 0.5,
							}}
						>
							<Typography variant="body">IGNORED (not excluded):</Typography>
							<Typography
								variant="body"
								fontSize={18}
								fontWeight={600}
							>
								{props.numberOfIgnoredSections}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					width: "100%",
					height: "30px",
					backgroundColor: "white",
					marginTop: "2px",
					display: "flex",
				}}
			>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					sx={{ display: "flex", justifyContent: "center" }}
				>
					({props.classIds.length})
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					color="red"
					sx={{ display: "flex", justifyContent: "center" }}
				>
					-
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					sx={{ display: "flex", justifyContent: "center" }}
				>
					({props.classIds.filter((classId) => classId.empty).length})
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					color="red"
					sx={{ display: "flex", justifyContent: "center" }}
				>
					-
				</Typography>

				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					sx={{ display: "flex", justifyContent: "center" }}
				>
					({props.classIds.filter((classId) => classId.tooSmall).length})
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					color="red"
					sx={{ display: "flex", justifyContent: "center" }}
				>
					-
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					sx={{ display: "flex", justifyContent: "center" }}
				>
					({props.webClasses.length})
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					color="red"
					sx={{ display: "flex", justifyContent: "center" }}
				>
					-
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					sx={{ display: "flex", justifyContent: "center" }}
				>
					(
					{
						props.goodClassIds.filter(
							(classId) =>
								classId.exclude_class === true || classId.exclude_class === "Y"
						).length
					}
					)
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					color="red"
					sx={{ display: "flex", justifyContent: "center" }}
				>
					-
				</Typography>

				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					sx={{ display: "flex", justifyContent: "center" }}
				>
					({props.noEndDates.length})
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					color="red"
					sx={{ display: "flex", justifyContent: "center" }}
				>
					-
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					sx={{ display: "flex", justifyContent: "center" }}
				>
					(
					{
						props.classIds.filter(
							(classId) =>
								classId.noInstructor && !classId.empty && !classId.tooSmall
						).length
					}
					)
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					color="red"
					sx={{ display: "flex", justifyContent: "center" }}
				>
					-
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					sx={{ display: "flex", justifyContent: "center" }}
				>
					({props.removedForCombine && props.removedForCombine.length})
				</Typography>
				<Typography
					variant="body"
					fontSize={18}
					fontWeight={600}
					color="black"
					sx={{ display: "flex", justifyContent: "center" }}
				>
					=
				</Typography>
				{difference === suitableClasses ? (
					<Typography
						variant="body"
						fontSize={18}
						fontWeight={600}
						color="green"
						sx={{ display: "flex", justifyContent: "center" }}
					>
						{difference}
					</Typography>
				) : (
					<Typography
						variant="body"
						fontSize={18}
						fontWeight={600}
						color="red"
						sx={{ display: "flex", justifyContent: "center" }}
					>
						{difference}
					</Typography>
				)}
				<Typography
					variant="body"
					fontSize={16}
					sx={{ display: "flex", alignSelf: "baseline" }}
				>
					{"<-"} This number should match the "Suitable" classes in the top
					right. (Unless you exclude a combined class)
				</Typography>
			</Box>
		</Box>
	);
}
