import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent, ImageList, Radio } from "@mui/material";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	RadioGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Typography, Checkbox } from "@mui/material";
import TextInput from "../../../../components/Utils/TextInput";
import { DatePickerComponent } from "./DatePickerComponent";
import dayjs from "dayjs";

export default function BatchUpdateDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};

	const [batchSelectedCourses, setBatchSelectedCourses] = useState([]);
	const [batchEndDate, setBatchEndDate] = useState("");
	const [batchExamDate, setBatchExamDate] = useState("");
	const [batchSurveyStartDate, setBatchSurveyStartDate] = useState("");
	const [batchSurveyEndDate, setBatchSurveyEndDate] = useState("");

	const [courseEndChecked, setCourseEndChecked] = useState(false);
	const [examDateChecked, setExamDateChecked] = useState(false);
	const [surveyStartChecked, setSurveyStartChecked] = useState(true);
	const [surveyEndChecked, setSurveyEndChecked] = useState(true);

	const mostCommon = (arr) => {
		return arr.sort(
			(a, b) =>
				arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
		)[arr.length - 1];
	};

	useEffect(() => {
		//using customCheckboxSelection which has classid and props.rows, filter for selectedCourse

		const selectedCourses = props.rows.filter((row) =>
			props.customCheckboxSelection.includes(row.classid)
		);
		setBatchSelectedCourses(selectedCourses);

		//find the most common c_edate in selectedCourses
		const mostCommonEndDate = mostCommon(
			selectedCourses.map((course) => course.c_edate)
		);
		setBatchEndDate(mostCommonEndDate);

		//find the most common examdate in selectedCourses
		const mostCommonExamDate = mostCommon(
			selectedCourses.map((course) => course.examdate)
		);
		setBatchExamDate(mostCommonExamDate);

		//find the most common survey_start in selectedCourses
		const mostCommonSurveyStartDate = mostCommon(
			selectedCourses.map((course) => course.survey_start)
		);
		setBatchSurveyStartDate(mostCommonSurveyStartDate);

		//find the most common survey_end in selectedCourses

		const mostCommonSurveyEndDate = mostCommon(
			selectedCourses.map((course) => course.survey_end)
		);
		setBatchSurveyEndDate(mostCommonSurveyEndDate);
	}, [props.customCheckboxSelection, props.rows]);

	const handleDateChangeEndDate = (date) => {
		setBatchEndDate(date);
	};

	const handleDateChangeExamDate = (date) => {
		setBatchExamDate(date);
	};

	const handleDateChangeSurveyStart = (date) => {
		setBatchSurveyStartDate(date);
	};

	const handleDateChangeSurveyEnd = (date) => {
		setBatchSurveyEndDate(date);
	};

	const handleChangeCourseEndChecked = () => {
		setCourseEndChecked((prev) => !prev);
	};

	const handleChangeExamDateChecked = () => {
		setExamDateChecked((prev) => !prev);
	};

	const handleChangeSurveyStartChecked = () => {
		setSurveyStartChecked((prev) => !prev);
	};

	const handleChangeSurveyEndChecked = () => {
		setSurveyEndChecked((prev) => !prev);
	};

	const handleConfirm = async () => {
		const updatedRows = props.rows.map((row) => {
			// Check if this row is selected based on customCheckboxSelection
			if (props.customCheckboxSelection.includes(row.classid)) {
				// Update the necessary fields based on the checkbox state
				return {
					...row,
					c_edate: courseEndChecked
						? dayjs(batchEndDate).format("YYYY-MM-DD")
						: row.c_edate,
					examdate: examDateChecked
						? dayjs(batchExamDate).format("YYYY-MM-DD")
						: row.examdate,
					survey_start: surveyStartChecked
						? dayjs(batchSurveyStartDate).format("YYYY-MM-DD")
						: row.survey_start,
					survey_end: surveyEndChecked
						? dayjs(batchSurveyEndDate).format("YYYY-MM-DD")
						: row.survey_end,
				};
			}
			// Return the original row if it is not selected
			return row;
		});

		// Assuming you have a prop function to set the updated rows
		props.setRows(updatedRows);
		props.setBatchUpdated(true);
		props.setUnsavedChanges(true);

		props.setOpen(false);
	};

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				maxWidth="600px"
			>
				<DialogTitle textAlign={"left"}>
					Update Dates for {batchSelectedCourses.length} Courses
				</DialogTitle>

				<>
					<DialogContent
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
							width: "100%",
						}}
					>
						<p className="max-w-[600px]">
							Set the course end, survey start, or survey end date for all
							selected courses. If you do not want to update a specific date,
							leave the checkbox unselected.
						</p>
						<div className="flex gap-4 mx-auto">
							<div className="flex flex-col ">
								<p>Course End</p>
								<Checkbox
									checked={courseEndChecked}
									onClick={handleChangeCourseEndChecked}
								/>
							</div>
							<div className="flex flex-col ">
								<p>Exam Date</p>
								<Checkbox
									checked={examDateChecked}
									onClick={handleChangeExamDateChecked}
								/>
							</div>
							<div className="flex flex-col ">
								<p>Survey Start</p>
								<Checkbox
									checked={surveyStartChecked}
									onClick={handleChangeSurveyStartChecked}
								/>
							</div>
							<div className="flex flex-col ">
								<p>Survey End</p>
								<Checkbox
									checked={surveyEndChecked}
									onClick={handleChangeSurveyEndChecked}
								/>
							</div>
						</div>
						<div className="mx-auto grid grid-cols-2 gap-4 ">
							{courseEndChecked && (
								<DatePickerComponent
									width="100%"
									label="Class End Date"
									value={dayjs(batchEndDate)}
									onChange={handleDateChangeEndDate}
								/>
							)}
							{examDateChecked && (
								<DatePickerComponent
									width="100%"
									label="Exam Date"
									value={dayjs(batchExamDate)}
									onChange={handleDateChangeExamDate}
									confirm
								/>
							)}

							{surveyStartChecked && (
								<DatePickerComponent
									width="100%"
									label="Survey Start Date"
									value={dayjs(batchSurveyStartDate)}
									onChange={handleDateChangeSurveyStart}
								/>
							)}
							{surveyEndChecked && (
								<DatePickerComponent
									width="100%"
									label="Survey End Date"
									value={dayjs(batchSurveyEndDate)}
									onChange={handleDateChangeSurveyEnd}
								/>
							)}
						</div>
					</DialogContent>

					<DialogActions>
						<Button onClick={handleClose}>Close</Button>
						<Button
							variant="contained"
							onClick={handleConfirm}
						>
							Confirm
						</Button>
					</DialogActions>
				</>
			</Dialog>
		</div>
	);
}
