import * as React from "react";
import { Tooltip, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";

export default function ToolTipHelp({ sx = [], sx2 = [], ...props }) {
	if (props.error) {
		return (
			<Tooltip sx={[{}, ...(Array.isArray(sx2) ? sx2 : [sx2])]} title={props.title} placement={props.placement} arrow>
				<Typography color="orange">
					Items Modified
					<ErrorIcon sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]} />
				</Typography>
			</Tooltip>
		);
	}
	return (
		<Tooltip title={props.title} placement={props.placement} arrow>
			<HelpIcon sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]} />
		</Tooltip>
	);
}

ToolTipHelp.propTypes = {
	children: PropTypes.node,
	sx: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]),
	sx2: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])), PropTypes.func, PropTypes.object]),
};
