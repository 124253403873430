import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl, FormLabel } from "@mui/material";
import dayjs from "dayjs";

export function RequisitionCalendar(props) {
	const [error, setError] = React.useState(null);

	const errorMessage = React.useMemo(() => {
		switch (error) {
			case "maxDate":
			case "minDate": {
				return "Please select valid dates.";
			}

			case "invalidDate": {
				if (props.confirm) {
					return "";
				} else {
					return "Please select valid dates.";
				}
			}

			default: {
				return "";
			}
		}
	}, [error]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<FormControl>
				<FormLabel
					sx={{
						marginLeft: props.marginLeft ? props.marginLeft : "0px",
						color: props.error ? "red" : "grey",
					}}
				>
					{props.label}
				</FormLabel>
				<DatePicker
					disabled={props.disabled}
					value={props.value || null}
					views={["month", "day"]}
					format="DD/MMM/YY"
					onChange={props.onChange}
					onError={(newError) => setError(newError)}
					slotProps={{
						textField: {
							helperText: errorMessage,
							sx: {
								width: props.width ? props.width : "245px",
								marginLeft: props.marginLeft,
								outline: props.error ? "1px solid red" : "none",
								borderRadius: props.error ? "5px" : "none",
							},
						},
					}}
					minDate={props.minDate}
					maxDate={props.maxDate}
				/>
			</FormControl>
		</LocalizationProvider>
	);
}
