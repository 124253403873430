import { Button, Box, Typography } from "@mui/material";
import ResolveDatesTable from "../Tables/ResolveDatesTable";
import TableHeader from "../Tables/TableHeader";

export default function ResolveDates(props) {
	return (
		<Box
			sx={{
				display: "flex",
				width: "100%",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<Box
				sx={{
					width: "100%",
					height: "15%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography variant="h6" fontWeight={600} sx={{ display: "flex", marginBottom: "20px" }} textAlign={"center"}>
					Step 3: Resolve Issues With Dates. Classes with no end date are displayed in the top table, and classes with no exam date in the bottom table.
				</Typography>
				<Typography variant="h7" color="red" fontWeight={600} sx={{ display: "flex", marginBottom: "20px" }} textAlign={"center"}>
					Note: If unresolved, classes with no end date will be EXCLUDED from the auto-requisition process. Classes with no exam date will be treated as if the final exam occurs on the last day of
					that class. If the class with no exam date is an evening class, the survey end date will be set based on the day of the week of the evening class.
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "75%",
				}}
			>
				<TableHeader
					classIds={props.classIds}
					webClasses={props.webClasses}
					goodClassIds={props.goodClassIds}
					noEndDates={props.noEndDates}
					suitableClassIds={props.suitableClassIds}
					stagedClasses={props.stagedClasses}
					setStagedClasses={props.setStagedClasses}
					removedForCombine={props.removedForCombine}
				/>

				<ResolveDatesTable
					noEndDates={props.noEndDates}
					setNoEndDates={props.setNoEndDates}
					noExamDates={props.noExamDates}
					setNoExamDates={props.setNoExamDates}
					setOpen={props.setOpen}
					goodClassIds={props.goodClassIds}
					setGoodClassIds={props.setGoodClassIds}
					selectedRow={props.selectedRow}
					setSelectedRow={props.setSelectedRow}
					suitableClassIds={props.suitableClassIds}
					setSuitableClassIds={props.setSuitableClassIds}
					noEndDatesBool
				/>
				<ResolveDatesTable
					noExamDates={props.noExamDates}
					setNoExamDates={props.setNoExamDates}
					noEndDates={props.noEndDates}
					setNoEndDates={props.setNoEndDates}
					setOpen={props.setOpen}
					goodClassIds={props.goodClassIds}
					setGoodClassIds={props.setGoodClassIds}
					selectedRow={props.selectedRow}
					setSelectedRow={props.setSelectedRow}
					suitableClassIds={props.suitableClassIds}
					setSuitableClassIds={props.setSuitableClassIds}
					noExamDatesBool
				/>
			</Box>
			<Box sx={{ display: "flex", gap: "10px" }}>
				<Button variant="contained" fullWidth onClick={() => props.setStep3(false)}>
					{"<-"}Back to Resolve Instructors
				</Button>
				{props.noInstructorClassIds.length === 0 && !props.step3disabled ? (
					<Button
						disabled={props.step3disabled}
						variant="contained"
						fullWidth
						/* sx={{ marginTop: "5px" }} */
						onClick={() => props.setStep4(true)}
					>
						Proceed{"->"}
					</Button>
				) : (
					<Button
						disabled={props.step3disabled}
						color="error"
						variant="contained"
						fullWidth
						/* sx={{ marginTop: "5px" }} */
						onClick={() => props.setStep4(true)}
					>
						Continue Without Resolving{"->"}
					</Button>
				)}
			</Box>
		</Box>
	);
}
