import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function AutocompleteComponent(props) {
	const handleChange = (event, newValue) => {
		if (props.autoRequisition) {
			props.setValue(newValue);
			props.setStep2(false);
			props.setStep3(false);
			props.setStep4(false);
			props.setSuitableClassIds([]);
			props.setNoEndDates([]);
			props.setNoExamDates([]);
			props.setGoodClassIds([]);
			props.setNoInstructorClassIds([]);
			props.setWebClasses([]);
			props.setSelectedRow({});
			props.setStagedClasses([]);
			props.setMbaOrNot(false);
			props.setRemovedForCombine([]);
			props.setAutoRoles([]);
			props.setLoggingDict({});
		} else {
			props.setValue(newValue);
		}
	};

	return (
		<div>
			<Autocomplete
				freeSolo
				disabled={props.disabled ? props.disabled : false}
				autoHighlight={props.autoHighlight ? props.autoHighlight : false}
				value={props.value}
				onChange={handleChange}
				inputValue={props.inputValue}
				onInputChange={(event, newInputValue) => {
					props.setInputValue(newInputValue);
				}}
				options={props.options}
				sx={{
					width: props.width ? props.width : 300,
					margin: props.margin ? props.margin : "auto",
					marginTop: props.marginTop ? props.marginTop : "20px",
					marginBottom: props.marginBottom ? props.marginBottom : "20px",
				}}
				getOptionLabel={(option) => option.department || ""}
				isOptionEqualToValue={(option, value) => option.department === value.department}
				renderOption={(props, option) => {
					return (
						<li {...props} key={option.key}>
							{option.department}
						</li>
					);
				}}
				renderInput={(params) => (
					<TextField
						variant={props.variant ? props.variant : "outlined"}
						{...params}
						label={props.label}
						/* inputProps={{
							...params.inputProps,
							onKeyDown: (e) => {
								if (e.key === "Enter") {
									e.stopPropagation();
								}
							},
						}} */
					/>
				)}
			/>
		</div>
	);
}
