import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SingleItemCatalogTable from "../../../../components/SingleItemCatalogTable";
import ItemsTable from "../../../../components/ItemsTable";
import { Tooltip } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "80%",
	height: "95%",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 2,
	display: "flex",
	flexDirection: "column",
};

export default function RequisitionModalItem(props) {
	const handleOpen = () => {
		props.setOpen(true);
	};
	const handleClose = () => {
		props.setOpen(false);
	};

	return (
		<div>
			{props.itemsDisabled ? (
				<Tooltip
					title="Survey dates must be chosen before choosing items"
					placement="top"
					arrow
					sx={{ display: props.itemsDisabled ? "inline" : "none" }}
				>
					<span>
						<Button
							variant="outlined"
							onClick={handleOpen}
							disabled
							sx={{ width: "245px" }}
						>
							Add Individual Items
						</Button>
					</span>
				</Tooltip>
			) : (
				<Button
					variant="outlined"
					onClick={handleOpen}
					sx={{ width: "245px" }}
				>
					Add Individual Items
				</Button>
			)}

			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box
						sx={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<Box
							sx={{
								height: "55%",
								marginBottom: "5px",
							}}
						>
							<SingleItemCatalogTable
								chosenItems={props.chosenItems}
								setChosenItems={props.setChosenItems}
								setModifiedItems={props.setModifiedItems}
							/>
						</Box>
						<Box sx={{ height: "40%" }}>
							<ItemsTable
								checkedRows={props.checkedRows}
								setCheckedRows={props.setCheckedRows}
								chosenItems={props.chosenItems}
								setChosenItems={props.setChosenItems}
								setTeamTeachingAgree={props.setTeamTeachingAgree}
								teamTeachingAgree={false}
								sortedChosenItems={props.sortedChosenItems}
								setSortedChosenItems={props.setSortedChosenItems}
								modifiedItems={props.modifiedItems}
								setModifiedItems={props.setModifiedItems}
								noModifications
							/>
						</Box>
						<Box sx={{ height: "5%" }}>
							<Button
								variant="contained"
								onClick={handleClose}
								sx={{
									marginTop: "10px",
									marginBottom: "10px",
									width: "100%",
								}}
							>
								Done
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
