import React from "react";
import logo from "../../images/UA_Logo_WHT_RGB.png";
import AppBarNav from "./AppBarNav";
import { Box, Button } from "@mui/material";
import { BrowserRouter as Router, Link as RouterLink } from "react-router-dom";
import { Typography } from "@mui/material";
import { Link } from "@mui/material";
import ProfileMenu from "./components/ProfileMenu";
import { useLocation } from "react-router";

function MasterNavbar() {
	const location = useLocation();
	function logout() {
		window.history.pushState("", "home", "/");
		window.history.replaceState("", "home", "/");
		sessionStorage.clear();
		window.location.reload();
	}

	const toggleDarkMode = () => {
		console.log("toggle dark mode");
	};

	if (location.pathname !== "/SignIn") {
		return (
			<div>
				<Box
					sx={{
						display: "flex",
						background: "#275d38",
						justifyContent: "space-between",
						paddingLeft: "1.5rem",
						paddingRight: "1.rem",
						height: "100px",
					}}
				>
					<Link
						component={RouterLink}
						to="/"
						sx={{
							display: "flex",
							alignItems: "center",
							padding: "1.5rem",
							height: "100%",
						}}
					>
						<img src={logo} alt="UofA logo" height={50} width={183} />
						<Box
							sx={{
								marginLeft: "2rem",
								display: "flex",
								flexDirection: "column",
								gap: "1rem",
								height: 50,
								paddingTop: ".5rem",
								WebkitFontSmoothing: "antialiased",
							}}
						>
							<Typography
								color="#FFFFFF"
								sx={{
									fontSize: ".875rem",
									fontWeight: 500,
									lineHeight: "0.5rem",
								}}
							>
								TSQS
							</Typography>
							<Typography
								color="#f2cd00"
								sx={{
									fontSize: "1.625rem",
									fontWeight: 300,
									lineHeight: "0.5rem",
								}}
							>
								Student Perspectives of Teaching (SPOT)
							</Typography>
						</Box>
					</Link>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							padding: "1.5rem",
							height: "50%",
							marginTop: "1.5rem",
							marginRight: ".5rem",
						}}
					>
						<ProfileMenu Logout={logout} toggleDarkMode={toggleDarkMode} />
					</Box>
				</Box>
				<Box
					sx={{
						background: "#fff",
						height: "60px",
						display: "flex",
						justifyContent: "center",
						boxShadow: "none",
						zIndex: "10",
					}}
				>
					<AppBarNav />
				</Box>
			</div>
		);
	}
}

export default MasterNavbar;
