import { useQuery } from "react-query";
import { getSTDUsriSearchParams } from "../components/Api/Getters";

export const useSTDUsriSearchQuery = (token, options = {}) => {
	const queryOptions = {
		refetchOnWindowFocus: false,
		staleTime: 100000 * 60, // 5 minutes
		...options, // spread the options object here
	};
	return useQuery(
		["allSTDUsriSearchParams", token],
		() => getSTDUsriSearchParams(token),
		queryOptions
	);
};
