import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardContent, ImageList, Radio } from "@mui/material";
import { FormControl, FormControlLabel, FormGroup, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { Typography, Checkbox } from "@mui/material";
import TextInput from "../../../../components/Utils/TextInput";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";

export default function SubmitAllSuitableCoursesDialog(props) {
	const token = props.token;
	const listOfSuitableCourses = props.listOfSuitableCourses;
	const handleClose = (event, reason) => {
		if (props.submitAllLoading && reason && reason === "backdropClick") return;
		props.setOpen(false);
	};

	const handleSubmitAllSuitableCourses = async () => {
		props.setSubmitAllLoading(true);
		await props.postAllSuitableClassesMutation.mutateAsync({
			token,
			classesToSubmit: listOfSuitableCourses,
		});
	};

	const columns = [
		{
			field: "classid",
			headerName: "Class ID",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Class ID</p>
					</div>
				);
			},
			renderCell: (params) => {
				return (
					<div>
						<p className="text-sm text-black font-semibold">{params.row.classid}</p>
					</div>
				);
			},
			width: 60,
		},
		{
			field: "ctitle",
			headerName: "Class Title",
			type: "string",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Class Title</p>
					</div>
				);
			},
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.ctitle}</p>
					</div>
				);
			},
			width: 140,
		},
		{
			field: "csize",
			editable: true,
			headerName: "# Std",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold"># Std</p>
					</div>
				);
			},
			align: "center",
			width: 60,
		},
		{
			field: "teamsize",
			headerName: "Team",
			width: 40,
			align: "center",
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold"># Inst</p>
					</div>
				);
			},
			disableClickEventBubbling: true,
		},
		{
			field: "blkid",
			headerName: "Block",
			width: 50,
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.blkid}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Block</p>
					</div>
				);
			},
			align: "center",

			disableClickEventBubbling: true,
		},
		{
			field: "name",
			headerName: "Instructor(s)",
			width: 150,
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Instructor(s)</p>
					</div>
				);
			},
			renderCell: props.renderInstructorNameTable,
		},

		{
			field: "c_edate",
			headerName: "Class End Date",
			width: 80,
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.c_edate}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">End Date</p>
					</div>
				);
			},
			headerAlign: "center",
			disableClickEventBubbling: true,
			align: "center",
		},

		{
			field: "examdate",
			headerName: "Exam Date",
			align: "center",
			headerAlign: "center",
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.examdate}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Exam Date</p>
					</div>
				);
			},
			width: 80,
			disableClickEventBubbling: true,
		},

		{
			field: "survey_start",
			headerName: "Survey Start",
			align: "center",
			headerAlign: "center",
			width: 80,
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.survey_start}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Survey Start</p>
					</div>
				);
			},
			disableClickEventBubbling: true,
		},

		{
			field: "survey_end",
			headerName: "Survey End",
			align: "center",
			headerAlign: "center",
			renderCell: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">{params.row.survey_end}</p>
					</div>
				);
			},
			renderHeader: (params) => {
				return (
					<div>
						<p className="text-xs text-black font-semibold">Survey End</p>
					</div>
				);
			},
			width: 80,
			disableClickEventBubbling: true,
		},
	];

	return (
		<div>
			<Dialog
				open={props.open}
				onClose={handleClose}
				maxWidth="1200px"
				className="flex flex-col items-center"
			>
				<DialogTitle textAlign={"left"}>Submitting All Suitable Courses</DialogTitle>

				<DialogContent
					sx={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}
				>
					<p className="max-w-[900px]">
						The following courses are suitable for submission. Please review the list below and
						click the "Confirm Submission" button to submit all suitable courses for evaluation.
					</p>

					<div className="w-[900px] h-[400px]">
						<DataGrid
							sx={{
								"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
									outline: "Nne !important",
								},
								"& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
									width: "0.4em",
								},
								"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
									background: "#f1f1f1",
								},
								"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
									backgroundColor: "#888",
								},
								"& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
									background: "#555",
								},

								borderRadius: "1px 1px 4px 4px",
							}}
							disableSelectionOnClick
							columnVisibilityModel={{}}
							rows={props.listOfSuitableCourses}
							density="compact"
							slots={{}}
							columns={columns}
							initialState={{
								pagination: {
									paginationModel: { pageSize: 100, page: 0 },
								},
							}}
							pageSizeOptions={[5, 10]}
							getRowId={(row) => row.classid}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} disabled={props.submitAllLoading}>
						Cancel
					</Button>

					<Button
						onClick={handleSubmitAllSuitableCourses}
						variant="contained"
						autoFocus
						color="success"
					>
						{props.submitAllLoading ? (
							<div className="flex justify-center gap-2">
								<p>Please Wait</p> <CircularProgress size={20} sx={{ color: "white" }} />
							</div>
						) : (
							"Confirm Submission"
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
