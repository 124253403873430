import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
// import LinearProgress from "@mui/material/LinearProgress";
import { Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { BsSearch } from "react-icons/bs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import { createSearchParams, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { SearchablePDF } from "../../Report Download/SearchablePDF";
import { rankingSorter, CustomPagination, CustomNoRowsOverlay } from "../../../SecondaryFunctions";
import { useSearchParams } from "react-router-dom";

const columns = [
	{
		field: "course",
		headerName: "Course",
		width: 400,
		align: "center",
		headerAlign: "center",
	},
	{
		field: "description",
		headerName: "Description",
		width: 500,
		align: "center",
		headerAlign: "center",
	},
	{
		field: "term",
		headerName: "Term",
		width: 200,
		align: "center",
		headerAlign: "center",
	},
	{
		field: "inst",
		headerName: "Instructor",
		width: 300,
		align: "center",
		headerAlign: "center",
	},
];

let idValue = 1;

export default function AvailableDDCReports() {
	// Define various state variables using the useState hook
	const [loaded, setLoaded] = useState(false); // To track if data is loaded
	const [loadingBar, setLoadingBar] = useState(true); // To show/hide loading bar
	const [apiFetched, setApiFetched] = useState(false); // To track API data fetching
	let [courseData, setCourseData] = useState([]); // Store fetched course data
	let [filteredCourseData, setFilteredCourseData] = useState([]); // Store filtered course data
	let [filters, setFilters] = useState({
		faculty: [],
		department: [],
		acyear: [],
		term: [],
	}); // Store filter values
	const [courseName, setCourseName] = useState(""); // Store course name for search
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedIDs, setSelectedIDs] = useState([]); // Store selected IDs
	const [selectedCourses, setSelectedCourses] = useState([]); // Store selected course names
	const [searchParams] = useSearchParams();
	const navigate = useNavigate(); // Navigation hook
	const [openWindow, setOpenWindow] = useState(false); // Control the visibility of a dialog window
	const [checked, setChecked] = useState([true, true, true, true]);
	const [pdfLoading, setPdfLoading] = useState(false);
	const [pdfGrouping, setPdfGrouping] = useState(1);

	const handleCheck0 = (event) => {
		setChecked([event.target.checked, checked[1], checked[2], checked[3]]);
	};

	const handleCheck1 = (event) => {
		setChecked([checked[0], event.target.checked, checked[2], checked[3]]);
	};

	const handleCheck2 = (event) => {
		setChecked([checked[0], checked[1], event.target.checked, checked[3]]);
	};

	const handleCheck3 = (event) => {
		setChecked([checked[0], checked[1], checked[2], event.target.checked]);
	};

	const handlePDFgrouping = (event) => {
		setPdfGrouping(event.target.value);
	};

	// Function to open the dialog window for generating reports
	const handleClickOpen = () => {
		if (selectedCourses.length > 0) {
			setOpenWindow(true);
		}
	};

	// Function to close the dialog window
	const handleClose = () => {
		setOpenWindow(false);
	};

	// Function to handle Enter key press for course search
	const handleKeypress = (e) => {
		if (e.keyCode === 13) {
			searchCourse();
		}
	};

	// Function to search for courses based on course name
	function searchCourse() {
		let temp = courseData.filter((item) => item.course.includes(courseName));
		setFilteredCourseData(temp);
	}

	// Function to clear search and reset filteredCourseData
	function handleClear() {
		setCourseName("");
		let temp = courseData.filter((item) => item.course.includes(""));
		setFilteredCourseData(temp);
	}

	// Function to update filter values
	function updateFilters(fgroup, value, checked) {
		// Create a copy of the filters object
		let updatedFilters = filters;

		// Check if the item should be added to the filters
		if (checked) {
			// Add the value to the filter group if it doesn't already exist
			if (!updatedFilters[fgroup].includes(value)) {
				updatedFilters[fgroup].push(value);
			}
		} else {
			// Remove the value from the filter group if it exists
			updatedFilters[fgroup] = updatedFilters[fgroup].filter((item) => item !== value);
		}

		// Update the filters state with the updated filters object
		setFilters(updatedFilters);
		// Apply filters to update filteredCourseData
		applyFilters();
	}

	// Function to apply selected filters and update filteredCourseData
	function applyFilters() {
		// Array of filter categories
		const filterCategories = ["faculty", "department", "acyear", "term"];

		// Initialize filteredData as an empty array
		let filteredData = [];

		// Iterate over each filter category
		filterCategories.forEach((category) => {
			// Check if the current filter category has selected values
			if (filters[category].length !== 0) {
				// Filter courseData based on the selected values of the current category
				let temp = courseData.filter((item) => filters[category].includes(item[category]));

				// If filteredData is empty, assign temp to it
				if (filteredData.length === 0) {
					filteredData = temp;
				} else {
					// Intersect filteredData with temp to get common elements
					filteredData = filteredData.filter((value) => temp.includes(value));
				}
			} else {
				// If no selected values for the current category, retain filteredData as it is or intersect it with courseData
				if (filteredData.length === 0) {
					filteredData = courseData;
				} else {
					filteredData = filteredData.filter((value) => courseData.includes(value));
				}
			}
		});
		// Check if filteredData is not empty
		if (filteredData.length !== 0) {
			// Sort filteredData based on 'termNum' and 'ctitle' properties using the 'rankingSorter' function
			filteredData.sort(rankingSorter("termNum", "ctitle"));

			// Set the filtered course data using the updated filteredData
			setFilteredCourseData(filteredData);
		}
	}

	// useEffect hook to fetch and process data from the API
	useEffect(() => {
		let url = process.env.REACT_APP_BACKEND_HOST.concat("/spot/ddc_reports/");

		async function fetch_ddc_results(terms, depts, empids) {
			// console.log(url.concat(empids, "/"))
			// Fetch data from the API using the provided empid and authorization token
			const response = await fetch(
				url.concat("(", terms, ");(", depts, ");(", empids, ");", sessionStorage.ccid, "/"),
				{ headers: { Authorization: `Token ${sessionStorage.token}` } }
			);
			const json = await response.json();

			// Process the fetched data and create an array of objects
			let apiData = [];
			for (let i = 0; i < json.length; i++) {
				let entry = {
					id: idValue,
					acyear: json[i].ACYEAR.trim(),
					term: json[i].DESC_TERM.trim(),
					termNum: json[i].TERM,
					faculty: json[i].FNAME.trim(),
					department: json[i].DISPLAY_NAME.trim(),
					reqnum: json[i].REQNUM,
					clsnum: json[i].CLSNUM,
					classid: json[i].CLASSID,
					course: json[i].CTITLE.trim(),
					description: json[i].DESCRIPTION.trim(),
					ctype: json[i].CTYPE.trim(),
					empid: json[i].EMPID,
					name: json[i].NAME.trim(),
					inst: json[i].NAME.trim().concat(" - ", json[i].EMPID),
				};
				apiData.push(entry);
				idValue++;
			}
			// Set the state to indicate API data has been fetched
			setApiFetched(true);
			return apiData;
		}
		// Fetch data if not loaded
		if (!loaded) {
			let apiData = [
				fetch_ddc_results(
					searchParams.get("term"),
					searchParams.get("dept"),
					searchParams.get("empid")
				),
			];
			if (apiFetched) {
				Promise.all(apiData).then((values) => {
					let data = values[0];
					// Sort the fetched data based on 'termNum' and 'ctitle'
					data.sort(rankingSorter("termNum", "ctitle"));
					setCourseData(data);
					setFilteredCourseData(data);
				});
				setLoaded(true);
			}
		}
		// Show loading bar if loaded and still loading
		if (loaded && loadingBar) {
			setTimeout(() => {
				setLoadingBar(false);
			}, 3000);
		}
	}, [loaded, courseData, filteredCourseData, loadingBar, apiFetched]);

	// function that iterates over selected rows and stores reqnum and clsnum from row data
	function getItems(items) {
		let selected_items = [];
		let selected_ids = [];
		let selected_courses = [];
		// Iterate over selected row data
		for (let i = 0; i < items.length; i++) {
			// Filter filteredCourseData to find the entry with matching id
			let entry = filteredCourseData.filter((x) => x.id === items[i]);
			selected_items.push({
				id: entry[0].reqnum.toString().concat("_", entry[0].clsnum.toString()),
				term: entry[0].term,
				course: entry[0].course,
				empid: entry[0].empid,
				name: entry[0].name,
				inst: entry[0].inst,
			});
			selected_ids.push(entry[0].reqnum.toString().concat("_", entry[0].clsnum.toString()));
			selected_courses.push(entry[0].course);
		}
		setSelectedItems(selected_items);
		setSelectedIDs(selected_ids);
		setSelectedCourses(selected_courses);
	}

	//temporary function that will be used to generate and print repoorts
	function generateReport() {
		navigate({
			pathname: "/ReportView",
			search: createSearchParams({
				reqID: selectedIDs.toString(),
				cname: selectedCourses.toString(),
			}).toString(),
		});
	}

	async function PrintReport() {
		function allFalse(arr) {
			for (let i = 0; i < arr.length; i++) {
				if (arr[i] === true) {
					return false;
				}
			}
			return true;
		}

		if (!allFalse(checked)) {
			if (pdfGrouping === 1) {
				setPdfLoading(true);
				await SearchablePDF(selectedIDs, checked, "SPOT_Report.pdf");
				setPdfLoading(false);
			} else if (pdfGrouping === 2) {
				setPdfLoading(true);
				for (let item of selectedItems) {
					let selected_data = [item.id];
					let filename = item.term.concat(" - ", item.course, " - ", item.name, ".pdf");
					await SearchablePDF(selected_data, checked, filename);
				}
				setPdfLoading(false);
			} else if (pdfGrouping === 3) {
				let courses = [...new Set(Object.values(selectedItems).map((item) => item.course))];
				setPdfLoading(true);
				for (let course of courses) {
					let selected_data = selectedItems
						.filter((item) => item.course === course)
						.map((item) => item.id);
					let filename = course.concat(".pdf");
					await SearchablePDF(selected_data, checked, filename);
				}
				setPdfLoading(false);
			} else if (pdfGrouping === 4) {
				let empids = [...new Set(Object.values(selectedItems).map((item) => item.empid))];
				setPdfLoading(true);
				for (let empid of empids) {
					let selected_data = selectedItems
						.filter((item) => item.empid === empid)
						.map((item) => item.id);
					let filename = selectedItems.find((item) => item.empid === empid).name.concat(".pdf");
					await SearchablePDF(selected_data, checked, filename);
				}
				setPdfLoading(false);
			}
			// handleClickOpen();
		}
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				height: "100%",
				maxHeight: "calc(100% - 160px)",
			}}
		>
			<Box
				sx={{
					width: "21%",
					maxWidth: 400,
					borderRight: "2px solid rgba(0, 0, 0, .5)",
					borderColor: "rgba(0, 0, 0, 0.2)",
					paddingLeft: "10px",
					paddingRight: "10px",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Breadcrumbs separator=">" style={{ marginTop: "10px", marginBottom: "20px" }}>
					<Link underline="always" color="#275d38" href="/">
						<Typography color="#275d38" sx={{ display: "flex", alignItems: "center" }}>
							<HomeIcon sx={{ mr: 0.5 }} />
							Home
						</Typography>
					</Link>
					<Typography color="text.primary">Reporting</Typography>
					<Typography color="text.primary">Dean and Chair SPOT Reports</Typography>
				</Breadcrumbs>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						marginBottom: "20px",
					}}
				>
					<TextField
						label="Course Title"
						size="small"
						value={courseName}
						onChange={(e) => {
							setCourseName(e.target.value.toUpperCase());
						}}
						onKeyUp={handleKeypress}
						sx={{ width: "322px" }}
						InputProps={{
							endAdornment: (
								<IconButton onClick={handleClear}>
									{courseName.length > 0 ? <ClearIcon /> : ""}
								</IconButton>
							),
						}}
					/>

					<Button
						variant="contained"
						style={{
							backgroundColor: "#275d38",
							fontSize: "1.2rem",
							marginLeft: "2px",
						}}
						onClick={searchCourse}
					>
						<BsSearch />
					</Button>
				</Box>

				<Accordion disableGutters>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Department</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{[...new Set(courseData.map((item) => item.department))].map((dept, index) => (
							<FormControlLabel
								key={index}
								control={
									<Checkbox
										onChange={(e) => {
											updateFilters("department", e.target.value, e.target.checked);
										}}
									/>
								}
								value={dept}
								label={dept}
							/>
						))}
					</AccordionDetails>
				</Accordion>

				<Accordion disableGutters>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Academic Year</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{[...new Set(courseData.map((item) => item.acyear))].map((acyear, index) => (
							<FormControlLabel
								key={index}
								control={
									<Checkbox
										onChange={(e) => {
											updateFilters("acyear", e.target.value, e.target.checked);
										}}
									/>
								}
								value={acyear}
								label={acyear}
							/>
						))}
					</AccordionDetails>
				</Accordion>

				<Accordion disableGutters>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>Term</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{[...new Set(courseData.map((item) => item.term))].map((term, index) => (
							<FormControlLabel
								key={index}
								control={
									<Checkbox
										onChange={(e) => {
											updateFilters("term", e.target.value, e.target.checked);
										}}
									/>
								}
								value={term}
								label={term}
							/>
						))}
					</AccordionDetails>
				</Accordion>
			</Box>
			<div
				style={{
					paddingTop: "15px",
					width: "80%",
					maxWidth: "1250px",
					margin: "0 auto",
				}}
			>
				<DataGrid
					getRowHeight={() => "auto"}
					disableColumnFilter
					disableColumnSelector
					disableColumnMenu
					style={{ textAlign: "center" }}
					columns={columns}
					rows={filteredCourseData}
					pageSize={100}
					rowsPerPageOptions={[100]}
					checkboxSelection
					onSelectionModelChange={(itm) => getItems(itm.sort())}
					density={"compact"}
					slots={{
						Pagination: CustomPagination,
						// LoadingOverlay: LinearProgress,
						LoadingOverlay: CircularProgress,
						NoRowsOverlay: CustomNoRowsOverlay,
					}}
					loading={loadingBar}
					sx={{ height: "82vh", width: "100%", zoom: 0.85 }}
				/>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginTop: "15px",
					}}
				>
					<Button
						style={{
							backgroundColor: "#275d38",
							marginBottom: "5px",
							width: "45%",
							maxWidth: "400px",
						}}
						variant="contained"
						onClick={generateReport}
					>
						<Typography style={{ fontSize: "16px" }}>Display Reports</Typography>
					</Button>
					<Button
						style={{
							backgroundColor: "#275d38",
							width: "45%",
							maxWidth: "400px",
						}}
						variant="contained"
						endIcon={<DownloadIcon />}
						onClick={handleClickOpen}
					>
						<Typography style={{ fontSize: "16px" }}>PDF Download</Typography>
					</Button>
					<Dialog open={openWindow} onClose={handleClose} fullWidth={true} maxWidth={"lg"}>
						<DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>SPOT REPORT</DialogTitle>
						{pdfLoading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									paddingBottom: "20px",
								}}
							>
								<CircularProgress size={80} />
							</Box>
						) : (
							<div>
								<DialogContent>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Typography>
											Select which elements you would like included in the PDF report:
										</Typography>
										<br></br>
										<FormGroup>
											<FormControlLabel
												control={<Checkbox checked={checked[0]} onChange={handleCheck0} />}
												label="Standard SPOT Questions"
											/>
											<FormControlLabel
												control={<Checkbox checked={checked[1]} onChange={handleCheck1} />}
												label="Supplementary Questions"
											/>
											<FormControlLabel
												control={<Checkbox checked={checked[2]} onChange={handleCheck2} />}
												label="Summary Data"
											/>
											<FormControlLabel
												control={<Checkbox checked={checked[3]} onChange={handleCheck3} />}
												label="Comments"
											/>
											<br></br>
											<FormControl variant="filled">
												<InputLabel>PDF Format</InputLabel>
												<Select value={pdfGrouping} label="PDF Format" onChange={handlePDFgrouping}>
													<MenuItem value={1}>Single PDF</MenuItem>
													<MenuItem value={2}>Seperate PDF's</MenuItem>
													<MenuItem value={3}>PDF per Course</MenuItem>
													<MenuItem value={4}>PDF per Instructor</MenuItem>
												</Select>
											</FormControl>
										</FormGroup>
									</Box>
								</DialogContent>

								<DialogActions sx={{ justifyContent: "center" }}>
									<Button onClick={handleClose}>Close</Button>
									<Button onClick={PrintReport}>Generate</Button>
								</DialogActions>
							</div>
						)}
					</Dialog>
				</div>
			</div>
		</div>
	);
}
