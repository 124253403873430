import React, { useContext, useEffect, useState, useRef } from "react";
import { TokenContext } from "../../../context/TokenContext";
import { Typography, Box, Checkbox, FormControlLabel } from "@mui/material";
import ListSelect from "../../../components/Utils/ListSelect";
import AutocompleteComponent from "../../../components/Utils/AutocompleteComponent";
import AddInstructorAuto from "./components/Dialogs/AddInstructorAuto";
import GroupClasses from "./components/pseudoPages/GroupClasses";
import ResolveInstructors from "./components/pseudoPages/ResolveInstructors";
import ExcludeClasses from "./components/pseudoPages/ExcludeClasses";
import ResolveDates from "./components/pseudoPages/ResolveDates";
import { useDepartmentsQuery } from "../../../Hooks/useDepartmentsQuery";
import { useTermsQuery } from "../../../Hooks/useTermsQuery";
import {
	useBlockItemsQuery,
	useBlockIdsByDeptQuery,
} from "../../../Hooks/useBlockItemsQuery";
import { useAllItemsQuery } from "../../../Hooks/useAllItemsQuery";
import { useItemCategoriesQuery } from "../../../Hooks/useItemCategoriesQuery";
import {
	useClassIdsAutoGeneration,
	useWebClasses,
	useCombinedClassesQuery,
} from "../../../Hooks/useClassIdsQuery";
import { useAutoRolesQuery } from "../../../Hooks/useAutoRolesQuery";

//excluded has to only show excluded that are otherwise viable
//same for instructor but I did that already
//suitable should reflect this
//ADD CHECK IN FOR COMBINED CLASSES SECONDARY, TERTIARY, etc. BEING IN WEB CLASSES
//Tally count wrong if comb class excluded

//Nursing----------------
//check current term, see if fall/winter or spring/summer, see if A or B based on that
//for nursing, if course title ends with A and semester is A, do not evaluate
//say sorry to Aleem and ask for Location
//nursing, do not eval anything that is not online/main/off-campus
//--------------------------

export default function AutoRequisition() {
	const token = useContext(TokenContext);
	const [autocompleteValue, setAutocompleteValue] = useState("");
	const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
	const [classIds, setClassIds] = useState([]);
	const [classesClassIdsArray, setClassesClassIdsArray] = useState([]);
	const [termValue, setTermValue] = useState("");
	const [open, setOpen] = useState(false);
	const [noInstructorClassIds, setNoInstructorClassIds] = useState([]);
	const [goodClassIds, setGoodClassIds] = useState([]);
	const [webClasses, setWebClasses] = useState([]);
	const [selectedRow, setSelectedRow] = useState({});
	const [step2, setStep2] = useState(false);
	const [step3, setStep3] = useState(false);
	const [step4, setStep4] = useState(false);
	const [suitableClassIds, setSuitableClassIds] = useState([]);
	const [noEndDates, setNoEndDates] = useState([]);
	const [noExamDates, setNoExamDates] = useState([]);
	const [allBlockItems, setAllBlockItems] = useState([]);
	const [deptBlockIds, setDeptBlockIds] = useState([]);
	const [autoRoles, setAutoRoles] = useState([]);
	const [stagedClasses, setStagedClasses] = useState([]);
	const [combinedClassIds, setCombinedClassIds] = useState([]);
	const [mbaOrNot, setMbaOrNot] = useState(false);
	const [removedByRole, setRemovedByRole] = useState([]);
	const [loggingDict, setLoggingDict] = useState({});
	const [teamsizeEditedDown, setTeamsizeEditedDown] = useState([]);
	const [removedForCombine, setRemovedForCombine] = useState([]);
	const { data: departments } = useDepartmentsQuery(token);
	const { data: termsList } = useTermsQuery(token);
	const { data: autoRolesData } = useAutoRolesQuery(
		token,
		autocompleteValue?.key
	);
	const { data: allItemsData } = useAllItemsQuery(token, {
		enabled: !!token && !!autocompleteValue?.key && !!termValue,
	});
	const { data: allBlockItemsData } = useBlockItemsQuery(token, {
		enabled: !!token && !!autocompleteValue?.key && !!termValue,
	});
	const { data: allItemCategoriesData } = useItemCategoriesQuery(token, {
		enabled: !!token && !!autocompleteValue?.key && !!termValue,
	});
	const { data: blockIds } = useBlockIdsByDeptQuery(
		token,
		autocompleteValue?.key,
		{
			enabled: !!token && !!autocompleteValue?.key && !!termValue,
		}
	);
	useEffect(() => {
		if (blockIds && blockIds.length > 0) {
			if (autocompleteValue?.key === 79) {
				let blockIdList = [];
				blockIds.forEach((blockId) => {
					if (
						blockId.blkid !== "1NURS01" &&
						blockId.blkid !== "1NURS02" &&
						blockId.blkid !== "1NURS03"
					) {
						blockIdList.push(blockId);
					}
				});
				setDeptBlockIds(blockIdList);
			} else {
				setDeptBlockIds(blockIds);
			}
		}
	}, [blockIds, autocompleteValue?.key]);

	const { data: webClassesData } = useWebClasses(token, termValue);
	const { data: classIdsData } = useClassIdsAutoGeneration(
		token,
		autocompleteValue?.key,
		termValue
	);

	const { data: combinedClassesData } = useCombinedClassesQuery(
		token,
		autocompleteValue?.key,
		termValue
	);
	const MBADepts = ["22", "31", "64", "35", "83", "23"];
	//MBA - accounting,finance,marketing,school of business, SMO
	//as well as business MBA
	//NOT faculty of business
	const MBACNumbers = ["5", "6", "8"];

	const canBeMBAOrNot =
		autocompleteValue &&
		autocompleteValue.key &&
		MBADepts.includes(String(autocompleteValue?.key));

	const step2disabled = termValue === "" || autocompleteValue === "";
	const regularEndOfTerm = "2024-06-12";

	const classIdsRef = useRef();
	classIdsRef.current = classIds;

	useEffect(() => {
		async function filterClasses() {
			if (
				token &&
				autocompleteValue &&
				termValue &&
				classIdsData &&
				classIdsData.length > 0
			) {
				if (canBeMBAOrNot) {
					if (mbaOrNot) {
						const mbaClassIds = classIdsData.filter((classId) =>
							MBACNumbers.includes(classId.cnumber[0])
						);
						setClassIds(mbaClassIds);
					} else {
						const nonMbaClassIds = classIdsData.filter(
							(classId) => !MBACNumbers.includes(classId.cnumber[0])
						);
						setClassIds(nonMbaClassIds);
					}
				} else {
					setClassIds(classIdsData);
				}
			}
		}
		filterClasses();
	}, [token, autocompleteValue, termValue, mbaOrNot, classIdsData]);

	useEffect(() => {
		async function fetchWebClasses() {
			if (token && webClassesData && webClassesData.length > 0) {
				const webClassesByDept = webClassesData.filter(
					(webClass) => webClass.dept_id === autocompleteValue?.key
				);
				const onlyClassIds = webClassesByDept.map(
					(webClass) => webClass.classid
				);
				const noDupes = [...new Set(onlyClassIds)];
				setClassesClassIdsArray(noDupes);
			}
		}
		fetchWebClasses();
	}, [token, webClassesData, autocompleteValue]);

	useEffect(() => {
		async function fetchCombinedClassIds() {
			if (token && combinedClassesData && combinedClassesData.length > 0) {
				setCombinedClassIds(combinedClassesData);
			}
		}
		fetchCombinedClassIds();
	}, [token, combinedClassesData, autocompleteValue, termValue]);

	useEffect(() => {
		async function fetchAllItemsAndBlockItems() {
			if (
				token &&
				allItemsData &&
				allItemsData.length > 0 &&
				allItemCategoriesData &&
				allItemCategoriesData.length > 0
			) {
				const allItems = allItemsData;
				const filteredItems = allItemsData.filter(
					(item) => item.cat_id !== 10 && item.itemno < 20000
				);
				const groupedItems = filteredItems.reduce((r, a) => {
					r[a.cat_id] = [...(r[a.cat_id] || []), a];
					return r;
				}, {});
				const updatedGroupedItems = {};
				for (const key in groupedItems) {
					if (groupedItems.hasOwnProperty(key)) {
						const cat_id = parseInt(key);
						const category = allItemCategoriesData.find(
							(category) => category.cat_id === cat_id
						);
						if (category) {
							updatedGroupedItems[category.cat_name] = groupedItems[key];
						}
					}
				}
				Object.values(updatedGroupedItems).forEach((itemsArray) => {
					itemsArray.sort((a, b) => {
						return a.itemno - b.itemno;
					});
				});
				if (allBlockItemsData && allBlockItemsData.length > 0) {
					const groupedItems = allBlockItemsData.reduce((r, a) => {
						r[a.blkid] = [...(r[a.blkid] || []), a];
						return r;
					}, {});

					if (allItems.length > 0) {
						const updatedAllBlockItems = { ...groupedItems };
						Object.keys(groupedItems).forEach((key) => {
							const blockItems = groupedItems[key];
							const updatedBlockItems = blockItems.map((blockItem) => {
								const item = allItems.find(
									(item) => item.itemno === blockItem.itemno
								);
								if (item) {
									return {
										...blockItem,
										...item,
									};
								} else {
									return blockItem;
								}
							});
							updatedAllBlockItems[key] = updatedBlockItems;
						});
						setAllBlockItems(updatedAllBlockItems);
					}
				}
			}
		}

		fetchAllItemsAndBlockItems();
	}, [token, allItemsData, allItemCategoriesData, allBlockItemsData]);

	const language = autocompleteValue
		? autocompleteValue?.key === 27
			? "FR"
			: "EN"
		: "EN";

	useEffect(() => {
		async function fetchAutoRolesByDept() {
			if (token && autoRolesData && autoRolesData.length > 0) {
				const autoRolesDict = {};
				autoRolesData.forEach((autoRole) => {
					const listOfNoValues = [];
					Object.keys(autoRole).forEach((key) => {
						if (autoRole[key] === "N") {
							listOfNoValues.push(key);
						}
					});
					autoRolesDict[autoRole.ctype] = listOfNoValues;
				});

				setAutoRoles(autoRolesDict);
			}
		}
		fetchAutoRolesByDept();
	}, [token, autocompleteValue, autoRolesData]);

	useEffect(() => {
		async function processClasses() {
			let targetClassIds = classIds;
			if (classesClassIdsArray.length > 0) {
				targetClassIds = await processWebClassRemoval(
					classIds,
					classesClassIdsArray
				);
			}
			if (targetClassIds && targetClassIds.length > 0) {
				const newClassIds = await processCombinedClassRemoval(
					targetClassIds,
					combinedClassIds
				);
				if (newClassIds && newClassIds.length > 0) {
					const postAnnotationClassIds = await processClassAnnotations(
						newClassIds
					);

					if (postAnnotationClassIds && postAnnotationClassIds.length > 0) {
						const goodClassesList = postAnnotationClassIds.filter(
							(classId) =>
								!classId.empty && !classId.tooSmall && !classId.noInstructor
						);
						const noInstructorList = postAnnotationClassIds.filter(
							(classId) =>
								classId.noInstructor && !classId.empty && !classId.tooSmall
						);
						const noEndDatesList = postAnnotationClassIds.filter(
							(classId) =>
								classId.noEndDate && !classId.empty && !classId.tooSmall
						);
						const noExamDatesList = postAnnotationClassIds.filter(
							(classId) =>
								classId.noExamDate && !classId.empty && !classId.tooSmall
						);
						setNoInstructorClassIds(noInstructorList);
						setNoEndDates(noEndDatesList);
						setNoExamDates(noExamDatesList);
						setGoodClassIds(goodClassesList);
					}
				} else {
					const postAnnotationClassIds = await processClassAnnotations(
						targetClassIds
					);
					if (postAnnotationClassIds && postAnnotationClassIds.length > 0) {
						const goodClassesList = postAnnotationClassIds.filter(
							(classId) => !classId.empty && !classId.tooSmall
						);
						const noInstructorList = postAnnotationClassIds.filter(
							(classId) =>
								classId.noInstructor && !classId.empty && !classId.tooSmall
						);
						const noEndDatesList = postAnnotationClassIds.filter(
							(classId) =>
								classId.noEndDate && !classId.empty && !classId.tooSmall
						);
						const noExamDatesList = postAnnotationClassIds.filter(
							(classId) =>
								classId.noExamDate && !classId.empty && !classId.tooSmall
						);
						setNoInstructorClassIds(noInstructorList);
						setNoEndDates(noEndDatesList);
						setNoExamDates(noExamDatesList);
						setGoodClassIds(goodClassesList);
					}
				}
			}
		}
		processClasses();
	}, [classIds, classesClassIdsArray, combinedClassIds]);

	useEffect(() => {
		if (goodClassIds.length > 0) {
			const suitableClasses = [];
			goodClassIds.forEach((classId) => {
				if (
					classId.exclude_class === false ||
					classId.exclude_class === undefined ||
					classId.exclude_class === null ||
					classId.exclude_class === "" ||
					classId.exclude_class === "N"
				) {
					suitableClasses.push(classId);
				}
			});
			setSuitableClassIds(suitableClasses);
		}
	}, [goodClassIds]);

	const checkIfEmpty = (obj) => {
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (removedByRole.length > 0) {
			const loggingDictCopy = { ...loggingDict };
			removedByRole.sort((a, b) => {
				if (a.classId.ctitle < b.classId.ctitle) {
					return -1;
				}
				if (a.classId.ctitle > b.classId.ctitle) {
					return 1;
				}
				return 0;
			});
			loggingDictCopy["Role Removed"] = removedByRole;
			setLoggingDict(loggingDictCopy);
		}
	}, [removedByRole]);

	useEffect(() => {
		if (teamsizeEditedDown.length > 0) {
			const loggingDictCopy = { ...loggingDict };
			teamsizeEditedDown.sort((a, b) => {
				if (a.ctitle < b.ctitle) {
					return -1;
				}
				if (a.ctitle > b.ctitle) {
					return 1;
				}
				return 0;
			});
			loggingDictCopy["Teamsize Modified"] = teamsizeEditedDown;
			setLoggingDict(loggingDictCopy);
		}
	}, [teamsizeEditedDown]);

	async function processClassAnnotations(newClassIds) {
		if (newClassIds && newClassIds.length > 0) {
			const classesAffectedByRoles = [];
			const removedRoles = []; // Array to store removed roles

			await newClassIds.forEach((classId) => {
				if (!checkIfEmpty(autoRoles)) {
					const classIdCtype = classId.ctype;
					const nonAutoRoles = autoRoles[classIdCtype];
					const ignoredByRoleList = [];

					classId.roles = classId.roles.filter((role) => {
						if (
							nonAutoRoles &&
							nonAutoRoles.length > 0 &&
							nonAutoRoles.includes(role.class_role.toLowerCase())
						) {
							ignoredByRoleList.push(role);
							classId.teamsize = classId.teamsize - 1;
							classesAffectedByRoles.push(classId);
							removedRoles.push({ role: role, classId: classId });
							return false; // Remove this role
						}
						return true; // Keep this role
					});
					if (classId.roles.length === 0 || classId.teamsize === 0) {
						classId.rolesFiltered = true;
					}
					return classId;
				} else {
					return classId;
				}
			});
			setRemovedByRole(removedRoles);

			const editedDownList = [];
			await newClassIds.forEach((classId) => {
				if (classId.roles) {
					if (classId.roles.length < classId.teamsize) {
						classId.teamsize = classId.roles.length;
						editedDownList.push(classId);
					} else {
						classId.teamsize = classId.roles.length;
					}
				}
			});
			setTeamsizeEditedDown(editedDownList);
			await newClassIds.forEach((classId) => {
				if (classId.csize === 0) {
					classId.empty = true;
				} else if (classId.csize < 4) {
					classId.tooSmall = true;
					classId.empty = false;
					if (
						(classId.roles && classId.roles.length === 0) ||
						classId.roles === undefined
					) {
						classId.noInstructor = true;
					} else if (classId.roles && classId.roles.length > 0) {
						classId.noInstructor = false;
						if (classId.c_edate === null || classId.c_edate === "") {
							classId.noEndDate = true;
						} else {
							classId.noEndDate = false;
							if (classId.examdate === null || classId.examdate === "") {
								classId.noExamDate = true;
							} else {
								classId.noExamDate = false;
							}
						}
					}
				} else if (classId.csize >= 4) {
					classId.empty = false;
					classId.tooSmall = false;
					if (
						(classId.roles && classId.roles.length === 0) ||
						classId.roles === undefined
					) {
						classId.noInstructor = true;
					} else if (classId.roles && classId.roles.length > 0) {
						classId.noInstructor = false;

						if (classId.c_edate === null || classId.c_edate === "") {
							classId.noEndDate = true;
						} else {
							classId.noEndDate = false;
							if (classId.examdate === null || classId.examdate === "") {
								classId.noExamDate = true;
							} else {
								classId.noExamDate = false;
							}
						}
					}
				}
			});
		}
		return newClassIds;
	}

	async function processWebClassRemoval(classIds, classesClassIdsArray) {
		await classIds.forEach((classId) => {
			if (classesClassIdsArray && classesClassIdsArray.length > 0) {
				if (classesClassIdsArray.includes(classId.classid)) {
					classId.webClass = true;
				} else {
					classId.webClass = false;
				}
			}
		});
		const withWebClasses = classIds.filter(
			(classId) => classId.webClass === true
		);
		setWebClasses(withWebClasses);
		const withoutWebClasses = classIds.filter(
			(classId) => classId.webClass === false
		);
		return withoutWebClasses;
	}

	async function processCombinedClassRemoval(classIds, combinedClassIds) {
		let combinedBool = false;
		const removedForCombine = [];
		await classIds.forEach((classId) => {
			if (
				combinedClassIds &&
				combinedClassIds.length > 0 &&
				!classId.empty &&
				!classId.tooSmall &&
				!classId.noInstructor
			) {
				const matchingItem = combinedClassIds.find(
					(item) => item.classid === classId.classid
				);
				if (matchingItem) {
					const combinedId = matchingItem.cc_id;
					const allCombinedWith = combinedClassIds.filter(
						(item) => item.cc_id === combinedId
					);
					const allCombinedWithNoCurrent = allCombinedWith.filter(
						(item) => item.classid !== classId.classid
					);
					classId.comb_classes = allCombinedWithNoCurrent;
					removedForCombine.push(allCombinedWithNoCurrent);
					combinedBool = true;
				}
			}
		});
		if (combinedBool) {
			await classIds.forEach((classId) => {
				if (classId.comb_classes && classId.comb_classes.length > 0) {
					const combClasses = classId.comb_classes;
					classId.combiningWith = [];
					classId.combiningWith.push(classId.classid);
					combClasses.forEach((combClass) => {
						classId.combiningWith.push(combClass.classid);
					});
				}
			});
			const withCombinedClasses = classIds.filter(
				(classId) => classId.comb_classes && classId.comb_classes.length > 0
			);
			const withoutCombinedClasses = classIds.filter(
				(classId) => !classId.comb_classes || classId.comb_classes.length === 0
			);
			const withCombinedClassesNoDuplicates = await filterDuplicateObjects(
				withCombinedClasses
			);
			const replacementCombClasses = [];
			withCombinedClassesNoDuplicates.forEach((classId) => {
				if (classId.comb_classes && classId.comb_classes.length > 0) {
					const combClasses = classId.comb_classes;
					const combClassIds = [];
					let combTitle = "";
					let combClassId = "";
					let combCSize = classId.csize;
					combClasses.forEach((combClass) => {
						combClassIds.push(combClass.classid);
						combCSize += combClass.csize;
						combTitle = combClass.cc_title;
						combClassId = combClass.cc_id;
					});

					const combClassIDString = combClassIds.join(",");
					const newClass = {
						acyear: classId.acyear,
						beenCombined: true,
						c_edate: classId.c_edate,
						c_sdate: classId.c_sdate,
						c_location: classId.c_location,
						catalog: classId.catalog,
						classid: classId.classid,
						cnumber: classId.cnumber,
						comb_classes: combClassIDString,
						csize: combCSize,
						ctitle: combTitle,
						ctype: classId.ctype,
						dept: classId.dept,
						description: classId.description,
						empty: classId.empty,
						examdate: classId.examdate,
						exclude_class: classId.exclude_class,
						faculty: classId.faculty,
						noEndDate: classId.noEndDate,
						noExamDate: classId.noExamDate,
						noInstructor: classId.noInstructor,
						original: classId.original,
						program: classId.program,
						roles: classId.roles,
						schedule: classId.schedule,
						section: classId.section,
						subject_id: classId.subject_id,
						survey_end: classId.survey_end,
						survey_start: classId.survey_start,
						teamsize: classId.teamsize,
						term: classId.term,
						webClass: classId.webClass,
					};
					replacementCombClasses.push(newClass);
				}
			});
			const newClassIds = [
				...withoutCombinedClasses,
				...replacementCombClasses,
			];
			setRemovedForCombine(removedForCombine);
			return newClassIds;
		}
	}

	async function filterDuplicateObjects(inputArray) {
		const uniqueObjects = [];
		inputArray.forEach((currentObject) => {
			const isUnique = !uniqueObjects.some((uniqueObject) =>
				arraysHaveSameValues(
					currentObject.combiningWith,
					uniqueObject.combiningWith
				)
			);
			if (isUnique) {
				uniqueObjects.push(currentObject);
			}
		});
		return uniqueObjects;
	}

	function arraysHaveSameValues(arr1, arr2) {
		if (arr1.length !== arr2.length) {
			return false;
		}
		arr1.sort();
		arr2.sort();
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i]) {
				return false;
			}
		}
		return true;
	}

	const handleTermChange = (event) => {
		setTermValue(event.target.value);
	};

	return (
		<Box
			sx={{
				width: "80vw",
				height: step3 && step2 ? "190vh" : "130vh",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				margin: "auto",
			}}
		>
			<Typography
				variant="h4"
				sx={{ marginTop: "5%" }}
			>
				Auto Requisition
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "20px",
				}}
			>
				<AutocompleteComponent
					options={departments ? departments : []}
					value={autocompleteValue}
					setValue={setAutocompleteValue}
					inputValue={autocompleteInputValue}
					setInputValue={setAutocompleteInputValue}
					label="Department"
					width="300px"
					variant="standard"
					autoRequisition
					setStep2={setStep2}
					setStep3={setStep3}
					setStep4={setStep4}
					setSuitableClassIds={setSuitableClassIds}
					setNoEndDates={setNoEndDates}
					setNoExamDates={setNoExamDates}
					setGoodClassIds={setGoodClassIds}
					setNoInstructorClassIds={setNoInstructorClassIds}
					setWebClasses={setWebClasses}
					setSelectedRow={setSelectedRow}
					setStagedClasses={setStagedClasses}
					setMbaOrNot={setMbaOrNot}
					setRemovedForCombine={setRemovedForCombine}
					setClassIds={setClassIds}
					setAutoRoles={setAutoRoles}
					setLoggingDict={setLoggingDict}
				/>

				<ListSelect
					value={termValue ? termValue : ""}
					list={termsList}
					variant="standard"
					label="Term"
					onChange={handleTermChange}
					width="300px"
					marginBottom="0px"
				/>
				{canBeMBAOrNot && (
					<FormControlLabel
						label="MBA?"
						control={
							<Checkbox
								checked={mbaOrNot}
								onChange={() => setMbaOrNot(!mbaOrNot)}
							/>
						}
					/>
				)}
			</Box>
			<Typography
				variant="h6"
				sx={{ marginTop: "1%", marginBottom: "1%" }}
				/* color={"red"} */
				fontWeight={600}
				textAlign={"center"}
			>
				*Disclaimer*: This tool is a work in progress. We are working to fine
				tune the logic and take into account each department's nuances. In the
				meantime, please use this tool with caution and double (or triple) check
				the results.
			</Typography>
			<Box
				sx={{
					width: "100%",
					minHeight: "90vh",
					display: "flex",
					justifyContent: "center",
					outline: "1px solid #E0E0E0",
					borderRadius: "5px",
					gap: "5%",
					p: 3,
				}}
			>
				{!step2 && (
					<ExcludeClasses
						classIds={classIds}
						goodClassIds={goodClassIds}
						setGoodClassIds={setGoodClassIds}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						noInstructorClassIds={noInstructorClassIds}
						setOpen={setOpen}
						setStep2={setStep2}
						webClasses={webClasses}
						step2disabled={step2disabled}
						suitableClassIds={suitableClassIds}
						noEndDates={noEndDates}
						noExamDates={noExamDates}
						stagedClasses={stagedClasses}
						setStagedClasses={setStagedClasses}
						removedForCombine={removedForCombine}
						removedByRole={removedByRole}
					/>
				)}
				{!step3 && step2 && (
					<ResolveInstructors
						classIds={classIds}
						goodClassIds={goodClassIds}
						setGoodClassIds={setGoodClassIds}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						noInstructorClassIds={noInstructorClassIds}
						setOpen={setOpen}
						setStep3={setStep3}
						webClasses={webClasses}
						setStep2={setStep2}
						suitableClassIds={suitableClassIds}
						noEndDates={noEndDates}
						noExamDates={noExamDates}
						stagedClasses={stagedClasses}
						setStagedClasses={setStagedClasses}
						removedForCombine={removedForCombine}
						removedByRole={removedByRole}
					/>
				)}
				{step3 && !step4 && (
					<ResolveDates
						classIds={classIds}
						goodClassIds={goodClassIds}
						setGoodClassIds={setGoodClassIds}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						noInstructorClassIds={noInstructorClassIds}
						setOpen={setOpen}
						setStep3={setStep3}
						webClasses={webClasses}
						setStep4={setStep4}
						suitableClassIds={suitableClassIds}
						setSuitableClassIds={setSuitableClassIds}
						noEndDates={noEndDates}
						setNoEndDates={setNoEndDates}
						noExamDates={noExamDates}
						setNoExamDates={setNoExamDates}
						stagedClasses={stagedClasses}
						setStagedClasses={setStagedClasses}
						removedForCombine={removedForCombine}
						removedByRole={removedByRole}
					/>
				)}

				{step4 && step3 && (
					<GroupClasses
						classIds={classIds}
						goodClassIds={goodClassIds}
						setGoodClassIds={setGoodClassIds}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						noInstructorClassIds={noInstructorClassIds}
						setOpen={setOpen}
						webClasses={webClasses}
						suitableClassIds={suitableClassIds}
						setSuitableClassIds={setSuitableClassIds}
						noEndDates={noEndDates}
						setNoEndDates={setNoEndDates}
						noExamDates={noExamDates}
						setNoExamDates={setNoExamDates}
						deptBlockIds={deptBlockIds}
						autoRoles={autoRoles}
						regularEndOfTerm={regularEndOfTerm}
						language={language}
						deptId={autocompleteValue.key}
						stagedClasses={stagedClasses}
						setStagedClasses={setStagedClasses}
						allBlockItems={allBlockItems}
						deptName={autocompleteValue.display_name}
						removedForCombine={removedForCombine}
						removedByRole={removedByRole}
						loggingDict={loggingDict}
						setLoggingDict={setLoggingDict}
						setAutocompleteValue={setAutocompleteValue}
						setStep2={setStep2}
						setStep3={setStep3}
						setStep4={setStep4}
					/>
				)}
			</Box>
			<AddInstructorAuto
				currentRow={selectedRow}
				open={open}
				setOpen={setOpen}
				setGoodClassIds={setGoodClassIds}
				goodClassIds={goodClassIds}
				noInstructorClassIds={noInstructorClassIds}
				setNoInstructorClassIds={setNoInstructorClassIds}
				classIds={classIds}
				setClassIds={setClassIds}
				noEndDates={noEndDates}
				setNoEndDates={setNoEndDates}
				noExamDates={noExamDates}
				setNoExamDates={setNoExamDates}
			/>
		</Box>
	);
}
