import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect, useContext } from "react";
import { Typography, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useAllItemsQuery } from "../../../Hooks/useAllItemsQuery";
import isAuthenticated from "../../../components/Utils/isAuthenticated";
import Snackbar from "@mui/material/Snackbar";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	height: "95vh",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 2,
	display: "flex",
	flexDirection: "column",
};

export default function TrackReqModal(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const [itemListStringDisplay, setItemListStringDisplay] = useState("");
	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const copyClassIds = () => {
		const updatedList = props.requisition.classes;
		let list = [];
		let nodupesList = [];
		if (updatedList && updatedList.length > 0) {
			updatedList.forEach((row) => {
				let item = row.classid;

				if (row.comb_classes.length > 0) {
					item = item + "c";
					list.push(item);
				} else {
					list.push(item);
				}
			});

			//remove dupes
			nodupesList = [...new Set(list)];
			const sortedList = nodupesList.sort();
			//join into string
			list = sortedList.join("\n");
		}

		// Copy list to clipboard
		navigator.clipboard.writeText(list).then(
			function () {
				setSnackbarOpen(true);
			},
			function (err) {
				console.error("Could not copy text: ", err);
			}
		);

		return list;
	};

	useEffect(() => {
		if (props.requisition.itemList) {
			const itemListString = props.requisition.itemList.map((item) => {
				return item.itemno;
			});
			setItemListStringDisplay(itemListString.join(", "));
		}
	}, [props.requisition]);

	const [numOfTTItems, setNumOfTTItems] = useState(0);
	useEffect(() => {
		if (props.requisition.itemList) {
			const teamTeachingItems = props.requisition.itemList.filter(
				(item) => item.repeat === "Yes"
			);
			setNumOfTTItems(teamTeachingItems.length);
			const teamTeachingItemsString = teamTeachingItems.map((item) => {
				return item.itemno;
			});
			setTeamTeachingItemsStringDisplay(teamTeachingItemsString.join(", "));
		}
	}, [props.requisition]);
	const [teamTeachingItemsStringDisplay, setTeamTeachingItemsStringDisplay] =
		useState("");

	const { data: allItemsData, status: allItemsStatus } =
		useAllItemsQuery(token);

	const [itemList, setItemList] = useState([]);

	useEffect(() => {
		if (props.requisition.itemList) {
			const itemList = props.requisition.itemList.map((item) => {
				if (item.itemno >= 1001 && item.itemno <= 1999) {
					const itemIndex = allItemsData.findIndex(
						(i) => i.itemno === item.itemno && i.reqnum === item.reqnum
					);
					return itemIndex !== -1
						? { ...item, ...allItemsData[itemIndex] }
						: item;
				} else {
					const itemIndex = allItemsData.findIndex(
						(i) => i.itemno === item.itemno
					);
					return itemIndex !== -1
						? { ...item, ...allItemsData[itemIndex] }
						: item;
				}
			});
			setItemList(itemList);
		}
	}, [props.requisition, allItemsData]);

	const handleClose = (event, reason) => {
		if (reason && reason === "backdropClick") return;
		props.setOpen(false);
	};
	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setSnackbarOpen(false);
	};

	const copyReqnum = () => {
		navigator.clipboard.writeText(props.requisition.reqnum).then(
			function () {
				setSnackbarOpen(true);
			},
			function (err) {
				console.error("Could not copy text: ", err);
			}
		);
	};

	const [uniqueClassIdCount, setUniqueClassIdCount] = useState(0);
	const [uniqueClassIds, setUniqueClassIds] = useState([]);

	useEffect(() => {
		if (props.requisition.classes) {
			const classIds = props.requisition.classes.map((classObj) => {
				return classObj.classid;
			});
			const uniqueClassIds = [...new Set(classIds)];
			setUniqueClassIds(uniqueClassIds);
			setUniqueClassIdCount(uniqueClassIds.length);
		}
	}, [props.requisition]);

	return (
		<div>
			<Modal
				open={props.open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box
						sx={{
							/*  outline: "1px solid red", */ display: "flex",
							flexDirection: "column",
							height: "95%",
						}}
					>
						<Snackbar
							open={snackbarOpen}
							autoHideDuration={2000}
							onClose={handleCloseSnackbar}
							message="Copied to clipboard!"
						/>
						<Typography fontSize={16} fontWeight={600} textAlign={"center"}>
							View/Edit Requisition
						</Typography>
						<Box
							sx={{
								outline: "2px solid #E0E0E0",
								borderRadius: "5px",
								display: "flex",
								padding: "4px",
								justifyContent: "space-between",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "65%" /* outline: "1px solid yellow" */,
								}}
							>
								<Box sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
									<Typography fontSize={16} fontWeight={600}>
										Requisition:
									</Typography>
									<Button onClick={copyReqnum} sx={{}}>
										<Typography fontSize={24} fontWeight={600} color="black">
											{props.requisition.reqnum} (Click to Copy)
										</Typography>
									</Button>
								</Box>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={16} fontWeight={600}>
										Date Created:
									</Typography>
									<Typography fontSize={16}>
										{props.requisition.rqdate}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={24}>Block IDs:</Typography>
									<Typography fontSize={24} fontWeight={600}>
										{props.requisition.blkid1}, {props.requisition.blkid2},{" "}
										{props.requisition.blkid3}{" "}
										{numOfTTItems > 0 ? " (TEAM)" : " (REGULAR)"}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={16} fontWeight={600}>
										Total Numerical Questions:
									</Typography>
									<Typography fontSize={16}>
										{props.requisition.totrtg}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={16} fontWeight={600}>
										Total Open-Ended Questions:
									</Typography>
									<Typography fontSize={16}>
										{props.requisition.totopn}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={16} fontWeight={600}>
										Items:
									</Typography>
									{itemListStringDisplay.length > 56 && (
										<Typography fontSize={16}>
											{itemListStringDisplay.slice(
												0,
												itemListStringDisplay.indexOf(",", 56) + 1
											)}
											{"\n"}
											{itemListStringDisplay.slice(
												itemListStringDisplay.indexOf(",", 56) + 1
											)}
										</Typography>
									)}
									{itemListStringDisplay.length <= 56 && (
										<Typography fontSize={16}>
											{itemListStringDisplay}
										</Typography>
									)}
								</Box>
								<Box
									sx={{
										display: "flex",
										gap: "3px",
										marginBottom: "20px",
									}}
								>
									<Typography fontSize={16} fontWeight={600}>
										Team Teaching Items:
									</Typography>
									{numOfTTItems === 0 && (
										<Typography fontSize={16} sx={{}}>
											NONE
										</Typography>
									)}
									{numOfTTItems > 0 && (
										<Typography fontSize={16} color="blue">
											({numOfTTItems}): {teamTeachingItemsStringDisplay}
										</Typography>
									)}
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "35%",
									/* outline: "1px solid purple", */
								}}
							>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={16} fontWeight={600}>
										Requested By:
									</Typography>
									<Typography fontSize={16}>
										{props.requisition.raddr1}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={16} fontWeight={600}>
										Department:
									</Typography>
									<Typography fontSize={16}>
										{props.requisition.raddr2}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={24}>Start Date:</Typography>
									<Typography fontSize={24} fontWeight={600}>
										{dayjs(props.requisition.start_date).format("DD/MMM/YY")}
									</Typography>
								</Box>
								<Box sx={{ display: "flex", gap: "3px" }}>
									<Typography fontSize={24}>End Date:</Typography>
									<Typography fontSize={24} fontWeight={600}>
										{dayjs(props.requisition.end_date).format("DD/MMM/YY")}
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box sx={{ width: "100%", height: "100%", display: "flex" }}>
							<Box sx={{ width: "50%", height: "100%", p: 1 }}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",

										maxHeight: "50%",
										width: "100%",
										overflowY: "auto",
									}}
								>
									{itemList.map((item, index) => {
										return (
											<Box
												key={index}
												sx={{
													display: "flex",
													flexDirection: "row",
													gap: "3px",
													padding: "5px",
													border: "1px solid #E0E0E0",
													borderRadius: "5px",
												}}
											>
												<Box
													sx={{
														display: "flex",

														gap: "3px",
													}}
												>
													<Typography fontSize={16}>{item.itemno})</Typography>
													<Typography fontSize={16}>{item.text}</Typography>
													<Typography fontSize={16}>({item.itmtyp})</Typography>
												</Box>
											</Box>
										);
									})}
								</Box>
							</Box>
							<Box sx={{ width: "50%", height: "100%", p: 1 }}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",

										width: "100%",
										overflowY: "auto",
									}}
								>
									<Button
										onClick={copyClassIds}
										variant="contained"
										sx={{ mb: 1 }}
									>
										Copy Class IDs (UNIQUE: {uniqueClassIdCount})
									</Button>
									{props.requisition.classes &&
										props.requisition.classes.map((classObj, index) => {
											return (
												<Box
													key={index}
													sx={{
														display: "flex",
														flexDirection: "row",

														padding: "5px",
														border: "1px solid #E0E0E0",
													}}
												>
													<Box
														sx={{
															display: "flex",
															justifyContent: "space-between",
															gap: "8px",
														}}
													>
														<Typography fontSize={16}>
															{classObj.clsnum}
														</Typography>
														<Typography fontSize={16}> | </Typography>
														<Typography fontSize={16}>
															{classObj.classid}
														</Typography>
														<Typography fontSize={16}> | </Typography>
														<Typography fontSize={16} color="blue">
															{classObj.ctitle}
														</Typography>
														<Typography fontSize={16}> | </Typography>
														<Typography fontSize={16}>
															{classObj.csize}
														</Typography>
														<Typography fontSize={16}> | </Typography>
														<Typography fontSize={16}>
															{classObj.name}
														</Typography>
														<Typography fontSize={16}> | </Typography>
														<Typography fontSize={16}>
															COMB:{classObj.comb_classes}
														</Typography>
													</Box>
												</Box>
											);
										})}
								</Box>
							</Box>
						</Box>
					</Box>
					<Button
						variant="contained"
						onClick={handleClose}
						sx={{
							position: "absolute",
							bottom: "5px",
							height: "40px",
							width: "400px",
							alignSelf: "center",
						}}
					>
						Done
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
