import * as React from "react";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ListItemButton, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useEffect, useContext } from "react";
import { ClassesContext } from "../../../../../context/RequisitionContext";
import LockIcon from "@mui/icons-material/Lock";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
	return [...a, ...not(b, a)];
}

export default function BlockItemDisplay(props) {
	const { chosenClasses } = useContext(ClassesContext);
	const [open, setOpen] = React.useState(false);
	const [enforcedList, setEnforcedList] = React.useState(false);

	useEffect(() => {
		//needs to be an effect
		if (
			props.list.every(
				(item) =>
					(item.itemno >= 1 && item.itemno <= 18) || (item.itemno >= 105 && item.itemno <= 122)
			)
		) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [props.list]);

	useEffect(() => {
		if (chosenClasses.some((c) => c.csize < 10 && c.csize > 0)) {
			props.list.forEach((item) => {
				if (item.itemno < 900 || item.itemno > 999) {
					item.disabled = true;
				} else {
					item.disabled = false;
				}
			});
		}
	}, [chosenClasses, props.list]);

	useEffect(() => {
		if (props.list && props.list.length > 0) {
			props.list.forEach((item) => {
				if ((item.itemno >= 1 && item.itemno <= 18) || (item.itemno >= 105 && item.itemno <= 122)) {
					item.disabled = true;
				} else {
					item.disabled = false;
				}
			});
		}
	}, [props.list]);

	const handleToggle = (value) => () => {
		const currentIndex = props.checked.indexOf(value);
		const newChecked = [...props.checked];
		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		props.setChecked(newChecked);
	};

	const numberOfChecked = (items) => intersection(props.checked, items).length;

	useEffect(() => {
		if (numberOfChecked(props.list) === props.list.length) {
			props.setChecked(not(props.checked, props.list));
		} else {
			props.setChecked(union(props.checked, props.list));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.list]);

	const handleDone = () => {
		props.checked.forEach((item) => {
			if (item.team_teaching === "Y" && props.teamTeachingAgree) {
				item.repeat = true;
			} else {
				item.repeat = false;
			}
		});
		props.setChosenItems(props.checked);
		props.handleClose();
	};

	useEffect(() => {
		if (props.list && props.list.length > 0) {
			const some = props.list.some(
				(item) =>
					(item.itemno >= 1 && item.itemno <= 18) || (item.itemno >= 105 && item.itemno <= 122)
			);
			if (some) {
				setEnforcedList(true);
			} else {
				setEnforcedList(false);
			}
		} else {
			setEnforcedList(false);
		}
	}, [props.list]);

	if (props.allBlockItemsLoading) {
		return <div>Loading...</div>;
	}

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				justifyContent: "center",
				width: "100%",
				height: "100%",
			}}
		>
			<Box
				sx={{
					alignItems: "center",
					display: "flex",
					justifyContent: "center",
					width: "100%",
					height: "100%",
					marginTop: "30px",
				}}
			>
				<Box sx={{ width: "80%", height: "100%" }}>
					<Card
						sx={{
							outline: "1px solid black",
						}}
					>
						<List
							sx={{
								height: "60vh",
								bgcolor: "background.paper",
								overflow: "auto",
							}}
							dense
							component="div"
							role="list"
						>
							{enforcedList && (
								<ListItemButton
									key="lockedItems"
									role="listitem"
									onClick={() => setOpen(!open)}
									sx={{
										height: "35px",
									}}
								>
									<ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
										{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</ListItemIcon>
									<ListItemText
										primary={`SPOT Instrument Questions (18)`}
										sx={{ display: "flex" }}
									/>
								</ListItemButton>
							)}
							{props.list &&
								props.list.length > 0 &&
								open &&
								props.listValue &&
								props.listValue !== "" &&
								props.list.map((value) => {
									if (
										(value.itemno >= 1 && value.itemno <= 18) ||
										(value.itemno >= 105 && value.itemno <= 122)
									) {
										const labelId = `transfer-list-all-item-${value}-label`;

										return (
											<ListItemButton
												disabled={value.disabled}
												key={value.itemno}
												role="listitem"
												onClick={handleToggle(value)}
												sx={{
													height: "35px",
												}}
											>
												<ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
													<LockIcon />
												</ListItemIcon>
												<ListItemText
													id={labelId}
													primary={`${value.itemno} - ${value.text} `}
													sx={{ display: "flex" }}
												/>
											</ListItemButton>
										);
									}
									return null;
								})}

							{props.list &&
								props.list.length > 0 &&
								props.listValue &&
								props.listValue !== "" &&
								props.list.map((value) => {
									if (
										!(value.itemno >= 1 && value.itemno <= 18) &&
										!(value.itemno >= 105 && value.itemno <= 122)
									) {
										const labelId = `transfer-list-all-item-${value}-label`;

										return (
											<ListItemButton
												disabled={value.disabled}
												key={value.itemno}
												role="listitem"
												onClick={handleToggle(value)}
												sx={{
													height: "35px",
												}}
											>
												<ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
													<Checkbox
														checked={props.checked.indexOf(value) !== -1}
														tabIndex={-1}
														inputProps={{
															"aria-labelledby": labelId,
														}}
													/>
												</ListItemIcon>
												<ListItemText
													id={labelId}
													primary={`${value.itemno} - ${value.text} `}
													sx={{ display: "flex" }}
												/>
											</ListItemButton>
										);
									}
									return null;
								})}
						</List>
					</Card>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-end",
					alignItems: "center",
					width: "100%",
					marginTop: "20px",
				}}
			>
				{props.chosenBlock === "" ? (
					<Button sx={{ width: "80%" }} color="error" variant="contained" onClick={handleDone}>
						Cancel
					</Button>
				) : (
					<Button sx={{ width: "80%" }} variant="contained" onClick={handleDone}>
						Confirm
					</Button>
				)}
			</Box>
		</Box>
	);
}
