import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Divider, Link } from "@mui/material";
import logo from "../../images/UA_Logo_WHT_RGB.png";
import spotgraphic from "../../images/spot-graphic2022.png";
import isAuthenticated from "../../components/Utils/isAuthenticated";
import GiveRoleForm from "../Access/GiveRoleForm";

export default function HomeLanding() {
	let auth = isAuthenticated();
	/* const token = auth.token; */
	const ccid = auth.ccid;
	const access = auth.access;

	if (!ccid || !access) {
		return (
			<div style={{ height: "100%", maxHeight: "calc(100% - 160px)" }}>
				<Box
					sx={{
						height: "510px",
						backgroundColor: "white",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "20px",
					}}
				>
					<Typography
						textAlign={"center"}
						fontSize={20}
					>
						Oops! Something went wrong and it seems like you don't have access
						to this application.
					</Typography>
					<Typography
						textAlign={"center"}
						fontSize={20}
					>
						Please contact us at tsqsweb@ualberta.ca for assistance.
					</Typography>
					<GiveRoleForm
						buttonTitle="Trying to Submit an Exam Grading Request?"
						buttonVariant="outlined"
						buttonWidth="350px"
					/>
				</Box>
			</div>
		);
	} else {
		return (
			<div style={{ height: "100%", maxHeight: "calc(100% - 160px)" }}>
				<Box
					sx={{
						height: "510px",
						backgroundColor: "white",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Grid
						container
						justify="center"
						alignItems="center"
						style={{ height: "65vh" }}
					>
						<Grid
							item
							md={6}
							style={{ padding: "20px" }}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									alt="SPOT"
									src={spotgraphic}
									loading="eager"
									style={{
										maxWidth: "auto",
										height: "auto",
										borderRadius: "20px",
										boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
									}}
								/>
							</div>
						</Grid>
						<Grid
							item
							md={6}
							style={{ padding: "20px" }}
						>
							<Typography
								component={"div"}
								variant="h3"
								gutterBottom
								// className="text-white"
								sx={{ fontWeight: "350", fontSize: "3.4rem" }}
							>
								Collect feedback that counts
							</Typography>
							<Typography
								component={"div"}
								variant="body1"
								sx={{ fontWeight: "300", fontSize: "1.25rem" }}
							>
								Student Perspectives of Teaching (SPOT) gather feedback from
								classes to help instructors, departments and faculties improve
								curriculum and instruction. The collection of SPOT is regulated
								by University of Alberta Policies and Procedures (
								<Link
									href="https://policiesonline.ualberta.ca/PoliciesProcedures/Procedures/Student-Input-to-the%20Evaluation-of-Teaching-and-Learning-Procedure.pdf"
									target="_"
								>
									UAPPOL
								</Link>
								). The current version of this policy may be viewed at the
								Teaching, Learning and Evaluation Policy.
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box
					sx={{
						height: "450px",
						backgroundColor: "#275D38",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						padding: "10px",
					}}
				>
					<Typography
						component={"div"}
						variant="h3"
						gutterBottom
						color="white"
						sx={{ fontWeight: "350", fontSize: "3.4rem" }}
					>
						SPOT Process
					</Typography>

					<Box>
						<Grid
							container
							style={{
								paddingLeft: "100px",
								paddingRight: "100px",
								paddingTop: "10px",
							}}
						>
							<Grid
								item
								md={4}
								style={{
									paddingLeft: "35px",
									paddingRight: "15px",
									paddingBottom: "5px",
								}}
							>
								<Typography
									component={"div"}
									variant="h6"
									color="white"
									sx={{ fontWeight: "350", fontSize: "1.25rem" }}
								>
									<b>Step 1:</b>
								</Typography>
								<Typography
									component={"div"}
									variant="body2"
									color="white"
									sx={{ fontWeight: "300", fontSize: "1rem" }}
								>
									Prior to the start of the feedback period, instructors will
									receive an email announcement with information and important
									dates for the survey period.
								</Typography>
							</Grid>
							<Grid
								item
								md={4}
								style={{
									paddingLeft: "35px",
									paddingRight: "35px",
									paddingBottom: "5px",
								}}
							>
								<Typography
									component={"div"}
									variant="h6"
									color="white"
									sx={{ fontWeight: "350", fontSize: "1.25rem" }}
								>
									<b>Step 2:</b>
								</Typography>
								<Typography
									component={"div"}
									variant="body2"
									color="white"
									sx={{ fontWeight: "300", fontSize: "1rem" }}
								>
									Once they have received the announcement, instructors will
									notify their students about the upcoming SPOT surveys to
									encourage participation.
								</Typography>
							</Grid>
							<Grid
								item
								md={4}
								style={{
									paddingLeft: "35px",
									paddingRight: "35px",
									paddingBottom: "5px",
								}}
							>
								<Typography
									component={"div"}
									variant="h6"
									color="white"
									sx={{ fontWeight: "350", fontSize: "1.25rem" }}
								>
									<b>Step 3:</b>
								</Typography>
								<Typography
									component={"div"}
									variant="body2"
									color="white"
									sx={{ fontWeight: "300", fontSize: "1rem" }}
								>
									When the SPOT surveys become available, students will receive
									emails with instructions and the appropriate links to complete
									their feedback.
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							style={{
								paddingLeft: "100px",
								paddingRight: "100px",
								paddingBottom: "10px",
							}}
						>
							<Grid
								item
								md={4}
								style={{
									paddingLeft: "35px",
									paddingRight: "35px",
									paddingTop: "5px",
								}}
							>
								<Typography
									component={"div"}
									variant="h6"
									color="white"
									sx={{ fontWeight: "350", fontSize: "1.25rem" }}
								>
									<b>Step 4:</b>
								</Typography>
								<Typography
									component={"div"}
									variant="body2"
									color="white"
									sx={{ fontWeight: "300", fontSize: "1rem" }}
								>
									Students will have until the end of the survey period to
									submit their responses. Surveys can be completed on any device
									with internet access, can be put down and resumed at any time,
									and are anonymous.
								</Typography>
							</Grid>
							<Grid
								item
								md={4}
								style={{
									paddingLeft: "35px",
									paddingRight: "35px",
									paddingTop: "5px",
								}}
							>
								<Typography
									component={"div"}
									variant="h6"
									color="white"
									sx={{ fontWeight: "350", fontSize: "1.25rem" }}
								>
									<b>Step 5:</b>
								</Typography>
								<Typography
									component={"div"}
									variant="body2"
									color="white"
									sx={{ fontWeight: "300", fontSize: "1rem" }}
								>
									If students have not completed their SPOT surveys, they will
									periodically receive reminders via email, with a final
									reminder being delivered on the last day of the survey window.
								</Typography>
							</Grid>
							<Grid
								item
								md={4}
								style={{
									paddingLeft: "35px",
									paddingRight: "35px",
									paddingTop: "5px",
								}}
							>
								<Typography
									component={"div"}
									variant="h6"
									color="white"
									sx={{ fontWeight: "350", fontSize: "1.25rem" }}
								>
									<b>Step 6:</b>
								</Typography>
								<Typography
									component={"div"}
									variant="body2"
									color="white"
									sx={{ fontWeight: "300", fontSize: "1rem" }}
								>
									Once the SPOT surveys have concluded and grades are finalized,
									instructors and students will be able to view survey results
									online through the SPOT Survey Report.
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Box sx={{ height: "400px", backgroundColor: "white" }}>
					<Grid
						container
						justify="center"
						alignItems="flex-start"
						style={{
							paddingLeft: "130px",
							paddingRight: "130px",
							paddingTop: "65px",
						}}
					>
						<Grid
							item
							md={6}
							style={{ paddingLeft: "35px", paddingRight: "35px" }}
						>
							<Typography
								component={"div"}
								variant="h3"
								gutterBottom
								sx={{ fontWeight: "350", fontSize: "3.4rem" }}
							>
								Contact Us
							</Typography>
							<Typography
								component={"div"}
								variant="body1"
								sx={{ fontWeight: "300", fontSize: "1rem" }}
							>
								You've got questions, and we've got advisors with answers. Check
								our FAQ page below for common questions and instructions for
								self serve options.
							</Typography>
							<br></br>
							<Typography
								component={"div"}
								variant="body1"
								sx={{ fontWeight: "300", fontSize: "1rem" }}
							>
								Support staff can be reached by emailing{" "}
								<a
									href="mailto:test.scoring@ualberta.ca"
									target="noreferrer"
									style={{
										textDecoration: "underline",
										color: "#275D38",
										fontSize: "1rem",
										fontWeight: "400",
									}}
								>
									test.scoring@ualberta.ca
								</a>{" "}
								or calling 780.492.2741. The TSQS office is located at{" "}
								<a
									href="https://www.ualberta.ca/maps.html?l=53.526142,-113.529437&z=17&campus=north_campus&b=gsb"
									target="noreferrer"
									style={{
										textDecoration: "underline",
										color: "#275D38",
										fontSize: "1rem",
										fontWeight: "400",
									}}
								>
									240 General Services Building.
								</a>
							</Typography>
							<br></br>
							<Typography
								component={"div"}
								sx={{ fontWeight: "300", fontSize: "1rem" }}
							>
								<a
									href="https://www.ualberta.ca/information-services-and-technology/services/test-scoring-questionnaire-services/student-perspectives-of-teaching.html"
									target="noreferrer"
									style={{
										textDecoration: "underline",
										color: "#275D38",
										fontSize: "1rem",
										fontWeight: "400",
									}}
								>
									Frequently Asked Questions
								</a>
							</Typography>
						</Grid>
						<Grid
							item
							md={1}
							style={{ paddingLeft: "35px", paddingRight: "35px" }}
						></Grid>
						<Grid
							item
							md={5}
							style={{
								paddingLeft: "35px",
								paddingRight: "35px",
								textAlign: "center",
							}}
						>
							<Typography
								component={"div"}
								variant="h3"
								sx={{ fontWeight: "300", fontSize: "3.4rem" }}
							>
								Business Hours
							</Typography>
							<br></br>
							<Typography
								component={"div"}
								variant="body1"
								sx={{ fontWeight: "300", fontSize: "1rem" }}
							>
								Monday to Friday
							</Typography>
							<Typography
								component={"div"}
								variant="body1"
								sx={{ fontWeight: "300", fontSize: "1rem" }}
							>
								8:00 a.m. - 12:00 p.m.
							</Typography>
							<Typography
								component={"div"}
								variant="body1"
								style={{
									fontStyle: "italic",
									fontWeight: "300",
									fontSize: "1rem",
								}}
							>
								Closed 12:00 p.m. - 1:00 p.m.
							</Typography>
							<Typography
								component={"div"}
								variant="body1"
								sx={{ fontWeight: "300", fontSize: "1rem" }}
							>
								1:00 p.m. - 4:00 p.m.
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ height: "600px", backgroundColor: "#275D38" }}>
					<Typography
						component={"div"}
						variant="body2"
						color="white"
						style={{
							paddingTop: "60px",
							paddingLeft: "20vh",
							paddingRight: "20vh",
							fontSize: "1.75rem",
							lineHeight: "2.25rem",
							fontWeight: "100",
						}}
					>
						<span
							style={{
								fontSize: "4.5rem",
								color: "#F2CD00",
								fontWeight: "200",
								lineHeight: "5rem",
							}}
						>
							Territorial Acknowledgement
						</span>{" "}
						The University of Alberta, its buildings, labs, and research
						stations are primarily located on the traditional territory of Cree,
						Blackfoot, Métis, Nakota Sioux, Iroquois, Dene, and
						Ojibway/Saulteaux/Anishinaabe nations; lands that are now known as
						part of Treaties 6, 7, and 8 and homeland of the Métis. The
						University of Alberta respects the sovereignty, lands, histories,
						languages, knowledge systems, and cultures of First Nations, Métis
						and Inuit nations.
						<Divider
							sx={{
								backgroundColor: "white",
								marginTop: "45px",
								marginBottom: "85px",
								height: "3px",
							}}
						/>
					</Typography>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<img
							alt="logo"
							src={logo}
							loading="eager"
							style={{ maxWidth: "20rem", height: "4rem" }}
						/>
					</Box>
				</Box>
			</div>
		);
	}
}
