import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getUrgentRequisitions, getUrgentMidtermFeedback } from "../../Api/Getters";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import isAuthenticated from "../../Utils/isAuthenticated";

export default function ProfileMenu(props) {
	const auth = isAuthenticated();
	const token = auth.token;
	const access = auth.access;
	const adminUser = access.includes("ADMIN");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [urgentRequisitions, setUrgentRequisitions] = useState([]);
	const [urgentMidtermFeedback, setUrgentMidtermFeedback] = useState([]);
	const [incomingRequisitions, setIncomingRequisitions] = useState([]);
	const [incomingMidtermFeedback, setIncomingMidtermFeedback] = useState([]);
	const [badgeCountIncoming, setBadgeCountIncoming] = useState(0);
	const [badgeCountUrgent, setBadgeCountUrgent] = useState(0);
	const [urgentReqCount, setUrgentReqCount] = useState(0);
	const [urgentMidtermCount, setUrgentMidtermCount] = useState(0);
	const [incomingReqCount, setIncomingReqCount] = useState(0);
	const [incomingMidtermCount, setIncomingMidtermCount] = useState(0);
	const [openUrgentReq, setOpenUrgentReq] = useState(false);
	const [openUrgentMidterm, setOpenUrgentMidterm] = useState(false);
	const [openIncomingReq, setOpenIncomingReq] = useState(false);
	const [openIncomingMidterm, setOpenIncomingMidterm] = useState(false);
	const navigate = useNavigate();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event) => {
		if (event.target.innerText === "Logout") {
			props.Logout();
		}
		setAnchorEl(null);
	};

	useEffect(() => {
		let urgentReqCounter = 0;
		let incomingReqCounter = 0;
		let urgentMidtermCounter = 0;
		let incomingMidtermCounter = 0;
		if (props.reqNotificationData && props.reqNotificationData.urgent_requisitions) {
			const urgentReqNumber = props.reqNotificationData.urgent_requisitions.length;
			const incomingReqNumber = props.reqNotificationData.incoming_requisitions.length;
			setUrgentReqCount(urgentReqNumber);
			setIncomingReqCount(incomingReqNumber);
			urgentReqCounter = urgentReqNumber;
			incomingReqCounter = incomingReqNumber;
			setIncomingRequisitions(props.reqNotificationData.incoming_requisitions);
			setUrgentRequisitions(props.reqNotificationData.urgent_requisitions);
		}

		if (props.midtermNotificationData && props.midtermNotificationData.urgent_midterms) {
			const urgentMidtermFeedbackCount = props.midtermNotificationData.urgent_midterms.length;

			const incomingMidtermFeedbackCount = props.midtermNotificationData.incoming_midterms.length;

			setUrgentMidtermCount(urgentMidtermFeedbackCount);

			setIncomingMidtermCount(incomingMidtermFeedbackCount);
			urgentMidtermCounter = urgentMidtermFeedbackCount;
			incomingMidtermCounter = incomingMidtermFeedbackCount;
			setIncomingMidtermFeedback(props.midtermNotificationData.incoming_midterms);

			setUrgentMidtermFeedback(props.midtermNotificationData.urgent_midterms);
		}

		const badgeCountUrgent = urgentReqCounter + urgentMidtermCounter;
		const badgeCountIncoming = incomingReqCounter + incomingMidtermCounter;

		setBadgeCountIncoming(badgeCountIncoming);
		setBadgeCountUrgent(badgeCountUrgent);
	}, [props.reqNotificationData, props.midtermNotificationData]);

	const handleOpenUrgentReq = (event) => {
		event.stopPropagation();
		setOpenUrgentReq(!openUrgentReq);
	};

	const handleOpenIncomingReq = (event) => {
		event.stopPropagation();
		setOpenIncomingReq(!openIncomingReq);
	};

	const handleOpenUrgentMidterm = (event) => {
		event.stopPropagation();
		setOpenUrgentMidterm(!openUrgentMidterm);
	};

	const handleOpenIncomingMidterm = (event) => {
		event.stopPropagation();
		setOpenIncomingMidterm(!openIncomingMidterm);
	};

	const handleRouteTrackRequisitions = () => {
		navigate("/TrackRequisitions");
		setOpenUrgentMidterm(false);
		setOpenUrgentReq(false);
	};

	const handleRouteMidtermView = () => {
		navigate("/MidtermView");
		setOpenUrgentMidterm(false);
		setOpenUrgentReq(false);
	};

	if (adminUser) {
		return (
			<React.Fragment>
				<Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
					<Tooltip title="Notifications">
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								height: "50%",
								marginRight: ".5rem",
							}}
							aria-controls={open ? "account-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={open ? "true" : undefined}
						>
							<Badge
								badgeContent={badgeCountIncoming}
								color="warning"
								anchorOrigin={{
									vertical: "top",
									horizontal: "left",
								}}
							>
								<Badge
									badgeContent={badgeCountUrgent}
									color="error"
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
								>
									<NotificationsIcon fontSize="large" color="primary" />
								</Badge>
							</Badge>
						</IconButton>
					</Tooltip>
				</Box>
				<Menu
					anchorEl={anchorEl}
					id="notification-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					slotProps={{
						paper: {
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: "background.paper",
									transform: "translateY(-50%) rotate(45deg)",
									zIndex: 0,
								},
							},
						},
					}}
					transformOrigin={{ horizontal: "right", vertical: "top" }}
					anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				>
					{urgentReqCount > 0 ? (
						<MenuItem onClick={handleOpenUrgentReq}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<Typography color="error">{urgentReqCount} Urgent Requisitions</Typography>
								<ListItemIcon sx={{}}>
									{openUrgentReq ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
								</ListItemIcon>
							</Box>
						</MenuItem>
					) : null}
					{openUrgentReq ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								maxHeight: "250px",
								overflow: "auto",
							}}
						>
							<Divider />
							{urgentRequisitions.map((req) => {
								return (
									<Typography sx={{ p: 1, marginLeft: "20px" }} key={req.reqnum}>
										{req.reqnum} - {req.start_date}
									</Typography>
								);
							})}
						</Box>
					) : null}
					{incomingReqCount > 0 ? (
						<MenuItem onClick={handleOpenIncomingReq}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<Typography color="orange">{incomingReqCount} Incoming Requisitions</Typography>
								<ListItemIcon sx={{}}>
									{openIncomingReq ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
								</ListItemIcon>
							</Box>
						</MenuItem>
					) : null}
					{openIncomingReq ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								maxHeight: "250px",
								overflow: "auto",
							}}
						>
							<Divider />
							{incomingRequisitions.map((req) => {
								return (
									<Typography sx={{ p: 1, marginLeft: "20px" }} key={req.reqnum}>
										{req.reqnum} - {req.start_date}
									</Typography>
								);
							})}
						</Box>
					) : null}
					<MenuItem sx={{ color: "primary" }} onClick={handleRouteTrackRequisitions}>
						<ListItemIcon>
							<CallMissedOutgoingIcon />
						</ListItemIcon>
						Go to Requisitions
					</MenuItem>
					<Divider />
					{urgentMidtermCount > 0 ? (
						<MenuItem onClick={handleOpenUrgentMidterm}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<Typography color="error">{urgentMidtermCount} Urgent Midterm</Typography>
								<ListItemIcon sx={{}}>
									{openUrgentMidterm ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
								</ListItemIcon>
							</Box>
						</MenuItem>
					) : null}
					{openUrgentMidterm ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								maxHeight: "200px",
								overflow: "auto",
							}}
						>
							<Divider />
							{urgentMidtermFeedback.map((midterm) => {
								return (
									<Typography sx={{ p: 1, marginLeft: "20px" }} key={midterm.mt_id}>
										{midterm.mt_id} - {midterm.start_date}
									</Typography>
								);
							})}
						</Box>
					) : null}
					{incomingMidtermCount > 0 ? (
						<MenuItem onClick={handleOpenIncomingMidterm}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									alignContent: "center",
									alignItems: "center",
								}}
							>
								<Typography color="orange">{incomingMidtermCount} Incoming Midterm</Typography>
								<ListItemIcon sx={{}}>
									{openIncomingMidterm ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
								</ListItemIcon>
							</Box>
						</MenuItem>
					) : null}
					{openIncomingMidterm ? (
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								maxHeight: "250px",
								overflow: "auto",
							}}
						>
							<Divider />
							{incomingMidtermFeedback.map((midterm) => {
								return (
									<Typography sx={{ p: 1, marginLeft: "20px" }} key={midterm.mt_id}>
										{midterm.mt_id} - {midterm.start_date}
									</Typography>
								);
							})}
						</Box>
					) : null}
					<MenuItem sx={{ color: "primary" }} onClick={handleRouteMidtermView}>
						<ListItemIcon>
							<CallMissedOutgoingIcon />
						</ListItemIcon>
						Go to Midterm View
					</MenuItem>
				</Menu>
			</React.Fragment>
		);
	}
}
