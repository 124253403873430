import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import SingleItemCatalogTable from "../../../../components/SingleItemCatalogTable";
import ItemsTable from "../../../../components/ItemsTable";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "90vw",
	height: "95vh",
	bgcolor: "background.paper",
	border: "2px solid #275d38",
	borderRadius: 2,
	p: 2,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
};

export default function ModifyAddItemsModal(props) {
	return (
		<div>
			<Modal open={props.openAddItemsModal} onClose={() => props.setOpenAddItemsModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Box
						sx={{
							height: "100%",
							overflow: "none",
							marginBottom: "20px",
						}}
					>
						<Box sx={{ height: "5%" }}>
							<Typography fontSize={18} fontWeight={600} textAlign={"center"}>
								Add Items to Requisition
							</Typography>
						</Box>
						<Box sx={{ height: "60%" }}>
							<SingleItemCatalogTable chosenItems={props.chosenItems} setChosenItems={props.setChosenItems} />
						</Box>
						<Box sx={{ height: "30%" }}>
							<ItemsTable
								noModifications
								inModify={props.inModify}
								inModifySecond
								chosenItems={props.chosenItems}
								setChosenItems={props.setChosenItems}
								rowData={props.rowData}
								setOpenEditItems={props.setOpenEditItems}
								sortedChosenItems={props.sortedChosenItems}
								setSortedChosenItems={props.setSortedChosenItems}
								setModifiedItems={props.setModifiedItems}
							/>
						</Box>

						<Box
							sx={{
								outline: "1px solid yellow",
								height: "5%",
								marginTop: "10px",
							}}
						>
							<Button
								variant="contained"
								onClick={() => {
									props.setOpenAddItemsModal(false);
								}}
								sx={{
									width: "100%",
								}}
							>
								Done
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
