import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

export default function InstanceDialog(props) {
	const handleClose = () => {
		props.setOpen(false);
	};
	const handleCloseSame = () => {
		props.handleAddInstantClassSame();
		handleClose();
	};

	const handleCloseDifferent = () => {
		props.handleAddInstantClassDifferent();
		handleClose();
	};

	return (
		<div>
			<Dialog open={props.open} onClose={handleClose} maxWidth="lg" sx={{ textAlign: "center" }}>
				<DialogActions>
					<Button
						disabled={!props.selectedRow.roles || props.selectedRow.roles.length === 0}
						sx={{
							width: "50%",
							height: "100px",
							display: "flex",
							flexDirection: "column",
						}}
						variant="contained"
						onClick={handleCloseSame}
					>
						Use Same Instructor(s) as Original Class
					</Button>
					<Button
						sx={{
							width: "50%",
							height: "100px",
							display: "flex",
							flexDirection: "column",
						}}
						color="secondary"
						variant="contained"
						onClick={handleCloseDifferent}
					>
						Use Different Instructor(s)
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
