import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import "../../App.css";

export const Nav = styled.nav`
	background: #275d38;
	height: 80px;
	display: flex;
	/*justify-content: center;*/
	justify-content: space-between;
	padding-left: 1.5rem;
	padding-right: 1rem;
	// padding: 0.5rem calc((100vw - 1800px) / 2);
	// z-index: 10;
`;

export const Nav2 = styled.nav`
	background: #fff;
	height: 60px;
	display: flex;
	justify-content: center;
	/* justify-content: space-between; */
	/* padding-left: 1.5rem; */
	box-shadow: 0 10px 9px rgb(0 0 0 / 20%);
	// padding-right: 1rem;
	// padding: 0.5rem calc((100vw - 1800px) / 2);
	z-index: 10;
`;

export const NavLink = styled(Link)`
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 1.5rem;
	height: 100%;
	font-size: 20px;
	cursor: pointer;
	&.active {
		color: #fff;
	}
	&:hover {
		transition: all 0.2s ease-in-out;
		color: #fff;
	}
`;

export const NavLink2 = styled(Link)`
	color: #2b292a;
	display: flex;
	align-items: center;
	text-decoration: none;
	margin-left: 1rem;
	padding: 1rem;
	height: 100%;
	font-size: 1.125rem;
	font-weight: 500;
	cursor: pointer;
	&.active {
		// color: #F2CD00;
		text-decoration: underline;
		color: #2b292a;
	}
	&:hover {
		transition: all 0.2s ease-in-out;
		text-decoration: underline;
		color: #2b292a;
	}
`;

export const Bars = styled(FaBars)`
	display: none;
	color: #fff;
	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 75%);
		font-size: 1.8rem;
		cursor: pointer;
	}
`;

export const NavMenu = styled.div`
	display: flex;
	align-items: center;
	// margin-left: -24px;
	/* Second Nav */
	// margin-right: 24px;
	/* Third Nav */
	/*width: 100vw;*/
	// white-space: nowrap;
`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	// margin-right: 4px;
	justify-content: flex-end;
	white-space: nowrap;
`;

export const NavBtnLink = styled.nav`
	border-radius: 114px;
	/*background: #256ce1;*/
	padding: 10px 22px;
	color: #fff;
	font-size: 18px;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	/* Second Nav */
	margin-left: 24px;
	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #275d38;
	}
`;

export const SideBarBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 54px;
  justify-content: flex-end;
  white-space: nowrap;
  color: #fff;
  font-size: 35px;
  cursor: pointer;
  &.active {
    color: #F2CD00;
 
  
`;
